import React from 'react'
import { Route } from 'react-router-dom'
import AddProjectForm from '../pages/AddProjectForm'
import ProjectSortedList from './ProjectSortedList'
import HiddenProjectList from './HiddenProjectList'

const ProjectUI = () => {
  return (
    <>
      <Route path="/projects/add" exact>
        {({ match }) => (
          <>
            <AddProjectForm show={match !== null} />
          </>
        )}
      </Route>
      <Route
        path="/(projects|projects/add)"
        exact
        render={() => [
          <ProjectSortedList key={'projects'} />,
          <HiddenProjectList key={'hiddenProjects'} />
        ]}
      />
    </>
  )
}

export default ProjectUI
