import { default as UsersComponent } from './components/UserList/UserList'
import { connect } from 'react-redux'
import { users, userErrors } from './reducers/selectors'
import { deleteUser } from './actions'
import { enhanceWithSpinner } from '../../../../components/Spinner'

const UsersWithSpinner = enhanceWithSpinner(
  ({ users }) => users === null || users === undefined,
  UsersComponent
)

const mapStateToProps = state => {
  return {
    users: users(state),
    errors: userErrors(state)
  }
}

const Users = connect(
  mapStateToProps,
  {
    deleteUser
  }
)(UsersWithSpinner)

export default Users
