import {
  PROJECTS_EDIT_IMAGE_SET_SUCCESS,
  PROJECT_IMAGES_SECTION_IMAGE_META_SUCCESS
} from '../actions'
import updateProject from '../../../../../reducers/updateProject'

const editImageSetReducer = {
  [PROJECT_IMAGES_SECTION_IMAGE_META_SUCCESS]: updateProject(
    (project, action) => {
      const {
        payload: { url, name },
        meta: { imageType, id }
      } = action
      const imageSetKey = 'projectListImages'
      const imageSets = project.imagesSection[imageSetKey]
      const position = imageSets.findIndex(t => t.id === id)
      const imageSet = imageSets.find(t => t.id === id)
      const image = {
        ...imageSet[imageType],
        url,
        name
      }
      const newImageSet = {
        ...imageSets[position],
        [imageType]: image
      }
      const newImageSets = imageSets.map(imageSet => {
        if (imageSet.id === id) {
          return newImageSet
        }
        return imageSet
      })
      const newProject = {
        ...project,
        imagesSection: {
          ...project.imagesSection,
          [imageSetKey]: newImageSets
        }
      }
      return newProject
    }
  ),
  [PROJECTS_EDIT_IMAGE_SET_SUCCESS]: updateProject((project, action) => {
    const {
      meta: { id },
      payload
    } = action
    const imageSetKey = 'projectListImages'
    const imageSets = project.imagesSection[imageSetKey]

    const newImageSets = imageSets.map(imageSet => {
      if (imageSet.id === id) {
        return payload
      }
      return imageSet
    })
    const newProject = {
      ...project,
      imagesSection: {
        ...project.imagesSection,
        [imageSetKey]: newImageSets
      }
    }
    return newProject
  })
}

export default editImageSetReducer
