import React from 'react'
import PropTypes from 'prop-types'
import DocumentsForm from './DocumentsForm'
import { ProjectConsumer } from '../../../ProjectContext'

const DocumentsPage = props => {
  const { documentsSection, submit } = props
  return (
    <ProjectConsumer>
      {project => {
        const projectId = project.id
        return (
          <DocumentsForm
            documentsSection={documentsSection}
            projectId={projectId}
            submit={submit}
          />
        )
      }}
    </ProjectConsumer>
  )
}

DocumentsPage.propTypes = {
  projectId: PropTypes.number.isRequired,
  documentsSection: PropTypes.object
}

export default DocumentsPage
