import { default as editTabSagaRoute } from './editTabSagaRoute'
import createPublicSpacesTypeSagaRoute from '../pages/CreatePublicSpacesType/sagas'
import editPublicSpacesTypeSagaRoute from '../pages/EditPublicSpacesType/sagas'

export {
  default as watchPublicSpacesTabSubmitted
} from './watchPublicSpacesTabSubmitted'

export default {
  ...editTabSagaRoute,
  ...editPublicSpacesTypeSagaRoute,
  ...createPublicSpacesTypeSagaRoute
}
