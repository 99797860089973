import { connect } from 'react-redux'
import { submit } from './actions'
import { default as EditObjectForm } from './components/EditObjectForm'

const mapStateToProps = state => {
  const { objectTypes = [] } = state.objects
  return {
    objectTypes
  }
}

export default connect(
  mapStateToProps,
  { submit }
)(EditObjectForm)
