import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const PROJECT_ADVANTAGES_CREATE_ADVANTAGE_SUBMIT_REQUEST =
  'PROJECT_ADVANTAGES_CREATE_ADVANTAGE_SUBMIT_REQUEST'
export const PROJECT_ADVANTAGES_CREATE_ADVANTAGE_SUBMIT_SUCCESS =
  'PROJECT_ADVANTAGES_CREATE_ADVANTAGE_SUBMIT_SUCCESS'
export const PROJECT_ADVANTAGES_CREATE_ADVANTAGE_SUBMIT_FAILURE =
  'PROJECT_ADVANTAGES_CREATE_ADVANTAGE_SUBMIT_FAILURE'

export const submit = (projectId, advantage) => {
  const success = {
    type: PROJECT_ADVANTAGES_CREATE_ADVANTAGE_SUBMIT_SUCCESS,
    meta: {
      projectId,
      advantage
    }
  }
  const formData = objectToFormData(advantage)
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/advantages/add`,
      method: 'post',
      headers: {},
      body: formData,
      types: [
        PROJECT_ADVANTAGES_CREATE_ADVANTAGE_SUBMIT_REQUEST,
        success,
        PROJECT_ADVANTAGES_CREATE_ADVANTAGE_SUBMIT_FAILURE
      ]
    }
  }
}
