import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const PROJECT_ARCHITECTURE_SECTION_REQUEST =
  'PROJECT_ARCHITECTURE_SECTION_REQUEST'
export const PROJECT_ARCHITECTURE_SECTION_SUCCESS =
  'PROJECT_ARCHITECTURE_SECTION_SUCCESS'
export const PROJECT_ARCHITECTURE_SECTION_FAILURE =
  'PROJECT_ARCHITECTURE_SECTION_FAILURE'

export const fetchSection = projectId => {
  const success = {
    type: PROJECT_ARCHITECTURE_SECTION_SUCCESS,
    meta: {
      projectId
    }
  }
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/architecture`,
      method: 'get',
      types: [
        PROJECT_ARCHITECTURE_SECTION_REQUEST,
        success,
        PROJECT_ARCHITECTURE_SECTION_FAILURE
      ]
    }
  }
}

export const PROJECT_ARCHITECTURE_SECTION_SUBMIT_REQUEST =
  'PROJECT_ARCHITECTURE_SECTION_SUBMIT_REQUEST'
export const PROJECT_ARCHITECTURE_SECTION_SUBMIT_SUCCESS =
  'PROJECT_ARCHITECTURE_SECTION_SUBMIT_SUCCESS'
export const PROJECT_ARCHITECTURE_SECTION_SUBMIT_FAILURE =
  'PROJECT_ARCHITECTURE_SECTION_SUBMIT_FAILURE'

export const submit = (projectId, architectureSection) => {
  const meta = {
    projectId,
    architectureSection
  }
  const request = {
    type: PROJECT_ARCHITECTURE_SECTION_SUBMIT_REQUEST,
    meta
  }
  const success = {
    type: PROJECT_ARCHITECTURE_SECTION_SUBMIT_SUCCESS,
    meta
  }
  const { tabs = [], ...rest } = architectureSection
  const items = tabs.map(t => t.id)
  const form = {
    ...rest,
    items
  }
  const formData = objectToFormData(form)
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/architecture`,
      method: 'put',
      headers: {},
      body: formData,
      types: [request, success, PROJECT_ARCHITECTURE_SECTION_SUBMIT_FAILURE]
    }
  }
}

export const PROJECT_ARCHITECTURE_SECTION_PIN_IMAGE_META_REQUEST =
  'PROJECT_ARCHITECTURE_ARCHITECTURE_PIN_IMAGE_META_REQUEST'
export const PROJECT_ARCHITECTURE_SECTION_PIN_IMAGE_META_SUCCESS =
  'PROJECT_ARCHITECTURE_SECTION_PIN_IMAGE_META_SUCCESS'
export const PROJECT_ARCHITECTURE_SECTION_PIN_IMAGE_META_FAILURE =
  'PROJECT_ARCHITECTURE_SECTION_PIN_IMAGE_META_FAILURE'

export const fetchPinImageMetaData = (projectId, iconType, imageType, url) => {
  const meta = {
    projectId,
    iconType,
    imageType
  }
  const success = {
    type: PROJECT_ARCHITECTURE_SECTION_PIN_IMAGE_META_SUCCESS,
    meta
  }
  const failure = {
    type: PROJECT_ARCHITECTURE_SECTION_PIN_IMAGE_META_FAILURE,
    meta
  }

  return {
    [RSAA]: {
      endpoint: url,
      method: 'get',
      types: [
        PROJECT_ARCHITECTURE_SECTION_PIN_IMAGE_META_REQUEST,
        success,
        failure
      ]
    }
  }
}
