import {
  PROJECT_TEAM_EDIT_OBJECT_SUBMIT_SUCCESS,
  PROJECT_TEAM_EDIT_OBJECT_SUBMIT_FAILURE,
  PROJECT_TEAM_SECTION_OBJECT_PIN_IMAGE_META_SUCCESS
} from '../actions'
import updateProject from '../../../../../reducers/updateProject'

const updateObject = reducer =>
  updateProject((project, action) => {
    const { objectId } = action.meta
    const objects = project.teamSection.teamObjects.map(
      teamObject =>
        teamObject.id === objectId
          ? {
              ...teamObject,
              ...reducer(teamObject, action)
            }
          : teamObject
    )

    return {
      ...project,
      teamSection: {
        ...project.teamSection,
        teamObjects: objects
      }
    }
  })

const editObjectReducer = {
  [PROJECT_TEAM_EDIT_OBJECT_SUBMIT_SUCCESS]: updateObject(
    (_teamObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_TEAM_EDIT_OBJECT_SUBMIT_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  [PROJECT_TEAM_SECTION_OBJECT_PIN_IMAGE_META_SUCCESS]: updateObject(
    (teamObject, { payload, meta }) => {
      const { imageType } = meta
      return {
        ...teamObject,
        [imageType]: {
          ...teamObject[imageType],
          ...payload
        }
      }
    }
  )
}

export default editObjectReducer
