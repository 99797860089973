import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Form } from 'react-final-form'
import { Transition } from 'react-transition-group'
import { TweenMax } from 'gsap/all'
import {
  required,
  formWithError,
  email,
  validate,
  latinAndNumbers
} from '../../../../../../../components/Validation'
import './addUserForm.css'
import { FormField } from '../../../../../../../components/Form'

class AddUserForm extends PureComponent {
  onSubmit = form => {
    const { createUser } = this.props
    createUser(form)
  }

  render() {
    const startState = { autoAlpha: 0, y: -120 }
    const { user } = this.props
    return (
      <Transition
        unmountOnExit
        in={this.props.show}
        timeout={1000}
        onEnter={node => TweenMax.set(node, startState)}
        addEndListener={(node, done) => {
          TweenMax.to(node, 0.5, {
            autoAlpha: this.props.show ? 1 : 0,
            y: this.props.show ? 0 : -120,
            onComplete: done
          })
        }}
      >
        <Form
          initialValues={user}
          onSubmit={this.onSubmit}
          render={({ handleSubmit, submitting, pristine }) => (
            <form onSubmit={handleSubmit} className="addUserForm">
              <div className="addUserForm__inputWrapper">
                <FormField
                  name="firstName"
                  type="text"
                  label="Создать пользователя"
                  placeholder="Имя"
                  validate={required}
                  className="form-control form-control-sm "
                />
              </div>
              <div className="addUserForm__inputWrapper">
                <FormField
                  name="lastName"
                  type="text"
                  label=""
                  placeholder="Фамилия"
                  validate={required}
                  className="form-control form-control-sm "
                />
              </div>
              <div className="addUserForm__inputWrapper">
                <FormField
                  name="login"
                  type="text"
                  label=""
                  placeholder="Логин"
                  validate={validate(required, latinAndNumbers)}
                  className="form-control form-control-sm "
                />
              </div>
              <div className="addUserForm__inputWrapper">
                <FormField
                  name="email"
                  type="text"
                  label=""
                  placeholder="Email"
                  validate={validate(required, email)}
                  className="form-control form-control-sm "
                />
              </div>
              <div className="buttons">
                <button
                  className="btn btn-sm btn-success"
                  type="submit"
                  disabled={submitting || pristine}
                >
                  Сохранить
                </button>
                <Link
                  to="/users"
                  className="btn btn-sm btn-danger"
                  disabled={submitting}
                >
                  Отменить
                </Link>
              </div>
            </form>
          )}
        />
      </Transition>
    )
  }
}

AddUserForm.propTypes = {
  createUser: PropTypes.func.isRequired,
  error: PropTypes.array
}

export default formWithError(AddUserForm)
