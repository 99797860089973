export const userProp = state => state.user

export const isAuthenticatedProp = state => userProp(state) !== null

export const tokenProp = state => {
  const user = userProp(state)
  return user !== null &&
    user !== undefined &&
    user.token !== null &&
    user.token !== undefined
    ? user.token.access_token
    : null
}

export const refreshTokenProp = state => {
  const user = userProp(state)
  return user !== null &&
    user !== undefined &&
    user.token !== null &&
    user.token !== undefined
    ? user.token.refresh_token
    : null
}

export const expiresInProp = state => {
  const user = userProp(state)
  return user !== null &&
    user !== undefined &&
    user.token !== null &&
    user.token !== undefined
    ? user.token.expires_in
    : null
}

export const rolesProp = state => {
  const user = userProp(state)
  return user !== null && user !== undefined ? user.roles : null
}
