import { RSAA } from 'redux-api-middleware'

export const PROJECT_CONSTRUCTION_PROGRESS_REQUEST =
  'PROJECT_CONSTRUCTION_PROGRESS_REQUEST'
export const PROJECT_CONSTRUCTION_PROGRESS_SUCCESS =
  'PROJECT_CONSTRUCTION_PROGRESS_SUCCESS'
export const PROJECT_CONSTRUCTION_PROGRESS_FAILURE =
  'PROJECT_CONSTRUCTION_PROGRESS_FAILURE'

export const fetchSection = projectId => {
  const success = {
    type: PROJECT_CONSTRUCTION_PROGRESS_SUCCESS,
    meta: {
      projectId
    }
  }
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/construction`,
      method: 'get',
      types: [
        PROJECT_CONSTRUCTION_PROGRESS_REQUEST,
        success,
        PROJECT_CONSTRUCTION_PROGRESS_FAILURE
      ]
    }
  }
}

export const PROJECT_CONSTRUCTION_PROGRESS_SUBMIT_REQUEST =
  'PROJECT_CONSTRUCTION_PROGRESS_SUBMIT_REQUEST'
export const PROJECT_CONSTRUCTION_PROGRESS_SUBMIT_SUCCESS =
  'PROJECT_CONSTRUCTION_PROGRESS_SUBMIT_SUCCESS'
export const PROJECT_CONSTRUCTION_PROGRESS_SUBMIT_FAILURE =
  'PROJECT_CONSTRUCTION_PROGRESS_SUBMIT_FAILURE'

export const submit = (projectId, constructionProgressSection) => {
  const success = {
    type: PROJECT_CONSTRUCTION_PROGRESS_SUBMIT_SUCCESS,
    meta: {
      projectId,
      constructionProgressSection
    }
  }
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/construction`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(constructionProgressSection),
      types: [
        PROJECT_CONSTRUCTION_PROGRESS_SUBMIT_REQUEST,
        success,
        PROJECT_CONSTRUCTION_PROGRESS_SUBMIT_FAILURE
      ]
    }
  }
}
