import { connect } from 'react-redux'
import { submit } from '../actions'
import ImagesSection from './ImagesSection'
import { formWithError } from '../../../../../../../../../components/Validation'

export default connect(
  null,
  {
    submit
  }
)(formWithError(ImagesSection))
