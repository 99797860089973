import { connect } from 'react-redux'
import { default as HomeComponent } from './Home'
import { userProp } from '../../Login/reducers/selectors'
import { loggedOutUser } from '../../Login/actions'

export { default as PageTitle } from './PageTitle'
export { createButtonComponent } from './Buttons'
export { default as ImageSet, ImageSetFormField } from './ImageSet'
export { default as Select } from './Select'
export {
  default as DocumentUploadFormField
} from './Form/DocumentUploadFormField'

const mapStateToProps = state => {
  return {
    user: userProp(state)
  }
}

const Home = connect(
  mapStateToProps,
  {
    logout: loggedOutUser
  }
)(HomeComponent)

export default Home
