import { call, select } from 'redux-saga/effects'
import fetchProjectsSaga from './fetchProjectsSaga'
import { projectProp } from '../reducers/selectors'

function* fetchProjectSaga(projectId) {
  let project = yield select(projectProp(projectId))
  if (project) {
    return project
  }

  const projects = yield call(fetchProjectsSaga)
  if (projects) {
    project = yield select(projectProp(projectId))
    return project
  }

  return null
}

export default fetchProjectSaga
