import {
  PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_SUBMIT_SUCCESS,
  PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_SUBMIT_FAILURE
} from '../actions'
import updateProject from '../../../../../reducers/updateProject'
import moment from 'moment'

const createGalleryReducer = {
  [PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_SUBMIT_SUCCESS]: updateProject(
    (project, { payload }) => {
      const section = project.constructionProgressSection
      const { galleries = [] } = section
      const newGalleries = [...galleries, payload]
      newGalleries.sort((left, right) => {
        return moment.utc(right.date).diff(moment.utc(left.date))
      })
      const newSection = {
        ...section,
        galleries: newGalleries
      }
      const newProject = {
        ...project,
        constructionProgressSection: newSection
      }
      return newProject
    }
  ),
  [PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_SUBMIT_FAILURE]: (
    state,
    action
  ) => {
    const { payload: { response } = {} } = action
    return {
      ...state,
      error: response
    }
  }
}

export default createGalleryReducer
