import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import {
  required,
  formWithError
} from '../../../../../../../../../../../../components/Validation'
import {
  FormField,
  TextareaField
} from '../../../../../../../../../../../../components/Form'
import {
  SmallIconImageFormField,
  ImageSetArrayFormField
} from '../../../../../../../../../../components/Form'
import { SubmitButton } from '../../../../../../../../../../components/Buttons'
import './mortgageForm.css'

const focusOnErrors = createDecorator()

const onSubmit = (projectId, advantageId, submit) => advantage => {
  submit(projectId, advantageId, advantage)
}

const createEditImageSetUrl = (projectId, galleryId) => id => {
  return `/projects/${projectId}/advantages/${galleryId}/images/${id}`
}

const EditAdvantageForm = ({ projectId, advantage, submit }) => {
  return (
    <Form
      initialValues={advantage}
      mutators={{
        ...arrayMutators
      }}
      onSubmit={onSubmit(projectId, advantage.id, submit)}
      decorators={[focusOnErrors]}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <Link
                  to={`/projects/${projectId}/advantages`}
                  className="formField__back"
                >
                  Назад
                </Link>
              </div>
            </div>
            <hr className="formField__separator" />
            <div className="row">
              <div className="col">
                <h4>Преимущество</h4>
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <FormField
                  name="title"
                  validate={required}
                  type="text"
                  label="Заголовок"
                  placeholder="Заголовок"
                />
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <TextareaField
                  name="description"
                  validate={required}
                  type="text"
                  label="Описание"
                  placeholder="Описание"
                />
              </div>
            </div>
            <SmallIconImageFormField form={form} />
            <hr className="formField__separator" />
            <div className="row">
              <div className="col">
                <h4>Галерея</h4>
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <ImageSetArrayFormField
                  form={form}
                  addImageSetUrl={`/projects/${projectId}/advantages/${
                    advantage.id
                  }/images/add`}
                  editImageSetUrl={createEditImageSetUrl(
                    projectId,
                    advantage.id
                  )}
                />
              </div>
            </div>
            <SubmitButton
              pristine={pristine}
              submitSucceeded={submitSucceeded}
              dirtySinceLastSubmit={dirtySinceLastSubmit}
            />
          </form>
        )
      }}
    />
  )
}

EditAdvantageForm.propTypes = {
  projectId: PropTypes.number.isRequired,
  advantage: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired
}

export default formWithError(EditAdvantageForm)
