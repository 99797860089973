import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '../../../components/Tabs'
import { ByDate } from '../../../components/Filters'
import Lines from '../../../components/Charts/Lines'
import Doughnut from '../../../components/Charts/Doughnut'
import Legend from '../../../components/Charts/Legend'
import '../../../components/Charts/charts.css'
import Spinner from '../../../../../../../components/Spinner'

const groupBy = (items, groupByKey, xKey, yKey) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[groupByKey]]: [
        ...(result[item[groupByKey]] || []),
        xKey ? { x: item[xKey], y: item[yKey] } : item
      ]
    }),
    {}
  )

const Rates = ({ data = [], loading, filters = {}, filterChange }) => {
  const labels = {
    isNotRegistered: 'Без регистрации',
    hasProfile: 'С анкетой без договора',
    hasContract: 'С договором и анкетой'
  }

  const dataByType = data
    .map(item => {
      const type = !item.isRegistered
        ? 'isNotRegistered'
        : !item.hasContract
          ? 'hasProfile'
          : 'hasContract'
      return {
        date: item.date,
        rate: item.rate,
        type
      }
    })
    .sort(
      (a, b) =>
        Object.keys(labels).indexOf(a.type) -
        Object.keys(labels).indexOf(b.type)
    )

  const linesData = groupBy(dataByType, 'type', 'date', 'rate')

  const legendsData = Object.keys(labels).map(key => {
    const value = linesData[key]
      ? linesData[key].reduce((result, item) => {
          return result + item.y
        }, 0) / linesData[key].length
      : 0

    return {
      name: labels[key],
      value: Math.round(value * 100) / 100
    }
  })

  const dataByRate = groupBy(dataByType, 'rate')

  const average = data.length
    ? data.reduce((result, item) => result + item.rate, 0) / data.length
    : 0

  const averageWeight = data.length
    ? Object.keys(dataByRate).reduce((result, key) => {
        return result + (key * dataByRate[key].length) / data.length
      }, 0) /
      Object.keys(dataByRate).reduce((result, key) => {
        return result + dataByRate[key].length / data.length
      }, 0)
    : 0

  const legendsDataSum = [
    ...legendsData,
    {
      name: 'Средневзвешенная оценка приложения',
      value: Math.round(averageWeight * 100) / 100,
      color: 'black',
      valueWithStars: true
    }
  ]

  const totalSum = [
    {
      name: 'Общее количество оценок',
      value: data.length,
      color: 'black'
    },
    {
      name: 'Средняя оценка приложения',
      value: Math.round(average * 100) / 100,
      color: 'black'
    },
    {
      name: 'Средневзвешенная оценка приложения',
      value: Math.round(averageWeight * 100) / 100,
      color: 'black'
    }
  ]

  const doughnutData = Object.keys(dataByRate).map(key => ({
    name: key,
    value: dataByRate[key].length
  }))

  return (
    <>
      <Tabs />
      <div className="pageWrapper">
        <ByDate
          options={{
            day: 'День',
            isoWeek: 'Неделя',
            month: 'Месяц',
            quarter: 'Квартал',
            year: 'Год'
          }}
          filterChange={filterChange}
          filters={filters}
        />
        <hr className="formField__separator" />
        {loading ? (
          <Spinner />
        ) : (
          <div className="charts__wrapper">
            <h4 className="charts__title">Общее количество оценок</h4>
            <div className="row chart">
              <div className="col-6">
                <Doughnut data={doughnutData} showSum={true} />
              </div>
              <div className="col-6">
                <Legend
                  data={doughnutData}
                  labelStars={true}
                  head={['Оценка', 'Количество, шт.']}
                />
              </div>
            </div>
            <div className="row">
              <div className="offset-6 col-6">
                <Legend data={totalSum} size={'big'} />
              </div>
            </div>
            <hr className="formField__separator" />
            <h4 className="charts__title">Оценки пользователей</h4>
            <div className="row chart">
              <div className="col">
                <Lines
                  data={linesData}
                  labels={labels}
                  tooltipWithStars={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Legend
                  data={legendsDataSum}
                  size={'big'}
                  head={['Статус пользователей', 'Средняя оценка']}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

Rates.propTypes = {
  data: PropTypes.array.isRequired,
  filters: PropTypes.object,
  filterChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default Rates
