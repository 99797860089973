import { RATES_SUCCESS, RATES_FILTER_CHANGE, RATES_REQUEST } from '../actions'
import moment from 'moment'

const initialState = {
  filters: {
    start: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    activePeriod: 'month'
  },
  data: null,
  loading: false
}

const mergedReducer = {
  [RATES_REQUEST]: (state = initialState, action) => {
    return {
      ...state,
      loading: true
    }
  },
  [RATES_SUCCESS]: (state = initialState, action) => {
    return {
      ...state,
      data: action.payload,
      loading: false
    }
  },
  [RATES_FILTER_CHANGE]: (state, action) => {
    const newFilter = {
      ...state.filters,
      ...action.payload
    }
    return {
      ...state,
      filters: {
        ...newFilter
      }
    }
  }
}

const ratesReducer = (state = initialState, action) => {
  let actionReducer = mergedReducer[action.type]
  if (actionReducer) {
    return actionReducer(state, action)
  }

  return state
}

export default ratesReducer
