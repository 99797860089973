import {
  fetchProjectAboutSection,
  PROJECT_ABOUT_SECTION_SUCCESS,
  PROJECT_ABOUT_SECTION_FAILURE
} from '../actions'
import createSectionSaga from '../../../sagas/createSectionSaga'

export default createSectionSaga(
  fetchProjectAboutSection,
  [PROJECT_ABOUT_SECTION_SUCCESS, PROJECT_ABOUT_SECTION_FAILURE],
  project => project.aboutSection,
  {
    sectionName: 'О проекте'
  }
)
