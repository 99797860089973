import { call } from 'redux-saga/effects'
import fetchAccomplishmentTabSaga from './fetchAccomplishmentTabSaga'

const editTabSagaRoute = {
  '/projects/:projectId/accomplishment/tabs/:tabId': function* runEditAccomplishmentTabSaga({
    projectId,
    tabId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const tabIdNumber = parseInt(tabId, 10)
    yield call(fetchAccomplishmentTabSaga, projectIdNumber, tabIdNumber)
  }
}

export default editTabSagaRoute
