import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './publicationError.css'

class PublicationError extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showError: false
    }
  }

  toggleError = () => {
    this.setState({ showError: !this.state.showError })
  }

  render() {
    const {
      error: { path, error }
    } = this.props
    const { showError } = this.state
    const cutError = showError ? error : `${error.substring(0, 180)}...`
    const closedClass = showError ? '' : 'publicationError--closed'
    return (
      <div
        className={`publicationError ${closedClass}`}
        onClick={this.toggleError}
      >
        <p> {path} </p>
        <p> {cutError} </p>
      </div>
    )
  }
}

PublicationError.propTypes = {
  error: PropTypes.object.isRequired
}

export default PublicationError
