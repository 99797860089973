import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { SortableContainer } from 'react-sortable-hoc'
import SortableItem from './SortableItem'
import './projectSortedList.css'

const SortableList = SortableContainer(({ projects, isSorting, ...rest }) => {
  const isSortingClass = isSorting ? 'sorting' : ''
  return (
    <div className={`projectSortedList no-after ${isSortingClass}`}>
      {projects.map((project, index) => (
        <SortableItem
          key={project.id}
          index={index}
          project={project}
          {...rest}
        />
      ))}
    </div>
  )
})

class ProjectSortedList extends PureComponent {
  state = {
    isSorting: false
  }

  onSortStart = () => {
    this.setState({ isSorting: true })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { move, projects } = this.props
    const curProject = projects[oldIndex]
    move(curProject.id, oldIndex, newIndex)
    this.setState({ isSorting: false })
  }

  render() {
    const { projects = [], ...rest } = this.props
    return (
      <SortableList
        projects={projects}
        onSortEnd={this.onSortEnd}
        onSortStart={this.onSortStart}
        isSorting={this.state.isSorting}
        distance={1}
        {...rest}
      />
    )
  }
}

ProjectSortedList.propTypes = {
  projects: PropTypes.array.isRequired,
  deleteProject: PropTypes.func.isRequired,
  hideProject: PropTypes.func.isRequired
}

export default ProjectSortedList
