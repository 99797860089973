import React from 'react'
import PropTypes from 'prop-types'
import { PageTitle } from '../../../../components'
import { Route } from 'react-router-dom'
import PublicationItem from './PublicationItem'
import './publicationTree.css'

const flat = section => {
  const acc = []

  const addPath = parent => {
    acc.push(parent.path)
    const { children = [] } = parent
    children.forEach(child => addPath(child, acc))
  }

  addPath(section)

  return acc
}

class PublicationTree extends React.Component {
  constructor(props) {
    super(props)
    this.state = { selected: [] }
  }

  select = section => {
    const paths = flat(section)
    const selected = [
      ...this.state.selected,
      ...paths.filter(path => this.state.selected.indexOf(path) === -1)
    ]
    this.setState({ selected })
  }

  unselect = section => {
    const paths = flat(section)
    const selected = this.state.selected.filter(
      path => paths.indexOf(path) === -1
    )
    this.setState({ selected })
  }

  refresh = section => {
    const { selected = [] } = this.state
    const isSelected = selected.indexOf(section.path) !== -1
    if (isSelected) {
      this.unselect(section)
    } else {
      this.select(section)
    }
  }

  publicate = () => {
    const { selected = [] } = this.state
    this.props.publicate(selected)
    this.setState({ selected: [] })
  }

  render() {
    const { section } = this.props
    const { hasChanges, children = [], isRunning, isChecking } =
      section !== null ? section : {}

    const pubList = children.filter(section => section.path).map(section => {
      return (
        <PublicationItem
          key={section.path}
          section={section}
          refresh={this.refresh}
          selected={this.state.selected}
        />
      )
    })
    const disabledClass =
      isRunning || !hasChanges || this.state.selected.length === 0
        ? 'disabled'
        : ''
    const disabledClass1 = isRunning || isChecking ? 'disabled' : ''
    return (
      <div>
        <PageTitle
          title={() => {
            return <h2>Публикация</h2>
          }}
        >
          <Route
            exact
            path="/publications"
            render={() => {
              return (
                <div
                  className={`btn btn-sm btn-warning ${disabledClass1}`}
                  onClick={this.props.checkHasChanges}
                >
                  Проверить изменения
                </div>
              )
            }}
          />
          <Route
            exact
            path="/publications"
            render={() => {
              return (
                <div
                  className={`btn btn-sm btn-warning ${disabledClass}`}
                  onClick={this.publicate}
                >
                  {isRunning
                    ? isChecking
                      ? 'Проверка изменений'
                      : 'Обновляется'
                    : 'Обновить'}
                </div>
              )
            }}
          />
        </PageTitle>
        {pubList}
      </div>
    )
  }
}

PublicationTree.propTypes = {
  section: PropTypes.object
}

export default PublicationTree
