import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { Transition } from 'react-transition-group'
import { TweenMax } from 'gsap/all'
import {
  required,
  formWithError
} from '../../../../../../../components/Validation'
import './addProjectForm.css'

class AddProjectForm extends PureComponent {
  onSubmit = form => {
    const { createProject } = this.props
    createProject(form)
  }

  render() {
    const startState = { autoAlpha: 0, y: -120 }
    return (
      <Transition
        unmountOnExit
        in={this.props.show}
        timeout={1000}
        onEnter={node => TweenMax.set(node, startState)}
        addEndListener={(node, done) => {
          TweenMax.to(node, 0.5, {
            autoAlpha: this.props.show ? 1 : 0,
            y: this.props.show ? 0 : -120,
            onComplete: done
          })
        }}
      >
        <Form
          onSubmit={this.onSubmit}
          render={({ handleSubmit, submitting, pristine }) => (
            <form onSubmit={handleSubmit} className="addProjectForm">
              <Field name="name" validate={required}>
                {({ input }) => (
                  <div className="addProjectForm__inputWrapper">
                    <label className="formField__label">Добавить проект</label>
                    <input
                      {...input}
                      type="text"
                      placeholder="Название"
                      className="form-control form-control-sm "
                    />
                  </div>
                )}
              </Field>
              <div className="buttons">
                <button
                  className="btn btn-sm btn-success"
                  type="submit"
                  disabled={submitting || pristine}
                >
                  Сохранить
                </button>
                <Link
                  to="/projects"
                  className="btn btn-sm btn-danger"
                  disabled={submitting}
                >
                  Отменить
                </Link>
              </div>
            </form>
          )}
        />
      </Transition>
    )
  }
}

AddProjectForm.propTypes = {
  createProject: PropTypes.func.isRequired,
  error: PropTypes.object
}

export default formWithError(AddProjectForm)
