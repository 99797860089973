import { connect } from 'react-redux'
import { Objects as ObjectsComponent } from './components/Objects'
import { objects } from './reducers/selectors'
import { enhanceWithSpinner } from '../../../../components'
import { submitObjects } from './actions'

const ObjectsWithSpinner = enhanceWithSpinner(
  ({ objects }) => objects === null || objects === undefined,
  ObjectsComponent
)

const mapStateToProps = state => {
  return {
    objects: objects(state)
  }
}

const Objects = connect(
  mapStateToProps,
  {
    submit: submitObjects
  }
)(ObjectsWithSpinner)

export default Objects
