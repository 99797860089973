import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_ADVANTAGES_CREATE_ADVANTAGE_SUBMIT_SUCCESS } from '../actions'

function* watchCreateAdvantageSaga(projectId) {
  while (true) {
    const {
      payload: { id }
    } = yield take(PROJECT_ADVANTAGES_CREATE_ADVANTAGE_SUBMIT_SUCCESS)
    yield put(push(`/projects/${projectId}/advantages/${id}`))
  }
}

export default watchCreateAdvantageSaga
