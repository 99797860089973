import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const PROJECT_LOCATION_CREATE_OBJECT_SUBMIT_REQUEST =
  'PROJECT_LOCATION_CREATE_OBJECT_SUBMIT_REQUEST'
export const PROJECT_LOCATION_CREATE_OBJECT_SUBMIT_SUCCESS =
  'PROJECT_LOCATION_CREATE_OBJECT_SUBMIT_SUCCESS'
export const PROJECT_LOCATION_CREATE_OBJECT_SUBMIT_FAILURE =
  'PROJECT_LOCATION_CREATE_OBJECT_SUBMIT_FAILURE'

export const submit = (projectId, locationObject) => {
  const success = {
    type: PROJECT_LOCATION_CREATE_OBJECT_SUBMIT_SUCCESS,
    meta: {
      projectId,
      locationObject
    }
  }
  const formData = objectToFormData(locationObject)
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/location/objects`,
      method: 'post',
      headers: {},
      body: formData,
      types: [
        PROJECT_LOCATION_CREATE_OBJECT_SUBMIT_REQUEST,
        success,
        PROJECT_LOCATION_CREATE_OBJECT_SUBMIT_FAILURE
      ]
    }
  }
}
