import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Select } from '../../../../../../../../components'

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const monthNames = {
  1: 'Январь',
  2: 'Февраль',
  3: 'Март',
  4: 'Апрель',
  5: 'Май',
  6: 'Июнь',
  7: 'Июль',
  8: 'Август',
  9: 'Сентябрь',
  10: 'Октябрь',
  11: 'Ноябрь',
  12: 'Декабрь'
}

const createYearOptions = date => {
  const year = date.getFullYear()
  const years = []
  for (let i = year; i >= year - 10; i--) {
    years.push(i)
  }

  return [
    {
      value: '',
      label: ''
    },
    ...years.map(year => ({ value: year.toString(), label: year.toString() }))
  ]
}

const createMonthOptions = () => {
  return [
    {
      value: '',
      label: ''
    },
    ...months.map(month => ({
      value: month.toString(),
      label: monthNames[month]
    }))
  ]
}

const getDate = ({ month, year }) => {
  if (month === null || year === null) {
    return null
  }
  const date = new Date(Date.UTC(year, month - 1, 1))
  const isValidDate = !isNaN(date.getTime())
  return isValidDate ? date : null
}

const getMonthAndYear = date => {
  const month = date.getUTCMonth() + 1
  const year = date.getUTCFullYear()
  return { month, year }
}

class MonthSelect extends PureComponent {
  state = null

  monthOptions = createMonthOptions()
  yearOptions = createYearOptions(new Date())

  getMonthAndYear = () => {
    if (this.state) {
      return {
        month: this.state.month,
        year: this.state.year
      }
    }

    const { date } = this.props
    if (date) {
      return getMonthAndYear(date)
    }

    return { month: null, year: null }
  }

  handleOnYearChange = event => {
    const value = event.target.value
    const { onChange } = this.props
    const { month } = this.getMonthAndYear()
    this.setState(
      {
        month,
        year: parseInt(value)
      },
      () => {
        onChange(getDate(this.state))
      }
    )
  }

  handleOnMonthChange = event => {
    const value = event.target.value
    const { onChange } = this.props
    const { year } = this.getMonthAndYear()
    this.setState(
      {
        year,
        month: parseInt(value)
      },
      () => {
        onChange(getDate(this.state))
      }
    )
  }

  render() {
    const { month, year } = this.getMonthAndYear()
    const yearValue = year ? year.toString() : null
    const monthValue = month ? month.toString() : null
    const { className } = this.props

    return (
      <div className="row">
        <div className="col-4 formField">
          <Select
            options={this.monthOptions}
            value={monthValue}
            onChange={this.handleOnMonthChange}
            className={className}
          />
        </div>
        <div className="col-2">
          <Select
            options={this.yearOptions}
            value={yearValue}
            onChange={this.handleOnYearChange}
            className={className}
          />
        </div>
      </div>
    )
  }
}

MonthSelect.propTypes = {
  date: PropTypes.object,
  error: PropTypes.object
}

export default MonthSelect
