import { connect } from 'react-redux'
import PublicationTree from './components/PublicationTree'
import { publication as selectPublication } from './reducers/selectors'
import { enhanceWithSpinner } from '../../../../components'
import { publicate, checkHasChanges } from './actions'

const visitSection = section => {
  const { publication, children = [] } = section
  const isRunning =
    publication &&
    (publication.status === 'Started' ||
      publication.subStatus === 'CheckingForChanges')

  if (isRunning) {
    return {
      isRunning: true,
      isChecking: publication.subStatus === 'CheckingForChanges'
    }
  }

  for (var i = 0; i < children.length; i++) {
    const result = visitSection(children[i])
    if (result.isRunning) {
      return result
    }
  }

  return {
    isRunning: false,
    isChecking: false
  }
}

const PublicationsWithSpinner = enhanceWithSpinner(({ section }) => {
  return section === null || section === undefined
}, PublicationTree)

const mapStateToProps = state => {
  const originalSection = selectPublication(state)
  if (originalSection == null) {
    return {}
  }
  const {
    name,
    path,
    publication,
    hasChanges,
    projects = [],
    ...rest
  } = selectPublication(state)
  const projectsChildren = projects.map(project => {
    const { name, path, publication, hasChanges, ...projectRest } = project
    const projectSection = {
      name,
      path,
      publication,
      hasChanges,
      children: Object.keys(projectRest).map(key => projectRest[key])
    }
    return projectSection
  })
  const children = [
    ...projectsChildren,
    ...Object.keys(rest).map(key => rest[key])
  ]
  let section = {
    name,
    path,
    publication,
    hasChanges,
    children,
    isRunning: false,
    isChecking: false
  }
  const result = visitSection(section)
  section.isRunning = result.isRunning
  section.isChecking = result.isChecking

  return { section }
}

const Publications = connect(
  mapStateToProps,
  {
    publicate,
    checkHasChanges
  }
)(PublicationsWithSpinner)

export default Publications
