import React from 'react'
import PropTypes from 'prop-types'

const FormControl = ({
  type = 'text',
  placeholder = '',
  className = 'formField__control form-control form-control-sm',
  label = null,
  error,
  ...rest
}) => {
  const components = []
  const errorClassName = error ? 'formField__control--error' : ''
  if (label) {
    components.push(
      <label key="label" className="formField__label">
        {label}
      </label>
    )
  }
  components.push(
    <input
      key="input"
      {...rest}
      type={type}
      placeholder={placeholder}
      className={className + ' ' + errorClassName}
    />
  )
  if (error) {
    if (Array.isArray(error)) {
      error.forEach((message, index) => {
        components.push(
          <div key={`${message}-${index}`} className="formField__error">
            {message}
          </div>
        )
      })
    } else {
      components.push(
        <div key="error" className="formField__error">
          {error}
        </div>
      )
    }
  }
  return components
}

FormControl.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string
}

export default FormControl
