import {
  fetchSection,
  PROJECT_TEAM_SECTION_SUCCESS,
  PROJECT_TEAM_SECTION_FAILURE
} from '../actions'
import createSectionSaga from '../../../sagas/createSectionSaga'
import fetchTeamSectionPinImagesSaga from './fetchTeamSectionPinImagesSaga'

export default createSectionSaga(
  fetchSection,
  [PROJECT_TEAM_SECTION_SUCCESS, PROJECT_TEAM_SECTION_FAILURE],
  project => project.teamSection,
  {
    sectionName: 'Команда проекта',
    teamObjects: []
  },
  fetchTeamSectionPinImagesSaga
)
