import { fork, call } from 'redux-saga/effects'
import watchCreateObjectSaga from './watchCreateObjectSaga'
import fetchDecorationSectionSaga from '../../../../../sagas/fetchDecorationSectionSaga'

const createObjectSagaRoute = {
  '/projects/:projectId/decoration/tabs/:tabId/decoration-type': function* runCreateDecorationTypeSaga({
    projectId,
    tabId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const tabIdNumber = parseInt(tabId, 10)
    const decorationSection = yield call(
      fetchDecorationSectionSaga,
      projectIdNumber
    )
    if (decorationSection) {
      yield fork(watchCreateObjectSaga, projectIdNumber, tabIdNumber)
    }
  }
}

export default createObjectSagaRoute
