import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import './formField.css'

const TextareaField = ({
  name,
  validate,
  label = null,
  placeholder = '',
  maxLength = 1000,
  className,
  ...rest
}) => {
  return (
    <Field name={name} validate={validate} className="formField">
      {({ input, meta }) => {
        const components = []
        const errorClassName =
          meta.error && meta.touched ? 'formField__control--error' : ''
        if (label) {
          components.push([
            <div className="formField__labelWrapper" key="label">
              <label className="formField__label">{label}</label>
              <span className="formField__count">
                {input.value.length}/{maxLength}
              </span>
            </div>
          ])
        }
        components.push(
          <textarea
            key="textarea"
            {...input}
            {...rest}
            maxLength={maxLength}
            placeholder={placeholder}
            className={`form-control form-control-sm formField__textarea ${className} ${errorClassName}`}
            rows="5"
          />
        )
        if (meta.error) {
          components.push(
            <span key="error" className="formField__error">
              {meta.touched && meta.error}
            </span>
          )
        }
        return components
      }}
    </Field>
  )
}

TextareaField.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func
}

export default TextareaField
