import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import { required } from '../../../../../../../../../../components/Validation'
import projectForm from '../../../../../../components/projectForm'
import { FormField } from '../../../../../../../../../../components/Form'
import { SubmitButton } from '../../../../../../../../components/Buttons'
import AdvantageSortableList from '../AdvantageSortableList'

const focusOnErrors = createDecorator()

const onSubmit = (projectId, submit) => mortgageSection => {
  submit(projectId, mortgageSection)
}

const AdvantageSectionForm = props => {
  const { projectId, submit, advantagesSection } = props
  return (
    <Form
      initialValues={advantagesSection}
      mutators={{
        ...arrayMutators
      }}
      onSubmit={onSubmit(projectId, submit)}
      decorators={[focusOnErrors]}
      render={({
        handleSubmit,
        submitting,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col formField">
                <FormField
                  name="sectionName"
                  validate={required}
                  type="text"
                  label="Название раздела"
                  placeholder="Название раздела"
                />
              </div>
            </div>
            <hr className="formField__separator" />
            <div className="row">
              <div className="col">
                <FieldArray name="advantages">
                  {({ fields }) => {
                    return (
                      <AdvantageSortableList
                        fields={fields}
                        projectId={projectId}
                        distance={1}
                      />
                    )
                  }}
                </FieldArray>
              </div>
            </div>
            <SubmitButton
              pristine={pristine}
              submitSucceeded={submitSucceeded}
              dirtySinceLastSubmit={dirtySinceLastSubmit}
            />
          </form>
        )
      }}
    />
  )
}

AdvantageSectionForm.propTypes = {
  submit: PropTypes.func.isRequired,
  advantagesSection: PropTypes.object.isRequired
}

export default projectForm(AdvantageSectionForm)
