import { RSAA } from 'redux-api-middleware'

export const PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_SUBMIT_REQUEST =
  'PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_SUBMIT_REQUEST'
export const PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_SUBMIT_SUCCESS =
  'PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_SUBMIT_SUCCESS'
export const PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_SUBMIT_FAILURE =
  'PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_SUBMIT_FAILURE'

export const submit = (projectId, gallery) => {
  const meta = {
    projectId,
    gallery
  }
  const success = {
    type: PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_SUBMIT_SUCCESS,
    meta
  }
  const failure = {
    type: PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_SUBMIT_FAILURE,
    meta
  }
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/construction/galleries`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(gallery),
      types: [
        PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_SUBMIT_REQUEST,
        success,
        failure
      ]
    }
  }
}
