import { COMMENTS_SUCCESS } from '../actions'
import { COMMENTS_FILTER_CHANGE } from '../actions'
import moment from 'moment'

const initialState = {
  filters: {
    activePeriod: 'month',
    start: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    end: moment()
      .endOf('month')
      .format('YYYY-MM-DD'),
    rate: []
  },
  data: [],
  hasMore: false,
  loading: false
}

const mergedReducer = {
  [COMMENTS_SUCCESS]: (state = initialState, action) => {
    return {
      ...state,
      data: state.data
        ? [...state.data, ...action.payload.data]
        : action.payload.data,
      hasMore: action.payload.hasMore
    }
  },
  [COMMENTS_FILTER_CHANGE]: (state, action) => {
    const newFilter = {
      ...state.filters,
      ...action.payload
    }
    return {
      ...state,
      data: [],
      filters: {
        ...newFilter
      }
    }
  }
}

const ratesReducer = (state = initialState, action) => {
  let actionReducer = mergedReducer[action.type]
  if (actionReducer) {
    return actionReducer(state, action)
  }

  return state
}

export default ratesReducer
