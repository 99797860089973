import { put, take } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_CREATE_PROJECT_SUCCESS } from '../actions'

export default function* watchProjectCreated() {
  while (true) {
    yield take(PROJECT_CREATE_PROJECT_SUCCESS)
    yield put(push('/projects'))
  }
}
