import { put, call, all } from 'redux-saga/effects'
import { fetchImageMetaData } from '../actions'
import { fetchImagesSectionSaga } from '../../../sagas'

function* fetchImageMetaDataSaga(projectId, id, imageMeta) {
  const { metaUrl, type } = imageMeta
  yield put(fetchImageMetaData(projectId, type, id, metaUrl))
}

function* fetchImageSetSaga(projectId, imageSetId) {
  const imageSection = yield call(fetchImagesSectionSaga, projectId)
  if (imageSection) {
    const { projectListImages = [] } = imageSection
    const imageSets = projectListImages
    const imageSet = imageSets.find(t => t.id === imageSetId)
    if (imageSet) {
      const { position, id, previewImageUrl, ...imageTypes } = imageSet
      const images = Object.keys(imageTypes)
        .map(key => {
          return {
            ...imageSet[key],
            type: key
          }
        })
        .filter(t => t.metaUrl)
      const fetchTasks = images.map(imageMeta =>
        call(fetchImageMetaDataSaga, projectId, id, imageMeta)
      )
      yield all(fetchTasks)
      return imageSet
    }
  }

  return null
}

export default fetchImageSetSaga
