import constructionProgressFormReducer from './constructionProgressFormReducer'
import createGalleryReducer from '../pages/CreateGallery/reducers'
import editGalleryReducer from '../pages/EditGallery/reducers'

const constructionProgressReducer = {
  ...constructionProgressFormReducer,
  ...createGalleryReducer,
  ...editGalleryReducer
}

export default constructionProgressReducer
