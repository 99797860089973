import {
  PROJECT_DECORATION_EDIT_DECORATION_TYPE_SUBMIT_SUCCESS,
  PROJECT_DECORATION_EDIT_DECORATION_TYPE_SUBMIT_FAILURE,
  PROJECT_DECORATION_OBJECT_SUCCESS,
  PROJECT_DECORATION_OBJECT_FAILURE
} from '../actions'
import updateDecorationType from './updateDecorationType'
import createImageSetReducer from '../pages/CreateImageSet/reducers'
import editImageSetReducer from '../pages/EditImageSet/reducers'

const editDecorationTypeReducer = {
  [PROJECT_DECORATION_EDIT_DECORATION_TYPE_SUBMIT_SUCCESS]: updateDecorationType(
    (_decorationObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_DECORATION_EDIT_DECORATION_TYPE_SUBMIT_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  [PROJECT_DECORATION_OBJECT_SUCCESS]: updateDecorationType(
    (_decorationObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_DECORATION_OBJECT_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  ...createImageSetReducer,
  ...editImageSetReducer
}

export default editDecorationTypeReducer
