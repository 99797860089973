import React, { PureComponent } from 'react'
import PropType from 'prop-types'
import UniversalPopup from '.'
import './style.css'

class ActionPopup extends PureComponent {
  render() {
    const {
      isOpened,
      title,
      body,
      onConfirm,
      onCancel,
      confirmText = 'Сохранить',
      cancelText = 'Отмена',
      children
    } = this.props
    return (
      <UniversalPopup
        isOpened={isOpened}
        title={title}
        body={body}
        buttons={() => [
          <div
            className="btn btn-outline-info btn-yes"
            onClick={onConfirm}
            key="1"
          >
            {confirmText}
          </div>,
          <div
            className="btn btn-outline-danger btn-no"
            onClick={onCancel}
            key="0"
          >
            {cancelText}
          </div>
        ]}
      >
        {children}
      </UniversalPopup>
    )
  }
}

ActionPopup.propTypes = {
  isOpened: PropType.bool.isRequired,
  title: PropType.func,
  body: PropType.func,
  onConfirm: PropType.func.isRequired,
  onCancel: PropType.func.isRequired,
  confirmText: PropType.string,
  cancelText: PropType.string
}

export default ActionPopup
