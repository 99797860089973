import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const EDIT_OBJECT_SUBMIT_REQUEST = 'EDIT_OBJECT_SUBMIT_REQUEST'
export const EDIT_OBJECT_SUBMIT_SUCCESS = 'EDIT_OBJECT_SUBMIT_SUCCESS'
export const EDIT_OBJECT_SUBMIT_FAILURE = 'EDIT_OBJECT_SUBMIT_FAILURE'

export const submit = (projectId, objectId, object) => {
  const success = {
    type: EDIT_OBJECT_SUBMIT_SUCCESS,
    meta: {
      projectId,
      objectId,
      object
    }
  }
  const formData = objectToFormData(object)
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/objects/${objectId}`,
      method: 'put',
      headers: {},
      body: formData,
      types: [EDIT_OBJECT_SUBMIT_REQUEST, success, EDIT_OBJECT_SUBMIT_FAILURE]
    }
  }
}

export const OBJECT_IMAGE_META_REQUEST = 'OBJECT_IMAGE_META_REQUEST'
export const OBJECT_IMAGE_META_SUCCESS = 'OBJECT_IMAGE_META_SUCCESS'
export const OBJECT_IMAGE_META_FAILURE = 'OBJECT_IMAGE_META_FAILURE'

export const fetchPinImageMetaData = (objectId, imageMeta) => {
  const meta = {
    objectId,
    imageType: imageMeta.type
  }
  const success = {
    type: OBJECT_IMAGE_META_SUCCESS,
    meta
  }
  const failure = {
    type: OBJECT_IMAGE_META_FAILURE,
    meta
  }
  return {
    [RSAA]: {
      endpoint: imageMeta.metaUrl,
      method: 'get',
      types: [OBJECT_IMAGE_META_REQUEST, success, failure]
    }
  }
}
