import { put, call, all } from 'redux-saga/effects'
import { fetchPinImageMetaData } from '../actions'
import fetchObjectsSaga from '../../../sagas/fetchObjectsSaga'

function* fetchPinImageMetaDataSaga(objectId, imageMeta) {
  yield put(fetchPinImageMetaData(objectId, imageMeta))
}

function* fetchObjectSaga(objectId) {
  const objects = yield call(fetchObjectsSaga)
  if (objects) {
    const object = objects.find(t => t.id === objectId)
    if (object) {
      const { objectType, id, position, ...imageTypes } = object
      const images = Object.keys(imageTypes)
        .map(key => {
          return {
            ...object[key],
            type: key
          }
        })
        .filter(t => t.metaUrl)
      const fetchTasks = images.map(imageMeta =>
        call(fetchPinImageMetaDataSaga, objectId, imageMeta)
      )
      yield all(fetchTasks)
      return object
    }
  }

  return null
}

export default fetchObjectSaga
