import updateAccomplishmentType from '../../../reducers/updateAccomplishmentType'

const updateImageSet = reducer =>
  updateAccomplishmentType((accomplishmentType, action) => {
    const { id } = action.meta
    const imageSets = accomplishmentType.imageSets.map(
      imageSet =>
        imageSet.id === id
          ? { ...imageSet, ...reducer(imageSet, action) }
          : imageSet
    )

    return {
      ...accomplishmentType,
      imageSets
    }
  })

export default updateImageSet
