import { CREATE_USER_FAILURE, CREATE_USER_SUCCESS } from '../actions'

const addUserReducer = {
  [CREATE_USER_SUCCESS]: (state, action) => {
    const newUsers = [...state.users, action.payload]
    return {
      users: newUsers
    }
  },
  [CREATE_USER_FAILURE]: (state, action) => {
    const {
      meta: { user }
    } = action
    return {
      ...state,
      errors: action.payload.response,
      user
    }
  }
}

export default addUserReducer
