import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_LOCATION_CREATE_OBJECT_SUBMIT_SUCCESS } from '../actions'

function* watchCreateObjectSaga(projectId) {
  while (true) {
    yield take(PROJECT_LOCATION_CREATE_OBJECT_SUBMIT_SUCCESS)
    yield put(push(`/projects/${projectId}/location`))
  }
}

export default watchCreateObjectSaga
