import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import ConstructionProgressForm from './ConstructionProgressForm'
import CreateGalleryForm from '../pages/CreateGallery'
import EditGalleryForm from '../pages/EditGallery'
import CreateImageSetForm from '../pages/EditGallery/pages/CreateImageSet'
import EditImageSetForm from '../pages/EditGallery/pages/EditImageSet'
import { ProjectConsumer } from '../../../ProjectContext'

const ConstructionProgressPage = props => {
  const {
    constructionProgressSection,
    match: { url }
  } = props
  return (
    <ProjectConsumer>
      {project => {
        const projectId = project.id
        return (
          <Switch>
            <Route
              exact
              path={`${url}/galleries/add`}
              render={() => {
                return <CreateGalleryForm project={project} />
              }}
            />
            <Route
              path={`${url}/galleries/:galleryId(\\d+)`}
              render={({ match: { params } }) => {
                const galleryId = parseInt(params.galleryId, 10)
                const gallery = constructionProgressSection.galleries.find(
                  t => t.id === galleryId
                )
                if (!gallery) {
                  return <Redirect to="/404" />
                }
                const backUrl = `${url}/galleries/${gallery.id}`
                return (
                  <Switch>
                    <Route
                      exact
                      path={`${url}/galleries/${gallery.id}/images/:id(\\d+)`}
                      render={({ match: { params } }) => {
                        const id = parseInt(params.id, 10)
                        const imageSet = gallery.imageSets.find(
                          t => t.id === id
                        )
                        if (!imageSet) {
                          return <Redirect to="/404" />
                        }
                        return (
                          <EditImageSetForm
                            galleryId={gallery.id}
                            projectId={project.id}
                            imageSet={imageSet}
                            id={id}
                            backUrl={backUrl}
                          />
                        )
                      }}
                    />
                    <Route
                      exact
                      path={`${url}/galleries/${gallery.id}/images/add`}
                      render={() => {
                        return (
                          <CreateImageSetForm
                            galleryId={gallery.id}
                            projectId={project.id}
                            backUrl={backUrl}
                          />
                        )
                      }}
                    />
                    <EditGalleryForm projectId={projectId} gallery={gallery} />
                  </Switch>
                )
              }}
            />
            <ConstructionProgressForm
              constructionProgressSection={constructionProgressSection}
              projectId={projectId}
            />
          </Switch>
        )
      }}
    </ProjectConsumer>
  )
}

ConstructionProgressPage.propTypes = {
  projectId: PropTypes.number.isRequired,
  constructionProgressSection: PropTypes.object
}

export default ConstructionProgressPage
