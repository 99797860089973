import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const BLOCKS_EDIT_IMAGE_SET_REQUEST = 'BLOCKS_EDIT_IMAGE_SET_REQUEST'
export const BLOCKS_EDIT_IMAGE_SET_SUCCESS = 'BLOCKS_EDIT_IMAGE_SET_SUCCESS'
export const BLOCKS_EDIT_IMAGE_SET_FAILURE = 'BLOCKS_EDIT_IMAGE_SET_FAILURE'

export const submit = (blockId, imageSetId, images) => {
  const success = {
    type: BLOCKS_EDIT_IMAGE_SET_SUCCESS,
    meta: {
      blockId,
      images,
      imageSetId
    }
  }
  const formData = objectToFormData(images)
  const endpoint = imageSetId
    ? `api/aboutCompany/blocks/${blockId}/images/${imageSetId}`
    : `api/aboutCompany/blocks/${blockId}/images`

  return {
    [RSAA]: {
      endpoint,
      method: 'put',
      types: [
        BLOCKS_EDIT_IMAGE_SET_REQUEST,
        success,
        BLOCKS_EDIT_IMAGE_SET_FAILURE
      ],
      body: formData
    }
  }
}

export const BLOCK_IMAGE_META_REQUEST = 'BLOCK_IMAGE_META_REQUEST'
export const BLOCK_IMAGE_META_SUCCESS = 'BLOCK_IMAGE_META_SUCCESS'
export const BLOCK_IMAGE_META_FAILURE = 'BLOCK_IMAGE_META_FAILURE'

export const fetchImageMetaData = (blockId, imageType, metaUrl) => {
  const success = {
    type: BLOCK_IMAGE_META_SUCCESS,
    meta: {
      blockId,
      imageType
    }
  }
  const failure = {
    type: BLOCK_IMAGE_META_FAILURE,
    meta: {
      blockId,
      imageType
    }
  }
  return {
    [RSAA]: {
      endpoint: metaUrl,
      method: 'get',
      types: [BLOCK_IMAGE_META_REQUEST, success, failure]
    }
  }
}
