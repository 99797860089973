import {
  PROJECT_TEAM_CREATE_OBJECT_SUBMIT_SUCCESS,
  PROJECT_TEAM_CREATE_OBJECT_SUBMIT_FAILURE
} from '../actions'
import updateProject from '../../../../../reducers/updateProject'

const createObjectReducer = {
  [PROJECT_TEAM_CREATE_OBJECT_SUBMIT_SUCCESS]: updateProject(
    (project, { payload }) => {
      const section = project.teamSection
      const teamObjects = [...section.teamObjects, payload]
      const newSection = {
        ...section,
        teamObjects
      }
      const newProject = {
        ...project,
        teamSection: newSection
      }
      return newProject
    }
  ),
  [PROJECT_TEAM_CREATE_OBJECT_SUBMIT_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  }
}

export default createObjectReducer
