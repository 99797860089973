import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import { SubmitButton } from '../../../../../components/Buttons'
import projectForm from '../../../components/projectForm'

const onSubmit = (projectId, submit) => section => submit(projectId, section)
const focusOnErrors = createDecorator()

const SectionPageForm = projectForm(
  ({ projectId, submit, initialValues, children, ...rest }) => {
    return (
      <Form
        initialValues={initialValues}
        mutators={{
          ...arrayMutators
        }}
        onSubmit={onSubmit(projectId, submit)}
        decorators={[focusOnErrors]}
        render={({
          handleSubmit,
          submitting,
          pristine,
          submitSucceeded,
          dirtySinceLastSubmit,
          form
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {children({ projectId, initialValues, form, ...rest })}
              <SubmitButton
                pristine={pristine}
                submitSucceeded={submitSucceeded}
                dirtySinceLastSubmit={dirtySinceLastSubmit}
              />
            </form>
          )
        }}
      />
    )
  }
)

SectionPageForm.propTypes = {
  projectId: PropTypes.number.isRequired,
  submit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired
}

export default SectionPageForm
