import React from 'react'
import PropTypes from 'prop-types'
import { required } from '../../../../../components/Validation'
import { ImageiOS2x, ImageiOS3x, ImageAndroidSvg } from '../../ImageUpload'
import ImageType from './ImageType'
import getName from './getName'

const SmallIconImageFormField = ({
  label = 'Изображение/иконка',
  form,
  prefix
}) => {
  const size = 'small'
  return [
    <div className="row" key="label">
      <div className="col">
        <h4>{label}</h4>
      </div>
    </div>,
    <div className="row" key="images">
      <div className="col">
        <label className="formField__label">iOS</label>
        <div className="imageGrid">
          <ImageiOS2x
            validate={required}
            name={getName(prefix, ImageType.iOS2x)}
            form={form}
            size={size}
          />
          <ImageiOS3x
            name={getName(prefix, ImageType.iOS3x)}
            form={form}
            size={size}
          />
        </div>
      </div>
      <div className="col">
        <label className="formField__label">Android</label>
        <div className="imageGrid">
          <ImageAndroidSvg
            name={getName(prefix, ImageType.androidSvg)}
            form={form}
            size={size}
          />
        </div>
      </div>
    </div>
  ]
}

SmallIconImageFormField.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  prefix: PropTypes.string
}

export default SmallIconImageFormField
