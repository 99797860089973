import {
  PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_IMAGE_SET_SUBMIT_SUCCESS,
  PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_IMAGE_SET_SUBMIT_FAILURE,
  PROJECT_CONSTRUCTION_PROGRESS_GALLERY_IMAGE_META_SUCCESS
} from '../actions'
import updateGallery from '../../../../../reducers/updateGallery'

const editImageSetReducer = {
  [PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_IMAGE_SET_SUBMIT_SUCCESS]: updateGallery(
    (gallery, action) => {
      const {
        payload,
        meta: { id }
      } = action
      const { imageSets = [] } = gallery
      const newImageSets = imageSets.map(set => (set.id === id ? payload : set))
      const newGallery = {
        ...gallery,
        imageSets: newImageSets
      }
      return newGallery
    }
  ),
  [PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_IMAGE_SET_SUBMIT_FAILURE]: (
    state,
    action
  ) => {
    const { payload: { response } = {} } = action
    return {
      ...state,
      error: response
    }
  },
  [PROJECT_CONSTRUCTION_PROGRESS_GALLERY_IMAGE_META_SUCCESS]: updateGallery(
    (gallery, action) => {
      const {
        payload,
        meta: { imageType, id }
      } = action
      const { imageSets = [] } = gallery
      const newImageSets = imageSets.map(set => {
        if (set.id === id) {
          return {
            ...set,
            [imageType]: {
              ...set[imageType],
              ...payload
            }
          }
        }
        return set
      })
      const newGallery = {
        ...gallery,
        imageSets: newImageSets
      }
      return newGallery
    }
  )
}

export default editImageSetReducer
