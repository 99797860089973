import { RSAA } from 'redux-api-middleware'
import { getUrlWithParams } from '../../../../../../../utils/getUrlWithParams'

export const COMMENTS_REQUEST = 'COMMENTS_REQUEST'
export const COMMENTS_SUCCESS = 'COMMENTS_SUCCESS'
export const COMMENTS_FAILURE = 'COMMENTS_FAILURE'

export const fetchComments = ({ activePeriod, ...params }) => {
  return {
    [RSAA]: {
      endpoint: getUrlWithParams('api/comments', params),
      method: 'get',
      types: [COMMENTS_REQUEST, COMMENTS_SUCCESS, COMMENTS_FAILURE],
      headers: { 'Content-Type': 'application/json' }
    }
  }
}

export const COMMENTS_FILTER_CHANGE = 'COMMENTS_FILTER_CHANGE'

export const filterChange = obj => {
  return {
    type: COMMENTS_FILTER_CHANGE,
    payload: obj
  }
}
