import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { branch, renderComponent } from 'recompose'
import addImageIcon from '../../../../images/icn-add-img.svg'
import './documentUpload.css'

const DocumentFile = ({ onRemove, document = {} }) => {
  const { url, preview } = document
  const downloadUrl = url ? url : preview ? preview : null
  return (
    <div>
      <a
        className="btn btn-outline-warning btn-sm btn--look"
        href={downloadUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="17"
          viewBox="0 0 11 17"
          className="icon"
        >
          <g fill="#F8941E" className="icon__path" fillRule="evenodd">
            <path d="M5.5 15.036l3.89-3.89a.5.5 0 0 1 .706.708l-4.242 4.242a.5.5 0 0 1-.708 0L.904 11.854a.5.5 0 1 1 .707-.708l3.889 3.89z" />
            <rect width="1" height="12" x="5" y="4" rx=".5" />
            <rect width="11" height="1" rx=".5" />
          </g>
        </svg>
        Посмотреть
      </a>
      <button
        type="button"
        className="btn btn-outline-danger btn-sm btn--kill"
        onClick={onRemove}
      >
        Удалить
      </button>
    </div>
  )
}

const isNotEmptyDocumentFile = props => {
  return (
    (props.document !== null && props.document.preview) ||
    (props.document !== null &&
      props.document.url !== null &&
      props.document.url !== undefined)
  )
}

const EmptyDocumentFile = props => {
  const { size = 'small', hasError = false } = props
  const isNotEmptyDocumentFileClass = isNotEmptyDocumentFile(props)
    ? 'documentUpload__dropZone--notEmpty'
    : ''
  const errorClass = hasError ? 'documentUpload__dropZone--error' : ''
  return (
    <div className={`documentUpload documentUpload--${size}`}>
      <Dropzone
        accept=".doc, .docx, .pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/x-pdf"
        onDrop={props.onDrop}
        multiple={false}
        className={`documentUpload__dropZone ${isNotEmptyDocumentFileClass} ${errorClass}`}
      >
        <div key="add" className="documentUpload__add">
          <img src={addImageIcon} alt="Загрузить" width={'16'} height={'16'} />
          &#160; Загрузить
        </div>
      </Dropzone>
    </div>
  )
}

const NotEmptyDocumentFile = props => <DocumentFile {...props} />

const DocumentFileUpload = branch(
  props => isNotEmptyDocumentFile(props),
  renderComponent(NotEmptyDocumentFile)
)(EmptyDocumentFile)

class DocumentUpload extends PureComponent {
  onRemove = event => {
    event.stopPropagation()
    this.props.onRemove()
  }

  onDrop = (files, rejectedFiles) => {
    rejectedFiles.forEach(file => window.URL.revokeObjectURL(file.preview))
    this.props.onDrop(files[0])
  }

  render() {
    const { document = null, hasError = false } = this.props
    return (
      <>
        <label className="formField__label">&#160;</label>
        <DocumentFileUpload
          document={document}
          onRemove={this.onRemove}
          onDrop={this.onDrop}
          hasError={hasError}
        />
      </>
    )
  }
}

DocumentUpload.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onDrop: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
}

export default DocumentUpload
