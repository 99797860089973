import createSectionPage from '../../createSectionPage'
import PublicSpacesSectionPage from './components'
import { submit } from './actions'

const PublicSpacesSectionRoutePage = createSectionPage(
  'publicSpaces',
  null,
  submit
)(PublicSpacesSectionPage)

export default PublicSpacesSectionRoutePage
