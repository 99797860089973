import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'
import SortableArray from './SortableArray'

const sortEnd = move => ({ oldIndex, newIndex }) => {
  move(oldIndex, newIndex)
}

const ArrayFormField = ({ name, ...rest }) => {
  return (
    <FieldArray name={name}>
      {({ fields }) => {
        return (
          <SortableArray
            {...rest}
            fields={fields}
            onSortEnd={sortEnd(fields.move)}
            distance={1}
          />
        )
      }}
    </FieldArray>
  )
}

ArrayFormField.propTypes = {
  name: PropTypes.string.isRequired
}

export default ArrayFormField
