import {
  PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_SUBMIT_SUCCESS,
  PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_SUBMIT_FAILURE
} from '../actions'
import updateGallery from '../../../reducers/updateGallery'

const editConstructionProgressReducer = {
  [PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_SUBMIT_SUCCESS]: updateGallery(
    (gallery, { payload }) => {
      return {
        ...gallery,
        ...payload
      }
    }
  ),
  [PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_SUBMIT_FAILURE]: (
    state,
    action
  ) => {
    const { payload: { response } = {} } = action
    return {
      ...state,
      error: response
    }
  }
}

export default editConstructionProgressReducer
