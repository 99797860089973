import React from 'react'
import PropTypes from 'prop-types'

const ProjectName = ({ project }) => <h2>{project.name}</h2>

ProjectName.propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
}

export default ProjectName
