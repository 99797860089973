import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import { required } from '../../../../../../../../../../../../../../components/Validation'
import { FormField } from '../../../../../../../../../../../../../../components/Form'
import { TextareaField } from '../../../../../../../../../../../../../../components/Form'
import projectForm from '../../../../../../../../../../components/projectForm'
import { SubmitButton } from '../../../../../../../../../../../../components/Buttons'
import './EditDecorationTypeForm.css'
import { ImageSetArrayFormField } from '../../../../../../../../../../../../components/Form'

const focusOnErrors = createDecorator()

const onSubmit = (projectId, tabId, objectId, submit) => decorationObject => {
  submit(projectId, tabId, objectId, decorationObject)
}

const createEditImageSetUrl = (projectId, tabId, objectId) => id => {
  return `/projects/${projectId}/decoration/tabs/${tabId}/decoration-type/${objectId}/images/${id}`
}

const EditDecorationTypeForm = ({
  projectId,
  tabId,
  objectId,
  submit,
  type
}) => {
  return (
    <Form
      initialValues={type}
      mutators={{
        ...arrayMutators
      }}
      onSubmit={onSubmit(projectId, tabId, objectId, submit)}
      decorators={[focusOnErrors]}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <Link
                  to={`/projects/${projectId}/decoration/tabs/${tabId}`}
                  className="formField__back"
                >
                  Назад
                </Link>
              </div>
            </div>
            <hr className="formField__separator" />
            <div className="row">
              <div className="col formField">
                <FormField
                  name="name"
                  validate={required}
                  type="text"
                  label="Название"
                  placeholder="Название"
                />
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <TextareaField
                  name="description"
                  label="Описание"
                  placeholder="Описание"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h4>Галерея</h4>
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <ImageSetArrayFormField
                  form={form}
                  addImageSetUrl={`/projects/${projectId}/decoration/tabs/${tabId}/decoration-type/${objectId}/images/add`}
                  editImageSetUrl={createEditImageSetUrl(
                    projectId,
                    tabId,
                    objectId
                  )}
                />
              </div>
            </div>
            <SubmitButton
              pristine={pristine}
              submitSucceeded={submitSucceeded}
              dirtySinceLastSubmit={dirtySinceLastSubmit}
            />
          </form>
        )
      }}
    />
  )
}

EditDecorationTypeForm.propTypes = {
  projectId: PropTypes.number.isRequired,
  tabId: PropTypes.number.isRequired,
  objectId: PropTypes.number.isRequired,
  submit: PropTypes.func.isRequired
}

export default projectForm(EditDecorationTypeForm)
