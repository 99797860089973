import { PUSH_SUCCESS, PUSH_REQUEST, PUSH_FAILURE } from '../actions'

const addPushReducer = {
  [PUSH_SUCCESS]: (state, action) => {
    const pushesArr = state.data
    return {
      ...state,
      data: [action.meta, ...pushesArr],
      loading: false
    }
  },
  [PUSH_REQUEST]: state => {
    return {
      ...state,
      loading: true
    }
  },
  [PUSH_FAILURE]: state => {
    return {
      ...state,
      loading: false
    }
  }
}

export default addPushReducer
