import {
  PROJECT_GENERAL_SECTION_SUCCESS,
  PROJECT_GENERAL_SECTION_FAILURE,
  PROJECT_GENERAL_SECTION_PIN_IMAGE_META_SUCCESS,
  PROJECT_GENERAL_SECTION_SUBMIT_SUCCESS
} from '../actions'
import { setPinImageUrlReducer } from './pinImage'
import createProjectReducer from '../../../../../reducers/createProjectReducer'

const generalSectionReducer = {
  [PROJECT_GENERAL_SECTION_SUCCESS]: (state, action) => {
    const projectReducer = createProjectReducer(project => {
      return {
        ...project,
        generalSection: action.payload
      }
    })

    return projectReducer(state, action)
  },
  [PROJECT_GENERAL_SECTION_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  [PROJECT_GENERAL_SECTION_PIN_IMAGE_META_SUCCESS]: setPinImageUrlReducer,
  [PROJECT_GENERAL_SECTION_SUBMIT_SUCCESS]: (state, action) => {
    const projectReducer = createProjectReducer(project => {
      const { generalSection } = action.meta
      const newGeneralSection = { ...project.generalSection, ...generalSection }
      return {
        ...project,
        generalSection: newGeneralSection
      }
    })

    return projectReducer(state, action)
  }
}

export default generalSectionReducer
