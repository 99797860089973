import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const PROJECT_ADVANTAGES_EDIT_ADVANTAGE_SUBMIT_REQUEST =
  'PROJECT_ADVANTAGES_EDIT_ADVANTAGE_SUBMIT_REQUEST'
export const PROJECT_ADVANTAGES_EDIT_ADVANTAGE_SUBMIT_SUCCESS =
  'PROJECT_ADVANTAGES_EDIT_ADVANTAGE_SUBMIT_SUCCESS'
export const PROJECT_ADVANTAGES_EDIT_ADVANTAGE_SUBMIT_FAILURE =
  'PROJECT_ADVANTAGES_EDIT_ADVANTAGE_SUBMIT_FAILURE'

export const submit = (projectId, advantageId, advantage) => {
  const success = {
    type: PROJECT_ADVANTAGES_EDIT_ADVANTAGE_SUBMIT_SUCCESS,
    meta: {
      projectId,
      advantageId,
      advantage
    }
  }
  const { imageSets = [], ...rest } = advantage
  const items = imageSets.map(t => t.id)
  const form = {
    ...rest,
    items
  }
  const formData = objectToFormData(form)
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/advantages/${advantageId}`,
      method: 'put',
      headers: {},
      body: formData,
      types: [
        PROJECT_ADVANTAGES_EDIT_ADVANTAGE_SUBMIT_REQUEST,
        success,
        PROJECT_ADVANTAGES_EDIT_ADVANTAGE_SUBMIT_FAILURE
      ]
    }
  }
}

export const PROJECT_ADVANTAGES_ADVANTAGE_IMAGE_META_REQUEST =
  'PROJECT_ADVANTAGES_ADVANTAGE_IMAGE_META_REQUEST'
export const PROJECT_ADVANTAGES_ADVANTAGE_IMAGE_META_SUCCESS =
  'PROJECT_ADVANTAGES_ADVANTAGE_IMAGE_META_SUCCESS'
export const PROJECT_ADVANTAGES_ADVANTAGE_IMAGE_META_FAILURE =
  'PROJECT_ADVANTAGES_ADVANTAGE_IMAGE_META_FAILURE'

export const fetchPinImageMetaData = (projectId, advantageId, imageMeta) => {
  const meta = {
    projectId,
    advantageId,
    imageType: imageMeta.type
  }
  const success = {
    type: PROJECT_ADVANTAGES_ADVANTAGE_IMAGE_META_SUCCESS,
    meta
  }
  const failure = {
    type: PROJECT_ADVANTAGES_ADVANTAGE_IMAGE_META_FAILURE,
    meta
  }
  return {
    [RSAA]: {
      endpoint: imageMeta.metaUrl,
      method: 'get',
      types: [PROJECT_ADVANTAGES_ADVANTAGE_IMAGE_META_REQUEST, success, failure]
    }
  }
}
