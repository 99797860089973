import React, { Component } from 'react'
import { Doughnut } from 'react-chartjs-2'
import COLORS from '../colors'
import './style.css'

const StyleDoughnut = WrappedComponent =>
  class extends Component {
    render() {
      const {
        colorsArray = COLORS,
        data,
        title,
        unit = '',
        showSum = false
      } = this.props

      const newData = {
        datasets: [
          {
            data: data.map(item => item.value),
            backgroundColor: colorsArray,
            borderWidth: 0,
            hoverBackgroundColor: colorsArray,
            hoverBorderColor: colorsArray,
            hoverBorderWidth: 5
          }
        ],
        labels: data.map(item => item.name)
      }

      const sum = data.reduce(function(result, current) {
        return result + current.value
      }, 0)

      const chartOptions = {
        legend: {
          display: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        cutoutPercentage: 90,
        tooltips: {
          enabled: false,
          custom: function(tooltipModel) {
            if (showSum) return false
            // Tooltip Element
            var tooltipEl = document.getElementById('chartjs-tooltip')

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div')
              tooltipEl.id = 'chartjs-tooltip'
              tooltipEl.innerHTML = '<div></div>'
              document.body.appendChild(tooltipEl)
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0
              return
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform')
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign)
            } else {
              tooltipEl.classList.add('no-transform')
            }

            // Set Text
            if (tooltipModel.body) {
              let bodyLines = tooltipModel.body
              let name = tooltipModel.body[0].lines[0].split(':')[0]
              let value = tooltipModel.body[0].lines[0].split(':')[1]
              let innerHtml = ''

              bodyLines.forEach(function(body, i) {
                var colors = tooltipModel.labelColors[i]
                innerHtml +=
                  '<div style="textAlign: center; color:' +
                  colors.backgroundColor +
                  '">' +
                  '<span style="font-size: 52px">' +
                  value.toLocaleString('ru') +
                  '</span>' +
                  '<span style="font-size: 22px"; font-weight: normal;>' +
                  unit +
                  '</span><br/><br/>' +
                  '<span style="color: var(--gray-dark); font-weight: normal;">' +
                  name +
                  '</span>' +
                  '</div>'
              })

              var tableRoot = tooltipEl.querySelector('div')
              tableRoot.innerHTML = innerHtml
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect()
            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1
            tooltipEl.style.position = 'absolute'
            tooltipEl.style.transform = 'translate(-50%, -0px)'
            tooltipEl.style.left =
              position.left + position.width / 2 + +window.pageXOffset + 'px'
            tooltipEl.style.top =
              position.top +
              position.height / 2 +
              window.pageYOffset -
              20 +
              'px'
            tooltipEl.style.fontFamily = 'Roboto'
            tooltipEl.style.fontSize = '16px'
            tooltipEl.style.fontWeight = 'bold'
            tooltipEl.style.padding = '0px'
            tooltipEl.style.width = position.height * 0.7 + 'px'
          }
        }
      }
      return (
        <div>
          <div className="doughnut__title">{title}</div>
          {showSum ? (
            <div className="doughnut__sum">
              <div className="doughnut__sumValue">{sum}</div>
              <div className="doughnut__sumDescription">
                Общее количество
                <br />
                оценок
              </div>
            </div>
          ) : null}
          <div style={{ padding: '0 80px' }}>
            <WrappedComponent
              data={newData}
              height={290}
              options={chartOptions}
            />
          </div>
        </div>
      )
    }
  }

export default StyleDoughnut(Doughnut)
