import updateProject from '../../../../../reducers/updateProject'

const updateTab = reducer =>
  updateProject((project, action) => {
    const { tabId } = action.meta
    const tabs = project.accomplishmentSection.tabs.map(
      accomplishmentTab =>
        accomplishmentTab.id === tabId
          ? { ...accomplishmentTab, ...reducer(accomplishmentTab, action) }
          : accomplishmentTab
    )

    return {
      ...project,
      accomplishmentSection: {
        ...project.accomplishmentSection,
        tabs: tabs
      }
    }
  })

export default updateTab
