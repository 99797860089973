import { fork, call } from 'redux-saga/effects'
import watchImageSetUpdatedSaga from './watchImageSetUpdatedSaga'
import fetchImageSetSaga from './fetchImageSetSaga'

const editImageSetSagaRoute = {
  '/projects/:projectId/architecture/tabs/:tabId/architecture-type/:objectId/images/:imageSetId(\\d+)': function* runEditImageSetSaga({
    projectId,
    tabId,
    objectId,
    imageSetId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const tabIdNumber = parseInt(tabId, 10)
    const objectIdNumber = parseInt(objectId, 10)
    const imageSet = yield call(
      fetchImageSetSaga,
      projectIdNumber,
      tabIdNumber,
      objectIdNumber,
      parseInt(imageSetId, 10)
    )
    if (imageSet) {
      yield fork(
        watchImageSetUpdatedSaga,
        projectIdNumber,
        tabIdNumber,
        objectIdNumber
      )
    }
  }
}

export default editImageSetSagaRoute
