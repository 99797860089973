import { call, put, take } from 'redux-saga/effects'
import { fetchObject, PROJECT_ARCHITECTURE_OBJECT_SUCCESS } from '../actions'
import fetchArchitectureTabSaga from '../../../sagas/fetchArchitectureTabSaga'

function* fetchObjectSaga(projectId, tabId, objectId) {
  const architectureTab = yield call(fetchArchitectureTabSaga, projectId, tabId)
  if (architectureTab) {
    yield put(fetchObject(projectId, tabId, objectId))
    const architectureObject = yield take(PROJECT_ARCHITECTURE_OBJECT_SUCCESS)
    if (architectureObject) {
      return architectureObject.payload
    }
  }
  return null
}

export default fetchObjectSaga
