import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ProjectConsumer } from '../../../ProjectContext'
import LocationSectionForm from './LocationSectionForm'
import CreateObjectForm from '../pages/CreateObject'
import EditObjectForm from '../pages/EditObject'

const LocationSectionPage = props => {
  const {
    locationSection,
    match: { url },
    submit
  } = props
  return (
    <ProjectConsumer>
      {project => {
        const projectId = project.id
        return (
          <Switch>
            <Route
              exact
              path={`${url}/objects`}
              render={() => {
                return <CreateObjectForm projectId={projectId} />
              }}
            />
            <Route
              path={`${url}/objects/:id(\\d+)`}
              render={({ match: { params } }) => {
                const objectId = parseInt(params.id, 10)
                const infrastructureObject = locationSection.infrastructureObjects.find(
                  t => t.id === objectId
                )
                if (!infrastructureObject) {
                  return <Redirect to="/404" />
                }
                return (
                  <EditObjectForm
                    projectId={projectId}
                    infrastructureObject={infrastructureObject}
                    objectId={objectId}
                  />
                )
              }}
            />
            <LocationSectionForm
              locationSection={locationSection}
              projectId={projectId}
              submit={submit}
            />
          </Switch>
        )
      }}
    </ProjectConsumer>
  )
}

LocationSectionPage.propTypes = {
  locationSection: PropTypes.shape({
    sectionName: PropTypes.string.isRequired,
    infrastructureObjects: PropTypes.arrayOf.isRequired
  }).isRequired,
  projectId: PropTypes.number.isRequired
}

export default LocationSectionPage
