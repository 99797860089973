import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import { required } from '../../../../../../../../../../../../../../components/Validation'
import { FormField } from '../../../../../../../../../../../../../../components/Form'
import { TextareaField } from '../../../../../../../../../../../../../../components/Form'
import projectForm from '../../../../../../../../../../components/projectForm'
import { SubmitButton } from '../../../../../../../../../../../../components/Buttons'
import './CreateArchitectureTypeForm.css'

const focusOnErrors = createDecorator()

const onSubmit = (projectId, tabId, submit) => architectureObject => {
  submit(projectId, tabId, architectureObject)
}

const CreateArchitectureTypeForm = ({ projectId, tabId, submit }) => {
  return (
    <Form
      mutators={{
        ...arrayMutators
      }}
      onSubmit={onSubmit(projectId, tabId, submit)}
      decorators={[focusOnErrors]}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <Link
                  to={`/projects/${projectId}/architecture/tabs/${tabId}`}
                  className="formField__back"
                >
                  Назад
                </Link>
              </div>
            </div>
            <hr className="formField__separator" />
            <div className="row">
              <div className="col formField">
                <FormField
                  name="name"
                  validate={required}
                  type="text"
                  label="Название"
                  placeholder="Название"
                />
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <TextareaField
                  name="description"
                  label="Описание"
                  placeholder="Описание"
                />
              </div>
            </div>
            <SubmitButton
              pristine={pristine}
              submitSucceeded={submitSucceeded}
              dirtySinceLastSubmit={dirtySinceLastSubmit}
            />
          </form>
        )
      }}
    />
  )
}

CreateArchitectureTypeForm.propTypes = {
  projectId: PropTypes.number.isRequired,
  tabId: PropTypes.number.isRequired,
  submit: PropTypes.func.isRequired
}

export default projectForm(CreateArchitectureTypeForm)
