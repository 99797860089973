import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'
import { required } from '../../../../../../../../../../components/Validation'
import projectForm from '../../../../../../components/projectForm'
import { FormField } from '../../../../../../../../../../components/Form'
import SectionPageForm from '../../../../components'
import ObjectSortableList from './ObjectSortableList'
import {
  MapImageFormField,
  getName
} from '../../../../../../../../components/Form'

const getMapImageName = imageType => getName(null, imageType)

const LocationSectionForm = props => {
  const { locationSection, ...rest } = props
  return (
    <SectionPageForm initialValues={locationSection} {...rest}>
      {({ projectId, form }) => {
        return (
          <>
            <div className="row">
              <div className="col formField">
                <FormField
                  name="sectionName"
                  validate={required}
                  type="text"
                  label="Название раздела"
                  placeholder="Название раздела"
                />
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <MapImageFormField
                  label1="Карта"
                  label2="Изображение/иконка"
                  getName={getMapImageName}
                  form={form}
                />
              </div>
            </div>
            <hr className="formField__separator" />
            <div className="row">
              <div className="col">
                <FieldArray name="advantages">
                  {({ fields }) => {
                    return (
                      <ObjectSortableList
                        fields={fields}
                        projectId={projectId}
                        distance={1}
                      />
                    )
                  }}
                </FieldArray>
              </div>
            </div>
          </>
        )
      }}
    </SectionPageForm>
  )
}

LocationSectionForm.propTypes = {
  locationSection: PropTypes.shape({
    sectionName: PropTypes.string.isRequired,
    infrastructureObjects: PropTypes.arrayOf.isRequired
  }).isRequired
}

export default projectForm(LocationSectionForm)
