import {
  PROJECT_ARCHITECTURE_EDIT_ARCHITECTURE_TYPE_SUBMIT_SUCCESS,
  PROJECT_ARCHITECTURE_EDIT_ARCHITECTURE_TYPE_SUBMIT_FAILURE,
  PROJECT_ARCHITECTURE_OBJECT_SUCCESS,
  PROJECT_ARCHITECTURE_OBJECT_FAILURE
} from '../actions'
import updateArchitectureType from './updateArchitectureType'
import createImageSetReducer from '../pages/CreateImageSet/reducers'
import editImageSetReducer from '../pages/EditImageSet/reducers'

const editArchitectureTypeReducer = {
  [PROJECT_ARCHITECTURE_EDIT_ARCHITECTURE_TYPE_SUBMIT_SUCCESS]: updateArchitectureType(
    (_architectureObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_ARCHITECTURE_EDIT_ARCHITECTURE_TYPE_SUBMIT_FAILURE]: (
    state,
    action
  ) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  [PROJECT_ARCHITECTURE_OBJECT_SUCCESS]: updateArchitectureType(
    (_architectureObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_ARCHITECTURE_OBJECT_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  ...createImageSetReducer,
  ...editImageSetReducer
}

export default editArchitectureTypeReducer
