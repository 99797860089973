import updateProject from '../../../../../reducers/updateProject'

const updateTab = reducer =>
  updateProject((project, action) => {
    const { tabId } = action.meta
    const tabs = project.decorationSection.tabs.map(
      decorationTab =>
        decorationTab.id === tabId
          ? { ...decorationTab, ...reducer(decorationTab, action) }
          : decorationTab
    )

    return {
      ...project,
      decorationSection: {
        ...project.decorationSection,
        tabs: tabs
      }
    }
  })

export default updateTab
