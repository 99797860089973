import { branch, renderComponent } from 'recompose'
import { default as Spinner } from './Spinner'

export default Spinner

export const enhanceWithSpinner = (
  predicate,
  WrappedComponent,
  SpinnerComponent = Spinner
) => {
  const enhance = branch(predicate, renderComponent(SpinnerComponent))
  return enhance(WrappedComponent)
}
