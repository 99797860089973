import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const PROJECT_GENERAL_SECTION_REQUEST = 'PROJECT_GENERAL_SECTION_REQUEST'
export const PROJECT_GENERAL_SECTION_SUCCESS = 'PROJECT_GENERAL_SECTION_SUCCESS'
export const PROJECT_GENERAL_SECTION_FAILURE = 'PROJECT_GENERAL_SECTION_FAILURE'

export const fetchProjectGeneralSection = projectId => {
  const success = {
    type: PROJECT_GENERAL_SECTION_SUCCESS,
    meta: {
      projectId
    }
  }
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/general`,
      method: 'get',
      types: [
        PROJECT_GENERAL_SECTION_REQUEST,
        success,
        PROJECT_GENERAL_SECTION_FAILURE
      ]
    }
  }
}

export const PROJECT_GENERAL_SECTION_SUBMIT_REQUEST =
  'PROJECT_GENERAL_SECTION_SUBMIT_REQUEST'
export const PROJECT_GENERAL_SECTION_SUBMIT_SUCCESS =
  'PROJECT_GENERAL_SECTION_SUBMIT_SUCCESS'
export const PROJECT_GENERAL_SECTION_SUBMIT_FAILURE =
  'PROJECT_GENERAL_SECTION_SUBMIT_FAILURE'

export const submitProjectGeneralSection = (projectId, generalSection) => {
  const meta = {
    projectId,
    generalSection
  }
  const request = {
    type: PROJECT_GENERAL_SECTION_SUBMIT_REQUEST,
    meta
  }
  const success = {
    type: PROJECT_GENERAL_SECTION_SUBMIT_SUCCESS,
    meta
  }
  const { isReady = false, announcement = false } = generalSection
  const formData = objectToFormData(generalSection)
  formData.set('isReady', isReady)
  formData.set('announcement', announcement)

  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/general`,
      method: 'put',
      headers: {},
      body: formData,
      types: [request, success, PROJECT_GENERAL_SECTION_SUBMIT_FAILURE]
    }
  }
}

export const PROJECT_GENERAL_SECTION_UPDATE = 'PROJECT_GENERAL_SECTION_UPDATE'

export const refresh = (projectId, generalSection) => {
  return {
    type: PROJECT_GENERAL_SECTION_UPDATE,
    payload: {
      projectId,
      generalSection
    }
  }
}

export const PROJECT_GENERAL_SECTION_PIN_IMAGE_META_REQUEST =
  'PROJECT_GENERAL_SECTION_PIN_IMAGE_META_REQUEST'
export const PROJECT_GENERAL_SECTION_PIN_IMAGE_META_SUCCESS =
  'PROJECT_GENERAL_SECTION_PIN_IMAGE_META_SUCCESS'
export const PROJECT_GENERAL_SECTION_PIN_IMAGE_META_FAILURE =
  'PROJECT_GENERAL_SECTION_PIN_IMAGE_META_FAILURE'

export const fetchPinImageMetaData = (projectId, pinImageType, url) => {
  const success = {
    type: PROJECT_GENERAL_SECTION_PIN_IMAGE_META_SUCCESS,
    meta: {
      projectId,
      pinImageType
    }
  }
  const failure = {
    type: PROJECT_GENERAL_SECTION_PIN_IMAGE_META_FAILURE,
    meta: {
      projectId,
      pinImageType
    }
  }
  return {
    [RSAA]: {
      endpoint: url,
      method: 'get',
      types: [PROJECT_GENERAL_SECTION_PIN_IMAGE_META_REQUEST, success, failure]
    }
  }
}
