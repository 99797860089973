import { fork, call } from 'redux-saga/effects'
import watchCreateObjectSaga from './watchCreateObjectSaga'
import fetchPublicSpacesSectionSaga from '../../../sagas/fetchPublicSpacesSectionSaga'

const createTabSagaRoute = {
  '/projects/:projectId/publicSpaces/tabs': function* runCreatePublicSpacesTabSaga({
    projectId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const publicSpacesSection = yield call(
      fetchPublicSpacesSectionSaga,
      projectIdNumber
    )
    if (publicSpacesSection) {
      yield fork(watchCreateObjectSaga, projectIdNumber)
    }
  }
}

export default createTabSagaRoute
