import { fork } from 'redux-saga/effects'
import fetchPushesSaga from './fetchPushesSaga'
import createPushSagaRoute from '../pages/AddPushForm/sagas'

const PushesSagaRoute = {
  '/pushes': function* runPushSaga() {
    yield fork(fetchPushesSaga)
  },
  ...createPushSagaRoute
}

export default PushesSagaRoute
