import { call, fork } from 'redux-saga/effects'

function* sectionRouteSaga(projectId, fetchSectionSaga, watchSectionSaga) {
  yield call(fetchSectionSaga, parseInt(projectId, 10))
  if (watchSectionSaga) {
    yield fork(watchSectionSaga)
  }
}

const createSectionSagaRoute = (
  section,
  fetchSectionSaga,
  watchSectionSaga
) => {
  const sectionRoute = `/projects/:projectId/${section}`
  const sectionRouteChildren = `/projects/:projectId/${section}/*`

  const sectionSagaRoute = {
    [sectionRoute]: function* runProjectSectionSaga({ projectId }) {
      yield call(
        sectionRouteSaga,
        projectId,
        fetchSectionSaga,
        watchSectionSaga
      )
    },
    [sectionRouteChildren]: function* runProjectSectionSaga({ projectId }) {
      yield call(
        sectionRouteSaga,
        projectId,
        fetchSectionSaga,
        watchSectionSaga
      )
    }
  }

  return sectionSagaRoute
}

export default createSectionSagaRoute
