import { RSAA } from 'redux-api-middleware'
import { tokenProp } from '../pages/Login/reducers/selectors'

const addAuthorizationHeader = (headers = {}) => state => {
  const token = tokenProp(state)
  if (token === null) {
    return headers
  }

  const headersWithToken = {
    ...headers,
    credentials: 'include',
    Authorization: `Bearer ${token}`
  }

  return headersWithToken
}

export default store => next => action => {
  const apiCallAction = action[RSAA]

  if (typeof apiCallAction === 'undefined') {
    return next(action)
  }

  const { endpoint, headers, types } = apiCallAction

  if (endpoint === '/config.json') {
    return next(action)
  }

  const state = store.getState()
  const mergeHeaders = addAuthorizationHeader(headers)
  const failureType =
    typeof types[2] === 'object' ? types[2] : { type: types[2] }
  const failureWithRSAA = {
    ...failureType,
    meta: {
      ...failureType.meta,
      __RSAA: apiCallAction
    }
  }
  const newTypes = apiCallAction.types.map((type, index) => {
    return index === 2 ? failureWithRSAA : type
  })
  const newAction = {
    ...apiCallAction,
    headers: mergeHeaders(state),
    types: newTypes
  }
  const newApiCall = {
    [RSAA]: newAction
  }

  return next(newApiCall)
}
