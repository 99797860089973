import {
  PROJECT_CREATE_PROJECT_SUCCESS,
  PROJECT_CREATE_PROJECT_FAILURE
} from '../actions'
import { mergeProjects } from '../../../reducers'

const addProjectReducer = {
  [PROJECT_CREATE_PROJECT_SUCCESS]: (state, action) => {
    const projects = mergeProjects(state.projects, action.payload)
    return {
      ...state,
      projects,
      error: null
    }
  },
  [PROJECT_CREATE_PROJECT_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  }
}

export default addProjectReducer
