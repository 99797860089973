import createProjectReducer from '../../../reducers/createProjectReducer'

export const updateProject = projectReducer => (state, action) => {
  const reducer = createProjectReducer(projectReducer)
  return reducer(state, action)
}

const createProjectSectionReducer = sectionName => (project, action) => {
  return {
    ...project,
    [sectionName]: {
      ...project[sectionName],
      ...action.payload,
      ...action.meta[sectionName]
    }
  }
}

const getErrorProjectState = (state, action) => {
  return {
    ...state,
    error: action.payload.response
  }
}

const createSectionReducer = (
  sectionName,
  fetchSectionActions,
  submitSectionActions
) => {
  const [fetchSuccesAction, fetchFailureAction] = fetchSectionActions
  const [submitSuccesAction, submitFailureAction] = submitSectionActions
  const projectReducer = createProjectSectionReducer(sectionName)
  const locationSectionReducer = {
    [fetchSuccesAction]: updateProject(projectReducer),
    [fetchFailureAction]: getErrorProjectState,
    [submitSuccesAction]: updateProject(projectReducer),
    [submitFailureAction]: getErrorProjectState
  }
  return locationSectionReducer
}

export default createSectionReducer
