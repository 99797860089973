import { put, all, fork } from 'redux-saga/effects'
import { fetchPinImageMetaData } from '../actions'

function* fetchPinImageMetaDataSaga(projectId, iconType, pinImageMeta) {
  const { metaUrl, type } = pinImageMeta
  if (metaUrl) {
    yield put(fetchPinImageMetaData(projectId, iconType, type, metaUrl))
  }
}

function* fetchLocationSectionPinImagesSaga(projectId, locationSection) {
  const images = Object.keys(locationSection)
    .filter(key => key.endsWith('Image'))
    .map(key => {
      return {
        ...locationSection[key],
        type: key,
        iconType: key.startsWith('map') ? 'Map' : 'Icon'
      }
    })
    .filter(t => t.metaUrl)

  const fetchTasks = images.map(pinImageMeta =>
    fork(
      fetchPinImageMetaDataSaga,
      projectId,
      pinImageMeta.iconType,
      pinImageMeta
    )
  )
  yield all(fetchTasks)
}

export default fetchLocationSectionPinImagesSaga
