import { fork, call } from 'redux-saga/effects'
import watchImageSetCreatedSaga from './watchImageSetCreatedSaga'
import fetchObjectSaga from '../../../sagas/fetchObjectSaga'

const createImageSetSagaRoute = {
  '/projects/:projectId/architecture/tabs/:tabId/architecture-type/:objectId/images/add': function* runCreateImageSetSaga({
    projectId,
    tabId,
    objectId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const tabIdNumber = parseInt(projectId, 10)
    const objectIdNumber = parseInt(objectId, 10)
    const architectureObject = yield call(
      fetchObjectSaga,
      projectIdNumber,
      tabIdNumber,
      objectIdNumber
    )
    if (architectureObject) {
      yield fork(
        watchImageSetCreatedSaga,
        projectIdNumber,
        tabIdNumber,
        objectIdNumber
      )
    }
  }
}

export default createImageSetSagaRoute
