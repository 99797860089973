import { take, fork, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import fetchImageSetSaga from './fetchImageSetSaga'
import { BLOCKS_EDIT_IMAGE_SET_SUCCESS } from '../actions'

function* watchImageSetUpdatedSaga(blockIdNumber) {
  while (true) {
    yield take(BLOCKS_EDIT_IMAGE_SET_SUCCESS)
    yield fork(fetchImageSetSaga, blockIdNumber)
    yield put(push(`/about`))
  }
}

export default watchImageSetUpdatedSaga
