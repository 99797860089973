import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const PROJECTS_EDIT_IMAGE_SET_REQUEST = 'PROJECTS_EDIT_IMAGE_SET_REQUEST'
export const PROJECTS_EDIT_IMAGE_SET_SUCCESS = 'PROJECTS_EDIT_IMAGE_SET_SUCCESS'
export const PROJECTS_EDIT_IMAGE_SET_FAILURE = 'PROJECTS_EDIT_IMAGE_SET_FAILURE'

export const submit = (projectId, id, images) => {
  const success = {
    type: PROJECTS_EDIT_IMAGE_SET_SUCCESS,
    meta: {
      projectId,
      id,
      images
    }
  }
  const formData = objectToFormData(images)

  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/images/list/${id}`,
      method: 'put',
      types: [
        PROJECTS_EDIT_IMAGE_SET_REQUEST,
        success,
        PROJECTS_EDIT_IMAGE_SET_FAILURE
      ],
      body: formData
    }
  }
}

export const PROJECT_IMAGES_SECTION_IMAGE_META_REQUEST =
  'PROJECT_IMAGES_SECTION_IMAGE_META_REQUEST'
export const PROJECT_IMAGES_SECTION_IMAGE_META_SUCCESS =
  'PROJECT_IMAGES_SECTION_IMAGE_META_SUCCESS'
export const PROJECT_IMAGES_SECTION_IMAGE_META_FAILURE =
  'PROJECT_IMAGES_SECTION_IMAGE_META_FAILURE'

export const fetchImageMetaData = (projectId, imageType, id, url) => {
  const success = {
    type: PROJECT_IMAGES_SECTION_IMAGE_META_SUCCESS,
    meta: {
      projectId,
      imageType,
      id
    }
  }
  const failure = {
    type: PROJECT_IMAGES_SECTION_IMAGE_META_FAILURE,
    meta: {
      projectId,
      imageType,
      id
    }
  }
  return {
    [RSAA]: {
      endpoint: url,
      method: 'get',
      types: [PROJECT_IMAGES_SECTION_IMAGE_META_REQUEST, success, failure]
    }
  }
}
