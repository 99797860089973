import { call } from 'redux-saga/effects'
import fetchDecorationTabSaga from './fetchDecorationTabSaga'

const editTabSagaRoute = {
  '/projects/:projectId/decoration/tabs/:tabId': function* runEditDecorationTabSaga({
    projectId,
    tabId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const tabIdNumber = parseInt(tabId, 10)
    yield call(fetchDecorationTabSaga, projectIdNumber, tabIdNumber)
  }
}

export default editTabSagaRoute
