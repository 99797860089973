import { fork, call } from 'redux-saga/effects'
import watchCreateObjectSaga from './watchCreateObjectSaga'
import fetchAccomplishmentSectionSaga from '../../../../../sagas/fetchAccomplishmentSectionSaga'

const createObjectSagaRoute = {
  '/projects/:projectId/accomplishment/tabs/:tabId/accomplishment-type': function* runCreateAccomplishmentTypeSaga({
    projectId,
    tabId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const tabIdNumber = parseInt(tabId, 10)
    const accomplishmentSection = yield call(
      fetchAccomplishmentSectionSaga,
      projectIdNumber
    )
    if (accomplishmentSection) {
      yield fork(watchCreateObjectSaga, projectIdNumber, tabIdNumber)
    }
  }
}

export default createObjectSagaRoute
