import { connect } from 'react-redux'
import { submitPush } from './actions'
import { PushForm as PushFormComponent } from './components'

const mapStateToProps = ({ pushes: { loading } }) => {
  return { loading }
}

export default connect(
  mapStateToProps,
  {
    submit: submitPush
  }
)(PushFormComponent)
