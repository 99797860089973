import { put, call, all } from 'redux-saga/effects'
import { fetchImageMetaData } from '../actions'
import fetchAboutSaga from '../../../sagas/fetchAboutSaga'

function* fetchImageMetaDataSaga(blockId, imageMeta, imageSetId) {
  const { metaUrl, type } = imageMeta
  yield put(fetchImageMetaData(blockId, type, metaUrl, imageSetId))
}

function* fetchImageSetSaga(blockId, imageSetId = null) {
  const about = yield call(fetchAboutSaga)
  if (about) {
    const { title, description, previewImageUrl, images, ...imageSet } = about[
      `block${blockId}`
    ]
    const curImageSet = imageSetId
      ? images.filter(image => image.id === imageSetId)[0]
      : imageSet
    const newImages = Object.keys(curImageSet)
      .map(key => {
        return {
          ...curImageSet[key],
          type: key
        }
      })
      .filter(t => t.metaUrl)
    const fetchTasks = newImages.map(imageMeta =>
      call(fetchImageMetaDataSaga, blockId, imageMeta, imageSetId)
    )
    yield all(fetchTasks)

    return true
  }

  return null
}

export default fetchImageSetSaga
