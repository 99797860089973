import {
  PROJECT_ACCOMPLISHMENT_EDIT_TAB_SUBMIT_SUCCESS,
  PROJECT_ACCOMPLISHMENT_EDIT_TAB_SUBMIT_FAILURE,
  PROJECT_ACCOMPLISHMENT_TAB_SUCCESS,
  PROJECT_ACCOMPLISHMENT_TAB_FAILURE
} from '../actions'
import createAccomplishmentTypeReducer from '../pages/CreateAccomplishmentType/reducers'
import editAccomplishmentTypeReducer from '../pages/EditAccomplishmentType/reducers'
import updateTab from './updateTab'

const editTabReducer = {
  [PROJECT_ACCOMPLISHMENT_EDIT_TAB_SUBMIT_SUCCESS]: updateTab(
    (_accomplishmentObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_ACCOMPLISHMENT_EDIT_TAB_SUBMIT_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  [PROJECT_ACCOMPLISHMENT_TAB_SUCCESS]: updateTab(
    (_accomplishmentObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_ACCOMPLISHMENT_TAB_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  ...createAccomplishmentTypeReducer,
  ...editAccomplishmentTypeReducer
}

export default editTabReducer
