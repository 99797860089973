import { fork, call } from 'redux-saga/effects'
import watchEditObjectSaga from './watchEditObjectSaga'
import fetchLocationObjectSaga from './fetchLocationObjectSaga'

const editObjectSagaRoute = {
  '/projects/:projectId/location/objects/:id': function* runCreateLocationSaga({
    projectId,
    id
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const idNumber = parseInt(id, 10)
    const locationObject = yield call(
      fetchLocationObjectSaga,
      projectIdNumber,
      idNumber
    )
    if (locationObject) {
      yield fork(watchEditObjectSaga, projectIdNumber)
    }
  }
}

export default editObjectSagaRoute
