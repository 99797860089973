import React from 'react'
import { createFileUploadField } from '../../../../components/Form'
import DocumentUpload from '../DocumentUpload'

const DocumentFileUpload = ({ value, ...rest }) => {
  return <DocumentUpload document={value} {...rest} />
}

const DocumentUploadFormField = createFileUploadField(DocumentFileUpload)

export default DocumentUploadFormField
