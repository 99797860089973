import { put, all, fork, select } from 'redux-saga/effects'
import { fetchPinImageMetaData } from '../actions'
import { teamSectionProp } from '../reducers/selectors'

function* fetchPinImageMetaDataSaga(projectId, pinImageMeta) {
  const { metaUrl, type } = pinImageMeta
  if (metaUrl) {
    yield put(fetchPinImageMetaData(projectId, type, metaUrl))
  }
}

function* fetchTeamSectionPinImagesSaga(projectId, teamSection) {
  const images = Object.keys(teamSection)
    .filter(key => key.endsWith('Image'))
    .map(key => {
      return {
        ...teamSection[key],
        type: key
      }
    })
    .filter(t => t.metaUrl)

  const fetchTasks = images.map(pinImageMeta =>
    fork(fetchPinImageMetaDataSaga, projectId, pinImageMeta)
  )
  yield all(fetchTasks)
}

export function* fetchPinImageFilesSaga(projectId) {
  const originalTeamSection = yield select(teamSectionProp(projectId))

  yield fork(fetchTeamSectionPinImagesSaga, projectId, originalTeamSection)
}

export default fetchTeamSectionPinImagesSaga
