import { connect } from 'react-redux'
import { default as ProjectSortedListComponent } from './ProjectSortedList'
import { projectsProp, projectsErrorProp } from '../../reducers/selectors'
import { move, deleteProject, hideProject } from '../../actions'

const mapStateToProps = state => {
  return {
    projects: projectsProp(state),
    error: projectsErrorProp(state)
  }
}

const ProjectSortedList = connect(
  mapStateToProps,
  {
    move,
    deleteProject,
    hideProject
  }
)(ProjectSortedListComponent)

export default ProjectSortedList
