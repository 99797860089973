import { default as editTabSagaRoute } from './editTabSagaRoute'
import createDecorationTypeSagaRoute from '../pages/CreateDecorationType/sagas'
import editDecorationTypeSagaRoute from '../pages/EditDecorationType/sagas'

export {
  default as watchDecorationTabSubmitted
} from './watchDecorationTabSubmitted'

export default {
  ...editTabSagaRoute,
  ...editDecorationTypeSagaRoute,
  ...createDecorationTypeSagaRoute
}
