import { fork, call } from 'redux-saga/effects'
import watchImageSetUpdatedSaga from './watchImageSetUpdatedSaga'
import fetchImageSetSaga from './fetchImageSetSaga'

const editImageSetSagaRoute = {
  '/projects/:projectId/images/list/edit/:imageSetId': function* runEditImageSetSaga({
    projectId,
    imageSetId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const imageSet = yield call(
      fetchImageSetSaga,
      projectIdNumber,
      parseInt(imageSetId, 10)
    )
    if (imageSet) {
      yield fork(watchImageSetUpdatedSaga, projectIdNumber)
    }
  }
}

export default editImageSetSagaRoute
