import { put, race, take, select } from 'redux-saga/effects'
import { fetchPushes, PUSH_LIST_FAILURE, PUSH_LIST_SUCCESS } from '../actions'
import { pushes } from '../reducers/selectors'

function* fetchPushesSaga() {
  const pushesProps = yield select(pushes)
  if (!pushesProps) {
    yield put(fetchPushes())
  }
  const { success } = yield race({
    success: take(PUSH_LIST_SUCCESS),
    failure: take(PUSH_LIST_FAILURE)
  })

  if (success) {
    return success.payload
  }

  return null
}

export default fetchPushesSaga
