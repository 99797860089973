import { connect } from 'react-redux'
import { Projects as ProjectsComponent } from './components'
import {
  projectsProp,
  projectsErrorProp,
  getProject
} from './reducers/selectors'
import { enhanceWithSpinner } from '../../../../components'

const ProjectsWithSpinner = enhanceWithSpinner(
  ({ projects }) => projects === null || projects === undefined,
  ProjectsComponent
)

const mapStateToProps = state => {
  return {
    projects: projectsProp(state),
    error: projectsErrorProp(state),
    getProject: getProject(state)
  }
}

const Projects = connect(
  mapStateToProps,
  {}
)(ProjectsWithSpinner)

export default Projects
