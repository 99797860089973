import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_ADVANTAGES_CREATE_GALLERY_IMAGE_SET_SUBMIT_SUCCESS } from '../actions'

function* watchImageSetCreatedSaga(projectId, galleryId) {
  while (true) {
    yield take(PROJECT_ADVANTAGES_CREATE_GALLERY_IMAGE_SET_SUBMIT_SUCCESS)
    yield put(push(`/projects/${projectId}/advantages/${galleryId}`))
  }
}

export default watchImageSetCreatedSaga
