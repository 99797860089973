import { fork } from 'redux-saga/effects'
import fetchRatesSaga from './fetchRatesSaga'
import watchFilterChange from './watchFilterChange'

const RatesSagaRoute = {
  '/stats/rates': function* runRatesSaga() {
    yield fork(fetchRatesSaga)
    yield fork(watchFilterChange, fetchRatesSaga)
  }
}

export default RatesSagaRoute
