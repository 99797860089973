import updateProject from '../../../../../reducers/updateProject'

const updateAdvantage = reducer =>
  updateProject((project, action) => {
    const { advantageId } = action.meta
    const advantages = project.advantagesSection.advantages.map(
      advantage =>
        advantage.id === advantageId
          ? { ...advantage, ...reducer(advantage, action) }
          : advantage
    )

    return {
      ...project,
      advantagesSection: {
        ...project.advantagesSection,
        advantages
      }
    }
  })

export default updateAdvantage
