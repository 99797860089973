import { createFormField } from '../../../../../../../../../../components/Form'
import { default as MonthSelect } from './MonthSelect'

const createOnChange = (form, name) => value => {
  form.change(name, value)
  form.blur(name)
}

const getProps = ({ form, input, name }) => {
  const value = input.value
  const onChange = createOnChange(form, name)
  if (value && value !== '') {
    const date = new Date(value)
    return {
      date,
      onChange
    }
  }
  return {
    date: null,
    onChange
  }
}

export default createFormField(getProps)(MonthSelect)
