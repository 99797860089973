import { connect } from 'react-redux'
import { default as LoginFormComponent } from './LoginForm'
import { login } from '../actions'

const Login = connect(
  null,
  {
    login
  }
)(LoginFormComponent)

export default Login
