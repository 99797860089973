import React from 'react'
import PropTypes from 'prop-types'
import { PageTitle } from '../../../components'
import { Route, Switch } from 'react-router-dom'
import AboutForm from './AboutForm'
import EditImageSetForm from '../pages/EditImageSet'

export const About = ({ about, submit }) => {
  return (
    <div className="about editProjectForm">
      <PageTitle
        title={() => {
          return <h2>О компании</h2>
        }}
      />
      <Switch>
        <Route
          exact
          path="/about/blocks/:blockId/images/:imageSetId"
          render={({
            match: {
              params: { blockId, imageSetId }
            }
          }) => {
            const { title, description, previewImageUrl, ...images } = about[
              `block${blockId}`
            ]
            return (
              <EditImageSetForm
                blockId={blockId}
                imageSetId={imageSetId}
                images={images}
              />
            )
          }}
        />
        <Route
          exact
          path="/about/blocks/:blockId/images"
          render={({
            match: {
              params: { blockId }
            }
          }) => {
            const { title, description, previewImageUrl, ...images } = about[
              `block${blockId}`
            ]
            return <EditImageSetForm blockId={blockId} images={images} />
          }}
        />
        <Route
          path="/about"
          render={() => {
            return <AboutForm about={about} submit={submit} />
          }}
        />
      </Switch>
    </div>
  )
}

About.propTypes = {
  about: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired
}
