import React from 'react'
import PropTypes from 'prop-types'
import { ImageMobile, ImageTablet } from '../../ImageUpload'
import ImageType from './ImageType'
import { default as getName } from './getName'

const defaultGetName = imageType => getName(null, imageType)

const MobileTabletImageFormField = ({
  label = 'Изображение',
  form,
  validate,
  ...rest
}) => {
  const imageGetName = rest.getName ? rest.getName : defaultGetName
  return [
    <div className="row" key="label">
      <div className="col">
        <h4>{label}</h4>
      </div>
    </div>,
    <div className="row" key="images">
      <div className="col">
        <label className="formField__label">Mobile</label>
        <div className="imageGrid">
          <ImageMobile
            validate={validate}
            name={imageGetName(ImageType.mobile)}
            form={form}
            size="big"
          />
        </div>
      </div>
      <div className="col">
        <label className="formField__label">Tablet</label>
        <div className="imageGrid">
          <ImageTablet
            name={imageGetName(ImageType.tablet)}
            form={form}
            size="big"
          />
        </div>
      </div>
    </div>
  ]
}

MobileTabletImageFormField.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  getName: PropTypes.func
}

export default MobileTabletImageFormField
