import { LOCATION_CHANGE } from 'connected-react-router'
import { takeLatest, take, fork } from 'redux-saga/effects'
import { EDIT_OBJECT_SUBMIT_SUCCESS } from '../actions'
import fetchObjectSaga from './fetchObjectSaga'

function* refreshImages(action) {
  const {
    meta: { projectId, objectId }
  } = action
  yield take(LOCATION_CHANGE)
  yield fork(fetchObjectSaga, projectId, objectId)
}

function* watchObjectSubmitted() {
  yield takeLatest(EDIT_OBJECT_SUBMIT_SUCCESS, refreshImages)
}

export default watchObjectSubmitted
