import { take, race, put, fork } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  loggedInUser,
  fetchCurrentUser
} from '../actions'

function* watchAuthenticationRequests() {
  while (true) {
    const request = yield take(LOGIN_USER_REQUEST)
    const { userName } = request.meta
    const { success, failure } = yield race({
      success: take(LOGIN_USER_SUCCESS),
      failure: take(LOGIN_USER_FAILURE)
    })

    if (success) {
      yield put(loggedInUser(userName, success.payload))
      yield put(fetchCurrentUser(success.payload.access_token))
      yield put(push('/'))
    } else if (failure) {
      console.log('login failed')
    }
  }
}

function* loginSaga() {
  yield fork(watchAuthenticationRequests)
}

export default loginSaga
