import updateTab from '../../../reducers/updateTab'

const updatePublicSpacesType = reducer =>
  updateTab((tab, action) => {
    const { objectId } = action.meta
    const publicSpacesTypes = tab.objects.map(
      publicSpacesType =>
        publicSpacesType.id === objectId
          ? { ...publicSpacesType, ...reducer(publicSpacesType, action) }
          : publicSpacesType
    )

    return {
      ...tab,
      objects: publicSpacesTypes
    }
  })

export default updatePublicSpacesType
