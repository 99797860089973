import { put, select, race, take, fork } from 'redux-saga/effects'
import {
  fetchProjects,
  PROJECTS_LIST_SUCCESS,
  PROJECTS_LIST_FAILURE,
  fetchCrmProjects,
  PROJECTS_CRM_LIST_SUCCESS,
  PROJECTS_CRM_LIST_FAILURE
} from '../actions'
import { projectsProp } from '../reducers/selectors'

function* fetchCrmProjectsSaga() {
  yield put(fetchCrmProjects())
  yield race({
    success: take(PROJECTS_CRM_LIST_SUCCESS),
    failure: take(PROJECTS_CRM_LIST_FAILURE)
  })
}

function* fetchProjectsSaga() {
  const projects = yield select(projectsProp)
  if (projects) {
    return projects
  }
  yield put(fetchProjects())
  const { success } = yield race({
    success: take(PROJECTS_LIST_SUCCESS),
    failure: take(PROJECTS_LIST_FAILURE)
  })

  if (success) {
    yield fork(fetchCrmProjectsSaga)
    return success.payload
  }

  return null
}

export default fetchProjectsSaga
