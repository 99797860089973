import { fork, call } from 'redux-saga/effects'
import watchAdvantageUpdatedSaga from './watchAdvantageUpdatedSaga'
import fetchAdvantageSaga from './fetchAdvantageSaga'

const editAdvantageSagaRoute = {
  '/projects/:projectId/advantages/:advantageId': function* runEditAdvantageSaga({
    projectId,
    advantageId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const advantageIdNumber = parseInt(advantageId, 10)
    const program = yield call(
      fetchAdvantageSaga,
      projectIdNumber,
      advantageIdNumber
    )
    if (program) {
      yield fork(watchAdvantageUpdatedSaga, projectIdNumber)
    }
  }
}

export default editAdvantageSagaRoute
