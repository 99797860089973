import imageSectionReducer from './imageSectionReducer'
import createImageSetReducer from '../pages/CreateImageSet/reducers'
import editImageSetReducer from '../pages/EditImageSet/reducers'

const imagesSectionReducer = {
  ...imageSectionReducer,
  ...createImageSetReducer,
  ...editImageSetReducer
}

export default imagesSectionReducer
