import createSectionPage from '../../createSectionPage'
import ArchitectureSectionPage from './components'
import { submit } from './actions'

const ArchitectureSectionRoutePage = createSectionPage(
  'architecture',
  null,
  submit
)(ArchitectureSectionPage)

export default ArchitectureSectionRoutePage
