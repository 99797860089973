import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { required } from '../../../../../../../../../../../../components/Validation'
import {
  FormField,
  TextareaField
} from '../../../../../../../../../../../../components/Form'
import projectForm from '../../../../../../../../components/projectForm'
import { SubmitButton } from '../../../../../../../../../../components/Buttons'
import {
  MobileTabletImageFormField,
  getName
} from '../../../../../../../../../../components/Form'
import './mortgageForm.css'

const getKeyProjectName = imageType => getName('keyProject', imageType)
const getMemberName = imageType => getName('member', imageType)

const focusOnErrors = createDecorator()

const onSubmit = (projectId, id, submit) => teamObject => {
  submit(projectId, id, teamObject)
}

const EditObjectForm = ({ projectId, objectId, submit, teamObject }) => {
  return (
    <Form
      initialValues={teamObject}
      onSubmit={onSubmit(projectId, objectId, submit)}
      decorators={[focusOnErrors]}
      render={({
        form,
        handleSubmit,
        submitting,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <Link
                  to={`/projects/${projectId}/team`}
                  className="formField__back"
                >
                  Назад
                </Link>
              </div>
            </div>
            <hr className="formField__separator" />
            <div className="row">
              <div className="col">
                <h4>Участник</h4>
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <FormField
                  name="name"
                  validate={required}
                  type="text"
                  label="Имя Фамилия / Название компании"
                  placeholder="Имя Фамилия / Название компании"
                />
              </div>
              <div className="col formField">
                <FormField
                  name="jobPosition"
                  validate={required}
                  type="text"
                  label="Должность / Вид деятельности"
                  placeholder="Должность / Вид деятельности"
                />
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <TextareaField
                  name="description"
                  type="text"
                  label="Описание"
                  placeholder="Описание"
                />
              </div>
            </div>
            <MobileTabletImageFormField
              form={form}
              getName={getMemberName}
              label="Изображение команды"
              validate={required}
            />
            <div className="row">
              <div className="col">
                <h4>Ключевой проект</h4>
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <FormField
                  name="keyName"
                  type="text"
                  label="Название"
                  placeholder="Название"
                />
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <FormField
                  name="teaser"
                  type="text"
                  label="Тизер"
                  placeholder="Тизер"
                />
              </div>
            </div>
            <MobileTabletImageFormField
              form={form}
              getName={getKeyProjectName}
              label="Изображение проекта"
            />
            <SubmitButton
              pristine={pristine}
              submitSucceeded={submitSucceeded}
              dirtySinceLastSubmit={dirtySinceLastSubmit}
            />
          </form>
        )
      }}
    />
  )
}

EditObjectForm.propTypes = {
  projectId: PropTypes.number.isRequired,
  objectId: PropTypes.number.isRequired,
  submit: PropTypes.func.isRequired,
  teamObject: PropTypes.object.isRequired
}

export default projectForm(EditObjectForm)
