import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import './styles.css'

const Header = memo(({ user: { userName }, logout }) => {
  return (
    <div className="app-header">
      <div className="container nav justify-content-end">
        <div className="app-header__user nav-item">
          <span className="app-header__name">
            <NavLink to="/account">{userName}</NavLink>
          </span>
          <button
            className="btn btn-secondary btn--noPadding app-header__exit"
            onClick={logout}
          />
        </div>
      </div>
    </div>
  )
})

Header.propTypes = {
  user: PropTypes.shape({
    userName: PropTypes.string.isRequired
  }),
  logout: PropTypes.func.isRequired
}

export default Header
