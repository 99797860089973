import { fork, call } from 'redux-saga/effects'
import watchImageSetUpdatedSaga from './watchImageSetUpdatedSaga'
import fetchImageSetSaga from './fetchImageSetSaga'

const editImageSetSagaRoute = {
  '/about/blocks/:blockId/images': function* runEditImageSetSaga({ blockId }) {
    const blockIdNumber = parseInt(blockId, 10)
    const imageSet = yield call(fetchImageSetSaga, blockIdNumber)
    if (imageSet) {
      yield fork(watchImageSetUpdatedSaga, blockIdNumber)
    }
  },
  '/about/blocks/:blockId/images/:imageSetId(\\d+)': function* runEditImageSetSaga({
    blockId,
    imageSetId
  }) {
    const blockIdNumber = parseInt(blockId, 10)
    const imageSetIdNumber = parseInt(imageSetId, 10)
    const imageSet = yield call(
      fetchImageSetSaga,
      blockIdNumber,
      imageSetIdNumber
    )
    if (imageSet) {
      yield fork(watchImageSetUpdatedSaga, blockIdNumber, imageSetIdNumber)
    }
  }
}

export default editImageSetSagaRoute
