import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import { required } from '../../../../../../../../../../components/Validation'
import projectForm from '../../../../../../components/projectForm'
import { FormField } from '../../../../../../../../../../components/Form'
import { SubmitButton } from '../../../../../../../../components/Buttons'
import GalleryListField from '../GalleryListField'
import './constructionProgressSection.css'
import BroadcastArrayFormField from '../BroadcastArrayFormField'

const focusOnErrors = createDecorator()

const onSubmit = (projectId, submit) => mortgageSection => {
  submit(projectId, mortgageSection)
}

const ConstructionProgressForm = props => {
  const { projectId, submit, constructionProgressSection } = props
  if (constructionProgressSection.broadcasts.length === 0) {
    constructionProgressSection.broadcasts.push({
      name: '',
      link: ''
    })
  }
  return (
    <Form
      initialValues={constructionProgressSection}
      mutators={{
        ...arrayMutators
      }}
      onSubmit={onSubmit(projectId, submit)}
      decorators={[focusOnErrors]}
      render={({
        handleSubmit,
        submitting,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col formField">
                <FormField
                  name="sectionName"
                  validate={required}
                  type="text"
                  label="Название раздела"
                  placeholder="Название раздела"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <BroadcastArrayFormField />
              </div>
            </div>
            <div className="galleryList__head">
              <h4>Галереи</h4>
              <Link
                to={`/projects/${projectId}/construction/galleries/add`}
                className="btn btn-warning btn-sm"
              >
                Добавить
              </Link>
            </div>
            <div className="row">
              <div className="col">
                <GalleryListField name="galleries" projectId={projectId} />
              </div>
            </div>
            <SubmitButton
              pristine={pristine}
              submitSucceeded={submitSucceeded}
              dirtySinceLastSubmit={dirtySinceLastSubmit}
            />
          </form>
        )
      }}
    />
  )
}

ConstructionProgressForm.propTypes = {
  submit: PropTypes.func.isRequired
}

export default projectForm(ConstructionProgressForm)
