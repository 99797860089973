import updateDecorationType from '../../../reducers/updateDecorationType'

const updateImageSet = reducer =>
  updateDecorationType((decorationType, action) => {
    const { id } = action.meta
    const imageSets = decorationType.imageSets.map(
      imageSet =>
        imageSet.id === id
          ? { ...imageSet, ...reducer(imageSet, action) }
          : imageSet
    )

    return {
      ...decorationType,
      imageSets
    }
  })

export default updateImageSet
