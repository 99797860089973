import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_DECORATION_CREATE_GALLERY_IMAGE_SET_SUBMIT_SUCCESS } from '../actions'

function* watchImageSetCreatedSaga(projectId, tabId, objectId) {
  while (true) {
    yield take(PROJECT_DECORATION_CREATE_GALLERY_IMAGE_SET_SUBMIT_SUCCESS)
    yield put(
      push(
        `/projects/${projectId}/decoration/tabs/${tabId}/decoration-type/${objectId}`
      )
    )
  }
}

export default watchImageSetCreatedSaga
