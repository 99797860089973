import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { SortableElement } from 'react-sortable-hoc'
import { ActionPopup } from '../../../../components/UniversalPopup'

class Item extends PureComponent {
  onHideHandler = () => {
    const { hideProject, project } = this.props
    hideProject(project.id)
  }

  render() {
    const { project, showPopup } = this.props
    return (
      <div className="projectSortedList__item">
        <div className="projectSortedList__dots" title="Перенести проект" />
        <Link
          to={`/projects/${project.id}/general`}
          className="projectSortedList__link"
        >
          {project.name}
        </Link>
        <button
          onClick={this.onHideHandler}
          className="btn btn--noPadding btn--invisible"
          title="Скрыть проект"
        />
        <button
          onClick={showPopup}
          className="btn btn--noPadding btn--delete"
          title="Удалить проект"
        />
      </div>
    )
  }
}

const SortableItem = SortableElement(Item)

class SortableItemWithPopup extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      popupDeleteOpened: false
    }
  }

  togglePopup = popup => {
    this.setState({ [popup]: !this.state[popup] })
  }

  createOnDelete = () => {
    const { project, deleteProject } = this.props
    return deleteProject(project.id)
  }

  render() {
    const { project, index, ...rest } = this.props
    const confirmationPopup = this.state.popupDeleteOpened ? (
      <ActionPopup
        title={() => 'Подтвердить удаление?'}
        confirmText="Да"
        onConfirm={this.createOnDelete}
        onCancel={this.togglePopup.bind(this, 'popupDeleteOpened')}
        isOpened={this.state.popupDeleteOpened}
      />
    ) : null

    return (
      <>
        <SortableItem
          index={index}
          key={project.id}
          project={project}
          showPopup={this.togglePopup.bind(this, 'popupDeleteOpened')}
          {...rest}
        />
        {confirmationPopup}
      </>
    )
  }
}

SortableItemWithPopup.propTypes = {
  project: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}

export default SortableItemWithPopup
