import { LOAD_CONFIG_SUCCESS } from '../actions'

const initialState = {
  apiRoot: null
}

const config = (state = initialState, action) => {
  const { type, payload } = action

  if (type === LOAD_CONFIG_SUCCESS) {
    const { apiRoot } = payload
    return { apiRoot }
  }

  return state
}

export default config
