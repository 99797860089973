import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import UserItem from '../User/UserItem'
import EditUserForm from '../../pages/EditUserForm'
import { Link, Redirect, Route, Switch } from 'react-router-dom'
import { PageTitle } from '../../../../components'
import AddUserForm from '../../pages/AddUserForm'
import Select from '../../../../components/Select'
import './userList.css'

class UserList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      currentRole: 'all'
    }
  }

  roleFilterChangeHandler = ev => {
    this.setState({ currentRole: ev.target.value })
  }

  render() {
    const { users, deleteUser } = this.props
    const userList = users
      .filter(
        user =>
          this.state.currentRole === 'all' ||
          user.role === this.state.currentRole
      )
      .map(user => (
        <UserItem user={user} deleteUser={deleteUser} key={user.login} />
      ))

    return (
      <>
        <Switch>
          <Route
            path="/(users|users/add)"
            exact
            render={() => {
              return (
                <>
                  <PageTitle
                    title={() => {
                      return (
                        <Switch>
                          <Route exact path="/(users|users/add)">
                            <h2>Пользователи</h2>
                          </Route>
                        </Switch>
                      )
                    }}
                  >
                    <Route
                      exact
                      path="/users"
                      render={() => {
                        return (
                          <Link
                            to="/users/add"
                            className="btn btn-sm btn-warning"
                          >
                            Добавить
                          </Link>
                        )
                      }}
                    />
                  </PageTitle>
                  <Route path="/users/add">
                    {({ match }) => (
                      <>
                        <AddUserForm
                          show={match !== null}
                          error={this.props.errors}
                        />
                      </>
                    )}
                  </Route>
                  <div className="pageWrapper">
                    <label className="text-secondary">Роль</label>
                    <Select
                      options={[
                        { label: 'Все', value: 'all' },
                        { label: 'Администратор', value: 'admin' },
                        { label: 'Контент-менеджер', value: 'content' },
                        { label: 'Crm Bot', value: 'crmBotUser' }
                      ]}
                      defaultValue="all"
                      onChange={this.roleFilterChangeHandler}
                      className={
                        'form-control form-control-sm userList__roleFilter col-4'
                      }
                    />
                  </div>
                  <div className="userList">{userList}</div>
                </>
              )
            }}
          />
          <Route
            exact
            path="/users/:id"
            render={({
              match: {
                params: { id }
              }
            }) => {
              const user = users.find(t => t.id === id)
              if (!user) {
                return <Redirect to="/404" />
              }
              return <EditUserForm user={user} />
            }}
          />
        </Switch>
      </>
    )
  }
}

UserList.propTypes = {
  users: PropTypes.array.isRequired,
  deleteUser: PropTypes.func.isRequired,
  error: PropTypes.array
}

export default UserList
