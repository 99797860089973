import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_DECORATION_CREATE_DECORATION_TYPE_SUBMIT_SUCCESS } from '../actions'

function* watchCreateObjectSaga(projectId, tabId) {
  while (true) {
    const {
      payload: { id }
    } = yield take(PROJECT_DECORATION_CREATE_DECORATION_TYPE_SUBMIT_SUCCESS)
    yield put(
      push(
        `/projects/${projectId}/decoration/tabs/${tabId}/decoration-type/${id}`
      )
    )
  }
}

export default watchCreateObjectSaga
