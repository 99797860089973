import {
  fetchSection,
  PROJECT_ARCHITECTURE_SECTION_SUCCESS,
  PROJECT_ARCHITECTURE_SECTION_FAILURE
} from '../actions'
import createSectionSaga from '../../../sagas/createSectionSaga'
import fetchArchitectureSectionPinImagesSaga from './fetchArchitectureSectionPinImagesSaga'

export default createSectionSaga(
  fetchSection,
  [PROJECT_ARCHITECTURE_SECTION_SUCCESS, PROJECT_ARCHITECTURE_SECTION_FAILURE],
  project => project.architectureSection,
  {
    sectionName: 'Объект',
    architectureObjects: []
  },
  fetchArchitectureSectionPinImagesSaga
)
