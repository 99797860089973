import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const OBJECTS_REQUEST = 'OBJECTS_REQUEST'
export const OBJECTS_SUCCESS = 'OBJECTS_SUCCESS'
export const OBJECTS_FAILURE = 'OBJECTS_FAILURE'

export const fetchObjects = () => {
  return {
    [RSAA]: {
      endpoint: `api/Projects/1/objects`,
      method: 'get',
      types: [OBJECTS_REQUEST, OBJECTS_SUCCESS, OBJECTS_FAILURE]
    }
  }
}

export const OBJECTS_SUBMIT_REQUEST = 'OBJECTS_SUBMIT_REQUEST'
export const OBJECTS_SUBMIT_SUCCESS = 'OBJECTS_SUBMIT_SUCCESS'
export const OBJECTS_SUBMIT_FAILURE = 'OBJECTS_SUBMIT_FAILURE'

export const submitObjects = objectsSection => {
  const request = {
    type: OBJECTS_SUBMIT_REQUEST
  }
  const success = {
    type: OBJECTS_SUBMIT_SUCCESS
  }
  const { objects } = objectsSection
  const submitForm = {
    sectionName: 'Справочник объектов',
    items: objects.map(t => t.id)
  }
  const formData = objectToFormData(submitForm)

  return {
    [RSAA]: {
      endpoint: `api/Projects/1/objects`,
      method: 'put',
      headers: {},
      body: formData,
      types: [request, success, OBJECTS_SUBMIT_FAILURE]
    }
  }
}
