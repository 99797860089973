import { put, select, race, take } from 'redux-saga/effects'
import { fetchAbout, ABOUT_SUCCESS, ABOUT_FAILURE } from '../actions'
import { about as aboutProps } from '../reducers/selectors'

function* fetchAboutSaga() {
  const about = yield select(aboutProps)
  if (about) {
    return about
  }
  yield put(fetchAbout())
  const { success } = yield race({
    success: take(ABOUT_SUCCESS),
    failure: take(ABOUT_FAILURE)
  })

  if (success) {
    return success.payload
  }

  return null
}

export default fetchAboutSaga
