import { put, call, all } from 'redux-saga/effects'
import { fetchImageMetaData } from '../actions'
import fetchObjectSaga from '../../../sagas/fetchObjectSaga'

function* fetchImageMetaDataSaga(projectId, tabId, objectId, id, imageMeta) {
  const { metaUrl, type } = imageMeta
  yield put(fetchImageMetaData(projectId, tabId, objectId, type, id, metaUrl))
}

function* fetchImageSetSaga(projectId, tabId, objectId, imageSetId) {
  const object = yield call(fetchObjectSaga, projectId, tabId, objectId)
  if (object) {
    const { imageSets = [] } = object
    const imageSet = imageSets.find(t => t.id === imageSetId)
    if (imageSet) {
      const { id, previewImageUrl, ...imageTypes } = imageSet
      const images = Object.keys(imageTypes)
        .map(key => {
          return {
            ...imageSet[key],
            type: key
          }
        })
        .filter(t => t.metaUrl)
      const fetchTasks = images.map(imageMeta =>
        call(fetchImageMetaDataSaga, projectId, tabId, objectId, id, imageMeta)
      )
      yield all(fetchTasks)
      return imageSet
    }
  }

  return null
}

export default fetchImageSetSaga
