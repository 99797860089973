import { call, put, take } from 'redux-saga/effects'
import { fetchDecorationSectionSaga } from '../../../sagas'
import { fetchTab, PROJECT_DECORATION_TAB_SUCCESS } from '../actions'

function* fetchDecorationTabSaga(projectId, tabId) {
  const decorationSection = yield call(fetchDecorationSectionSaga, projectId)
  if (decorationSection) {
    yield put(fetchTab(projectId, tabId))
    const decorationTab = yield take(PROJECT_DECORATION_TAB_SUCCESS)
    if (decorationTab) {
      return decorationTab.payload
    }
  }

  return null
}

export default fetchDecorationTabSaga
