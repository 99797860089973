import { fork } from 'redux-saga/effects'
import createObjectSagaRoute from '../pages/CreateObject/sagas'
import editObjectSagaRoute from '../pages/EditObject/sagas'
import fetchObjectsSaga from './fetchObjectsSaga'

const objectsSagaRoute = {
  '/objects': function* runUsersSaga() {
    yield fork(fetchObjectsSaga)
  },
  ...editObjectSagaRoute,
  ...createObjectSagaRoute
}

export default objectsSagaRoute
