import { RSAA } from 'redux-api-middleware'

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'

export const login = (userName, password) => {
  const loginUserRequest = {
    type: LOGIN_USER_REQUEST,
    meta: { userName }
  }
  return {
    [RSAA]: {
      endpoint: 'api/Token',
      method: 'post',
      types: [loginUserRequest, LOGIN_USER_SUCCESS, LOGIN_USER_FAILURE],
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userName,
        password
      })
    }
  }
}

export const NEW_TOKEN_REQUEST = 'NEW_TOKEN_REQUEST'
export const NEW_TOKEN_SUCCESS = 'NEW_TOKEN_SUCCESS'
export const NEW_TOKEN_FAILURE = 'NEW_TOKEN_FAILURE'

export const fetchNewToken = refreshToken => {
  return {
    [RSAA]: {
      endpoint: 'api/Token',
      method: 'post',
      types: [NEW_TOKEN_REQUEST, NEW_TOKEN_SUCCESS, NEW_TOKEN_FAILURE],
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        grant_type: 'refresh_token',
        refresh_token: refreshToken
      })
    }
  }
}

export const LOGIN_USER_LOGGED_IN = 'LOGIN_USER_LOGGED_IN'

export const loggedInUser = (userName, token) => ({
  type: LOGIN_USER_LOGGED_IN,
  payload: {
    userName,
    token
  }
})

export const LOGIN_USER_LOGGED_OUT = 'LOGIN_USER_LOGGED_OUT'

export const loggedOutUser = () => ({
  type: LOGIN_USER_LOGGED_OUT
})

export const USER_CURRENT_REQUEST = 'USER_CURRENT_REQUEST'
export const USER_CURRENT_SUCCESS = 'USER_CURRENT_SUCCESS'
export const USER_CURRENT_FAILURE = 'USER_CURRENT_FAILURE'

export const fetchCurrentUser = token => {
  return {
    [RSAA]: {
      endpoint: 'api/users/me',
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`
      },
      types: [USER_CURRENT_REQUEST, USER_CURRENT_SUCCESS, USER_CURRENT_FAILURE]
    }
  }
}
