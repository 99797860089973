import { LOCATION_CHANGE } from 'connected-react-router'
import { takeLatest, take, fork } from 'redux-saga/effects'
import { PROJECT_ADVANTAGES_EDIT_GALLERY_IMAGE_SET_SUBMIT_SUCCESS } from '../actions'
import fetchImageSetSaga from './fetchImageSetSaga'

function* refreshImageSaga(action) {
  const {
    meta: { projectId, galleryId, id }
  } = action
  yield take(LOCATION_CHANGE)
  yield fork(fetchImageSetSaga, projectId, galleryId, id)
}

function* watchImageSetSubmitted() {
  yield takeLatest(
    PROJECT_ADVANTAGES_EDIT_GALLERY_IMAGE_SET_SUBMIT_SUCCESS,
    refreshImageSaga
  )
}

export default watchImageSetSubmitted
