import { DECORATION_CHANGE } from 'connected-react-router'
import { takeLatest, take, fork } from 'redux-saga/effects'
import { PROJECT_DECORATION_EDIT_TAB_SUBMIT_SUCCESS } from '../actions'
import fetchDecorationTabSaga from './fetchDecorationTabSaga'

function* refreshImages(action) {
  const {
    meta: { projectId, tabId }
  } = action
  yield take(DECORATION_CHANGE)
  yield fork(fetchDecorationTabSaga, projectId, tabId)
}

function* watchImageSetSubmitted() {
  yield takeLatest(PROJECT_DECORATION_EDIT_TAB_SUBMIT_SUCCESS, refreshImages)
}

export default watchImageSetSubmitted
