import {
  EDIT_OBJECT_SUBMIT_SUCCESS,
  EDIT_OBJECT_SUBMIT_FAILURE,
  OBJECT_IMAGE_META_SUCCESS
} from '../actions'

const editObjectReducer = {
  [EDIT_OBJECT_SUBMIT_SUCCESS]: (state, action) => {
    const curObjectIndex = state.findIndex(
      item => item.id === action.payload.id
    )
    let newObjects = state.slice(0)
    newObjects.splice(curObjectIndex, 1, action.payload)

    return newObjects
  },
  [EDIT_OBJECT_SUBMIT_FAILURE]: (state, action) => {
    return {
      ...state
    }
  },
  [OBJECT_IMAGE_META_SUCCESS]: (state, action) => {
    const curObjectIndex = state.findIndex(
      item => item.id === action.meta.objectId
    )
    const curObject = state[curObjectIndex]
    const newObject = {
      ...curObject,
      [action.meta.imageType]: {
        ...curObject[action.meta.imageType],
        ...action.payload
      }
    }

    let newObjects = state.slice(0)
    newObjects.splice(curObjectIndex, 1, newObject)

    return newObjects
  }
}

export default editObjectReducer
