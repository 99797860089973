import {
  PROJECT_TEAM_SECTION_SUCCESS,
  PROJECT_TEAM_SECTION_FAILURE,
  PROJECT_TEAM_SECTION_SUBMIT_SUCCESS,
  PROJECT_TEAM_SECTION_SUBMIT_FAILURE,
  PROJECT_TEAM_SECTION_PIN_IMAGE_META_SUCCESS
} from '../actions'
import createSectionReducer from '../../../reducers/createSectionReducer'
import { setPinImageUrl } from './pinImage'
import createObjectReducer from '../pages/CreateObject/reducers'
import editObjectReducer from '../pages/EditObject/reducers'

const teamSectionReducer = createSectionReducer(
  'teamSection',
  [PROJECT_TEAM_SECTION_SUCCESS, PROJECT_TEAM_SECTION_FAILURE],
  [PROJECT_TEAM_SECTION_SUBMIT_SUCCESS, PROJECT_TEAM_SECTION_SUBMIT_FAILURE]
)

export default {
  ...teamSectionReducer,
  [PROJECT_TEAM_SECTION_PIN_IMAGE_META_SUCCESS]: (state, action) => {
    return setPinImageUrl(state, action)
  },
  ...createObjectReducer,
  ...editObjectReducer
}
