import advantageSectionFormReducer from './advantageSectionReducer'
import createAdvantageReducer from '../pages/CreateAdvantage/reducers'
import editAdvantageReducer from '../pages/EditAdvantage/reducers'

const advantageSectionReducer = {
  ...advantageSectionFormReducer,
  ...createAdvantageReducer,
  ...editAdvantageReducer
}

export default advantageSectionReducer
