import { fork, take } from 'redux-saga/effects'
import { RATES_FILTER_CHANGE } from '../actions'

export default function* watchFilterChange(fetchSaga) {
  while (true) {
    const filter = yield take(RATES_FILTER_CHANGE)
    if (filter) {
      yield fork(fetchSaga, filter.payload)
    }
  }
}
