import { call, put, take } from 'redux-saga/effects'
import { fetchObject, PROJECT_DECORATION_OBJECT_SUCCESS } from '../actions'
import fetchDecorationTabSaga from '../../../sagas/fetchDecorationTabSaga'

function* fetchObjectSaga(projectId, tabId, objectId) {
  const decorationTab = yield call(fetchDecorationTabSaga, projectId, tabId)
  if (decorationTab) {
    yield put(fetchObject(projectId, tabId, objectId))
    const decorationObject = yield take(PROJECT_DECORATION_OBJECT_SUCCESS)
    if (decorationObject) {
      return decorationObject.payload
    }
  }
  return null
}

export default fetchObjectSaga
