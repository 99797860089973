import { put, take } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PUSH_SUCCESS } from '../actions'

export default function* watchPushCreated() {
  while (true) {
    yield take(PUSH_SUCCESS)
    yield put(push('/pushes'))
  }
}
