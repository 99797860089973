import { RSAA } from 'redux-api-middleware'
import { getUrlWithParams } from '../../../../../../../utils/getUrlWithParams'

export const RATES_REQUEST = 'RATES_REQUEST'
export const RATES_SUCCESS = 'RATES_SUCCESS'
export const RATES_FAILURE = 'RATES_FAILURE'

export const fetchRates = (params = {}) => {
  return {
    [RSAA]: {
      endpoint: getUrlWithParams('api/rates', params),
      method: 'get',
      types: [RATES_REQUEST, RATES_SUCCESS, RATES_FAILURE],
      headers: { 'Content-Type': 'application/json' }
    }
  }
}

export const RATES_FILTER_CHANGE = 'RATES_FILTER_CHANGE'

export const filterChange = obj => {
  return {
    type: RATES_FILTER_CHANGE,
    payload: obj
  }
}
