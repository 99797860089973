import { LOCATION_CHANGE } from 'connected-react-router'
import { takeLatest, take, fork } from 'redux-saga/effects'
import { PROJECT_GENERAL_SECTION_SUBMIT_SUCCESS } from '../actions'
import { fetchPinImageFilesSaga } from './fetchGeneralSectionPinImagesSaga'

function* refreshPinImagesSaga(action) {
  const {
    meta: { projectId, generalSection }
  } = action
  yield take(LOCATION_CHANGE)
  yield fork(fetchPinImageFilesSaga, projectId, generalSection)
}

function* watchGeneralSectionSubmitted() {
  yield takeLatest(PROJECT_GENERAL_SECTION_SUBMIT_SUCCESS, refreshPinImagesSaga)
}

export default watchGeneralSectionSubmitted
