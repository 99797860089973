import {
  PROJECT_ADVANTAGES_EDIT_ADVANTAGE_SUBMIT_SUCCESS,
  PROJECT_ADVANTAGES_ADVANTAGE_IMAGE_META_SUCCESS
} from '../actions'
import updateAdvantage from './updateAdvantage'

const editAdvantageReducer = {
  [PROJECT_ADVANTAGES_ADVANTAGE_IMAGE_META_SUCCESS]: updateAdvantage(
    (advantage, action) => {
      const {
        payload: { url, name },
        meta: { imageType }
      } = action
      const newImage = {
        ...advantage[imageType],
        url,
        name
      }
      const newAdvantage = {
        ...advantage,
        [imageType]: newImage
      }
      return newAdvantage
    }
  ),
  [PROJECT_ADVANTAGES_EDIT_ADVANTAGE_SUBMIT_SUCCESS]: updateAdvantage(
    (_x, action) => action.meta.advantage
  )
}

export default editAdvantageReducer
