import { arrayMove } from 'react-sortable-hoc'
import {
  PROJECTS_LIST_SUCCESS,
  PROJECT_MOVE_REQUEST,
  PROJECT_DELETE_SUCCESS,
  PROJECT_HIDE_SUCCESS,
  PROJECT_UNHIDE_SUCCESS,
  PROJECTS_CRM_LIST_SUCCESS
} from '../actions'
import mergeProjects from './mergeProjects'

const getProjectListState = (state, action) => {
  const { projects = [], hiddenProjects = [] } = action.payload
  const mergedProjects = mergeProjects(state.projects, projects)

  return {
    ...state,
    projects: mergedProjects,
    hiddenProjects,
    error: null
  }
}

const projectListReducer = {
  [PROJECTS_LIST_SUCCESS]: getProjectListState,
  [PROJECT_MOVE_REQUEST]: (state, action) => {
    const { oldIndex, newIndex } = action.meta
    return {
      ...state,
      projects: arrayMove(state.projects, oldIndex, newIndex),
      error: null
    }
  },
  [PROJECT_DELETE_SUCCESS]: getProjectListState,
  [PROJECT_HIDE_SUCCESS]: getProjectListState,
  [PROJECT_UNHIDE_SUCCESS]: getProjectListState,
  [PROJECTS_CRM_LIST_SUCCESS]: (state, action) => {
    const {
      payload: {
        result: { developmentprojects = [] }
      }
    } = action

    var crmProjects = developmentprojects.map(project => {
      const { developmentprojectId, name } = project
      return {
        id: developmentprojectId,
        name
      }
    })

    return {
      ...state,
      crmProjects,
      error: null
    }
  }
}

export default projectListReducer
