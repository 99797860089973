import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ActionPopup } from '../../../../components/UniversalPopup'

class HiddenProjectItem extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      popupDeleteOpened: false
    }
  }

  togglePopup = popup => {
    this.setState({ [popup]: !this.state[popup] })
  }

  createOnDelete = () => {
    const { project, deleteProject } = this.props
    this.setState({ popupDeleteOpened: false })
    return deleteProject(project.id)
  }

  onUnHideHandler = () => {
    const { unHideProject, project } = this.props
    unHideProject(project.id)
  }

  render() {
    const { project } = this.props
    const confirmationPopup = this.state.popupDeleteOpened ? (
      <ActionPopup
        title={() => 'Подтвердить удаление?'}
        confirmText="Да"
        onConfirm={this.createOnDelete}
        onCancel={this.togglePopup.bind(this, 'popupDeleteOpened')}
        isOpened={this.state.popupDeleteOpened}
      />
    ) : null

    return [
      <div className="hiddenProjectdList__item" key="item">
        <Link
          to={`/projects/${project.id}/general`}
          className="hiddenProjectdList__link"
        >
          {project.name}
        </Link>
        <button
          onClick={this.onUnHideHandler}
          className="btn btn--noPadding btn--visible"
          title="Показать проект"
        />
        <button
          onClick={this.togglePopup.bind(this, 'popupDeleteOpened')}
          className="btn btn--noPadding btn--delete"
          title="Удалить проект"
        />
      </div>,
      <div key="popup">{confirmationPopup}</div>
    ]
  }
}

HiddenProjectItem.propTypes = {
  project: PropTypes.object.isRequired,
  unHideProject: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired
}

export default HiddenProjectItem
