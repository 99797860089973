import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'
import { required } from '../../../../../../../../../../components/Validation'
import projectForm from '../../../../../../components/projectForm'
import { FormField } from '../../../../../../../../../../components/Form'
import SectionPageForm from '../../../../components'
import TabSortableList from './TabSortableList'
import { MobileTabletImageFormField } from '../../../../../../../../components/Form'

const ArchitectureSectionForm = props => {
  const { architectureSection, ...rest } = props
  return (
    <SectionPageForm initialValues={architectureSection} {...rest}>
      {({ projectId, form }) => {
        return (
          <>
            <div className="row">
              <div className="col formField">
                <FormField
                  name="sectionName"
                  validate={required}
                  type="text"
                  label="Название раздела"
                  placeholder="Название раздела"
                />
              </div>
              <div className="col formField">
                <FormField
                  name="blockTitle"
                  type="text"
                  label="Заголовок блока"
                  placeholder="Заголовок блока"
                />
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <MobileTabletImageFormField form={form} />
              </div>
            </div>
            <hr className="formField__separator" />
            <div className="row">
              <div className="col">
                <FieldArray name="tabs">
                  {({ fields }) => {
                    return (
                      <TabSortableList
                        fields={fields}
                        projectId={projectId}
                        distance={1}
                      />
                    )
                  }}
                </FieldArray>
              </div>
            </div>
          </>
        )
      }}
    </SectionPageForm>
  )
}

ArchitectureSectionForm.propTypes = {
  architectureSection: PropTypes.shape({
    sectionName: PropTypes.string.isRequired,
    infrastructureObjects: PropTypes.arrayOf.isRequired
  }).isRequired
}

export default projectForm(ArchitectureSectionForm)
