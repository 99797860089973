import updateProject from '../../../../../reducers/updateProject'

const updateTab = reducer =>
  updateProject((project, action) => {
    const { tabId } = action.meta
    const tabs = project.architectureSection.tabs.map(
      architectureTab =>
        architectureTab.id === tabId
          ? { ...architectureTab, ...reducer(architectureTab, action) }
          : architectureTab
    )

    return {
      ...project,
      architectureSection: {
        ...project.architectureSection,
        tabs: tabs
      }
    }
  })

export default updateTab
