import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '../../../components/Tabs'
import { ByDate, ByStars } from '../../../components/Filters'
import Comment from './Comment'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from '../../../../../components/Loader'

const Comments = ({
  data = [],
  hasMore,
  filters = {},
  filterChange,
  fetchComments
}) => {
  const commentsList = data.map((comment, index) => {
    return <Comment comment={comment} key={comment.text + index} />
  })
  const moreFilters = { ...filters, skip: commentsList.length, take: 20 }

  return (
    <>
      <Tabs />
      <div className="pageWrapper">
        <ByDate
          options={{
            day: 'День',
            isoWeek: 'Неделя',
            month: 'Месяц',
            quarter: 'Квартал',
            year: 'Год'
          }}
          filterChange={filterChange}
          filters={filters}
        />
        <ByStars
          name="rate"
          options={[1, 2, 3, 4, 5]}
          filterChange={filterChange}
          filters={filters}
        />
        <hr className="formField__separator" />
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={() => fetchComments(moreFilters)}
          hasMore={hasMore}
          loader={<Loader key="loader" />}
        >
          {commentsList}
        </InfiniteScroll>
      </div>
    </>
  )
}

Comments.propTypes = {
  data: PropTypes.array.isRequired,
  filters: PropTypes.object,
  filterChange: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired
}

export default Comments
