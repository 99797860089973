import { OBJECTS_SUBMIT_SUCCESS, OBJECTS_SUCCESS } from '../actions'
import editObjectReducer from '../pages/EditObject/reducers'
import createObjectReducer from '../pages/CreateObject/reducers'

const initialState = null

const mergedReducer = {
  [OBJECTS_SUCCESS]: (state, action) => {
    const { payload = {} } = action
    const { objects = [] } = payload
    return [...objects]
  },
  [OBJECTS_SUBMIT_SUCCESS]: (state, action) => {
    return [...action.payload.objects]
  },
  ...editObjectReducer,
  ...createObjectReducer
}

const objectsReducer = (state = initialState, action) => {
  let actionReducer = mergedReducer[action.type]
  if (actionReducer) {
    return actionReducer(state, action)
  }

  return state
}

export default objectsReducer
