import React from 'react'
import PropTypes from 'prop-types'
import { required } from '../../../../../components/Validation'
import {
  ImageiOS2x,
  ImageiOS3x,
  ImageAndroidSvg,
  ImageMobile,
  ImageTablet
} from '../../ImageUpload'
import ImageType from './ImageType'

const MapImageFormField = ({ form, getName }) => {
  return [
    <div className="row" key="label1">
      <div className="col">
        <h4>Карта</h4>
      </div>
    </div>,
    <div className="row" key="images1">
      <div className="col">
        <label className="formField__label">Mobile</label>
        <div className="imageGrid">
          <ImageMobile
            validate={required}
            name={getName(ImageType.mobile)}
            form={form}
            size="big"
          />
        </div>
      </div>
      <div className="col">
        <label className="formField__label">Tablet</label>
        <div className="imageGrid">
          <ImageTablet
            name={getName(ImageType.tablet)}
            form={form}
            size="big"
          />
        </div>
      </div>
    </div>,
    <div className="row" key="label2">
      <div className="col">
        <h4>Пин проекта на карте</h4>
      </div>
    </div>,
    <div className="row" key="images2">
      <div className="col">
        <label className="formField__label">iOS</label>
        <div className="imageGrid">
          <ImageiOS2x
            name={getName(ImageType.iOS2x)}
            form={form}
            size="small"
          />
          <ImageiOS3x
            name={getName(ImageType.iOS3x)}
            form={form}
            size="small"
          />
        </div>
      </div>
      <div className="col">
        <label className="formField__label">Android</label>
        <div className="imageGrid">
          <ImageAndroidSvg
            name={getName(ImageType.androidSvg)}
            form={form}
            size="small"
          />
        </div>
      </div>
    </div>
  ]
}

MapImageFormField.propTypes = {
  form: PropTypes.object.isRequired,
  label1: PropTypes.string,
  label2: PropTypes.string,
  size: PropTypes.string,
  getName: PropTypes.func.isRequired
}

export default MapImageFormField
