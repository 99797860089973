import { call, put, take } from 'redux-saga/effects'
import { fetchPublicSpacesSectionSaga } from '../../../sagas'
import { fetchTab, PROJECT_PUBLIC_SPACES_TAB_SUCCESS } from '../actions'

function* fetchPublicSpacesTabSaga(projectId, tabId) {
  const publicSpacesSection = yield call(
    fetchPublicSpacesSectionSaga,
    projectId
  )
  if (publicSpacesSection) {
    yield put(fetchTab(projectId, tabId))
    const publicSpacesTab = yield take(PROJECT_PUBLIC_SPACES_TAB_SUCCESS)
    if (publicSpacesTab) {
      return publicSpacesTab.payload
    }
  }

  return null
}

export default fetchPublicSpacesTabSaga
