import {
  LOGIN_USER_LOGGED_IN,
  LOGIN_USER_LOGGED_OUT,
  NEW_TOKEN_SUCCESS,
  USER_CURRENT_SUCCESS
} from '../actions'

const initialState = null

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_LOGGED_IN:
      return {
        ...state,
        ...action.payload
      }
    case LOGIN_USER_LOGGED_OUT:
      return null
    case USER_CURRENT_SUCCESS:
      return {
        ...state,
        roles: action.payload.roles
      }
    case NEW_TOKEN_SUCCESS:
      return {
        ...state,
        token: { ...action.payload }
      }
    default:
      return state
  }
}

export default userReducer
