import React from 'react'
import PropTypes from 'prop-types'
import { SortableElement } from 'react-sortable-hoc'

const SortableItem = SortableElement(
  ({ header, children, itemIndex, fields, ...rest }) => {
    const props = {
      ...rest,
      index: itemIndex,
      fields,
      onRemove: () => fields.remove(itemIndex)
    }
    return (
      <div className="sortableArray__item">
        <div className="col">
          <div className="sortableArray__head">
            <div className="sortableArray__dots" title="Перенести" />
            {header ? header(props) : false}
          </div>
          {children ? children(props) : false}
        </div>
      </div>
    )
  }
)

SortableItem.propTypes = {
  children: PropTypes.func,
  header: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
}

export default SortableItem
