import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const PROJECTS_CREATE_IMAGE_SET_REQUEST =
  'PROJECTS_CREATE_IMAGE_SET_REQUEST'
export const PROJECTS_CREATE_IMAGE_SET_SUCCESS =
  'PROJECTS_CREATE_IMAGE_SET_SUCCESS'
export const PROJECTS_CREATE_IMAGE_SET_FAILURE =
  'PROJECTS_CREATE_IMAGE_SET_FAILURE'

const createImageSet = (projectId, images) => {
  const meta = {
    projectId,
    images
  }
  const request = {
    type: PROJECTS_CREATE_IMAGE_SET_REQUEST,
    meta
  }
  const success = {
    type: PROJECTS_CREATE_IMAGE_SET_SUCCESS,
    meta
  }
  const formData = objectToFormData(images)

  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/images`,
      method: 'post',
      types: [request, success, PROJECTS_CREATE_IMAGE_SET_FAILURE],
      body: formData
    }
  }
}

export const createListImageSet = createImageSet
