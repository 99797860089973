import { RSAA } from 'redux-api-middleware'

export const PROJECT_PUBLIC_SPACES_EDIT_TAB_SUBMIT_REQUEST =
  'PROJECT_PUBLIC_SPACES_EDIT_TAB_SUBMIT_REQUEST'
export const PROJECT_PUBLIC_SPACES_EDIT_TAB_SUBMIT_SUCCESS =
  'PROJECT_PUBLIC_SPACES_EDIT_TAB_SUBMIT_SUCCESS'
export const PROJECT_PUBLIC_SPACES_EDIT_TAB_SUBMIT_FAILURE =
  'PROJECT_PUBLIC_SPACES_EDIT_TAB_SUBMIT_FAILURE'

export const submit = (projectId, tabId, publicSpacesObject) => {
  const success = {
    type: PROJECT_PUBLIC_SPACES_EDIT_TAB_SUBMIT_SUCCESS,
    meta: {
      projectId,
      tabId,
      publicSpacesObject
    }
  }

  return {
    [RSAA]: {
      endpoint: `api/projects/${projectId}/publicSpaces/tabs/${tabId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(publicSpacesObject),
      types: [
        PROJECT_PUBLIC_SPACES_EDIT_TAB_SUBMIT_REQUEST,
        success,
        PROJECT_PUBLIC_SPACES_EDIT_TAB_SUBMIT_FAILURE
      ]
    }
  }
}

export const PROJECT_PUBLIC_SPACES_TAB_REQUEST =
  'PROJECT_PUBLIC_SPACES_TAB_REQUEST'
export const PROJECT_PUBLIC_SPACES_TAB_SUCCESS =
  'PROJECT_PUBLIC_SPACES_TAB_SUCCESS'
export const PROJECT_PUBLIC_SPACES_TAB_FAILURE =
  'PROJECT_PUBLIC_SPACES_TAB_FAILURE'

export const fetchTab = (projectId, tabId) => {
  const success = {
    type: PROJECT_PUBLIC_SPACES_TAB_SUCCESS,
    meta: {
      projectId,
      tabId
    }
  }

  return {
    [RSAA]: {
      endpoint: `api/projects/${projectId}/publicSpaces/tabs/${tabId}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      types: [
        PROJECT_PUBLIC_SPACES_TAB_REQUEST,
        success,
        PROJECT_PUBLIC_SPACES_TAB_FAILURE
      ]
    }
  }
}
