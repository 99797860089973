import React, { useState } from 'react'
import { PageTitle } from '../../../components'
import { ClientVersion } from './CleintVersion'
import AddClientVersionForm from './AddClientVersionForm'
import './styles.css'

export const ClientVersionsList = ({
  versions: { list, page, error },
  createClientVersion,
  deleteClientVersion,
  loadMore
}) => {
  const [shownAddForm, setShownAddForm] = useState()

  const onCreateClientVersion = data => {
    createClientVersion(data)
  }

  return (
    <div className="clients-versions">
      <PageTitle title={() => <h2>Версии клиентов</h2>}>
        <div
          className="btn btn-sm btn-warning"
          onClick={() => setShownAddForm(true)}
        >
          Добавить версию
        </div>
      </PageTitle>

      {shownAddForm && (
        <AddClientVersionForm
          show={shownAddForm}
          setShownAddForm={setShownAddForm}
          onFormSubmit={onCreateClientVersion}
        />
      )}

      {error && <p style={{ color: 'red' }}>{error}</p>}

      <div className="clients-versions__head">
        <div>Клиент</div>
        <div>Версия</div>
        <div>Обязательная</div>
      </div>
      {list.map(version => (
        <ClientVersion
          key={version.id}
          version={version}
          deleteClientVersion={deleteClientVersion}
        />
      ))}

      {page && (
        <div className="clients-versions__buttons">
          <button className="btn btn-sm btn-secondary" onClick={loadMore}>
            Показать еще
          </button>
        </div>
      )}
    </div>
  )
}
