import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import logo from '../../../images/mr_logo.svg'
import './style.css'

class LoginForm extends PureComponent {
  handleSubmit = event => {
    event.preventDefault()
    const { login } = this.props
    login(this.login.value, this.password.value)
  }

  render() {
    return (
      <div className="login-form rounded">
        <img
          src={logo}
          className="login-form__logo"
          width="68"
          height="68"
          alt="MR Group"
        />
        <form onSubmit={this.handleSubmit}>
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                name="login"
                placeholder="Логин"
                ref={login => {
                  this.login = login
                }}
                required
                maxLength="50"
                autoComplete="on"
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <input
                type="password"
                name="password"
                placeholder="Пароль"
                ref={password => {
                  this.password = password
                }}
                required
                autoComplete="on"
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-block btn-warning">
              Войти
            </button>
          </div>
        </form>
      </div>
    )
  }
}

LoginForm.propTypes = {
  login: PropTypes.func.isRequired
}

export default LoginForm
