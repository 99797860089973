import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ActionPopup } from '../../../../components/UniversalPopup'
import './user.css'

class UserItem extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      popupDeleteOpened: false
    }
  }

  togglePopup = popup => {
    this.setState({ [popup]: !this.state[popup] })
  }

  onDeleteHandler = () => {
    const { user, deleteUser } = this.props
    deleteUser(user.id)
    this.setState({ popupDeleteOpened: !this.state.popupDeleteOpened })
  }

  render() {
    const { user } = this.props
    const roles = {
      admin: 'Администратор',
      content: 'Контент-менеджер',
      crmBotUser: 'Crm Bot'
    }
    const confirmationPopup = this.state.popupDeleteOpened ? (
      <ActionPopup
        title={() => 'Подтвердить удаление?'}
        confirmText="Да"
        onConfirm={this.onDeleteHandler}
        onCancel={this.togglePopup.bind(this, 'popupDeleteOpened')}
        isOpened={this.state.popupDeleteOpened}
      />
    ) : null

    const firstName = user.firstName ? user.firstName : null
    const lastName = user.lastName ? user.lastName : null
    const login = user.login ? user.login : ''
    const displayName =
      firstName || lastName ? `${lastName} ${firstName}` : login
    return (
      <>
        <div className="userList__item">
          <Link to={`/users/${user.id}`} className="userList__link">
            <div className="user__role">{roles[user.role]}</div>
            {displayName}
          </Link>
          <button
            onClick={this.togglePopup.bind(this, 'popupDeleteOpened')}
            className="btn btn--noPadding btn--delete"
            title="Удалить пользователя"
          />
        </div>
        {confirmationPopup}
      </>
    )
  }
}

UserItem.propTypes = {
  user: PropTypes.object.isRequired
}

export default UserItem
