import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { SketchPicker } from 'react-color'
import onClickOutside from 'react-onclickoutside'
import './formField.css'

class ColorPicker extends PureComponent {
  state = {
    isOpened: false
  }

  //from react-onclickoutside
  handleClickOutside = evt => {
    this.setState({ isOpened: false })
  }

  ButtonClickHandler = () => {
    this.toggle()
  }

  toggle = () => {
    this.setState({ isOpened: !this.state.isOpened })
  }

  CheckAllowedCharacters = ev => {
    let hexCharacters = /[0-9a-f]/g
    if (!hexCharacters.test(ev.key)) ev.preventDefault()
  }

  render() {
    const { name, validate, label = null, placeholder = '' } = this.props
    return (
      <Field name={name} validate={validate} className="formField">
        {({ input, meta }) => {
          const curColor = input.value
          const errorClassName =
            meta.error && meta.touched ? 'formField__control--error' : ''
          return (
            <div>
              <label key="label">{label}</label>
              <input
                key="input"
                {...input}
                onChange={ev => {
                  input.onChange(
                    '#' +
                      ev.target.value.substring(
                        ev.target.value.indexOf('#') + 1
                      )
                  )
                }}
                onKeyPress={this.CheckAllowedCharacters}
                placeholder={placeholder}
                className={`form-control form-control-sm ${errorClassName}`}
                maxLength={7}
                minLength={4}
                autoComplete="off"
              />
              <div
                key="button"
                className="formField__colorPicker-button"
                style={{ backgroundColor: curColor }}
                onClick={this.ButtonClickHandler}
              />
              {this.state.isOpened ? (
                <SketchPicker
                  key="picker"
                  color={curColor}
                  onChange={color => {
                    input.onChange(color.hex)
                  }}
                  disableAlpha={true}
                  className="formField__colorPicker-options"
                />
              ) : null}
              {meta.error ? (
                <span key="error" className="formField__error">
                  {meta.touched && meta.error}
                </span>
              ) : null}
            </div>
          )
        }}
      </Field>
    )
  }
}

ColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func
}

export default onClickOutside(ColorPicker)
