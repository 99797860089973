import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {
  isAuthenticatedProp,
  rolesProp
} from '../pages/Login/reducers/selectors'

const PrivateNavlink = ({
  isAuthenticated,
  rights,
  roles,
  dispatch,
  ...rest
}) => {
  const isRights = rights
    ? rights.reduce(
        (result, item) => result || (roles && roles.includes(item)),
        false
      )
    : true

  return isAuthenticated && isRights ? <NavLink {...rest} /> : null
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticatedProp(state),
  roles: rolesProp(state)
})

export default connect(
  mapStateToProps,
  null
)(PrivateNavlink)
