const COLORS = [
  '#f8941e',
  '#1e9ef8',
  '#f3532b',
  '#49ce1f',
  '#d519d6',
  '#24bbeb',
  '#16d8af',
  '#4046f5',
  '#bacc13',
  '#1e181b'
]

export default COLORS
