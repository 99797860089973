import {
  fetchSection,
  PROJECT_CONSTRUCTION_PROGRESS_SUCCESS,
  PROJECT_CONSTRUCTION_PROGRESS_FAILURE
} from '../actions'

import createSectionSaga from '../../../sagas/createSectionSaga'

export default createSectionSaga(
  fetchSection,
  [
    PROJECT_CONSTRUCTION_PROGRESS_SUCCESS,
    PROJECT_CONSTRUCTION_PROGRESS_FAILURE
  ],
  project => project.constructionProgressSection,
  {
    sectionName: 'Ход строительства',
    broadcasts: [],
    galleries: []
  }
)
