import {
  fetchSection,
  PROJECT_ADVANTAGES_SECTION_SUCCESS,
  PROJECT_ADVANTAGES_SECTION_FAILURE
} from '../actions'
import createSectionSaga from '../../../sagas/createSectionSaga'

export default createSectionSaga(
  fetchSection,
  [PROJECT_ADVANTAGES_SECTION_SUCCESS, PROJECT_ADVANTAGES_SECTION_FAILURE],
  project => project.advantagesSection,
  {
    sectionName: 'Преимущества',
    advantages: []
  }
)
