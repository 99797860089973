import {
  PROJECT_DECORATION_EDIT_TAB_SUBMIT_SUCCESS,
  PROJECT_DECORATION_EDIT_TAB_SUBMIT_FAILURE,
  PROJECT_DECORATION_TAB_SUCCESS,
  PROJECT_DECORATION_TAB_FAILURE
} from '../actions'
import createDecorationTypeReducer from '../pages/CreateDecorationType/reducers'
import editDecorationTypeReducer from '../pages/EditDecorationType/reducers'
import updateTab from './updateTab'

const editTabReducer = {
  [PROJECT_DECORATION_EDIT_TAB_SUBMIT_SUCCESS]: updateTab(
    (_decorationObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_DECORATION_EDIT_TAB_SUBMIT_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  [PROJECT_DECORATION_TAB_SUCCESS]: updateTab(
    (_decorationObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_DECORATION_TAB_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  ...createDecorationTypeReducer,
  ...editDecorationTypeReducer
}

export default editTabReducer
