import {
  PROJECT_ARCHITECTURE_EDIT_TAB_SUBMIT_SUCCESS,
  PROJECT_ARCHITECTURE_EDIT_TAB_SUBMIT_FAILURE,
  PROJECT_ARCHITECTURE_TAB_SUCCESS,
  PROJECT_ARCHITECTURE_TAB_FAILURE
} from '../actions'
import createArchitectureTypeReducer from '../pages/CreateArchitectureType/reducers'
import editArchitectureTypeReducer from '../pages/EditArchitectureType/reducers'
import updateTab from './updateTab'

const editTabReducer = {
  [PROJECT_ARCHITECTURE_EDIT_TAB_SUBMIT_SUCCESS]: updateTab(
    (_architectureObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_ARCHITECTURE_EDIT_TAB_SUBMIT_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  [PROJECT_ARCHITECTURE_TAB_SUCCESS]: updateTab(
    (_architectureObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_ARCHITECTURE_TAB_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  ...createArchitectureTypeReducer,
  ...editArchitectureTypeReducer
}

export default editTabReducer
