import React from 'react'
import ParameterButtons from './ParameterButtons'
import { ArrayFormField } from '../../../../../../../../components/Form'
import { required } from '../../../../../../../../../../components/Validation'
import { FormField } from '../../../../../../../../../../components/Form'

const BroadcastArrayFormField = () => {
  return (
    <ArrayFormField
      name="broadcasts"
      header={({ fields, index }) => {
        return (
          <>
            <h4>Трансляция {index + 1}</h4>
            <ParameterButtons fields={fields} index={index} />
          </>
        )
      }}
    >
      {({ name }) => {
        return (
          <div className="row">
            <div className="col formField">
              <FormField
                name={`${name}.name`}
                validate={required}
                type="text"
                label="Название трансляции"
              />
            </div>
            <div className="col formField">
              <FormField
                name={`${name}.link`}
                validate={required}
                type="text"
                label="Ссылка на онлайн-трансляцию"
              />
            </div>
          </div>
        )
      }}
    </ArrayFormField>
  )
}

export default BroadcastArrayFormField
