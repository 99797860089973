import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './pagetitle.css'

class PageTitle extends PureComponent {
  render() {
    const { title, children } = this.props

    return (
      <div className="pageTitle">
        <div>{typeof title === 'function' ? title() : title}</div>
        <div>{children}</div>
      </div>
    )
  }
}

PageTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired
}

export default PageTitle
