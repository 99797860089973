import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECTS_EDIT_IMAGE_SET_SUCCESS } from '../actions'

function* watchImageSetUpdatedSaga(projectId) {
  while (true) {
    yield take(PROJECTS_EDIT_IMAGE_SET_SUCCESS)
    yield put(push(`/projects/${projectId}/images`))
  }
}

export default watchImageSetUpdatedSaga
