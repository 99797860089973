import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ProjectConsumer } from '../../../ProjectContext'
import AccomplishmentSectionForm from './AccomplishmentSectionForm'
import CreateTabForm from '../pages/CreateTab'
import EditTabForm from '../pages/EditTab'
import CreateAccomplishmentType from '../pages/EditTab/pages/CreateAccomplishmentType'
import EditAccomplishmentType from '../pages/EditTab/pages/EditAccomplishmentType'
import CreateImageSet from '../pages/EditTab/pages/EditAccomplishmentType/pages/CreateImageSet'
import EditImageSet from '../pages/EditTab/pages/EditAccomplishmentType/pages/EditImageSet'

const AccomplishmentSectionPage = props => {
  const {
    accomplishmentSection,
    match: { url },
    submit
  } = props

  return (
    <ProjectConsumer>
      {project => {
        const projectId = project.id
        return (
          <Switch>
            <Route
              exact
              path={`${url}/tabs`}
              render={() => {
                return <CreateTabForm projectId={projectId} />
              }}
            />
            <Route
              exact
              path={`${url}/tabs/:id(\\d+)`}
              render={({ match: { params } }) => {
                const tabId = parseInt(params.id, 10)
                const tab = accomplishmentSection.tabs.find(t => t.id === tabId)
                if (!tab) {
                  return <Redirect to="/404" />
                }
                return (
                  <EditTabForm projectId={projectId} tab={tab} tabId={tabId} />
                )
              }}
            />
            <Route
              exact
              path={`${url}/tabs/:id(\\d+)/accomplishment-type`}
              render={({ match: { params } }) => {
                const tabId = parseInt(params.id, 10)
                const tab = accomplishmentSection.tabs.find(t => t.id === tabId)
                if (!tab) {
                  return <Redirect to="/404" />
                }
                return (
                  <CreateAccomplishmentType
                    projectId={projectId}
                    tab={tab}
                    tabId={tabId}
                  />
                )
              }}
            />
            <Route
              exact
              path={`${url}/tabs/:id(\\d+)/accomplishment-type/:objectId(\\d+)`}
              render={({ match: { params } }) => {
                const tabId = parseInt(params.id, 10)
                const tab = accomplishmentSection.tabs.find(t => t.id === tabId)
                const objectId = parseInt(params.objectId, 10)
                const type = tab.objects.find(t => t.id === objectId)
                return (
                  <EditAccomplishmentType
                    projectId={projectId}
                    tab={tab}
                    tabId={tabId}
                    type={type}
                    objectId={objectId}
                  />
                )
              }}
            />
            <Route
              exact
              path={`${url}/tabs/:tabId(\\d+)/accomplishment-type/:objectId(\\d+)/images/add`}
              render={({ match: { params } }) => {
                const tabId = parseInt(params.tabId, 10)
                const tab = accomplishmentSection.tabs.find(t => t.id === tabId)
                const objectId = parseInt(params.objectId, 10)
                const type = tab.objects.find(t => t.id === objectId)
                if (!type) {
                  return <Redirect to="/404" />
                }

                return (
                  <CreateImageSet
                    projectId={projectId}
                    tab={tab}
                    tabId={tabId}
                    type={type}
                    objectId={objectId}
                  />
                )
              }}
            />
            <Route
              exact
              path={`${url}/tabs/:tabId(\\d+)/accomplishment-type/:objectId(\\d+)/images/:id`}
              render={({ match: { params } }) => {
                const tabId = parseInt(params.tabId, 10)
                const tab = accomplishmentSection.tabs.find(t => t.id === tabId)
                let objectId, object, imageId, imageSet
                if (tab) {
                  objectId = parseInt(params.objectId, 10)
                  object = tab.objects.find(t => t.id === objectId)
                  if (object && object.imageSets) {
                    imageId = parseInt(params.id, 10)
                    imageSet = object.imageSets.find(t => t.id === imageId)
                  }
                }

                return (
                  <EditImageSet
                    projectId={projectId}
                    tab={tab}
                    tabId={tabId}
                    object={object}
                    objectId={objectId}
                    imageSet={imageSet}
                    id={imageId}
                    backUrl={`${url}/tabs/${tabId}/accomplishment-type/${objectId}`}
                  />
                )
              }}
            />
            <AccomplishmentSectionForm
              accomplishmentSection={accomplishmentSection}
              projectId={projectId}
              submit={submit}
            />
          </Switch>
        )
      }}
    </ProjectConsumer>
  )
}

AccomplishmentSectionPage.propTypes = {
  accomplishmentSection: PropTypes.shape({
    sectionName: PropTypes.string.isRequired,
    infrastructureObjects: PropTypes.arrayOf.isRequired
  }).isRequired,
  projectId: PropTypes.number.isRequired
}

export default AccomplishmentSectionPage
