import { connect } from 'react-redux'
import { createProject } from './actions'
import { projectsErrorProp } from '../../reducers/selectors'
import { AddProjectForm as AddProjectFormComponent } from './components'

const mapStateToProps = state => {
  return {
    error: projectsErrorProp(state)
  }
}

export default connect(
  mapStateToProps,
  {
    createProject
  }
)(AddProjectFormComponent)
