import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import './calendar.css'
import moment from 'moment'
import 'moment/locale/ru'

class ByDate extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      focusedInput: null,
      startDate: moment(),
      endDate: moment()
    }
  }

  changePeriod = key => () => {
    const { filterChange } = this.props
    let startDate
    let endDate
    switch (key) {
      case 'day':
        startDate = moment().format('YYYY-MM-DD')
        endDate = moment().format('YYYY-MM-DD')
        break
      case 'isoWeek':
      case 'month':
      case 'quarter':
      case 'year':
        startDate = moment()
          .startOf(key)
          .format('YYYY-MM-DD')
        endDate = moment()
          .endOf(key)
          .format('YYYY-MM-DD')
        break
      default:
        startDate = moment()
          .startOf('month')
          .format('YYYY-MM-DD')
        endDate = moment()
          .endOf('month')
          .format('YYYY-MM-DD')
    }
    filterChange({
      activePeriod: key,
      start: startDate,
      end: endDate
    })
  }

  render() {
    const { filters = {}, filterChange, options } = this.props
    const buttons = Object.keys(options).map(key => {
      const period = filters.activePeriod
      const isActiveClass = period === key ? 'filters__btn--active' : ''
      return (
        <div
          className={`filters__btn filters__btn--period ${isActiveClass}`}
          onClick={this.changePeriod(key)}
          key={key}
        >
          {options[key]}
        </div>
      )
    })
    moment.locale('ru')
    return (
      <div className="filters">
        <header className="filters__title">Период</header>
        <div className="filters__wrapper">
          <div className="filters__group">{buttons}</div>
          <div className="filters__datepicker">
            <DateRangePicker
              startDate={moment(filters.start)} // momentPropTypes.momentObj or null,
              startDateId="start" // PropTypes.string.isRequired,
              endDate={moment(filters.end)} // momentPropTypes.momentObj or null,
              endDateId="end" // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) => {
                filterChange({
                  activePeriod: false,
                  start: startDate.format('YYYY-MM-DD'),
                  end: endDate
                    ? endDate.format('YYYY-MM-DD')
                    : startDate.format('YYYY-MM-DD')
                })
              }} // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              isOutsideRange={() => false}
              hideKeyboardShortcutsPanel={true}
              isDayBlocked={day => day > moment()}
              initialVisibleMonth={() =>
                moment(this.state.endDate).add(-1, 'months')
              }
              firstDayOfWeek={1}
              small={true}
              showDefaultInputIcon
              inputIconPosition="after"
              minimumNights={0}
              readOnly={true}
              displayFormat="DD MMMM YYYY"
              monthFormat={'MMMM YYYY'}
              daySize={40}
            />
          </div>
        </div>
      </div>
    )
  }
}

ByDate.propTypes = {
  filterChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  filters: PropTypes.object
}

export default ByDate
