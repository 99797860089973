import { default as projectAccomplishmentSectionSagaRoute } from './projectAccomplishmentSectionSagaRoute'
import createTabSagaRoute from '../pages/CreateTab/sagas'
import editTabSagaRoute from '../pages/EditTab/sagas'

export {
  default as fetchAccomplishmentSectionSaga
} from './fetchAccomplishmentSectionSaga'
export { watchAccomplishmentTabSubmitted } from '../pages/EditTab/sagas'

export default {
  ...editTabSagaRoute,
  ...createTabSagaRoute,
  ...projectAccomplishmentSectionSagaRoute
}
