import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const PROJECT_ACCOMPLISHMENT_EDIT_GALLERY_IMAGE_SET_SUBMIT_REQUEST =
  'PROJECT_ACCOMPLISHMENT_EDIT_GALLERY_IMAGE_SET_SUBMIT_REQUEST'
export const PROJECT_ACCOMPLISHMENT_EDIT_GALLERY_IMAGE_SET_SUBMIT_SUCCESS =
  'PROJECT_ACCOMPLISHMENT_EDIT_GALLERY_IMAGE_SET_SUBMIT_SUCCESS'
export const PROJECT_ACCOMPLISHMENT_EDIT_GALLERY_IMAGE_SET_SUBMIT_FAILURE =
  'PROJECT_ACCOMPLISHMENT_EDIT_GALLERY_IMAGE_SET_SUBMIT_FAILURE'

export const submit = (projectId, tabId, objectId, id, imageSet) => {
  const meta = {
    projectId,
    tabId,
    objectId,
    id,
    imageSet
  }
  const success = {
    type: PROJECT_ACCOMPLISHMENT_EDIT_GALLERY_IMAGE_SET_SUBMIT_SUCCESS,
    meta
  }
  const failure = {
    type: PROJECT_ACCOMPLISHMENT_EDIT_GALLERY_IMAGE_SET_SUBMIT_FAILURE,
    meta
  }
  const formData = objectToFormData(imageSet)

  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/accomplishment/tabs/${tabId}/objects/${objectId}/images/${id}`,
      method: 'put',
      headers: {},
      body: formData,
      types: [
        PROJECT_ACCOMPLISHMENT_EDIT_GALLERY_IMAGE_SET_SUBMIT_REQUEST,
        success,
        failure
      ]
    }
  }
}

export const PROJECT_ACCOMPLISHMENT_GALLERY_IMAGE_META_REQUEST =
  'PROJECT_ACCOMPLISHMENT_GALLERY_IMAGE_META_REQUEST'
export const PROJECT_ACCOMPLISHMENT_GALLERY_IMAGE_META_SUCCESS =
  'PROJECT_ACCOMPLISHMENT_GALLERY_IMAGE_META_SUCCESS'
export const PROJECT_ACCOMPLISHMENT_GALLERY_IMAGE_META_FAILURE =
  'PROJECT_ACCOMPLISHMENT_GALLERY_IMAGE_META_FAILURE'

export const fetchImageMetaData = (
  projectId,
  tabId,
  objectId,
  imageType,
  id,
  url
) => {
  const meta = {
    projectId,
    tabId,
    objectId,
    imageType,
    id
  }
  const success = {
    type: PROJECT_ACCOMPLISHMENT_GALLERY_IMAGE_META_SUCCESS,
    meta
  }
  const failure = {
    type: PROJECT_ACCOMPLISHMENT_GALLERY_IMAGE_META_FAILURE,
    meta
  }
  return {
    [RSAA]: {
      endpoint: url,
      method: 'get',
      types: [
        PROJECT_ACCOMPLISHMENT_GALLERY_IMAGE_META_REQUEST,
        success,
        failure
      ]
    }
  }
}
