import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_PUBLIC_SPACES_EDIT_GALLERY_IMAGE_SET_SUBMIT_SUCCESS } from '../actions'

function* watchImageSetUpdatedSaga(projectId, tabId, objectId) {
  while (true) {
    const {
      meta: { objectId }
    } = yield take(PROJECT_PUBLIC_SPACES_EDIT_GALLERY_IMAGE_SET_SUBMIT_SUCCESS)
    yield put(
      push(
        `/projects/${projectId}/publicSpaces/tabs/${tabId}/publicSpaces-type/${objectId}`
      )
    )
  }
}

export default watchImageSetUpdatedSaga
