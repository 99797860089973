import { put, race, take, select } from 'redux-saga/effects'
import { fetchComments, COMMENTS_FAILURE, COMMENTS_SUCCESS } from '../actions'
import { comments } from '../reducers/selectors'

function* fetchCommentsSaga() {
  const commentsProps = yield select(comments)
  yield put(fetchComments(commentsProps.filters))
  const { success } = yield race({
    success: take(COMMENTS_SUCCESS),
    failure: take(COMMENTS_FAILURE)
  })

  if (success) {
    return success.payload
  }

  return null
}

export default fetchCommentsSaga
