import {
  CLIENTS_VERSIONS_SUCCESS,
  CLIENT_VERSION_CREATE_FAILURE
} from '../actions'

const initialState = {
  page: 1,
  list: null,
  error: null
}

const reset = () => {
  return initialState
}

const fetched = (state, action) => {
  const { page, list } = state
  const { payload } = action
  return {
    page: payload.length ? page + 1 : null,
    list: [...(list || []), ...payload],
    error: null
  }
}

const fail = (state, action) => {
  return {
    ...state,
    error: action.payload.response.detail
  }
}

const mergedReducer = {
  CLIENT_VERSIONS_RESET: reset,
  [CLIENTS_VERSIONS_SUCCESS]: fetched,
  [CLIENT_VERSION_CREATE_FAILURE]: fail
}

const clientsVersionsReducer = (state = initialState, action) => {
  let actionReducer = mergedReducer[action.type]
  if (actionReducer) {
    return actionReducer(state, action)
  }

  return state
}

export default clientsVersionsReducer
