import React from 'react'
import PropTypes from 'prop-types'
import { PageTitle } from '../../../components'
import PushList from './PushList'
import { Link, Route } from 'react-router-dom'
import PushForm from '../pages/AddPushForm'

export const Push = ({ pushes, hasMore, fetchPushes }) => {
  return (
    <div className="push">
      <PageTitle
        title={() => {
          return <h2>Push-уведомления</h2>
        }}
      >
        <Route
          exact
          path="/pushes"
          render={() => {
            return (
              <Link to="/pushes/add" className="btn btn-sm btn-warning">
                Добавить
              </Link>
            )
          }}
        />
      </PageTitle>
      <Route
        exact
        path="/pushes"
        render={() => {
          return (
            <PushList
              pushes={pushes}
              hasMore={hasMore}
              fetchPushes={fetchPushes}
            />
          )
        }}
      />
      <Route exact path="/pushes/add" component={PushForm} />
    </div>
  )
}

Push.propTypes = {
  pushes: PropTypes.array,
  hasMore: PropTypes.bool,
  fetchPushes: PropTypes.func.isRequired
}
