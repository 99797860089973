import React from 'react'
import PropTypes from 'prop-types'
import { Route, Link, Switch, Redirect } from 'react-router-dom'
import EditProjectForm from '../pages/EditProjectForm'
import ProjectUI from './ProjectUI'
import { PageTitle } from '../../../components'
import ProjectName from './ProjectName'

const Projects = ({ projects, getProject }) => {
  return (
    <div className="projects">
      <PageTitle
        title={() => {
          return (
            <Switch>
              <Route exact path="/(projects|projects/add)">
                <h2>Проекты</h2>
              </Route>
              <Route
                path="/projects/:id(\d+)/:sectionId"
                render={({
                  match: {
                    params: { id }
                  }
                }) => {
                  const idInt = parseInt(id, 10)
                  const project = getProject(idInt)
                  if (!project) {
                    return <Redirect to="/404" />
                  }
                  return <ProjectName project={project} />
                }}
              />
            </Switch>
          )
        }}
      >
        <Route
          exact
          path="/projects"
          render={() => {
            return (
              <Link to="/projects/add" className="btn btn-sm btn-warning">
                Добавить
              </Link>
            )
          }}
        />
      </PageTitle>
      <Route path="/projects" component={ProjectUI} />
      <Route path="/projects/:id(\d+)" component={EditProjectForm} />
    </div>
  )
}

Projects.propTypes = {
  projects: PropTypes.array.isRequired,
  getProject: PropTypes.func.isRequired
}

export default Projects
