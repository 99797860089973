import { fork } from 'redux-saga/effects'
import fetchCommentsSaga from './fetchCommentsSaga'
import watchFilterChange from './watchFilterChange'

const CommentsSagaRoute = {
  '/stats/comments': function* runCommentsSaga() {
    yield fork(fetchCommentsSaga)
    yield fork(watchFilterChange, fetchCommentsSaga)
  }
}

export default CommentsSagaRoute
