import React from 'react'
import PropTypes from 'prop-types'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { AddImageSet } from '../../ImageSet'

const ImageSetItem = ({ component, ...rest }) => {
  const ImageSetComponent = component
  return <ImageSetComponent {...rest} />
}

ImageSetItem.propTypes = {
  component: PropTypes.func.isRequired
}

const ImageSetSortableItem = SortableElement(ImageSetItem)

const ImageSetSortableArray = ({
  fields,
  addImageSetUrl,
  onRemove,
  ...rest
}) => {
  return (
    <div className="imageGrid">
      {fields.map((name, index) => {
        const imageSet = fields.value[index]
        const id = imageSet.id
        const onImageSetRemove = onRemove(id)
        const handleOnRemove = () => onImageSetRemove(index)
        return (
          <ImageSetSortableItem
            {...rest}
            name={name}
            key={index}
            index={index}
            imageSet={imageSet}
            onRemove={handleOnRemove}
          />
        )
      })}
      <AddImageSet addImagePath={`${addImageSetUrl}`} />
    </div>
  )
}

export default SortableContainer(ImageSetSortableArray)
