import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import {
  required,
  formWithError
} from '../../../../../../../components/Validation'
import { SubmitButton } from '../../../../../components/Buttons'
import { ImageMobile, ImageTablet } from '../../../../../components/ImageUpload'
import { Link } from 'react-router-dom'

const focusOnErrors = createDecorator()

const EditImageSetForm = ({ submit, images, blockId, imageSetId }) => {
  const onSubmit = images => {
    submit(blockId, imageSetId, images)
  }

  return (
    <Form
      initialValues={images}
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <Link to={`/about`} className="formField__back">
                  Назад
                </Link>
              </div>
            </div>
            <hr className="formField__separator" />
            <div className="row">
              <div className="col">
                <h4>Изображение</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-9">
                <div className="imageGrid">
                  <ImageMobile
                    name="mobileImage"
                    size="big"
                    form={form}
                    validate={required}
                  />
                  <ImageTablet name="tabletImage" size="big" form={form} />
                </div>
              </div>
            </div>
            <SubmitButton
              pristine={pristine}
              submitSucceeded={submitSucceeded}
              dirtySinceLastSubmit={dirtySinceLastSubmit}
            />
          </form>
        )
      }}
    />
  )
}

EditImageSetForm.propTypes = {
  blockId: PropTypes.number.isRequired,
  images: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  imageSetId: PropTypes.number
}

export default formWithError(EditImageSetForm)
