import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import {
  required,
  formWithError
} from '../../../../../../../../../components/Validation'
import { FormField } from '../../../../../../../../../components/Form'
import { SubmitButton } from '../../../../../../../components/Buttons'
import ImageSetArrayFormField from '../../../../../../../components/Form/ImageSetArrayFormField'

const focusOnErrors = createDecorator()

const updatePositions = (imageSets = []) => {
  return imageSets.map((imageSet, position) => {
    return {
      ...imageSet,
      position
    }
  })
}

class ImagesSection extends PureComponent {
  onSubmit = imagesSection => {
    const { project, submit } = this.props
    const projectListImages = updatePositions(imagesSection.projectListImages)
    const projectCardImages = updatePositions(imagesSection.projectCardImages)
    const newImagesSection = {
      ...imagesSection,
      projectListImages,
      projectCardImages
    }
    submit(project.id, newImagesSection)
  }

  editImageSetUrl = id => {
    const { project } = this.props
    return `/projects/${project.id}/images/list/edit/${id}`
  }

  render() {
    const {
      imagesSection,
      project: { id }
    } = this.props

    const addListImageRoute = `/projects/${id}/images/list/add`

    return (
      <Form
        initialValues={imagesSection}
        mutators={{
          ...arrayMutators
        }}
        onSubmit={this.onSubmit}
        decorators={[focusOnErrors]}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          submitSucceeded,
          dirtySinceLastSubmit
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col formField">
                <FormField
                  name="sectionName"
                  validate={required}
                  type="text"
                  label="Название раздела"
                  placeholder="Название раздела"
                />
              </div>
            </div>
            <hr className="formField__separator" />
            <div className="row">
              <div className="col">
                <h4>Изображения в списке и карточке проектов</h4>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ImageSetArrayFormField
                  form={form}
                  name="projectListImages"
                  deletedSetsName="deletedListImageSets"
                  addImageSetUrl={addListImageRoute}
                  editImageSetUrl={this.editImageSetUrl}
                />
              </div>
            </div>
            <SubmitButton
              pristine={pristine}
              submitSucceeded={submitSucceeded}
              dirtySinceLastSubmit={dirtySinceLastSubmit}
            />
          </form>
        )}
      />
    )
  }
}

ImagesSection.propTypes = {
  imagesSection: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
}

export default formWithError(ImagesSection)
