import {
  PROJECT_ARCHITECTURE_SECTION_SUCCESS,
  PROJECT_ARCHITECTURE_SECTION_FAILURE,
  PROJECT_ARCHITECTURE_SECTION_SUBMIT_SUCCESS,
  PROJECT_ARCHITECTURE_SECTION_SUBMIT_FAILURE,
  PROJECT_ARCHITECTURE_SECTION_PIN_IMAGE_META_SUCCESS
} from '../actions'
import createSectionReducer from '../../../reducers/createSectionReducer'
import { setPinImageUrl } from './pinImage'
import createTabReducer from '../pages/CreateTab/reducers'
import editTabReducer from '../pages/EditTab/reducers'

const architectureSectionReducer = createSectionReducer(
  'architectureSection',
  [PROJECT_ARCHITECTURE_SECTION_SUCCESS, PROJECT_ARCHITECTURE_SECTION_FAILURE],
  [
    PROJECT_ARCHITECTURE_SECTION_SUBMIT_SUCCESS,
    PROJECT_ARCHITECTURE_SECTION_SUBMIT_FAILURE
  ]
)

export default {
  ...architectureSectionReducer,
  [PROJECT_ARCHITECTURE_SECTION_PIN_IMAGE_META_SUCCESS]: (state, action) => {
    return setPinImageUrl(state, action)
  },
  ...createTabReducer,
  ...editTabReducer
}
