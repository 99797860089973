import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import {
  isAuthenticatedProp,
  rolesProp
} from '../pages/Login/reducers/selectors'

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  rights,
  roles,
  dispatch,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        const isRights = rights
          ? rights.reduce(
              (result, item) => result || roles.includes(item),
              false
            )
          : true

        return isAuthenticated && isRights ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }}
    />
  )
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticatedProp(state),
  roles: rolesProp(state)
})

export default connect(
  mapStateToProps,
  null
)(PrivateRoute)
