import {
  fetchSection,
  PROJECT_DOCUMENTS_SECTION_SUCCESS,
  PROJECT_DOCUMENTS_SECTION_FAILURE
} from '../actions'
import fetchPinImagesSaga from './fetchPinImagesSaga'

import createSectionSaga from '../../../sagas/createSectionSaga'

export default createSectionSaga(
  fetchSection,
  [PROJECT_DOCUMENTS_SECTION_SUCCESS, PROJECT_DOCUMENTS_SECTION_FAILURE],
  project => project.documentsSection,
  {
    sectionName: 'Документы',
    documents: []
  },
  fetchPinImagesSaga
)
