import React from 'react'
import COLORS from '../colors'
import Stars from '../../../components/Stars/Stars'
import './legend.css'

const LegendItem = ({
  value,
  valueWithStars = false,
  size,
  unit,
  color,
  label,
  labelStars = false
}) => {
  const currLabel = labelStars ? <Stars value={parseInt(label, 10)} /> : label
  const starsValue = valueWithStars ? <Stars value={value} /> : null
  return (
    <tr className={`legend__tr legend__tr--${size}`}>
      <td className="legend__name">{currLabel}</td>
      <td
        style={{
          color: color,
          textAlign: 'right'
        }}
      >
        {starsValue}
        <span className={`legend__value legend__value--${size}`}>{value}</span>
        <span className="legend__unit">{unit}</span>
      </td>
    </tr>
  )
}

const Legend = ({
  colorsArray = COLORS,
  unit,
  data = [],
  size = 'small',
  head = [],
  labelStars = false
}) => {
  const headTd = head.map(title => <td key={title}>{title}</td>)
  const items = data.map((item, i) => {
    return (
      <LegendItem
        labelStars={labelStars}
        valueWithStars={item.valueWithStars}
        value={item.value}
        unit={unit}
        color={item.color ? item.color : colorsArray[i]}
        label={item.name}
        key={item.name}
        size={size}
      />
    )
  })
  return (
    <table className="legend__table">
      <thead>
        <tr>{headTd}</tr>
      </thead>
      <tbody>{items}</tbody>
    </table>
  )
}

export default Legend
