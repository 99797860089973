import { EDIT_USER_SUCCESS } from '../actions'

const editUserReducer = {
  [EDIT_USER_SUCCESS]: (state, action) => {
    const userId = action.payload.id
    const userIndex = state.users.findIndex(item => item.id === userId)
    let newUsers = state.users.slice()
    newUsers.splice(userIndex, 1, action.payload)
    return { ...state, users: newUsers }
  }
}

export default editUserReducer
