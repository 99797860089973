import { RSAA } from 'redux-api-middleware'

export const PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_SUBMIT_REQUEST =
  'PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_SUBMIT_REQUEST'
export const PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_SUBMIT_SUCCESS =
  'PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_SUBMIT_SUCCESS'
export const PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_SUBMIT_FAILURE =
  'PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_SUBMIT_FAILURE'

export const submit = (projectId, galleryId, gallery) => {
  const success = {
    type: PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_SUBMIT_SUCCESS,
    meta: {
      projectId,
      galleryId,
      gallery
    }
  }
  const { imageSets = [], ...rest } = gallery
  const items = imageSets.map(t => t.id)
  const form = {
    ...rest,
    items
  }
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/construction/galleries/${galleryId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(form),
      types: [
        PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_SUBMIT_REQUEST,
        success,
        PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_SUBMIT_FAILURE
      ]
    }
  }
}
