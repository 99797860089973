import { RSAA } from 'redux-api-middleware'

export const PUSH_LIST_REQUEST = 'PUSH_LIST_REQUEST'
export const PUSH_LIST_SUCCESS = 'PUSH_LIST_SUCCESS'
export const PUSH_LIST_FAILURE = 'PUSH_LIST_FAILURE'

export const fetchPushes = (skip = 0, take = 10) => {
  return {
    [RSAA]: {
      endpoint: `api/notifications?skip=${skip}&take=${take}`,
      method: 'get',
      types: [PUSH_LIST_REQUEST, PUSH_LIST_SUCCESS, PUSH_LIST_FAILURE],
      headers: { 'Content-Type': 'application/json' }
    }
  }
}
