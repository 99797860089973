import { ACCOMPLISHMENT_CHANGE } from 'connected-react-router'
import { takeLatest, take, fork } from 'redux-saga/effects'
import { PROJECT_ACCOMPLISHMENT_EDIT_TAB_SUBMIT_SUCCESS } from '../actions'
import fetchAccomplishmentTabSaga from './fetchAccomplishmentTabSaga'

function* refreshImages(action) {
  const {
    meta: { projectId, tabId }
  } = action
  yield take(ACCOMPLISHMENT_CHANGE)
  yield fork(fetchAccomplishmentTabSaga, projectId, tabId)
}

function* watchImageSetSubmitted() {
  yield takeLatest(
    PROJECT_ACCOMPLISHMENT_EDIT_TAB_SUBMIT_SUCCESS,
    refreshImages
  )
}

export default watchImageSetSubmitted
