import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import GeneralSection from '../pages/GeneralSection'
import ImagesSection from '../pages/ImagesSection'
import AboutSection from '../pages/AboutSection'
import ConstructionProgressSectionRoutePage from '../pages/ConstructionProgressSection'
import AdvantageSectionRoutePage from '../pages/AdvantageSection'
import LocationSectionRoutePage from '../pages/LocationSection'
import AccomplishmentSectionRoutePage from '../pages/AccomplishmentSection'
import DecorationSectionRoutePage from '../pages/DecorationSection'
import ArchitectureSectionRoutePage from '../pages/ArchitectureSection'
import PublicSpacesSectionRoutePage from '../pages/PublicSpacesSection'
import TeamSectionRoutePage from '../pages/TeamSection'
import DocumentsSectionRoutePage from '../pages/DocumentsSection'
import SubNav from './SubNav'
import {
  CreateListImageSetForm,
  EditListImageSetForm
} from '../pages/ImagesSection/pages'
import './editProjectForm.css'
import { ProjectProvider } from '../ProjectContext'

const EditProjectForm = ({ project, crmProjects }) => {
  return (
    <ProjectProvider value={project}>
      <div className="row">
        <div className="col-3">
          <Route
            path="/projects"
            render={props => <SubNav {...props} projectId={project.id} />}
          />
        </div>
        <div className="col-9 editProjectForm__wrapper">
          <div className="editProjectForm">
            <Route
              exact
              path={`/projects/${project.id}/general`}
              render={props => (
                <GeneralSection
                  {...props}
                  project={project}
                  crmProjects={crmProjects}
                />
              )}
            />
            <Route
              exact
              path={`/projects/${project.id}/images`}
              render={props => {
                return (
                  <ImagesSection
                    {...props}
                    imagesSection={project.imagesSection}
                    project={project}
                  />
                )
              }}
            />
            <Route
              exact
              path={`/projects/${project.id}/about`}
              render={props => {
                return <AboutSection {...props} project={project} />
              }}
            />
            <Route
              exact
              path={`/projects/${project.id}/images/list/add`}
              render={props => {
                return <CreateListImageSetForm {...props} project={project} />
              }}
            />
            <Route
              exact
              path={`/projects/${project.id}/images/list/edit/:imageSetId`}
              render={props => {
                return <EditListImageSetForm {...props} project={project} />
              }}
            />
            <ConstructionProgressSectionRoutePage />
            <AdvantageSectionRoutePage />
            <LocationSectionRoutePage />
            <AccomplishmentSectionRoutePage />
            <ArchitectureSectionRoutePage />
            <PublicSpacesSectionRoutePage />
            <DecorationSectionRoutePage />
            <TeamSectionRoutePage />
            <DocumentsSectionRoutePage />
          </div>
        </div>
      </div>
    </ProjectProvider>
  )
}

EditProjectForm.propTypes = {
  project: PropTypes.object.isRequired
}

export default EditProjectForm
