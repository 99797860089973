import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const PROJECT_IMAGES_SECTION_REQUEST = 'PROJECT_IMAGES_SECTION_REQUEST'
export const PROJECT_IMAGES_SECTION_SUCCESS = 'PROJECT_IMAGES_SECTION_SUCCESS'
export const PROJECT_IMAGES_SECTION_FAILURE = 'PROJECT_IMAGES_SECTION_FAILURE'

export const fetchProjectImagesSection = projectId => {
  const success = {
    type: PROJECT_IMAGES_SECTION_SUCCESS,
    meta: {
      projectId
    }
  }
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/images`,
      method: 'get',
      types: [
        PROJECT_IMAGES_SECTION_REQUEST,
        success,
        PROJECT_IMAGES_SECTION_FAILURE
      ]
    }
  }
}

export const PROJECT_IMAGES_SECTION_SUBMIT_REQUEST =
  'PROJECT_IMAGES_SECTION_SUBMIT_REQUEST'
export const PROJECT_IMAGES_SECTION_SUBMIT_SUCCESS =
  'PROJECT_IMAGES_SECTION_SUBMIT_SUCCESS'
export const PROJECT_IMAGES_SECTION_SUBMIT_FAILURE =
  'PROJECT_IMAGES_SECTION_SUBMIT_FAILURE'

export const submit = (projectId, imagesSection) => {
  const success = {
    type: PROJECT_IMAGES_SECTION_SUBMIT_SUCCESS,
    meta: {
      projectId,
      imagesSection
    }
  }
  const { projectListImages = [], ...rest } = imagesSection
  const items = projectListImages.map(t => t.id)
  const form = {
    ...rest,
    items
  }
  const formData = objectToFormData(form)

  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/images`,
      method: 'put',
      body: formData,
      types: [
        PROJECT_IMAGES_SECTION_SUBMIT_REQUEST,
        success,
        PROJECT_IMAGES_SECTION_SUBMIT_FAILURE
      ]
    }
  }
}
