import { fork, call } from 'redux-saga/effects'
import watchGalleryUpdatedSaga from './watchGalleryUpdatedSaga'
import fetchGallerySaga from './fetchGallerySaga'

const editGallerySagaRoute = {
  '/projects/:projectId/construction/galleries/:galleryId': function* runEditGallerySaga({
    projectId,
    galleryId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const galleryIdNumber = parseInt(galleryId, 10)
    const gallery = yield call(
      fetchGallerySaga,
      projectIdNumber,
      galleryIdNumber
    )
    if (gallery) {
      yield fork(watchGalleryUpdatedSaga, projectIdNumber)
    }
  }
}

export default editGallerySagaRoute
