import React from 'react'
import { PageTitle } from '../../../components'
import { Route, Switch, Redirect } from 'react-router-dom'
import Rates from './../pages/Rates'
import Comments from './../pages/Comments'

const Stats = () => {
  return (
    <div className="stats">
      <PageTitle
        title={() => {
          return <h2>Статистика</h2>
        }}
      />
      <Switch>
        <Redirect exact from="/stats" to="/stats/rates" />
        <Route exact path="/stats/rates" component={Rates} />
        <Route exact path="/stats/comments" component={Comments} />
      </Switch>
    </div>
  )
}

export default Stats
