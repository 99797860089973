import React from 'react'
import PropTypes from 'prop-types'
import starShape from './star-shape.svg'
import './stars.css'

const Stars = ({
  value = 0,
  isActive = false,
  colorPrimary = '#333333',
  colorSecondary = '#d8d8d8',
  colorActive = '#f8941e',
  size = 130,
  className = ''
}) => {
  const valueInProcent = (value / 5) * 100
  const mainColor = isActive ? colorActive : colorPrimary
  const styleObj = {
    width: `${size}px`,
    background: `linear-gradient(to right, ${mainColor} 0%, ${mainColor} ${valueInProcent}%, ${colorSecondary} ${valueInProcent}%, ${colorSecondary} 100%)`
  }
  return (
    <div className={`stars ${className}`} style={styleObj}>
      <img
        src={starShape}
        width={size}
        title={value}
        alt={value}
        className="stars__mask"
      />
    </div>
  )
}

Stars.propTypes = {
  value: PropTypes.number, //from 0 to 5
  colorPrimary: PropTypes.string,
  colorSecondary: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number //width in px
}

export default Stars
