import React from 'react'
import PropTypes from 'prop-types'
import HiddenProjectItem from './HiddenProjectItem'
import './hiddenProjectList.css'

const HiddenProjectList = ({ hiddenProjects = [], unHideProject, ...rest }) => {
  if (hiddenProjects.length === 0) return null
  return (
    <div className="hiddenProjectdList">
      <h2 className="hiddenProjectdList__title">Скрытые проекты</h2>
      {hiddenProjects.map(project => (
        <HiddenProjectItem
          key={project.id}
          project={project}
          unHideProject={unHideProject}
          {...rest}
        />
      ))}
    </div>
  )
}

HiddenProjectList.propTypes = {
  hiddenProjects: PropTypes.array.isRequired,
  unHideProject: PropTypes.func.isRequired
}

export default HiddenProjectList
