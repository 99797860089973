import { call } from 'redux-saga/effects'
import fetchImagesSectionSaga from './fetchImagesSectionSaga'

const projectImagesSectionSagaRoute = {
  '/projects/:projectId/images': function* runProjectImagesSectionSaga({
    projectId
  }) {
    yield call(fetchImagesSectionSaga, parseInt(projectId, 10))
  },
  '/projects/:projectId/images/*': function* runProjectImagesSectionSaga({
    projectId
  }) {
    yield call(fetchImagesSectionSaga, parseInt(projectId, 10))
  }
}

export default projectImagesSectionSagaRoute
