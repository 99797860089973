import { RSAA } from 'redux-api-middleware'

export const PUBLICATIONS_REQUEST = 'PUBLICATIONS_REQUEST'
export const PUBLICATIONS_SUCCESS = 'PUBLICATIONS_SUCCESS'
export const PUBLICATIONS_FAILURE = 'PUBLICATIONS_FAILURE'

export const fetchPublications = () => {
  return {
    [RSAA]: {
      endpoint: `api/publication`,
      method: 'get',
      types: [PUBLICATIONS_REQUEST, PUBLICATIONS_SUCCESS, PUBLICATIONS_FAILURE]
    }
  }
}

export const PUBLICATIONS_HAS_CHANGES_REQUEST =
  'PUBLICATIONS_HAS_CHANGES_REQUEST'
export const PUBLICATIONS_HAS_CHANGES_SUCCESS =
  'PUBLICATIONS_HAS_CHANGES_SUCCESS'
export const PUBLICATIONS_HAS_CHANGES_FAILURE =
  'PUBLICATIONS_HAS_CHANGES_FAILURE'

export const checkHasChanges = () => {
  return {
    [RSAA]: {
      endpoint: `api/publication/changes`,
      method: 'post',
      types: [
        PUBLICATIONS_HAS_CHANGES_REQUEST,
        PUBLICATIONS_HAS_CHANGES_SUCCESS,
        PUBLICATIONS_HAS_CHANGES_FAILURE
      ]
    }
  }
}

export const PUBLICATIONS_REFRESH_REQUEST = 'PUBLICATIONS_REFRESH_REQUEST'
export const PUBLICATIONS_REFRESH_SUCCESS = 'PUBLICATIONS_REFRESH_SUCCESS'
export const PUBLICATIONS_REFRESH_FAILURE = 'PUBLICATIONS_REFRESH_FAILURE'

export const publicate = (path = []) => {
  const body = JSON.stringify({
    paths: path
  })
  return {
    [RSAA]: {
      endpoint: `api/publication`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body,
      types: [
        PUBLICATIONS_REFRESH_REQUEST,
        PUBLICATIONS_REFRESH_SUCCESS,
        PUBLICATIONS_REFRESH_FAILURE
      ]
    }
  }
}
