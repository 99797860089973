import { RSAA } from 'redux-api-middleware'

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE'

export const editUser = (userId, form) => {
  return {
    [RSAA]: {
      endpoint: `api/users/${userId}`,
      method: 'put',
      types: [EDIT_USER_REQUEST, EDIT_USER_SUCCESS, EDIT_USER_FAILURE],
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(form)
    }
  }
}

export const updateUser = user => {
  return {
    type: EDIT_USER_SUCCESS,
    payload: user
  }
}
