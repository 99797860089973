import { ARCHITECTURE_CHANGE } from 'connected-react-router'
import { takeLatest, take, fork } from 'redux-saga/effects'
import { PROJECT_ARCHITECTURE_EDIT_TAB_SUBMIT_SUCCESS } from '../actions'
import fetchArchitectureTabSaga from './fetchArchitectureTabSaga'

function* refreshImages(action) {
  const {
    meta: { projectId, tabId }
  } = action
  yield take(ARCHITECTURE_CHANGE)
  yield fork(fetchArchitectureTabSaga, projectId, tabId)
}

function* watchImageSetSubmitted() {
  yield takeLatest(PROJECT_ARCHITECTURE_EDIT_TAB_SUBMIT_SUCCESS, refreshImages)
}

export default watchImageSetSubmitted
