import { RSAA } from 'redux-api-middleware'

export const PROJECT_ADVANTAGES_SECTION_REQUEST =
  'PROJECT_ADVANTAGES_SECTION_REQUEST'
export const PROJECT_ADVANTAGES_SECTION_SUCCESS =
  'PROJECT_ADVANTAGES_SECTION_SUCCESS'
export const PROJECT_ADVANTAGES_SECTION_FAILURE =
  'PROJECT_ADVANTAGES_SECTION_FAILURE'

export const fetchSection = projectId => {
  const success = {
    type: PROJECT_ADVANTAGES_SECTION_SUCCESS,
    meta: {
      projectId
    }
  }
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/advantages`,
      method: 'get',
      types: [
        PROJECT_ADVANTAGES_SECTION_REQUEST,
        success,
        PROJECT_ADVANTAGES_SECTION_FAILURE
      ]
    }
  }
}

export const PROJECT_ADVANTAGES_SECTION_SUBMIT_REQUEST =
  'PROJECT_ADVANTAGES_SECTION_SUBMIT_REQUEST'
export const PROJECT_ADVANTAGES_SECTION_SUBMIT_SUCCESS =
  'PROJECT_ADVANTAGES_SECTION_SUBMIT_SUCCESS'
export const PROJECT_ADVANTAGES_SECTION_SUBMIT_FAILURE =
  'PROJECT_ADVANTAGES_SECTION_SUBMIT_FAILURE'

export const submit = (projectId, advantagesSection) => {
  const meta = {
    projectId,
    advantagesSection
  }
  const success = {
    type: PROJECT_ADVANTAGES_SECTION_SUBMIT_SUCCESS,
    meta
  }
  const failure = {
    type: PROJECT_ADVANTAGES_SECTION_SUBMIT_FAILURE,
    meta
  }

  const { advantages = [], ...rest } = advantagesSection
  const items = advantages.map(t => t.id)
  const form = {
    ...rest,
    items
  }

  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/advantages`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(form),
      types: [PROJECT_ADVANTAGES_SECTION_SUBMIT_REQUEST, success, failure]
    }
  }
}
