import { enhanceWithSpinner } from '../../../../../../components'
import createProjectSectionRoutePage from './createProjectSectionRoutePage'

const createSectionPage = (
  section,
  sectionName = null,
  submit
) => WrappedComponent => {
  const realSectionName = sectionName ? sectionName : section + 'Section'
  const SectionWithSpinner = enhanceWithSpinner(props => {
    const { project } = props
    return (
      project === null ||
      project[realSectionName] === undefined ||
      project[realSectionName] === null
    )
  }, WrappedComponent)

  const mapStateToProps = (state, ownProps) => {
    const { project } = ownProps
    const sectionValue = project[realSectionName]
    return { [realSectionName]: sectionValue }
  }

  const SectionRoutePage = createProjectSectionRoutePage(
    section,
    mapStateToProps,
    { submit }
  )(SectionWithSpinner)

  return SectionRoutePage
}

export default createSectionPage
