import { put } from 'redux-saga/effects'
import { fetchPublications, checkHasChanges } from '../actions'

function* fetchPublicationsSagaOnce() {
  yield put(fetchPublications())
}

export function* refreshHasChangesSaga() {
  yield put(checkHasChanges())
}

export default fetchPublicationsSagaOnce
