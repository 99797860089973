import { connect } from 'react-redux'
import { default as AboutSection } from './components/AboutSection'
import { submitProjectAboutSection } from './actions'
import { enhanceWithSpinner } from '../../../../../../../../components'
import convertSection from './convertSection'

const AboutSectionWithSpinner = enhanceWithSpinner(props => {
  const { project } = props
  return project === null || project.aboutSection === undefined
}, AboutSection)

const mapStateToProps = (state, ownProps) => {
  if (!ownProps.project || !ownProps.project.aboutSection) {
    return {}
  }
  const aboutSection = convertSection(ownProps.project.aboutSection)
  return {
    aboutSection,
    projectId: ownProps.project.id
  }
}

export default connect(
  mapStateToProps,
  {
    submit: submitProjectAboutSection
  }
)(AboutSectionWithSpinner)
