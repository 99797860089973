import { call } from 'redux-saga/effects'
import fetchObjectSaga from './fetchObjectSaga'

const editObjectSagaRoute = {
  '/projects/:projectId/architecture/tabs/:tabId/architecture-type/:objectId': function* runEditArchitectureTypeSaga({
    projectId,
    tabId,
    objectId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const tabIdNumber = parseInt(tabId, 10)
    const objectIdNumber = parseInt(objectId, 10)
    yield call(fetchObjectSaga, projectIdNumber, tabIdNumber, objectIdNumber)
  }
}

export default editObjectSagaRoute
