import {
  CREATE_OBJECT_SUBMIT_SUCCESS,
  CREATE_OBJECT_SUBMIT_FAILURE
} from '../actions'

const createObjectReducer = {
  [CREATE_OBJECT_SUBMIT_SUCCESS]: (state, action) => {
    return [...state, action.payload]
  },
  [CREATE_OBJECT_SUBMIT_FAILURE]: (state, action) => {
    return {
      ...state
    }
  }
}

export default createObjectReducer
