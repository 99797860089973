import { fork } from 'redux-saga/effects'
import loginSaga from '../sagas/loginSaga'

const loginSagaRoute = {
  '/login': function* runLoginSaga() {
    yield fork(loginSaga)
  }
}

export default loginSagaRoute
