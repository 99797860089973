import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import AdvantageSectionForm from './AdvantageSectionForm'
import CreateAdvantageForm from '../pages/CreateAdvantage'
import EditAdvantageForm from '../pages/EditAdvantage'
import CreateImageSetForm from '../pages/EditAdvantage/pages/CreateImageSet'
import EditImageSetForm from '../pages/EditAdvantage/pages/EditImageSet'
import { ProjectConsumer } from '../../../ProjectContext'

const AdvantageSectionPage = props => {
  const {
    advantagesSection,
    match: { url }
  } = props
  return (
    <ProjectConsumer>
      {project => {
        const projectId = project.id
        return (
          <Switch>
            <Route
              exact
              path={`${url}/add`}
              render={() => {
                return <CreateAdvantageForm projectId={projectId} />
              }}
            />
            <Route
              path={`${url}/:id(\\d+)`}
              render={({ match: { params } }) => {
                const advantageId = parseInt(params.id, 10)
                const advantage = advantagesSection.advantages.find(
                  t => t.id === advantageId
                )
                if (!advantage) {
                  return <Redirect to="/404" />
                }
                const backUrl = `${url}/${advantageId}`
                return (
                  <Switch>
                    <Route
                      exact
                      path={`${url}/${advantageId}/images/:id(\\d+)`}
                      render={({ match: { params } }) => {
                        const id = parseInt(params.id, 10)
                        const imageSet = advantage.imageSets.find(
                          t => t.id === id
                        )
                        if (!imageSet) {
                          return <Redirect to="/404" />
                        }
                        return (
                          <EditImageSetForm
                            galleryId={advantageId}
                            projectId={project.id}
                            imageSet={imageSet}
                            id={id}
                            backUrl={backUrl}
                          />
                        )
                      }}
                    />
                    <Route
                      exact
                      path={`${url}/${advantage.id}/images/add`}
                      render={() => {
                        return (
                          <CreateImageSetForm
                            galleryId={advantage.id}
                            projectId={project.id}
                            backUrl={backUrl}
                          />
                        )
                      }}
                    />
                    <EditAdvantageForm
                      projectId={projectId}
                      advantage={advantage}
                    />
                  </Switch>
                )
              }}
            />
            <AdvantageSectionForm
              advantagesSection={advantagesSection}
              projectId={projectId}
            />
          </Switch>
        )
      }}
    </ProjectConsumer>
  )
}

AdvantageSectionPage.propTypes = {
  projectId: PropTypes.number.isRequired,
  advantagesSection: PropTypes.object
}

export default AdvantageSectionPage
