import { put, all, fork } from 'redux-saga/effects'
import { fetchPinImageMetaData } from '../actions'

function* fetchPinImageMetaDataSaga(projectId, pinImageMeta) {
  const { metaUrl, type, id } = pinImageMeta
  if (metaUrl) {
    yield put(fetchPinImageMetaData(projectId, id, type, metaUrl))
  }
}

function* fetchTeamObjectPinImagesSaga(projectId, teamObject) {
  const images = Object.keys(teamObject)
    .filter(key => key.endsWith('Image'))
    .map(key => {
      return {
        ...teamObject[key],
        id: teamObject.id,
        type: key,
        url: teamObject[key].url
      }
    })
    .filter(t => t.metaUrl && !t.url)

  const fetchTasks = images.map(pinImageMeta =>
    fork(fetchPinImageMetaDataSaga, projectId, pinImageMeta)
  )
  yield all(fetchTasks)
}

export default fetchTeamObjectPinImagesSaga
