import updatePublicSpacesType from '../../../reducers/updatePublicSpacesType'

const updateImageSet = reducer =>
  updatePublicSpacesType((publicSpacesType, action) => {
    const { id } = action.meta
    const imageSets = publicSpacesType.imageSets.map(
      imageSet =>
        imageSet.id === id
          ? { ...imageSet, ...reducer(imageSet, action) }
          : imageSet
    )

    return {
      ...publicSpacesType,
      imageSets
    }
  })

export default updateImageSet
