import { default as projectDecorationSectionSagaRoute } from './projectDecorationSectionSagaRoute'
import createTabSagaRoute from '../pages/CreateTab/sagas'
import editTabSagaRoute from '../pages/EditTab/sagas'

export {
  default as fetchDecorationSectionSaga
} from './fetchDecorationSectionSaga'
export { watchDecorationTabSubmitted } from '../pages/EditTab/sagas'

export default {
  ...editTabSagaRoute,
  ...createTabSagaRoute,
  ...projectDecorationSectionSagaRoute
}
