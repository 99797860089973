import { put, call, all } from 'redux-saga/effects'
import { fetchPinImageMetaData } from '../actions'
import { fetchAdvantagesSectionSaga } from '../../../sagas'

function* fetchPinImageMetaDataSaga(projectId, advantageId, imageMeta) {
  yield put(fetchPinImageMetaData(projectId, advantageId, imageMeta))
}

function* fetchAdvantageSaga(projectId, advantageId) {
  const advantageseSection = yield call(fetchAdvantagesSectionSaga, projectId)
  if (advantageseSection) {
    const { advantages = [] } = advantageseSection
    const advantage = advantages.find(t => t.id === advantageId)
    if (advantage) {
      const { title, description, id, position, ...imageTypes } = advantage
      const images = Object.keys(imageTypes)
        .map(key => {
          return {
            ...advantage[key],
            type: key
          }
        })
        .filter(t => t.metaUrl)
      const fetchTasks = images.map(imageMeta =>
        call(fetchPinImageMetaDataSaga, projectId, advantageId, imageMeta)
      )
      yield all(fetchTasks)
      return advantage
    }
  }

  return null
}

export default fetchAdvantageSaga
