import updateTab from '../../../reducers/updateTab'

const updateDecorationType = reducer =>
  updateTab((tab, action) => {
    const { objectId } = action.meta
    const decorationTypes = tab.objects.map(
      decorationType =>
        decorationType.id === objectId
          ? { ...decorationType, ...reducer(decorationType, action) }
          : decorationType
    )

    return {
      ...tab,
      objects: decorationTypes
    }
  })

export default updateDecorationType
