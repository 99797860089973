import {
  PROJECT_IMAGES_SECTION_SUCCESS,
  PROJECT_IMAGES_SECTION_SUBMIT_SUCCESS
} from '../actions'
import updateProject from '../../../reducers/updateProject'

const imageSectionReducer = {
  [PROJECT_IMAGES_SECTION_SUCCESS]: updateProject((project, action) => {
    const {
      payload: {
        sectionName = 'Изображения',
        projectCardImages = [],
        projectListImages = [],
        deletedListImageSets = [],
        deletedCardImageSets = [],
        ...rest
      }
    } = action
    return {
      ...project,
      imagesSection: {
        ...project.imagesSection,
        sectionName,
        projectCardImages,
        projectListImages,
        deletedListImageSets,
        deletedCardImageSets,
        ...rest
      }
    }
  }),
  [PROJECT_IMAGES_SECTION_SUBMIT_SUCCESS]: updateProject((project, action) => {
    const { payload } = action
    return {
      ...project,
      imagesSection: {
        ...project.imagesSection,
        ...payload
      }
    }
  })
}

export default imageSectionReducer
