import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_SUBMIT_SUCCESS } from '../actions'
import { fetchSection } from '../../../actions'

function* watchGalleryUpdatedSaga(projectId) {
  while (true) {
    yield take(PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_SUBMIT_SUCCESS)
    yield put(fetchSection(projectId))
    yield put(push(`/projects/${projectId}/construction`))
  }
}

export default watchGalleryUpdatedSaga
