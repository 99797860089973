import React from 'react'
import PropTypes from 'prop-types'
import Stars from '../../../components/Stars/Stars'
import moment from 'moment'
import './comment.css'

const formatPhone = (phone = '') => {
  if (!phone || phone.length < 4) {
    return phone
  }

  const number = phone.replace(/\D/g, '')
  let match = number.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)

  if (!match) return null

  let formated = `+${match[1]}`
  if (match.length > 1) {
    formated += ` (${match[2]})`
  }
  if (match.length > 2) {
    formated += ` ${match[3]}`
  }
  if (match.length > 3) {
    formated += `-${match[4]}`
  }

  return <a href={`tel:${formated}`}>{formated}</a>
}

const Comment = ({ comment }) => {
  const {
    date,
    author,
    text,
    rate,
    phoneNumber = null,
    contractNumber = null
  } = comment
  const phone = formatPhone(phoneNumber)
  const contract =
    contractNumber && contractNumber !== '' ? `, #${contractNumber}` : null

  return (
    <>
      <hr className="formField__separator" />
      <div className="comment">
        <div className="comment__wrapper">
          <div className="comment__author">
            {author}
            <br />
            <div className="comment__info">
              {phone}
              {contract}
            </div>
          </div>
          <div className="comment__rate">
            <Stars value={rate} />
            <div className="comment__date">
              {moment(date).format('DD MMMM YYYY')}
            </div>
          </div>
        </div>
        <div className="comment__text">{text}</div>
      </div>
    </>
  )
}

Comment.propTypes = {
  comment: PropTypes.object.isRequired
}

export default Comment
