import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { projectProp } from '../../reducers/selectors'

const mapProjectStateToProps = mapStateToProps => (state, ownProps) => {
  const { projectId } = ownProps
  const project = projectProp(projectId)(state)
  const propsWithProject = { ...ownProps, project }
  const props = mapStateToProps
    ? mapStateToProps(state, propsWithProject)
    : propsWithProject
  return { ...propsWithProject, ...props }
}

const createProjectSectionRoutePage = (
  section,
  mapStateToProps,
  actions
) => WrappedComponent => {
  const connectToProjectState = connect(
    mapProjectStateToProps(mapStateToProps),
    actions
  )
  const ConnectedWrappedComponent = connectToProjectState(WrappedComponent)
  ConnectedWrappedComponent.displayName = `${section}SectionPage`
  return ({ exact = false, ...ownProps }) => {
    return (
      <Route
        path={`/projects/:id(\\d+)/${section}`}
        render={props => {
          const {
            history,
            location,
            match: {
              params: { id }
            },
            ...rest
          } = props
          const projectId = parseInt(id, 10)
          if (isNaN(projectId)) {
            return <Redirect to="404" />
          }
          const projectProps = {
            ...rest,
            ...ownProps,
            match: props.match,
            projectId
          }
          return <ConnectedWrappedComponent {...projectProps} />
        }}
      />
    )
  }
}

export default createProjectSectionRoutePage
