import { call, fork } from 'redux-saga/effects'
import routesSaga from './routesSaga'
import apiRootSaga from './apiRootSaga'
import unauthorizedSaga from './unauthorizedSaga'
import { watchGeneralSectionSubmitted } from '../pages/Home/pages/Projects/pages/EditProjectForm/pages/GeneralSection/sagas'
import { watchGalleryImageSetSubmitted } from '../pages/Home/pages/Projects/pages/EditProjectForm/pages/ConstructionProgressSection/sagas'
import { watchAdvantageImageSetSubmitted } from '../pages/Home/pages/Projects/pages/EditProjectForm/pages/AdvantageSection/sagas'
import { watchLocationObjectSubmitted } from '../pages/Home/pages/Projects/pages/EditProjectForm/pages/LocationSection/sagas'
import { watchDocumentSubmitted } from '../pages/Home/pages/Projects/pages/EditProjectForm/pages/DocumentsSection/sagas'
import fetchObjectsSaga from '../pages/Home/pages/Objects/sagas/fetchObjectsSaga'

export default function* root(history, dispatch) {
  yield call(apiRootSaga)
  yield fork(routesSaga(history, dispatch))
  yield fork(unauthorizedSaga)
  yield fork(fetchObjectsSaga)
  yield fork(watchGeneralSectionSubmitted)
  yield fork(watchGalleryImageSetSubmitted)
  yield fork(watchAdvantageImageSetSubmitted)
  yield fork(watchLocationObjectSubmitted)
  yield fork(watchDocumentSubmitted)
}
