import createSectionPage from '../../createSectionPage'
import AccomplishmentSectionPage from './components'
import { submit } from './actions'

const AccomplishmentSectionRoutePage = createSectionPage(
  'accomplishment',
  null,
  submit
)(AccomplishmentSectionPage)

export default AccomplishmentSectionRoutePage
