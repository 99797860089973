import {
  PROJECT_ACCOMPLISHMENT_CREATE_ACCOMPLISHMENT_TYPE_SUBMIT_SUCCESS,
  PROJECT_ACCOMPLISHMENT_CREATE_ACCOMPLISHMENT_TYPE_SUBMIT_FAILURE
} from '../actions'
import updateTab from '../../../reducers/updateTab'

const createAccomplishmentTypeReducer = {
  [PROJECT_ACCOMPLISHMENT_CREATE_ACCOMPLISHMENT_TYPE_SUBMIT_SUCCESS]: updateTab(
    (tab, { payload }) => {
      const accomplishmentTypes = [...tab.objects, payload]
      return {
        ...tab,
        objects: accomplishmentTypes
      }
    }
  ),
  [PROJECT_ACCOMPLISHMENT_CREATE_ACCOMPLISHMENT_TYPE_SUBMIT_FAILURE]: (
    state,
    action
  ) => {
    return {
      ...state,
      error: action.payload.response
    }
  }
}

export default createAccomplishmentTypeReducer
