import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const CREATE_OBJECT_SUBMIT_REQUEST = 'CREATE_OBJECT_SUBMIT_REQUEST'
export const CREATE_OBJECT_SUBMIT_SUCCESS = 'CREATE_OBJECT_SUBMIT_SUCCESS'
export const CREATE_OBJECT_SUBMIT_FAILURE = 'CREATE_OBJECT_SUBMIT_FAILURE'

export const submit = object => {
  const success = {
    type: CREATE_OBJECT_SUBMIT_SUCCESS,
    meta: {
      object
    }
  }
  const formData = objectToFormData(object)
  return {
    [RSAA]: {
      endpoint: `api/Projects/1/objects`,
      method: 'post',
      headers: {},
      body: formData,
      types: [
        CREATE_OBJECT_SUBMIT_REQUEST,
        success,
        CREATE_OBJECT_SUBMIT_FAILURE
      ]
    }
  }
}
