import {
  takeLatest,
  put,
  race,
  take,
  all,
  fork,
  select
} from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'
import {
  submitFiles,
  submitFile,
  fetchDocument,
  PROJECT_DOCUMENTS_SECTION_SUBMIT_SUCCESS,
  PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_FAILURE,
  PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_SUCCESS
} from '../actions'
import { documentsSectionProp } from '../reducers/selectors'

function* submitDocuments(action) {
  const {
    meta: { projectId, documentsSection }
  } = action

  const documents = {
    documents: documentsSection.documents
  }
  yield put(submitFiles(projectId, documents.documents))

  const { success } = yield race({
    success: take(PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_SUCCESS),
    failure: take(PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_FAILURE)
  })

  if (success) {
    const createdDocuments = success.payload.documents
    const tasks = createdDocuments
      .map((document, index) => {
        const documentFile = documentsSection.documents[index]
        if (documentFile && documentFile.file.preview) {
          return {
            ...document,
            file: documentFile.file
          }
        }

        return null
      })
      .filter(t => t !== null)
      .map(t => put(submitFile(projectId, t.id, t.file)))

    yield all(tasks)
  }
}

function* submitDocumentsSaga() {
  yield takeLatest(PROJECT_DOCUMENTS_SECTION_SUBMIT_SUCCESS, submitDocuments)
}

export function* fetchDocuments(projectId) {
  const section = yield select(documentsSectionProp(projectId))
  const { documents = [] } = section
  const tasks = documents
    .filter(t => t.file)
    .map(document => put(fetchDocument(projectId, document)))
  yield all(tasks)
}

function* refreshDocuments(action) {
  const {
    meta: { projectId }
  } = action
  yield take(LOCATION_CHANGE)
  yield fork(fetchDocuments, projectId)
}

export function* watchDocumentSubmitted() {
  yield takeLatest(
    PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_SUCCESS,
    refreshDocuments
  )
}

export default submitDocumentsSaga
