import {
  fetchSection,
  PROJECT_PUBLIC_SPACES_SECTION_SUCCESS,
  PROJECT_PUBLIC_SPACES_SECTION_FAILURE
} from '../actions'
import createSectionSaga from '../../../sagas/createSectionSaga'
import fetchPublicSpacesSectionPinImagesSaga from './fetchPublicSpacesSectionPinImagesSaga'

export default createSectionSaga(
  fetchSection,
  [
    PROJECT_PUBLIC_SPACES_SECTION_SUCCESS,
    PROJECT_PUBLIC_SPACES_SECTION_FAILURE
  ],
  project => project.publicSpacesSection,
  {
    sectionName: 'Объект',
    publicSpacesObjects: []
  },
  fetchPublicSpacesSectionPinImagesSaga
)
