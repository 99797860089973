import { default as advantagesSectionSagaRoute } from './advantagesSectionSagaRoute'
import createAdvantageSagaRoute from '../pages/CreateAdvantage/sagas/'
import editAdvantageSagaRoute from '../pages/EditAdvantage/sagas'
export {
  default as fetchAdvantagesSectionSaga
} from './fetchAdvantagesSectionSaga'
export {
  default as watchAdvantageImageSetSubmitted
} from '../pages/EditAdvantage/pages/EditImageSet/sagas/watchImageSetSubmitted'

export default {
  ...createAdvantageSagaRoute,
  ...editAdvantageSagaRoute,
  ...advantagesSectionSagaRoute
}
