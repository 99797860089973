import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import { required } from '../../../../../../../../../../../../components/Validation'
import {
  FormField,
  TextareaField
} from '../../../../../../../../../../../../components/Form'
import projectForm from '../../../../../../../../components/projectForm'
import { SelectField } from '../../../../../../../../../../components/Form'
import { SubmitButton } from '../../../../../../../../../../components/Buttons'
import './mortgageForm.css'

const focusOnErrors = createDecorator()

const onSubmit = (projectId, submit) => locationObject => {
  submit(projectId, locationObject)
}

const CreateObjectForm = ({ projectId, submit, objectTypes }) => {
  const options = [
    { label: '', value: '' },
    ...objectTypes.map(type => {
      return {
        label: type.name,
        value: type.value
      }
    })
  ]
  return (
    <Form
      mutators={{
        ...arrayMutators
      }}
      onSubmit={onSubmit(projectId, submit)}
      decorators={[focusOnErrors]}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <Link
                  to={`/projects/${projectId}/location`}
                  className="formField__back"
                >
                  Назад
                </Link>
              </div>
            </div>
            <hr className="formField__separator" />
            <div className="row">
              <div className="col">
                <h4>Объект инфраструктуры</h4>
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <SelectField
                  name="objectType"
                  form={form}
                  options={options}
                  validate={required}
                  label="Тип объекта"
                  placeholder="Тип объекта"
                />
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <FormField
                  name="title"
                  validate={required}
                  type="text"
                  label="Заголовок"
                  placeholder="Заголовок"
                />
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <TextareaField
                  name="description"
                  type="text"
                  label="Описание"
                  placeholder="Описание"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h4>Координаты</h4>
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <FormField
                  name="location.latitude"
                  validate={required}
                  type="text"
                  label="Широта"
                />
              </div>
              <div className="col formField">
                <FormField
                  name="location.longitude"
                  validate={required}
                  type="text"
                  label="Долгота"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h4>Адрес</h4>
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <FormField
                  name="address"
                  type="text"
                  label="Улица, дом, корпус"
                />
              </div>
            </div>
            <SubmitButton
              pristine={pristine}
              submitSucceeded={submitSucceeded}
              dirtySinceLastSubmit={dirtySinceLastSubmit}
            />
          </form>
        )
      }}
    />
  )
}

CreateObjectForm.propTypes = {
  projectId: PropTypes.number.isRequired,
  submit: PropTypes.func.isRequired,
  objectTypes: PropTypes.array.isRequired
}

export default projectForm(CreateObjectForm)
