import { fork, call } from 'redux-saga/effects'
import watchImageSetUpdatedSaga from './watchImageSetUpdatedSaga'
import fetchImageSetSaga from './fetchImageSetSaga'

const editImageSetSagaRoute = {
  '/projects/:projectId/construction/galleries/:galleryId/images/:imageSetId(\\d+)': function* runEditImageSetSaga({
    projectId,
    galleryId,
    imageSetId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const imageSet = yield call(
      fetchImageSetSaga,
      projectIdNumber,
      parseInt(galleryId, 10),
      parseInt(imageSetId, 10)
    )
    if (imageSet) {
      yield fork(watchImageSetUpdatedSaga, projectIdNumber)
    }
  }
}

export default editImageSetSagaRoute
