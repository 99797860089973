import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import FormControl from './FormControl'

const isImageDeletedFormFieldName = name => `${name}IsDeleted`

const createFileUploadComponent = FileUploadComponent => {
  const FileUploadField = ({ form, name, validate, value, ...rest }) => {
    return (
      <Field name={name} validate={validate}>
        {({ input, meta }) => {
          const value = input.value !== '' ? input.value : null
          const hasError = meta.error && meta.touched
          return (
            <FileUploadComponent
              {...rest}
              key="file"
              value={value}
              onDrop={files => {
                form.change(name, files)
                form.change(isImageDeletedFormFieldName(name), 'false')
                form.blur(name)
              }}
              onRemove={() => {
                form.change(name, null)
                form.change(isImageDeletedFormFieldName(name), 'true')
                form.blur(name)
              }}
              hasError={hasError}
            />
          )
        }}
      </Field>
    )
  }

  FileUploadField.propTypes = {
    ...FormControl.propTypes,
    name: PropTypes.string.isRequired,
    validate: PropTypes.func,
    form: PropTypes.object.isRequired
  }

  return FileUploadField
}

export default createFileUploadComponent
