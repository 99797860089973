import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_ACCOMPLISHMENT_CREATE_ACCOMPLISHMENT_TYPE_SUBMIT_SUCCESS } from '../actions'

function* watchCreateObjectSaga(projectId, tabId) {
  while (true) {
    const {
      payload: { id }
    } = yield take(
      PROJECT_ACCOMPLISHMENT_CREATE_ACCOMPLISHMENT_TYPE_SUBMIT_SUCCESS
    )
    yield put(
      push(
        `/projects/${projectId}/accomplishment/tabs/${tabId}/accomplishment-type/${id}`
      )
    )
  }
}

export default watchCreateObjectSaga
