import { RSAA } from 'redux-api-middleware'

export const PROJECT_DECORATION_EDIT_TAB_SUBMIT_REQUEST =
  'PROJECT_DECORATION_EDIT_TAB_SUBMIT_REQUEST'
export const PROJECT_DECORATION_EDIT_TAB_SUBMIT_SUCCESS =
  'PROJECT_DECORATION_EDIT_TAB_SUBMIT_SUCCESS'
export const PROJECT_DECORATION_EDIT_TAB_SUBMIT_FAILURE =
  'PROJECT_DECORATION_EDIT_TAB_SUBMIT_FAILURE'

export const submit = (projectId, tabId, decorationObject) => {
  const success = {
    type: PROJECT_DECORATION_EDIT_TAB_SUBMIT_SUCCESS,
    meta: {
      projectId,
      tabId,
      decorationObject
    }
  }

  return {
    [RSAA]: {
      endpoint: `api/projects/${projectId}/decoration/tabs/${tabId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(decorationObject),
      types: [
        PROJECT_DECORATION_EDIT_TAB_SUBMIT_REQUEST,
        success,
        PROJECT_DECORATION_EDIT_TAB_SUBMIT_FAILURE
      ]
    }
  }
}

export const PROJECT_DECORATION_TAB_REQUEST = 'PROJECT_DECORATION_TAB_REQUEST'
export const PROJECT_DECORATION_TAB_SUCCESS = 'PROJECT_DECORATION_TAB_SUCCESS'
export const PROJECT_DECORATION_TAB_FAILURE = 'PROJECT_DECORATION_TAB_FAILURE'

export const fetchTab = (projectId, tabId) => {
  const success = {
    type: PROJECT_DECORATION_TAB_SUCCESS,
    meta: {
      projectId,
      tabId
    }
  }

  return {
    [RSAA]: {
      endpoint: `api/projects/${projectId}/decoration/tabs/${tabId}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      types: [
        PROJECT_DECORATION_TAB_REQUEST,
        success,
        PROJECT_DECORATION_TAB_FAILURE
      ]
    }
  }
}
