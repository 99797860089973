export const required = value => (value ? undefined : 'Необходимо заполнить')

export const password = value => {
  if (value) {
    return value.length >= 10
      ? undefined
      : 'Пароль должен содержать минимум 10 символов'
  }
  return undefined
}

export const email = value => {
  const re = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  if (value) {
    return re.test(value) ? undefined : 'Невалидный Email'
  }
  return undefined
}

export const phone = value => {
  const re = /^\+7[0-9]{10}$/
  if (value) {
    return re.test(value) ? undefined : 'Телефон в формате +7XXXXXXXXXX'
  }
  return undefined
}

export const latinAndNumbers = value => {
  const re = /^[A-Za-z0-9]*$/
  if (value) {
    return re.test(value) ? undefined : 'Латинские символы и цифры'
  }
  return undefined
}

export const numbers = value => {
  const re = /^\d*$/
  if (value) {
    return re.test(value) ? undefined : 'Только цифры'
  }
  return undefined
}

export const repeatValue = password => value => {
  if (password) {
    return value === password ? undefined : 'Пароли должны совпадать'
  }
  return undefined
}

export const validate = (...validators) => value => {
  for (let i = 0; i < validators.length; i++) {
    let result = validators[i](value)
    if (result) {
      return result
    }
  }

  return undefined
}

export const requiredPassword = validate(required, password)

export const repeatPassword = validate(repeatValue, password)

export { default as FormError } from './FormError'
export { formWithError } from './formWithError'
