import React from 'react'
import loading from './loading.png'
import './loader.css'

const Loader = () => {
  return (
    <div className="loader" key={0}>
      <img src={loading} width={'40'} height={'40'} alt={'Загрузка...'} />
    </div>
  )
}

export default Loader
