import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_ARCHITECTURE_CREATE_GALLERY_IMAGE_SET_SUBMIT_SUCCESS } from '../actions'

function* watchImageSetCreatedSaga() {
  while (true) {
    const {
      meta: { objectId, projectId, tabId }
    } = yield take(PROJECT_ARCHITECTURE_CREATE_GALLERY_IMAGE_SET_SUBMIT_SUCCESS)
    yield put(
      push(
        `/projects/${projectId}/architecture/tabs/${tabId}/architecture-type/${objectId}`
      )
    )
  }
}

export default watchImageSetCreatedSaga
