import { RSAA } from 'redux-api-middleware'

export const PROJECTS_LIST_REQUEST = 'PROJECTS_LIST_REQUEST'
export const PROJECTS_LIST_SUCCESS = 'PROJECTS_LIST_SUCCESS'
export const PROJECTS_LIST_FAILURE = 'PROJECTS_LIST_FAILURE'

export const fetchProjects = () => {
  return {
    [RSAA]: {
      endpoint: 'api/projects',
      method: 'get',
      types: [
        PROJECTS_LIST_REQUEST,
        PROJECTS_LIST_SUCCESS,
        PROJECTS_LIST_FAILURE
      ]
    }
  }
}

export const PROJECTS_CRM_LIST_REQUEST = 'PROJECTS_CRM_LIST_REQUEST'
export const PROJECTS_CRM_LIST_SUCCESS = 'PROJECTS_CRM_LIST_SUCCESS'
export const PROJECTS_CRM_LIST_FAILURE = 'PROJECTS_CRM_LIST_FAILURE'

export const fetchCrmProjects = () => {
  const request = {
    method: 'getDevelopmentprojects'
  }
  return {
    [RSAA]: {
      endpoint: 'api/crm',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request),
      types: [
        PROJECTS_CRM_LIST_REQUEST,
        PROJECTS_CRM_LIST_SUCCESS,
        PROJECTS_CRM_LIST_FAILURE
      ]
    }
  }
}

export const PROJECT_MOVE_REQUEST = 'PROJECT_MOVE_REQUEST'
export const PROJECT_MOVE_SUCCESS = 'PROJECT_MOVE_SUCCESS'
export const PROJECT_MOVE_FAILURE = 'PROJECT_MOVE_FAILURE'

export const move = (projectId, oldIndex, newIndex) => {
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/${newIndex}`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      types: [
        {
          type: PROJECT_MOVE_REQUEST,
          meta: { oldIndex, newIndex }
        },
        PROJECT_MOVE_SUCCESS,
        PROJECT_MOVE_FAILURE
      ]
    }
  }
}

export const PROJECT_DELETE_REQUEST = 'PROJECT_DELETE_REQUEST'
export const PROJECT_DELETE_SUCCESS = 'PROJECT_DELETE_SUCCESS'
export const PROJECT_DELETE_FAILURE = 'PROJECT_DELETE_FAILURE'

export const deleteProject = projectId => {
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}`,
      method: 'delete',
      types: [
        PROJECT_DELETE_REQUEST,
        {
          type: PROJECT_DELETE_SUCCESS,
          meta: { projectId }
        },
        PROJECT_DELETE_FAILURE
      ]
    }
  }
}

export const PROJECT_HIDE_REQUEST = 'PROJECT_HIDE_REQUEST'
export const PROJECT_HIDE_SUCCESS = 'PROJECT_HIDE_SUCCESS'
export const PROJECT_HIDE_FAILURE = 'PROJECT_HIDE_FAILURE'

export const hideProject = projectId => {
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/hide`,
      method: 'put',
      types: [
        PROJECT_HIDE_REQUEST,
        {
          type: PROJECT_HIDE_SUCCESS,
          meta: { projectId }
        },
        PROJECT_HIDE_FAILURE
      ]
    }
  }
}

export const PROJECT_UNHIDE_REQUEST = 'PROJECT_UNHIDE_REQUEST'
export const PROJECT_UNHIDE_SUCCESS = 'PROJECT_UNHIDE_SUCCESS'
export const PROJECT_UNHIDE_FAILURE = 'PROJECT_UNHIDE_FAILURE'

export const unHideProject = projectId => {
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/unhide`,
      method: 'put',
      types: [
        PROJECT_UNHIDE_REQUEST,
        {
          type: PROJECT_UNHIDE_SUCCESS,
          meta: { projectId }
        },
        PROJECT_UNHIDE_FAILURE
      ]
    }
  }
}
