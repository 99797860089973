import { fork } from 'redux-saga/effects'
import fetchUsersSaga from './fetchUsersSaga'
import createUserSagaRoute from '../pages/AddUserForm/sagas'
import editUserSagaRoute from '../pages/EditUserForm/sagas'

const UsersSagaRoute = {
  '/users': function* runUsersSaga() {
    yield fork(fetchUsersSaga)
  },
  ...createUserSagaRoute,
  ...editUserSagaRoute
}

export default UsersSagaRoute
