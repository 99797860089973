import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const ABOUT_REQUEST = 'ABOUT_REQUEST'
export const ABOUT_SUCCESS = 'ABOUT_SUCCESS'
export const ABOUT_FAILURE = 'ABOUT_FAILURE'

export const fetchAbout = () => {
  return {
    [RSAA]: {
      endpoint: 'api/aboutCompany',
      method: 'get',
      types: [ABOUT_REQUEST, ABOUT_SUCCESS, ABOUT_FAILURE]
    }
  }
}

export const ABOUT_SUBMIT_REQUEST = 'ABOUT_SUBMIT_REQUEST'
export const ABOUT_SUBMIT_SUCCESS = 'ABOUT_SUBMIT_SUCCESS'
export const ABOUT_SUBMIT_FAILURE = 'ABOUT_SUBMIT_FAILURE'

export const submitAbout = aboutObj => {
  const meta = {
    aboutObj
  }
  const request = {
    type: ABOUT_SUBMIT_REQUEST,
    meta
  }
  const success = {
    type: ABOUT_SUBMIT_SUCCESS,
    meta
  }
  const { block2 } = aboutObj
  const { images = [], ...rest } = block2
  const items = images.map(t => t.id)
  const about = {
    ...aboutObj,
    block2: {
      ...rest,
      items
    }
  }
  const formData = objectToFormData(about)

  return {
    [RSAA]: {
      endpoint: `api/aboutCompany`,
      method: 'put',
      headers: {},
      body: formData,
      types: [request, success, ABOUT_SUBMIT_FAILURE]
    }
  }
}

export const ABOUT_ROOT_IMAGE_META_REQUEST = 'ABOUT_ROOT_IMAGE_META_REQUEST'
export const ABOUT_ROOT_IMAGE_META_SUCCESS = 'ABOUT_ROOT_IMAGE_META_SUCCESS'
export const ABOUT_ROOT_IMAGE_META_FAILURE = 'ABOUT_ROOT_IMAGE_META_FAILURE'

export const fetchRootImageMetaData = (metaUrl, name) => {
  const success = {
    type: ABOUT_ROOT_IMAGE_META_SUCCESS,
    meta: {
      name
    }
  }
  const failure = {
    type: ABOUT_ROOT_IMAGE_META_FAILURE,
    meta: {
      name
    }
  }
  return {
    [RSAA]: {
      endpoint: metaUrl,
      method: 'get',
      types: [ABOUT_ROOT_IMAGE_META_REQUEST, success, failure]
    }
  }
}
