import { fork } from 'redux-saga/effects'
import watchPushCreated from './watchPushCreated'
import fetchPushesSaga from '../../../sagas/fetchPushesSaga'

const createPushSagaRoute = {
  '/pushes/add': function* runAddPushSaga() {
    yield fork(fetchPushesSaga)
    yield fork(watchPushCreated)
  }
}

export default createPushSagaRoute
