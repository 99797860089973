import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_DECORATION_CREATE_TAB_SUBMIT_SUCCESS } from '../actions'

function* watchCreateTabSaga(projectId) {
  while (true) {
    const {
      payload: { id }
    } = yield take(PROJECT_DECORATION_CREATE_TAB_SUBMIT_SUCCESS)
    yield put(push(`/projects/${projectId}/decoration/tabs/${id}`))
  }
}

export default watchCreateTabSaga
