const getName = (prefix, imageType) => {
  if (prefix) {
    const imageTypeName = !imageType.startsWith('i') // iOS images prefix
      ? imageType.charAt(0).toUpperCase() + imageType.slice(1)
      : imageType

    return `${prefix}${imageTypeName}`
  }

  return imageType
}

export default getName
