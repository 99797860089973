import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const PROJECT_ADVANTAGES_CREATE_GALLERY_IMAGE_SET_SUBMIT_REQUEST =
  'PROJECT_ADVANTAGES_CREATE_GALLERY_IMAGE_SET_SUBMIT_REQUEST'
export const PROJECT_ADVANTAGES_CREATE_GALLERY_IMAGE_SET_SUBMIT_SUCCESS =
  'PROJECT_ADVANTAGES_CREATE_GALLERY_IMAGE_SET_SUBMIT_SUCCESS'
export const PROJECT_ADVANTAGES_CREATE_GALLERY_IMAGE_SET_SUBMIT_FAILURE =
  'PROJECT_ADVANTAGES_CREATE_GALLERY_IMAGE_SET_SUBMIT_FAILURE'

export const submit = (projectId, galleryId, imageSet) => {
  const meta = {
    projectId,
    advantageId: galleryId,
    imageSet
  }
  const success = {
    type: PROJECT_ADVANTAGES_CREATE_GALLERY_IMAGE_SET_SUBMIT_SUCCESS,
    meta
  }
  const failure = {
    type: PROJECT_ADVANTAGES_CREATE_GALLERY_IMAGE_SET_SUBMIT_FAILURE,
    meta
  }
  const formData = objectToFormData(imageSet)

  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/advantages/${galleryId}/images`,
      method: 'post',
      headers: {},
      body: formData,
      types: [
        PROJECT_ADVANTAGES_CREATE_GALLERY_IMAGE_SET_SUBMIT_REQUEST,
        success,
        failure
      ]
    }
  }
}
