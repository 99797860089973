import { connect } from 'react-redux'
import { createUser } from './actions'
import { AddUserForm as AddUserFormComponent } from './components'

const mapStateToProps = state => {
  const { user } = state
  return {
    user
  }
}

export default connect(
  mapStateToProps,
  {
    createUser
  }
)(AddUserFormComponent)
