import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import createDecorator from 'final-form-focus'
import { required } from '../../../../../../../../../../../../components/Validation'
import { FormField } from '../../../../../../../../../../../../components/Form'
import projectForm from '../../../../../../../../components/projectForm'
import { SubmitButton } from '../../../../../../../../../../components/Buttons'
import AccomplishmentTypeSortableList from './AccomplishmentTypeSortableList'

const focusOnErrors = createDecorator()

const onSubmit = (projectId, id, submit) => accomplishmentObject => {
  submit(projectId, id, accomplishmentObject)
}

const EditTabForm = ({ projectId, tabId, submit, tab }) => {
  return (
    <Form
      initialValues={tab}
      mutators={{
        ...arrayMutators
      }}
      onSubmit={onSubmit(projectId, tabId, submit)}
      decorators={[focusOnErrors]}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <Link
                  to={`/projects/${projectId}/accomplishment`}
                  className="formField__back"
                >
                  Назад
                </Link>
              </div>
            </div>
            <hr className="formField__separator" />
            <div className="row">
              <div className="col formField">
                <FormField
                  name="name"
                  validate={required}
                  type="text"
                  label="Название таба"
                  placeholder="Название таба"
                />
              </div>
            </div>
            <hr className="formField__separator" />
            <div className="row">
              <div className="col">
                <FieldArray name="tabs">
                  {({ fields }) => {
                    return (
                      <AccomplishmentTypeSortableList
                        fields={fields}
                        projectId={projectId}
                        tabId={tabId}
                        distance={1}
                      />
                    )
                  }}
                </FieldArray>
              </div>
            </div>
            <SubmitButton
              pristine={pristine}
              submitSucceeded={submitSucceeded}
              dirtySinceLastSubmit={dirtySinceLastSubmit}
            />
          </form>
        )
      }}
    />
  )
}

EditTabForm.propTypes = {
  projectId: PropTypes.number.isRequired,
  tabId: PropTypes.number.isRequired,
  submit: PropTypes.func.isRequired,
  tab: PropTypes.object.isRequired
}

export default projectForm(EditTabForm)
