import { connect } from 'react-redux'
import { submit } from './actions'
import { default as EditImageSetFormComponent } from './components'

const mapStateToProps = (state, ownProps) => {
  return {
    blockId: parseInt(ownProps.blockId, 10),
    imageSetId: ownProps.imageSetId ? parseInt(ownProps.imageSetId, 10) : null
  }
}

const EditImageSetForm = connect(
  mapStateToProps,
  { submit: submit }
)(EditImageSetFormComponent)

export default EditImageSetForm
