import React from 'react'
import PropTypes from 'prop-types'
import './checkbox.scss'

const CheckBox = ({ options, checked = false, name, className, ...rest }) => {
  return (
    <div className={className}>
      <input {...rest} checked={checked} type="checkbox" id={name} />
      <label htmlFor={name}>
        <div className="styledCheckbox__item styledCheckbox__item--yes">Да</div>
        <div className="styledCheckbox__item styledCheckbox__item--no">Нет</div>
      </label>
    </div>
  )
}

CheckBox.propTypes = {
  checked: PropTypes.bool
}

export default CheckBox
