import { ABOUT_SUCCESS } from '../actions'
import editImageSetReducer from '../pages/EditImageSet/reducers'
import { ABOUT_ROOT_IMAGE_META_SUCCESS, ABOUT_SUBMIT_REQUEST } from '../actions'

const initialState = {
  about: null,
  error: null
}

const mergedReducer = {
  [ABOUT_SUCCESS]: (state, action) => {
    return {
      ...state,
      about: action.payload,
      error: null
    }
  },
  [ABOUT_SUBMIT_REQUEST]: (state, action) => {
    return { ...state, about: action.meta.aboutObj }
  },
  [ABOUT_ROOT_IMAGE_META_SUCCESS]: (state, action) => {
    const {
      meta: { name },
      payload
    } = action

    const newImage = { type: 'video', ...state.about[name], ...payload }
    const newAbout = { ...state.about, [name]: newImage }
    return {
      ...state,
      about: newAbout
    }
  },
  ...editImageSetReducer
}

const aboutReducer = (state = initialState, action) => {
  let actionReducer = mergedReducer[action.type]
  if (actionReducer) {
    return actionReducer(state, action)
  }

  return state
}

export default aboutReducer
