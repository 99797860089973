import { fork } from 'redux-saga/effects'
import homeSaga from './homeSaga'
import projectsSagaRoute from '../pages/Projects/sagas'
import aboutSagaRoute from '../pages/About/sagas'
import statsSagaRoute from '../pages/Stats/sagas'
import usersSagaRoute from '../pages/Users/sagas'
import pushesSagaRoute from '../pages/Push/sagas'
import objectsSagaRoute from '../pages/Objects/sagas'
import publicationsSagaRoute from '../pages/Publications/sagas'
import clientsVersionsSagaRoute from '../pages/ClientsVersions/sagas'

const homeSagaRoute = {
  '/': function* runHomeSaga() {
    yield fork(homeSaga)
  },
  ...projectsSagaRoute,
  ...aboutSagaRoute,
  ...statsSagaRoute,
  ...pushesSagaRoute,
  ...usersSagaRoute,
  ...objectsSagaRoute,
  ...publicationsSagaRoute,
  ...clientsVersionsSagaRoute
}

export default homeSagaRoute
