import {
  PROJECT_ABOUT_SECTION_REQUEST,
  PROJECT_ABOUT_SECTION_SUCCESS,
  PROJECT_ABOUT_SECTION_FAILURE,
  PROJECT_ABOUT_SECTION_SUBMIT_SUCCESS,
  PROJECT_ABOUT_SECTION_SUBMIT_FAILURE
} from '../actions'
import createSectionReducer, {
  updateProject
} from '../../../reducers/createSectionReducer'
import convertSection from '../convertSection'

const sectionReducer = createSectionReducer(
  'aboutSection',
  [PROJECT_ABOUT_SECTION_SUCCESS, PROJECT_ABOUT_SECTION_FAILURE],
  [PROJECT_ABOUT_SECTION_SUBMIT_SUCCESS, PROJECT_ABOUT_SECTION_SUBMIT_FAILURE]
)

const updateAboutSection = (project, action) => {
  const {
    meta: { aboutSection = null },
    payload = null
  } = action

  if (aboutSection == null && payload == null) {
    return project
  }

  const section = payload ? payload : aboutSection
  const newProject = {
    ...project,
    aboutSection: section ? convertSection(section) : project.aboutSection
  }

  return newProject
}

const aboutSectionReducer = {
  ...sectionReducer,
  [PROJECT_ABOUT_SECTION_REQUEST]: updateProject(updateAboutSection),
  [PROJECT_ABOUT_SECTION_SUCCESS]: updateProject(updateAboutSection)
}

export default aboutSectionReducer
