import { RSAA } from 'redux-api-middleware'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE'

export const fetchUsers = () => {
  return {
    [RSAA]: {
      endpoint: 'api/users',
      method: 'get',
      types: [USER_LIST_REQUEST, USER_LIST_SUCCESS, USER_LIST_FAILURE],
      headers: { 'Content-Type': 'application/json' }
    }
  }
}

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE'

export const deleteUser = userId => {
  return {
    [RSAA]: {
      endpoint: `api/users/${userId}`,
      method: 'delete',
      types: [
        USER_DELETE_REQUEST,
        {
          type: USER_DELETE_SUCCESS,
          meta: { userId }
        },
        USER_DELETE_FAILURE
      ]
    }
  }
}
