import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { FORM_ERROR } from 'final-form'
import ChangePasswordForm from './ChangePasswordForm'
import { getAbsoluteUrl } from '../../../../utils/getAbsoluteUrl'
import { apiRootProp } from '../../../../reducers/selectors'
import { tokenProp } from '../../../Login/reducers/selectors'
import { loggedOutUser } from '../../../Login/actions'

const AccountPage = props => {
  const { endpoint, token, logout } = props
  if (!endpoint) {
    return null
  }

  const [updated, setUpdated] = useState(false)

  const changePassword = useCallback(
    async user => {
      const options = {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(user)
      }
      return fetch(endpoint, options)
        .then(response => {
          if (response.status === 401) {
            logout()
            return Promise.resolve({})
          }
          return new Promise(resolve =>
            response
              .json()
              .then(json => {
                return resolve({
                  status: response.status,
                  ok: response.ok,
                  json
                })
              })
              .catch(() => {
                return resolve({
                  status: response.status,
                  ok: response.ok,
                  json: {}
                })
              })
          )
        })
        .then(response => {
          const { json, ok, status } = response
          if (ok) {
            setUpdated(true)
          } else if (status === 400) {
            const {
              Password: password,
              ConfirmPassword: confirmPassword,
              ...rest
            } = json
            return {
              password,
              confirmPassword,
              ...rest
            }
          } else {
            throw new Error('Не удалось сохранить пользователя')
          }
        })
        .catch(error => {
          console.log(error)
          return { [FORM_ERROR]: error.message }
        })
    },
    [endpoint, token, setUpdated]
  )

  if (updated) {
    return <Redirect to="/" />
  }

  return <ChangePasswordForm changePassword={changePassword} />
}

const mapStateToProps = state => {
  const apiRoot = apiRootProp(state)
  const endpoint = getAbsoluteUrl(apiRoot, 'api/users/password')
  const token = tokenProp(state)
  return {
    endpoint,
    token
  }
}

const mapDispatchToProps = {
  logout: loggedOutUser
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountPage)
