import React from 'react'
import PropTypes from 'prop-types'
import FormField from '../FormField'
import CheckBox from '../../CheckBox'

const createOnChange = (form, name) => event => {
  const value = event.target.checked
  form.change(name, value)
  form.blur(name)
}

const CheckBoxField = ({ form, name, onChange, ...rest }) => {
  return (
    <FormField name={name} {...rest}>
      {({ input, className }) => {
        return (
          <CheckBox
            name={name}
            checked={input.value}
            onChange={createOnChange(form, name)}
            className={className}
          />
        )
      }}
    </FormField>
  )
}

CheckBoxField.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired
}

export default CheckBoxField
