import { connect } from 'react-redux'
import { FORM_ERROR } from 'final-form'
import { updateUser as createUpdateUserAction } from './actions'
import EditUserForm from './components/EditUserForm'
import { getAbsoluteUrl } from '../../../../../../utils/getAbsoluteUrl'
import { apiRootProp } from '../../../../../../reducers/selectors'
import { tokenProp } from '../../../../../Login/reducers/selectors'

const mapStateToProps = (state, ownProps) => {
  const { user } = ownProps
  const apiRoot = apiRootProp(state)
  const endpoint = getAbsoluteUrl(apiRoot, `api/users/${user.id}`)
  const token = tokenProp(state)
  return {
    endpoint,
    token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateUser: async (endpoint, token, user) => {
      const options = {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(user)
      }
      return fetch(endpoint, options)
        .then(response => {
          return new Promise(resolve =>
            response.json().then(json =>
              resolve({
                status: response.status,
                ok: response.ok,
                json
              })
            )
          )
        })
        .then(response => {
          const { json, ok, status } = response
          if (ok) {
            dispatch(createUpdateUserAction(json))
          } else if (status === 400) {
            const { Password: password, ...rest } = json
            return {
              password,
              ...rest
            }
          } else {
            throw new Error('Не удалось сохранить пользователя')
          }
        })
        .catch(error => {
          console.log(error)
          return { [FORM_ERROR]: error.message }
        })
    }
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { endpoint, token } = stateProps
  const { updateUser } = dispatchProps
  return {
    ...ownProps,
    updateUser: user => updateUser(endpoint, token, user)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EditUserForm)
