import {
  PROJECT_ARCHITECTURE_EDIT_GALLERY_IMAGE_SET_SUBMIT_SUCCESS,
  PROJECT_ARCHITECTURE_EDIT_GALLERY_IMAGE_SET_SUBMIT_FAILURE,
  PROJECT_ARCHITECTURE_GALLERY_IMAGE_META_SUCCESS
} from '../actions'
import updateImageSet from './updateImageSet'

const editImageSetReducer = {
  [PROJECT_ARCHITECTURE_EDIT_GALLERY_IMAGE_SET_SUBMIT_SUCCESS]: updateImageSet(
    (_imageSet, { payload }) => payload
  ),
  [PROJECT_ARCHITECTURE_EDIT_GALLERY_IMAGE_SET_SUBMIT_FAILURE]: (
    state,
    action
  ) => {
    const { payload: { response } = {} } = action
    return {
      ...state,
      error: response
    }
  },
  [PROJECT_ARCHITECTURE_GALLERY_IMAGE_META_SUCCESS]: updateImageSet(
    (imageSet, action) => {
      const {
        payload,
        meta: { imageType }
      } = action
      return {
        ...imageSet,
        [imageType]: {
          ...imageSet[imageType],
          ...payload
        }
      }
    }
  )
}

export default editImageSetReducer
