import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const SortableItemLink = ({ to, children }) => {
  return (
    <Link to={to} className="sortableArray__link">
      {children}
    </Link>
  )
}

SortableItemLink.propTypes = {
  to: PropTypes.string.isRequired
}

export default SortableItemLink
