import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ProjectConsumer } from '../../../ProjectContext'
import TeamSectionForm from './TeamSectionForm'
import CreateObjectForm from '../pages/CreateObject'
import EditObjectForm from '../pages/EditObject'

const TeamSectionPage = props => {
  const {
    teamSection,
    match: { url },
    submit
  } = props
  return (
    <ProjectConsumer>
      {project => {
        const projectId = project.id
        return (
          <Switch>
            <Route
              exact
              path={`${url}/objects`}
              render={() => {
                return <CreateObjectForm projectId={projectId} />
              }}
            />
            <Route
              path={`${url}/objects/:id(\\d+)`}
              render={({ match: { params } }) => {
                const objectId = parseInt(params.id, 10)
                const teamObject = teamSection.teamObjects.find(
                  t => t.id === objectId
                )
                if (!teamObject) {
                  return <Redirect to="/404" />
                }
                return (
                  <EditObjectForm
                    projectId={projectId}
                    teamObject={teamObject}
                    objectId={objectId}
                  />
                )
              }}
            />
            <TeamSectionForm
              teamSection={teamSection}
              projectId={projectId}
              submit={submit}
            />
          </Switch>
        )
      }}
    </ProjectConsumer>
  )
}

TeamSectionPage.propTypes = {
  teamSection: PropTypes.shape({
    sectionName: PropTypes.string.isRequired,
    teamObjects: PropTypes.arrayOf.isRequired
  }).isRequired,
  projectId: PropTypes.number.isRequired
}

export default TeamSectionPage
