import { call, put, take } from 'redux-saga/effects'
import { fetchObject, PROJECT_ACCOMPLISHMENT_OBJECT_SUCCESS } from '../actions'
import fetchAccomplishmentTabSaga from '../../../sagas/fetchAccomplishmentTabSaga'

function* fetchObjectSaga(projectId, tabId, objectId) {
  const accomplishmentTab = yield call(
    fetchAccomplishmentTabSaga,
    projectId,
    tabId
  )
  if (accomplishmentTab) {
    yield put(fetchObject(projectId, tabId, objectId))
    const accomplishmentObject = yield take(
      PROJECT_ACCOMPLISHMENT_OBJECT_SUCCESS
    )
    if (accomplishmentObject) {
      return accomplishmentObject.payload
    }
  }
  return null
}

export default fetchObjectSaga
