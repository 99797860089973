import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.css'

class Date extends PureComponent {
  state = {
    date: null
  }

  handleChange = value => {
    const { onChange } = this.props
    this.setState(
      {
        date: value
      },
      () => {
        onChange(moment(value))
      }
    )
  }

  render() {
    const { date: initialDate } = this.props
    const { date } = this.state

    return (
      <div>
        <DatePicker
          selected={date || initialDate}
          onChange={this.handleChange}
          locale={ru}
          dateFormat="d MMMM yyyy"
          className="form-control form-control-sm"
          onChangeRaw={ev => {
            ev.preventDefault()
          }}
        />
      </div>
    )
  }
}

Date.propTypes = {
  date: PropTypes.object,
  error: PropTypes.object
}

export default Date
