import { default as projectLocationSectionSagaRoute } from './projectLocationSectionSagaRoute'
import createObjectSagaRoute from '../pages/CreateObject/sagas'
import editObjectSagaRoute from '../pages/EditObject/sagas'

export { default as fetchLocationSectionSaga } from './fetchLocationSectionSaga'
export { watchLocationObjectSubmitted } from '../pages/EditObject/sagas'

export default {
  ...editObjectSagaRoute,
  ...createObjectSagaRoute,
  ...projectLocationSectionSagaRoute
}
