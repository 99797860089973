import updateProject from '../../../../../reducers/updateProject'

const updateTab = reducer =>
  updateProject((project, action) => {
    const { tabId } = action.meta
    const tabs = project.publicSpacesSection.tabs.map(
      publicSpacesTab =>
        publicSpacesTab.id === tabId
          ? { ...publicSpacesTab, ...reducer(publicSpacesTab, action) }
          : publicSpacesTab
    )

    return {
      ...project,
      publicSpacesSection: {
        ...project.publicSpacesSection,
        tabs: tabs
      }
    }
  })

export default updateTab
