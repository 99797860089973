import { fork, call, select } from 'redux-saga/effects'
import watchUserEdited from './watchUserEdited'
import fetchUsersSaga from '../../../sagas/fetchUsersSaga'
import { users } from '../../../reducers/selectors'

const editUserSagaRoute = {
  '/users/:userId': function* runEditUserSaga() {
    const usersProps = yield select(users)
    if (!usersProps) {
      yield call(fetchUsersSaga)
    }
    yield fork(watchUserEdited)
  }
}

export default editUserSagaRoute
