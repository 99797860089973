import { RSAA } from 'redux-api-middleware'

export const PROJECT_ARCHITECTURE_CREATE_TAB_SUBMIT_REQUEST =
  'PROJECT_ARCHITECTURE_CREATE_TAB_SUBMIT_REQUEST'
export const PROJECT_ARCHITECTURE_CREATE_TAB_SUBMIT_SUCCESS =
  'PROJECT_ARCHITECTURE_CREATE_TAB_SUBMIT_SUCCESS'
export const PROJECT_ARCHITECTURE_CREATE_TAB_SUBMIT_FAILURE =
  'PROJECT_ARCHITECTURE_CREATE_TAB_SUBMIT_FAILURE'

export const submit = (projectId, architectureObject) => {
  const success = {
    type: PROJECT_ARCHITECTURE_CREATE_TAB_SUBMIT_SUCCESS,
    meta: {
      projectId,
      architectureObject
    }
  }
  return {
    [RSAA]: {
      endpoint: `api/projects/${projectId}/architecture/tabs`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(architectureObject),
      types: [
        PROJECT_ARCHITECTURE_CREATE_TAB_SUBMIT_REQUEST,
        success,
        PROJECT_ARCHITECTURE_CREATE_TAB_SUBMIT_FAILURE
      ]
    }
  }
}
