import { call, put, take } from 'redux-saga/effects'
import { fetchArchitectureSectionSaga } from '../../../sagas'
import { fetchTab, PROJECT_ARCHITECTURE_TAB_SUCCESS } from '../actions'

function* fetchArchitectureTabSaga(projectId, tabId) {
  const architectureSection = yield call(
    fetchArchitectureSectionSaga,
    projectId
  )
  if (architectureSection) {
    yield put(fetchTab(projectId, tabId))
    const architectureTab = yield take(PROJECT_ARCHITECTURE_TAB_SUCCESS)
    if (architectureTab) {
      return architectureTab.payload
    }
  }

  return null
}

export default fetchArchitectureTabSaga
