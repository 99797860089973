import {
  PROJECT_ADVANTAGES_CREATE_ADVANTAGE_SUBMIT_SUCCESS,
  PROJECT_ADVANTAGES_CREATE_ADVANTAGE_SUBMIT_FAILURE
} from '../actions'
import updateProject from '../../../../../reducers/updateProject'

const createAdvantageReducer = {
  [PROJECT_ADVANTAGES_CREATE_ADVANTAGE_SUBMIT_SUCCESS]: updateProject(
    (project, { payload }) => {
      const section = project.advantagesSection
      const advantages = [...section.advantages, payload]
      const newSection = {
        ...section,
        advantages
      }
      const newProject = {
        ...project,
        advantagesSection: newSection
      }
      return newProject
    }
  ),
  [PROJECT_ADVANTAGES_CREATE_ADVANTAGE_SUBMIT_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  }
}

export default createAdvantageReducer
