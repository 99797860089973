import { fork, call } from 'redux-saga/effects'
import watchEditObjectSaga from './watchEditObjectSaga'
import fetchObjectSaga from './fetchObjectSaga'

const editObjectSagaRoute = {
  '/objects/:id(\\d+)': function* runCreateObjectsSaga({ id }) {
    const idNumber = parseInt(id, 10)
    const object = yield call(fetchObjectSaga, idNumber)
    if (object) {
      yield fork(watchEditObjectSaga)
    }
  }
}

export default editObjectSagaRoute
