import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_PUBLIC_SPACES_CREATE_PUBLIC_SPACES_TYPE_SUBMIT_SUCCESS } from '../actions'

function* watchCreateObjectSaga(projectId, tabId) {
  while (true) {
    const {
      payload: { id }
    } = yield take(
      PROJECT_PUBLIC_SPACES_CREATE_PUBLIC_SPACES_TYPE_SUBMIT_SUCCESS
    )
    yield put(
      push(
        `/projects/${projectId}/publicSpaces/tabs/${tabId}/publicSpaces-type/${id}`
      )
    )
  }
}

export default watchCreateObjectSaga
