import { fork } from 'redux-saga/effects'
import watchUserCreated from './watchUserCreated'
import fetchUsersSaga from '../../../sagas/fetchUsersSaga'

const createUserSagaRoute = {
  '/users/add': function* runAddUserSaga() {
    yield fork(fetchUsersSaga)
    yield fork(watchUserCreated)
  }
}

export default createUserSagaRoute
