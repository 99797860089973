import React from 'react'
import './styles.css'

const Hover = ({ children, overflow }) => {
  return (
    <div className="hover">
      {children}
      <div className="hover__overflow">{overflow}</div>
    </div>
  )
}

export default Hover
