import { RSAA } from 'redux-api-middleware'

export const LOAD_CONFIG_REQUEST = 'LOAD_CONFIG_REQUEST'
export const LOAD_CONFIG_SUCCESS = 'LOAD_CONFIG_SUCCESS'
export const LOAD_CONFIG_FAILURE = 'LOAD_CONFIG_FAILURE'

export const loadConfig = () => {
  return {
    [RSAA]: {
      method: 'get',
      endpoint: '/config.json',
      types: [LOAD_CONFIG_REQUEST, LOAD_CONFIG_SUCCESS, LOAD_CONFIG_FAILURE]
    }
  }
}
