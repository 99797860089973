import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const ImageSetLink = ({ blockId, previewImageUrl }) => {
  const addClass = previewImageUrl ? '' : 'aboutForm__imageSet--add'
  return (
    <Link
      to={`/about/blocks/${blockId}/images`}
      className={`aboutForm__imageSet ${addClass}`}
      style={
        previewImageUrl ? { backgroundImage: `url(${previewImageUrl})` } : {}
      }
    >
      {previewImageUrl ? (
        <div className="aboutForm__overlay">Кликните для редактирования</div>
      ) : (
        'Добавить изображение'
      )}
    </Link>
  )
}

ImageSetLink.propTypes = {
  blockId: PropTypes.number.isRequired,
  previewImageUrl: PropTypes.string
}

export default ImageSetLink
