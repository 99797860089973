import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

const FormField = ({
  name,
  label,
  className = 'formField__control form-control form-control-sm',
  validate,
  children,
  ...rest
}) => {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const labelRenderd = label ? (
          <label key="label" className="formField__label">
            {label}
          </label>
        ) : null
        const error =
          meta.error && meta.touched ? (
            <span className="formField__error" key="error">
              {meta.error}
            </span>
          ) : null
        const errorClassName = meta.error
          ? className + 'formField__control--error'
          : className

        return (
          <>
            {labelRenderd}
            {children({
              ...rest,
              input,
              meta,
              className: errorClassName
            })}
            {error}
          </>
        )
      }}
    </Field>
  )
}

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func
}

export default FormField
