import { put, race, take, select } from 'redux-saga/effects'
import { fetchRates, RATES_FAILURE, RATES_SUCCESS } from '../actions'
import { rates } from '../reducers/selectors'

function* fetchRatesSaga() {
  const ratesProps = yield select(rates)
  yield put(fetchRates(ratesProps.filters))
  const { success } = yield race({
    success: take(RATES_SUCCESS),
    failure: take(RATES_FAILURE)
  })

  if (success) {
    return success.payload
  }

  return null
}

export default fetchRatesSaga
