import { fork, call } from 'redux-saga/effects'
import watchCreateObjectSaga from './watchCreateObjectSaga'
import fetchTeamSectionSaga from '../../../sagas/fetchTeamSectionSaga'

const createObjectSagaRoute = {
  '/projects/:projectId/team/objects': function* runCreateTeamSaga({
    projectId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const teamSection = yield call(fetchTeamSectionSaga, projectIdNumber)
    if (teamSection) {
      yield fork(watchCreateObjectSaga, projectIdNumber)
    }
  }
}

export default createObjectSagaRoute
