import { RSAA } from 'redux-api-middleware'

export const CLIENTS_VERSIONS_REQUEST = 'CLIENTS_VERSIONS_REQUEST'
export const CLIENTS_VERSIONS_SUCCESS = 'CLIENTS_VERSIONS_SUCCESS'
export const CLIENTS_VERSIONS_FAILURE = 'CLIENTS_VERSIONS_FAILURE'

export const fetchClientVersions = ({ page = 1, pageSize = 10 }) => {
  return {
    [RSAA]: {
      endpoint: `api/ClientVersion/?page=${page}&pageSize=${pageSize}`,
      method: 'get',
      types: [
        CLIENTS_VERSIONS_REQUEST,
        CLIENTS_VERSIONS_SUCCESS,
        CLIENTS_VERSIONS_FAILURE
      ]
    }
  }
}

export const CLIENT_VERSION_CREATE_REQUEST = 'CLIENT_VERSION_CREATE_REQUEST'
export const CLIENT_VERSION_CREATE_SUCCESS = 'CLIENT_VERSION_CREATE_SUCCESS'
export const CLIENT_VERSION_CREATE_FAILURE = 'CLIENT_VERSION_CREATE_FAILURE'

export const createClientVersion = data => {
  return {
    [RSAA]: {
      endpoint: `api/ClientVersion`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      types: [
        CLIENT_VERSION_CREATE_REQUEST,
        CLIENT_VERSION_CREATE_SUCCESS,
        CLIENT_VERSION_CREATE_FAILURE
      ]
    }
  }
}

export const CLIENT_VERSION_DELETE_REQUEST = 'CLIENT_VERSION_DELETE_REQUEST'
export const CLIENT_VERSION_DELETE_SUCCESS = 'CLIENT_VERSION_DELETE_SUCCESS'
export const CLIENT_VERSION_DELETE_FAILURE = 'CLIENT_VERSION_DELETE_FAILURE'

export const deleteClientVersion = id => {
  return {
    [RSAA]: {
      endpoint: `api/ClientVersion/${id}`,
      method: 'delete',
      types: [
        CLIENT_VERSION_DELETE_REQUEST,
        CLIENT_VERSION_DELETE_SUCCESS,
        CLIENT_VERSION_DELETE_FAILURE
      ]
    }
  }
}
