import { fork, call } from 'redux-saga/effects'
import watchImageSetCreatedSaga from './watchImageSetCreatedSaga'
import { fetchAdvantageSaga } from '../../../sagas'

const createImageSetSagaRoute = {
  '/projects/:projectId/advantages/:galleryId/images/add': function* runCreateImageSetSaga({
    projectId,
    galleryId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const galleryIdNumber = parseInt(galleryId, 10)
    const gallery = yield call(
      fetchAdvantageSaga,
      projectIdNumber,
      galleryIdNumber
    )
    if (gallery) {
      yield fork(watchImageSetCreatedSaga, projectIdNumber, galleryIdNumber)
    }
  }
}

export default createImageSetSagaRoute
