import React from 'react'
import PropTypes from 'prop-types'
import FormField from '../FormField'
import ImageSet from '../../ImageSet'

const ImageSetFormField = props => {
  return (
    <FormField {...props}>
      {({ imageSet, onRemove, ...rest }) => {
        const { id, position, ...images } = imageSet
        return (
          <ImageSet {...rest} id={id} images={images} onRemove={onRemove} />
        )
      }}
    </FormField>
  )
}

ImageSetFormField.propTypes = {
  imageSet: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  editImageSetUrl: PropTypes.func.isRequired
}

export default ImageSetFormField
