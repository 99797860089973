import React from 'react'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import {
  formWithError,
  requiredPassword,
  repeatPassword
} from '../../../../components/Validation'
import { SubmitButton } from '../../components/Buttons'
import { FormField } from '../../../../components/Form'

const focusOnErrors = createDecorator()

const ChangePasswordForm = props => {
  const { changePassword: onSubmit } = props
  return (
    <Form
      initialValues={{
        password: '',
        confirmPassword: ''
      }}
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      render={({
        handleSubmit,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit,
        form
      }) => {
        return (
          <div className="editUserForm pageWrapper">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col">
                  <h4>Смена пароля</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-6 formField">
                  <div>
                    <FormField
                      name="password"
                      type="password"
                      label="Новый пароль"
                      validate={requiredPassword}
                    />
                  </div>
                </div>
                <div className="col-6 formField">
                  <div>
                    <FormField
                      name="confirmPassword"
                      type="password"
                      label="Новый пароль еще раз"
                      validate={repeatPassword(form.getState().values.password)}
                    />
                  </div>
                </div>
              </div>
              <SubmitButton
                pristine={pristine}
                submitSucceeded={submitSucceeded}
                dirtySinceLastSubmit={dirtySinceLastSubmit}
              />
            </form>
          </div>
        )
      }}
    />
  )
}

export default formWithError(ChangePasswordForm)
