const convertImage = image => {
  const images = Object.keys(image)
    .filter(t => t.endsWith('Image'))
    .map(t => ({
      [t]: {
        name: t,
        url: image[t]
      }
    }))
    .reduce((prev, cur) => ({ ...cur, ...prev }), {})

  return images
}

const convertSection = aboutSection => {
  const {
    image3D = null,
    mobileImage = null,
    tabletImage = null,
    ...rest
  } = aboutSection

  if (mobileImage !== null || tabletImage !== null) {
    return aboutSection
  }
  if (image3D === null) {
    return aboutSection
  }

  const section = {
    ...rest,
    ...convertImage(image3D)
  }

  return section
}

export default convertSection
