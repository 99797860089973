import addProjectReducer from '../pages/AddProjectForm/reducers'
import editProjectReducer from '../pages/EditProjectForm/reducers'
import projectListReducer from './projectListReducer'

const initialState = {
  projects: null,
  hiddenProjects: null,
  error: null
}

const mergedReducer = {
  ...projectListReducer,
  ...addProjectReducer,
  ...editProjectReducer
}

const projectsReducer = (state = initialState, action) => {
  var actionReducer = mergedReducer[action.type]
  if (actionReducer) {
    return actionReducer(state, action)
  }

  return state
}

export { default as mergeProjects } from './mergeProjects'

export default projectsReducer
