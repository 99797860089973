import projectListSagaRoute from './projectListSagaRoute'
import createProjectSagaRoute from '../pages/AddProjectForm/sagas'
import editProjectSagaRoute from '../pages/EditProjectForm/sagas'

const projectsSagaRoute = {
  ...projectListSagaRoute,
  ...createProjectSagaRoute,
  ...editProjectSagaRoute
}

export default projectsSagaRoute
