import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Hover } from '../../../../components'
import { Link } from 'react-router-dom'

class ImageSet extends PureComponent {
  handleOnRemove = event => {
    event.preventDefault()
    const { id, onRemove } = this.props
    onRemove(id)
  }

  render() {
    const {
      images: { previewImageUrl },
      editImageRoute,
      editImageSetUrl,
      id
    } = this.props
    const style = { backgroundImage: `url(${previewImageUrl})` }
    const editUrl = editImageSetUrl
      ? editImageSetUrl(id)
      : `${editImageRoute}/${id}`

    return (
      <Link to={editUrl} className="imageSet">
        <Hover
          overflow={
            <div className="imageSet__overlay">
              <div className="imageSet__edit">Кликните для редактирования</div>
              <div className="imageSet__delete" onClick={this.handleOnRemove}>
                Удалить
              </div>
            </div>
          }
        >
          <div className="imageSet__image" style={style} />
        </Hover>
      </Link>
    )
  }
}

ImageSet.propTypes = {
  images: PropTypes.shape({
    iOS2x: PropTypes.object,
    iOS3x: PropTypes.object,
    androidSvg: PropTypes.object,
    previewImageUrl: PropTypes.string.isRequired
  }),
  onRemove: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
}

export default ImageSet
