import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import projectForm from '../../../../../../../../components/projectForm'
import { SubmitButton } from '../../../../../../../../../../components/Buttons'
import MonthSelectField from '../../../../components/MonthSelect'
import { required } from '../../../../../../../../../../../../components/Validation'

const focusOnErrors = createDecorator()

const onSubmit = (projectId, submit) => gallery => {
  submit(projectId, gallery)
}

const CreateGalleryForm = ({ project, submit }) => {
  const {
    constructionProgressSection: { galleries = [] }
  } = project
  const projectId = project.id

  return (
    <Form
      mutators={{
        ...arrayMutators
      }}
      onSubmit={onSubmit(projectId, submit)}
      decorators={[focusOnErrors]}
      validate={values => {
        const { date } = values
        if (!date || galleries.length === 0) {
          return {}
        }
        const existingDateGallery = galleries.find(gallery => {
          const galleryDate = new Date(gallery.date)
          return (
            galleryDate.getFullYear() === date.getFullYear() &&
            galleryDate.getMonth() === date.getMonth()
          )
        })

        if (existingDateGallery) {
          return {
            date: 'Найдена существующая галерея для указанного периода'
          }
        }
        return {}
      }}
      render={({
        handleSubmit,
        submitting,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit,
        form
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <Link
                  to={`/projects/${projectId}/construction`}
                  className="formField__back"
                >
                  Назад
                </Link>
              </div>
            </div>
            <hr className="formField__separator" />
            <div className="row">
              <div className="col">
                <h4>Галерея</h4>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <MonthSelectField
                  validate={required}
                  name="date"
                  label="Период"
                  form={form}
                />
              </div>
            </div>
            <SubmitButton
              pristine={pristine}
              submitSucceeded={submitSucceeded}
              dirtySinceLastSubmit={dirtySinceLastSubmit}
            />
          </form>
        )
      }}
    />
  )
}

CreateGalleryForm.propTypes = {
  project: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired
}

export default projectForm(CreateGalleryForm)
