import { put, race, take } from 'redux-saga/effects'
import { fetchObjects, OBJECTS_SUCCESS, OBJECTS_FAILURE } from '../actions'

function* fetchObjectsSaga() {
  yield put(fetchObjects())
  const { success } = yield race({
    success: take(OBJECTS_SUCCESS),
    failure: take(OBJECTS_FAILURE)
  })

  if (success) {
    return success.payload.objects
  }

  return null
}

export default fetchObjectsSaga
