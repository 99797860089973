import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { declOfNum } from '../../../../../../../utils/declOfNum'
import './publicationItem.css'
import PublicationError from '../PublicationError'
import RefreshIcon from './RefreshIcon'
import LoadingIcon from './LoadingIcon'
import ExpandIcon from './ExpandIcon'

const PublicationStatus = ({
  section,
  hasChanges = false,
  refresh,
  selected = []
}) => {
  const { publication } = section
  const isSelected = selected.indexOf(section.path) !== -1
  if (
    publication === null ||
    publication.startDate === null ||
    publication.startDate === undefined
  ) {
    return (
      <>
        Не обновлялось
        <RefreshIcon refresh={() => refresh(section)} isSelected={isSelected} />
      </>
    )
  }

  const { startDate, status, errors, completion, subStatus } = publication

  if (status === 'Started' || subStatus === 'CheckingForChanges') {
    return (
      <>
        {completion}% <LoadingIcon />
      </>
    )
  }

  if (status === 'Error') {
    return (
      <>
        {errors} {declOfNum(errors, ['ошибка', 'ошибки', 'ошибок'])} (
        {moment(startDate).format('DD.MM.YY, HH:mm')}){' '}
        {hasChanges ? (
          <RefreshIcon
            refresh={() => refresh(section)}
            isSelected={isSelected}
          />
        ) : null}
      </>
    )
  }

  return (
    <>
      Обновлен {moment(startDate).format('DD.MM.YYYY, HH:mm')}{' '}
      <RefreshIcon refresh={() => refresh(section)} isSelected={isSelected} />
    </>
  )
}

class PublicationItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showError: false,
      showSubSection: false
    }
  }

  toggleError = () => {
    this.setState({ showError: !this.state.showError })
  }

  toggleSubSection = () => {
    this.setState({ showSubSection: !this.state.showSubSection })
  }

  render() {
    const {
      section: { name, hasChanges = false, publication = null, children = [] },
      selected,
      subSection = false,
      refresh
    } = this.props

    if (
      publication !== null &&
      publication.status === 'Finished' &&
      !hasChanges
    ) {
      return null
    }

    if (subSection && !hasChanges) {
      return null
    }

    const completeStatus = (
      <PublicationStatus
        section={this.props.section}
        hasChanges={hasChanges}
        refresh={refresh}
        selected={selected}
      />
    )

    const { status, errorArray } =
      publication !== null ? publication : { status: '', errorArray: [] }
    const completeStatusClass =
      status === 'Started'
        ? '--inProgress'
        : status === 'Error'
          ? '--errors'
          : '--success'
    const errorList =
      errorArray && errorArray.length ? (
        <div className="publicationItem__errorList">
          {errorArray.map(error => (
            <PublicationError error={error} key={error.path} />
          ))}
        </div>
      ) : null

    const subSections =
      children.length === 0 || !this.state.showSubSection ? null : (
        <div className={`publicationItem__subSection`}>
          {children.map(t => {
            return (
              <div key={t.path}>
                <PublicationItem
                  section={t}
                  subSection={true}
                  refresh={refresh}
                  selected={selected}
                />
                {errorList}
              </div>
            )
          })}
        </div>
      )

    return (
      <>
        <div
          className={`publicationItem ${
            subSection ? 'publicationItem--subItem' : ''
          }`}
          onClick={this.toggleSubSection}
        >
          <div className="publicationItem__name">{name}</div>
          <div
            className={`publicationItem__status publicationItem__status${completeStatusClass}`}
          >
            {completeStatus}
          </div>
          {!subSection && children.length > 0 ? (
            <ExpandIcon isExpanded={this.state.showSubSection} />
          ) : null}
        </div>
        {subSections}
      </>
    )
  }
}

PublicationItem.propTypes = {
  section: PropTypes.object.isRequired
}

export default PublicationItem
