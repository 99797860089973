import { fork, call } from 'redux-saga/effects'
import watchCreateObjectSaga from './watchCreateObjectSaga'
import fetchDecorationSectionSaga from '../../../sagas/fetchDecorationSectionSaga'

const createTabSagaRoute = {
  '/projects/:projectId/decoration/tabs': function* runCreateDecorationTabSaga({
    projectId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const decorationSection = yield call(
      fetchDecorationSectionSaga,
      projectIdNumber
    )
    if (decorationSection) {
      yield fork(watchCreateObjectSaga, projectIdNumber)
    }
  }
}

export default createTabSagaRoute
