import { default as projectImagesSectionSagaRoute } from './projectImagesSectionSagaRoute'
import createImageSetSagaRoute from '../pages/CreateImageSet/sagas'
import editImageSetSagaRoute from '../pages/EditImageSet/sagas'

export { default as fetchImagesSectionSaga } from './fetchImagesSectionSaga'

export default {
  ...createImageSetSagaRoute,
  ...editImageSetSagaRoute,
  ...projectImagesSectionSagaRoute
}
