const createProjectReducer = projectReducer => (state, action) => {
  const { projects = [], hiddenProjects = [] } = state
  const { meta = {}, payload = {} } = action

  const projectId = meta.projectId
    ? meta.projectId
    : payload.projectId
      ? payload.projectId
      : undefined
  if (!projectId) {
    return state
  }

  if (projects.find(t => t.id === projectId)) {
    const newProjects = projects.map(project => {
      if (project.id === projectId) {
        return projectReducer(project, action)
      }
      return project
    })

    return {
      ...state,
      projects: newProjects
    }
  }

  if (hiddenProjects.find(t => t.id === projectId)) {
    const newProjects = hiddenProjects.map(project => {
      if (project.id === projectId) {
        return projectReducer(project, action)
      }
      return project
    })

    return {
      ...state,
      hiddenProjects: newProjects
    }
  }

  return state
}

export const createPinImageUrlReducer = sectionName => (state, action) => {
  if (!action.meta) {
    debugger
  }

  const { imageType } = action.meta
  const { name, url } = action.payload
  const reducer = createProjectReducer(project => {
    return {
      ...project,
      [sectionName]: {
        ...project[sectionName],
        [imageType]: {
          ...project[sectionName][imageType],
          name,
          url
        }
      }
    }
  })

  return reducer(state, action)
}

export default createProjectReducer
