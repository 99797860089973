import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import { formWithError } from '../../../../../../../../../../../../components/Validation'
import { SubmitButton } from '../../../../../../../../../../components/Buttons'
import { ImageSetArrayFormField } from '../../../../../../../../../../components/Form'
import MonthSelectField from '../../../../components/MonthSelect'
import { ProjectConsumer } from '../../../../../../ProjectContext'
import moment from 'moment'
import { required } from '../../../../../../../../../../../../components/Validation'

const focusOnErrors = createDecorator()

const onSubmit = (projectId, galleryId, submit) => gallery => {
  submit(projectId, galleryId, gallery)
}

const createEditImageSetUrl = (projectId, galleryId) => id => {
  return `/projects/${projectId}/construction/galleries/${galleryId}/images/${id}`
}

const EditGalleryForm = ({ projectId, gallery, submit }) => {
  const galleryWithDate = {
    ...gallery,
    date: moment.utc(gallery.date).toDate()
  }
  return (
    <ProjectConsumer>
      {project => {
        const {
          constructionProgressSection: { galleries = [] }
        } = project
        return (
          <Form
            initialValues={galleryWithDate}
            mutators={{
              ...arrayMutators
            }}
            onSubmit={onSubmit(projectId, gallery.id, submit)}
            decorators={[focusOnErrors]}
            validate={values => {
              const { date, id } = values
              if (!date || galleries.length === 0) {
                return {}
              }
              const existingDateGallery = galleries.find(gallery => {
                const galleryDate = moment.utc(gallery.date).toDate()
                return (
                  id !== gallery.id &&
                  galleryDate.getFullYear() === date.getFullYear() &&
                  galleryDate.getMonth() === date.getMonth()
                )
              })

              if (existingDateGallery) {
                return {
                  date: 'Найдена существующая галерея для указанного периода'
                }
              }
              return {}
            }}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              submitSucceeded,
              dirtySinceLastSubmit
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <Link
                        to={`/projects/${projectId}/construction`}
                        className="formField__back"
                      >
                        Назад
                      </Link>
                    </div>
                  </div>
                  <hr className="formField__separator" />
                  <div className="row">
                    <div className="col">
                      <h4>Галерея</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <MonthSelectField
                        name="date"
                        label="Период"
                        form={form}
                        validate={required}
                      />
                    </div>
                  </div>
                  <hr className="formField__separator" />
                  <div className="row">
                    <div className="col formField">
                      <ImageSetArrayFormField
                        form={form}
                        name="imageSets"
                        addImageSetUrl={`/projects/${
                          project.id
                        }/construction/galleries/${gallery.id}/images/add`}
                        editImageSetUrl={createEditImageSetUrl(
                          project.id,
                          gallery.id
                        )}
                      />
                    </div>
                  </div>
                  <SubmitButton
                    pristine={pristine}
                    submitSucceeded={submitSucceeded}
                    dirtySinceLastSubmit={dirtySinceLastSubmit}
                  />
                </form>
              )
            }}
          />
        )
      }}
    </ProjectConsumer>
  )
}

EditGalleryForm.propTypes = {
  projectId: PropTypes.number.isRequired,
  gallery: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired
}

export default formWithError(EditGalleryForm)
