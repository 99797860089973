import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import {
  required,
  formWithError
} from '../../../../../../../../../components/Validation'
import { FormField } from '../../../../../../../../../components/Form'
import { TextareaField } from '../../../../../../../../../components/Form'
import { SubmitButton } from '../../../../../../../components/Buttons'
import { MobileTabletImageFormField } from '../../../../../../../components/Form'
import './aboutSection.css'

const focusOnErrors = createDecorator()

class AboutSection extends PureComponent {
  onSubmit = aboutSection => {
    const { projectId, submit } = this.props
    submit(projectId, aboutSection)
  }

  render() {
    const { aboutSection } = this.props

    return (
      <Form
        initialValues={aboutSection}
        mutators={{
          ...arrayMutators
        }}
        onSubmit={this.onSubmit}
        decorators={[focusOnErrors]}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          submitSucceeded,
          dirtySinceLastSubmit
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col formField">
                  <FormField
                    name="sectionName"
                    validate={required}
                    type="text"
                    label="Название раздела"
                    placeholder="Название раздела"
                  />
                </div>
              </div>
              <hr className="formField__separator" />
              <div className="row">
                <div className="col formField">
                  <TextareaField
                    name="description"
                    validate={required}
                    label="Описание"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h4>3D визуализация</h4>
                </div>
              </div>
              <div className="row">
                <div className="col formField">
                  <MobileTabletImageFormField form={form} />
                </div>
              </div>
              <SubmitButton
                pristine={pristine}
                submitSucceeded={submitSucceeded}
                dirtySinceLastSubmit={dirtySinceLastSubmit}
              />
            </form>
          )
        }}
      />
    )
  }
}

AboutSection.propTypes = {
  projectId: PropTypes.number.isRequired,
  aboutSection: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired
}

export default formWithError(AboutSection)
