import {
  PROJECT_PUBLIC_SPACES_CREATE_GALLERY_IMAGE_SET_SUBMIT_SUCCESS,
  PROJECT_PUBLIC_SPACES_CREATE_GALLERY_IMAGE_SET_SUBMIT_FAILURE
} from '../actions'
import updatePublicSpacesType from '../../../reducers/updatePublicSpacesType'

const createImageSetReducer = {
  [PROJECT_PUBLIC_SPACES_CREATE_GALLERY_IMAGE_SET_SUBMIT_SUCCESS]: updatePublicSpacesType(
    (publicSpacesType, { payload }) => {
      const { imageSets = [] } = publicSpacesType
      const newImageSets = [payload, ...imageSets]
      const newGallery = {
        ...publicSpacesType,
        imageSets: newImageSets
      }
      return newGallery
    }
  ),
  [PROJECT_PUBLIC_SPACES_CREATE_GALLERY_IMAGE_SET_SUBMIT_FAILURE]: (
    state,
    action
  ) => {
    const { payload: { response } = {} } = action
    return {
      ...state,
      error: response
    }
  }
}

export default createImageSetReducer
