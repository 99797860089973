const initialState = {
  submitting: false
}

const mergedReducer = {
  _SUBMIT_REQUEST: () => {
    return {
      submitting: true
    }
  },
  _SUBMIT_SUCCESS: () => {
    return {
      submitting: false
    }
  },
  _SUBMIT_FAILURE: () => {
    return {
      submitting: false,
      error: 'Изменения не сохранены'
    }
  }
}

const submittingReducer = (state = initialState, action) => {
  const type = action.type.substring(
    action.type.indexOf('_SUBMIT_'),
    action.type.length
  )
  let actionReducer = mergedReducer[type]
  if (actionReducer) {
    return actionReducer(state, action)
  }

  return state
}

export default submittingReducer
