import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { Link } from 'react-router-dom'
import createDecorator from 'final-form-focus'
import projectForm from '../../../../../../../../../../../components/projectForm'
import { SubmitButton } from '../../../../../../../../../../../../../components/Buttons'
import { BigImageFormField } from '../../../../../../../../../../../../../components/Form'

const focusOnErrors = createDecorator()

const onSubmit = (projectId, tabId, objectId, id, submit) => imageSet => {
  submit(projectId, tabId, objectId, id, imageSet)
}

const EditImageSetForm = ({
  projectId,
  tabId,
  objectId,
  id,
  submit,
  backUrl,
  imageSet
}) => {
  return (
    <Form
      initialValues={imageSet}
      onSubmit={onSubmit(projectId, tabId, objectId, id, submit)}
      decorators={[focusOnErrors]}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <Link to={backUrl} className="formField__back">
                  Назад
                </Link>
              </div>
            </div>
            <hr className="formField__separator" />
            <div className="row">
              <div className="col">
                <BigImageFormField label="Изображение" form={form} />
              </div>
            </div>
            <SubmitButton
              pristine={pristine}
              submitSucceeded={submitSucceeded}
              dirtySinceLastSubmit={dirtySinceLastSubmit}
            />
          </form>
        )
      }}
    />
  )
}

EditImageSetForm.propTypes = {
  projectId: PropTypes.number.isRequired,
  tabId: PropTypes.number.isRequired,
  objectId: PropTypes.number.isRequired,
  backUrl: PropTypes.string.isRequired
}

export default projectForm(EditImageSetForm)
