import React from 'react'
import { Link } from 'react-router-dom'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { RemoveButton } from '../../../../../../components/Buttons'
import './galleryListField.css'

const GalleryListItem = ({
  gallery: { id, name },
  projectId,
  fields,
  index
}) => {
  return (
    <div className="galleryListField__item">
      <Link
        to={`/projects/${projectId}/construction/galleries/${id}`}
        className="galleryListField__link"
      >
        {name}
      </Link>
      <RemoveButton
        title="Удалить галлерею"
        onClick={() => fields.remove(index)}
      />
    </div>
  )
}

const GalleryListField = ({ projectId }) => {
  return (
    <FieldArray name="galleries">
      {({ fields }) => {
        return (
          <div className="galleryListField__list">
            {fields.map((name, index) => {
              return (
                <Field name={`${name}`} key={name}>
                  {({ input }) => {
                    return (
                      <GalleryListItem
                        gallery={input.value}
                        projectId={projectId}
                        fields={fields}
                        index={index}
                      />
                    )
                  }}
                </Field>
              )
            })}
          </div>
        )
      }}
    </FieldArray>
  )
}

export default GalleryListField
