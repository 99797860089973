import { fork, call } from 'redux-saga/effects'
import watchCreateImageSetSaga from './watchCreateImageSetSaga'
import { fetchImagesSectionSaga } from '../../../sagas'

const createImageSetSagaRoute = {
  '/projects/:projectId/images/*/add': function* runCreateImageSetSaga({
    projectId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const imageSection = yield call(fetchImagesSectionSaga, projectIdNumber)
    if (imageSection) {
      yield fork(watchCreateImageSetSaga, projectIdNumber)
    }
  }
}

export default createImageSetSagaRoute
