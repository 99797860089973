export const projectsProp = state => state.projects.projects
export const hiddenProjectsProp = state => state.projects.hiddenProjects

export const projectsErrorProp = state => state.projects.error

export const projectProp = (projectId, includeHidden = true) => state => {
  const projects = projectsProp(state)
  if (projects == null) {
    return null
  }
  const project = projects.find(t => t.id === projectId)
  if (project === undefined) {
    return includeHidden ? hiddenProjectProp(projectId)(state) : null
  }

  return project
}

export const hiddenProjectProp = projectId => state => {
  const projects = hiddenProjectsProp(state)
  if (projects == null) {
    return null
  }
  const project = projects.find(t => t.id === projectId)
  if (project === undefined) {
    return null
  }

  return project
}

export const getProject = (state, includeHidden = true) => projectId => {
  const projects = projectsProp(state)
  if (projects == null) {
    return null
  }
  const project = projectProp(projectId)(state)
  if (project === null) {
    return includeHidden ? hiddenProjectProp(projectId)(state) : null
  }

  return project
}
