import { put, fork, take, race, select } from 'redux-saga/effects'
import { fetchClientVersions } from '../actions'
import {
  CLIENT_VERSION_CREATE_SUCCESS,
  CLIENT_VERSION_DELETE_SUCCESS
} from '../actions'

export const clientsVersionsSagaRoute = {
  '/versions': function* runFetchClientVersionsSaga() {
    yield put({ type: 'CLIENT_VERSIONS_RESET' })
    yield put(fetchClientVersions({ page: 1 }))
    yield fork(loadMoreClientsVersionsSaga)

    while (true) {
      yield race({
        success: take(CLIENT_VERSION_CREATE_SUCCESS),
        error: take(CLIENT_VERSION_DELETE_SUCCESS)
      })
      yield put({ type: 'CLIENT_VERSIONS_RESET' })
      yield put(fetchClientVersions({ page: 1 }))
    }
  }
}

function* loadMoreClientsVersionsSaga() {
  while (true) {
    yield take('CLIENTS_VERSIONS_MORE')
    const { page } = yield select(state => state.clientsVersions)
    yield put(fetchClientVersions({ page }))
  }
}
