import { connect } from 'react-redux'
import { submit } from './actions'
import { default as EditPublicSpacesTypeForm } from './components/EditPublicSpacesTypeForm'
import { projectProp } from '../../../../../../../../reducers/selectors'

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps
  const project = projectProp(projectId)(state)
  const { objectTypes = [] } = project
  return {
    objectTypes
  }
}

export default connect(
  mapStateToProps,
  { submit }
)(EditPublicSpacesTypeForm)
