import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  ArrayFormField,
  SortableItemLink
} from '../../../../../../../../../../components/Form'

const PublicSpacesTypeFormField = ({ projectId, tabId }) => {
  return (
    <ArrayFormField
      name="objects"
      header={({ value, onRemove }) => {
        return (
          <>
            <SortableItemLink
              to={`/projects/${projectId}/publicSpaces/tabs/${tabId}/publicSpaces-type/${
                value.id
              }`}
            >
              {value.name || 'noName'}
            </SortableItemLink>
            <button
              type="button"
              onClick={onRemove}
              className="btn btn--noPadding btn--delete"
              title="Удалить объект"
            />
          </>
        )
      }}
    />
  )
}

const PublicSpacesTypeSortableList = ({ projectId, tabId }) => {
  return [
    <div className="objectSortableList__head" key="header">
      <h4>Объект</h4>
      <Link
        to={`/projects/${projectId}/publicSpaces/tabs/${tabId}/publicSpaces-type`}
        className="btn btn-warning btn-sm"
      >
        Добавить
      </Link>
    </div>,
    <PublicSpacesTypeFormField projectId={projectId} tabId={tabId} key="list" />
  ]
}

PublicSpacesTypeSortableList.propTypes = {
  fields: PropTypes.object.isRequired
}

export default PublicSpacesTypeSortableList
