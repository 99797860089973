import { PROJECTS_CREATE_IMAGE_SET_SUCCESS } from '../actions'
import updateProject from '../../../../../reducers/updateProject'

const createImageSetReducer = {
  [PROJECTS_CREATE_IMAGE_SET_SUCCESS]: updateProject((project, action) => {
    const { payload } = action
    const imageSetKey = 'projectListImages'
    const imageSets = project.imagesSection[imageSetKey]

    const newImageSets = [...imageSets, payload]
    const newProject = {
      ...project,
      imagesSection: {
        ...project.imagesSection,
        [imageSetKey]: newImageSets
      }
    }
    return newProject
  })
}

export default createImageSetReducer
