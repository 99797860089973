import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'
import ImageSetSortableArray from './ImageSetSortableArray'
import ImageSetFormField from './ImageSetFormField'

const sortEnd = move => ({ oldIndex, newIndex }) => {
  move(oldIndex, newIndex)
}

const onRemove = fields => () => index => {
  fields.remove(index)
}

const ImageSetArrayFormField = ({
  name = 'imageSets',
  addImageSetUrl,
  editImageSetUrl
}) => {
  return (
    <>
      <FieldArray name={name}>
        {({ fields }) => {
          return (
            <ImageSetSortableArray
              onRemove={onRemove(fields)}
              fields={fields}
              addImageSetUrl={addImageSetUrl}
              editImageSetUrl={editImageSetUrl}
              onSortEnd={sortEnd(fields.move)}
              component={ImageSetFormField}
              distance={1}
              axis="xy"
              lockToContainerEdges={true}
              lockOffset="20%"
            />
          )
        }}
      </FieldArray>
    </>
  )
}

ImageSetArrayFormField.propTypes = {
  name: PropTypes.string,
  addImageSetUrl: PropTypes.string.isRequired,
  editImageSetUrl: PropTypes.func.isRequired
}

export default ImageSetArrayFormField
