import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import FormControl from './FormControl'
import './formField.css'

const FormField = ({ name, validate, ...rest }) => {
  return (
    <Field name={name} validate={validate} className="formField">
      {({ input, meta }) => {
        const { submitError = [] } = meta
        const error =
          submitError.length > 0
            ? submitError
            : meta.error && meta.touched
              ? meta.error
              : null
        return <FormControl {...rest} {...input} error={error} />
      }}
    </Field>
  )
}

FormField.propTypes = {
  ...FormControl.propTypes,
  name: PropTypes.string.isRequired,
  validate: PropTypes.func
}

export default FormField
