import React from 'react'

const LoadingIcon = () => {
  return (
    <div className="publicationItem__loading">
      <svg className="publicationItem__circularLoading" viewBox="25 25 50 50">
        <circle
          className="publicationItem__loadingPath"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke="#f8941e"
          strokeWidth="5"
        />
      </svg>
    </div>
  )
}

export default LoadingIcon
