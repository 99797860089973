export const getAbsoluteUrl = (apiRoot, endpoint) => {
  if (endpoint.startsWith('http')) {
    return endpoint
  }
  if (apiRoot === null) {
    return undefined
  }
  const lastChar = apiRoot.length > 0 ? apiRoot[apiRoot.length - 1] : null
  const absoluteEndpoint =
    lastChar === '/' ? apiRoot + endpoint : apiRoot + '/' + endpoint

  return encodeURI(absoluteEndpoint)
}

export const getAbsoluteUrlFn = apiRoot => endpoint =>
  getAbsoluteUrl(apiRoot, endpoint)
