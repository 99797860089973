import React from 'react'
import PropTypes from 'prop-types'
import { enhanceWithSpinner } from '../../../../../components/Spinner'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from '../../../components/Loader'
import './push.css'

const PushList = ({ pushes, hasMore, fetchPushes }) => {
  const pushItems = pushes.map((push, index) => {
    const { createdDate, title, text, id } = push
    return (
      <div key={id}>
        {index ? <hr className="formField__separator" /> : null}
        <div className="push">
          <div className="push__date">
            {moment(createdDate).format('DD MMMM YYYY HH:mm')}
          </div>
          <div className="push__title">{title}</div>
          <div className="push__text">{text}</div>
        </div>
      </div>
    )
  })
  return (
    <div className="pageWrapper">
      <InfiniteScroll
        pageStart={0}
        initialLoad={false}
        loadMore={() => fetchPushes(pushes.length)}
        hasMore={hasMore}
        loader={<Loader key="loader" />}
      >
        {pushItems}
      </InfiniteScroll>
    </div>
  )
}

PushList.propTypes = {
  pushes: PropTypes.array.isRequired
}

const PushWithSpinner = enhanceWithSpinner(
  ({ pushes }) => pushes === null || pushes === undefined,
  PushList
)

export default PushWithSpinner
