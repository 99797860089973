import { call } from 'redux-saga/effects'
import fetchArchitectureTabSaga from './fetchArchitectureTabSaga'

const editTabSagaRoute = {
  '/projects/:projectId/architecture/tabs/:tabId': function* runEditArchitectureTabSaga({
    projectId,
    tabId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const tabIdNumber = parseInt(tabId, 10)
    yield call(fetchArchitectureTabSaga, projectIdNumber, tabIdNumber)
  }
}

export default editTabSagaRoute
