import {
  fetchSection,
  PROJECT_DECORATION_SECTION_SUCCESS,
  PROJECT_DECORATION_SECTION_FAILURE
} from '../actions'
import createSectionSaga from '../../../sagas/createSectionSaga'
import fetchDecorationSectionPinImagesSaga from './fetchDecorationSectionPinImagesSaga'

export default createSectionSaga(
  fetchSection,
  [PROJECT_DECORATION_SECTION_SUCCESS, PROJECT_DECORATION_SECTION_FAILURE],
  project => project.decorationSection,
  {
    sectionName: 'Отделка',
    decorationObjects: []
  },
  fetchDecorationSectionPinImagesSaga
)
