import { fork, call } from 'redux-saga/effects'
import watchCreateAdvantageSaga from './watchCreateAdvantageSaga'
import fetchAdvantagesSectionSaga from '../../../sagas/fetchAdvantagesSectionSaga'

const createAdvantageSagaRoute = {
  '/projects/:projectId/advantages/add': function* runCreateAdvantageSaga({
    projectId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const advantagesSection = yield call(
      fetchAdvantagesSectionSaga,
      projectIdNumber
    )
    if (advantagesSection) {
      yield fork(watchCreateAdvantageSaga, projectIdNumber)
    }
  }
}

export default createAdvantageSagaRoute
