import { default as editConstructionProgressReducer } from './editConstructionProgressReducer'
import { default as createImageSetReducer } from '../pages/CreateImageSet/reducers'
import { default as editImageSetReducer } from '../pages/EditImageSet/reducers'

const reducer = {
  ...editConstructionProgressReducer,
  ...createImageSetReducer,
  ...editImageSetReducer
}

export default reducer
