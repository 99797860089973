import {
  BLOCKS_EDIT_IMAGE_SET_SUCCESS,
  BLOCK_IMAGE_META_SUCCESS
} from '../actions'
import { blockById } from './selectors'

const editImageSetReducer = {
  [BLOCK_IMAGE_META_SUCCESS]: (state, action) => {
    const {
      meta: { blockId, imageType },
      payload
    } = action
    const curBlock = blockById(blockId)(state)
    const oldImage = curBlock[imageType]
    const newImage = { ...oldImage, ...payload }
    const newBlock = { ...curBlock, [imageType]: newImage }
    const newAbout = { ...state.about, [`block${blockId}`]: newBlock }
    return {
      ...state,
      about: newAbout
    }
  },

  [BLOCKS_EDIT_IMAGE_SET_SUCCESS]: (state, action) => {
    const {
      meta: { blockId, imageSetId },
      payload
    } = action
    const curBlock = blockById(blockId)(state)
    const newImages = imageSetId
      ? curBlock.images.map(image => {
          if (image.id === payload.id) {
            return payload
          }
          return image
        })
      : curBlock.images
        ? { images: [...curBlock.images, payload] }
        : payload
    const newBlock = {
      ...curBlock,
      ...newImages,
      previewImageUrl: payload.previewImageUrl
    }
    const newAbout = { ...state.about, [`block${blockId}`]: newBlock }
    return {
      ...state,
      about: newAbout
    }
  }
}

export default editImageSetReducer
