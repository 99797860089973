export const blockById = blockId => state => {
  return state.about[`block${blockId}`]
}

const imageSet = blockId => state => {
  const blockProps = blockById(blockId)(state)
  const { title, description, previewImageUrl, ...imagesSet } = blockProps
  return imagesSet
}

export default imageSet
