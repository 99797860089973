import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import {
  required,
  formWithError,
  phone
} from '../../../../../../../../../components/Validation'
import { FormField } from '../../../../../../../../../components/Form'
import TextareaField from '../../../../../../../../../components/Form/TextareaField'
import { SubmitButton } from '../../../../../../../components/Buttons'
import {
  PinImageFormField,
  SelectField,
  CheckBoxField
} from '../../../../../../../components/Form'
import './generalSection.css'

const focusOnErrors = createDecorator()

class GeneralSection extends PureComponent {
  removeNonImages = generalSection => {
    const notImages = Object.keys(generalSection).filter(
      key => key.endsWith('Image') && !(generalSection[key] instanceof File)
    )

    const updatedGeneralSection = {
      ...generalSection
    }
    notImages.forEach(notImageKey => {
      delete updatedGeneralSection[notImageKey]
    })

    return updatedGeneralSection
  }

  onSubmit = project => {
    const {
      project: { id },
      submit
    } = this.props
    const { generalSection } = project
    const model = this.removeNonImages(generalSection)
    submit(id, model)
  }

  render() {
    const { project, crmProjects = [] } = this.props
    const options = [
      {
        label: '',
        value: null
      },
      ...crmProjects.map(t => {
        return {
          label: t.name,
          value: t.id
        }
      })
    ]

    return (
      <Form
        initialValues={project}
        mutators={{
          ...arrayMutators
        }}
        onSubmit={this.onSubmit}
        decorators={[focusOnErrors]}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          submitSucceeded,
          dirtySinceLastSubmit
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col formField">
                  <FormField
                    name="generalSection.sectionName"
                    validate={required}
                    type="text"
                    label="Название раздела"
                    placeholder="Название раздела"
                  />
                </div>
                <div className="col formField">
                  <SelectField
                    form={form}
                    options={options}
                    name="generalSection.crmId"
                    label="ID проекта в CRM"
                  />
                </div>
              </div>
              <hr className="formField__separator" />
              <div className="row">
                <div className="col formField">
                  <FormField
                    name="generalSection.name"
                    type="text"
                    label="Название"
                    placeholder="Название"
                    validate={required}
                  />
                </div>
                <div className="col formField">
                  <FormField
                    name="generalSection.buildingType"
                    type="text"
                    label="Тип"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 formField">
                  <FormField
                    name="generalSection.deadline"
                    type="text"
                    label="Срок сдачи"
                    placeholder="напр. 1 кв. 2018"
                  />
                </div>
                <div className="row col-6 formField">
                  <div className="col-6 formField">
                    <CheckBoxField
                      form={form}
                      name="generalSection.isReady"
                      type="checkbox"
                      className="styledCheckbox"
                      label="Дом сдан?"
                    />
                  </div>
                  <div className="col-6 formField">
                    <CheckBoxField
                      form={form}
                      name="generalSection.announcement"
                      type="checkbox"
                      className="styledCheckbox"
                      label="Анонс"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h4>Адрес</h4>
                </div>
              </div>
              <div className="row">
                <div className="col formField">
                  <FormField
                    name="generalSection.address.city"
                    validate={required}
                    type="text"
                    label="Город"
                  />
                </div>
                <div className="col formField">
                  <FormField
                    name="generalSection.address.subway"
                    validate={required}
                    type="text"
                    label="Метро"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col formField">
                  <FormField
                    name="generalSection.address.subwayLineColor"
                    validate={required}
                    type="text"
                    label="Цвет ветки метро"
                  />
                </div>
                <div className="col formField">
                  <FormField
                    name="generalSection.address.subwayDistance"
                    validate={required}
                    type="text"
                    label="Расстояние до метро"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col formField">
                  <FormField
                    name="generalSection.address.addressString"
                    validate={required}
                    type="text"
                    label="Адрес"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h4>Координаты</h4>
                </div>
              </div>
              <div className="row">
                <div className="col formField">
                  <FormField
                    name="generalSection.location.latitude"
                    validate={required}
                    type="text"
                    label="Широта"
                  />
                </div>
                <div className="col formField">
                  <FormField
                    name="generalSection.location.longitude"
                    validate={required}
                    type="text"
                    label="Долгота"
                  />
                </div>
              </div>
              <PinImageFormField
                label="Пин на карте"
                name="generalSection"
                form={form}
              />
              <div className="row">
                <div className="col-6 formField">
                  <FormField
                    name="generalSection.phoneNumber"
                    type="text"
                    label="Телефон"
                    placeholder="+7XXXXXXXXXX"
                    validate={phone}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col formField">
                  <TextareaField
                    name="generalSection.disclaimer"
                    validate={required}
                    type="text"
                    label="Дисклеймер"
                  />
                </div>
              </div>
              <SubmitButton
                pristine={pristine}
                submitSucceeded={submitSucceeded}
                dirtySinceLastSubmit={dirtySinceLastSubmit}
              />
            </form>
          )
        }}
      />
    )
  }
}

GeneralSection.propTypes = {
  project: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired
}

export default formWithError(GeneralSection)
