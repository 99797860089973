import React from 'react'
import PropTypes from 'prop-types'
import './submitButton.css'
import { connect } from 'react-redux'
import { submittingSelector } from '../../reducers/selectors'

const SubmitButton = ({
  text = 'Сохранить',
  pristine,
  submitSucceeded,
  dirtySinceLastSubmit,
  submitting
}) => {
  const activeClass =
    !pristine || submitting.submitting ? 'submitButton--active' : ''

  return (
    <div className={`submitButton ${activeClass}`}>
      <div className="container submitButton__wrapper">
        {submitting.submitting || !pristine ? (
          <button
            className="btn btn-sm btn-primary submitButton__btn"
            type="submit"
          >
            {submitting.submitting ? (
              <>
                <div className="submitButton__loader" />
                <div className="submitButton__noevents" />
              </>
            ) : (
              text
            )}
          </button>
        ) : (
          <span className="text-primary">
            {submitting.error ? (
              <span className="text-danger">{submitting.error}</span>
            ) : (
              'Изменения сохранены'
            )}
          </span>
        )}
      </div>
    </div>
  )
}

SubmitButton.propTypes = {
  text: PropTypes.string,
  submitting: PropTypes.object.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  dirtySinceLastSubmit: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  submitting: submittingSelector(state)
})

export default connect(
  mapStateToProps,
  null
)(SubmitButton)
