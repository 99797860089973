import React from 'react'
import PropTypes from 'prop-types'
import { required } from '../../../../../components/Validation'
import { ImageMobile, ImageTablet } from '../../ImageUpload'
import ImageType from './ImageType'
import getName from './getName'

const BigImageFormField = ({ label = 'Изображение/иконка', form, prefix }) => {
  const size = 'big'
  return [
    <div className="row" key="label">
      <div className="col">
        <h4>{label}</h4>
      </div>
    </div>,
    <div className="row" key="images">
      <div className="col">
        <div className="imageGrid">
          <ImageMobile
            validate={required}
            name={getName(prefix, ImageType.mobile)}
            form={form}
            size={size}
          />
          <ImageTablet
            name={getName(prefix, ImageType.tablet)}
            form={form}
            size={size}
          />
        </div>
      </div>
    </div>
  ]
}

BigImageFormField.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  prefix: PropTypes.string
}

export default BigImageFormField
