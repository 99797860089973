import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const PROJECT_ABOUT_SECTION_REQUEST = 'PROJECT_ABOUT_SECTION_REQUEST'
export const PROJECT_ABOUT_SECTION_SUCCESS = 'PROJECT_ABOUT_SECTION_SUCCESS'
export const PROJECT_ABOUT_SECTION_FAILURE = 'PROJECT_ABOUT_SECTION_FAILURE'

export const fetchProjectAboutSection = projectId => {
  const meta = {
    projectId
  }
  const request = {
    type: PROJECT_ABOUT_SECTION_REQUEST,
    meta
  }
  const success = {
    type: PROJECT_ABOUT_SECTION_SUCCESS,
    meta
  }
  const failure = {
    type: PROJECT_ABOUT_SECTION_SUCCESS,
    meta
  }
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/about`,
      method: 'get',
      types: [request, success, failure]
    }
  }
}

export const PROJECT_ABOUT_SECTION_SUBMIT_REQUEST =
  'PROJECT_ABOUT_SECTION_SUBMIT_REQUEST'
export const PROJECT_ABOUT_SECTION_SUBMIT_SUCCESS =
  'PROJECT_ABOUT_SECTION_SUBMIT_SUCCESS'
export const PROJECT_ABOUT_SECTION_SUBMIT_FAILURE =
  'PROJECT_ABOUT_SECTION_SUBMIT_FAILURE'

export const submitProjectAboutSection = (projectId, aboutSection) => {
  const meta = {
    projectId,
    aboutSection
  }
  const request = {
    type: PROJECT_ABOUT_SECTION_SUBMIT_REQUEST,
    meta
  }
  const success = {
    type: PROJECT_ABOUT_SECTION_SUBMIT_SUCCESS,
    meta
  }
  const formData = objectToFormData(aboutSection)
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/about`,
      method: 'put',
      headers: {},
      body: formData,
      types: [request, success, PROJECT_ABOUT_SECTION_SUBMIT_FAILURE]
    }
  }
}
