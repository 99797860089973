import React, { PureComponent } from 'react'
import { TimelineLite } from 'gsap/all'
import './style.css'

export { default as ActionPopup } from './ActionPopup'
export { default as ErrorPopup } from './ErrorPopup'

export default class UniversalPopup extends PureComponent {
  constructor(props) {
    super(props)
    this.modalDialog = null
    this.modalTween = new TimelineLite({ paused: true })
  }

  componentDidMount = () => {
    this.modalTween
      .from(this.modalDialog, 0.25, { y: -50, autoAlpha: 1 })
      .paused(false)
  }

  render() {
    const { title, body, buttons, children } = this.props
    const singleButtonClass =
      buttons() && buttons().length === 1 ? 'universal-popup__btns_center' : ''
    return (
      <div className={'universal-popup '}>
        <div className="wrapper" ref={div => (this.modalDialog = div)}>
          <div className="universal-popup__title">{title()}</div>
          {typeof body === 'function' ? body() : children}
          <div className={`universal-popup__btns ${singleButtonClass}`}>
            {buttons()}
          </div>
        </div>
      </div>
    )
  }
}
