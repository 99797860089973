import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECTS_CREATE_IMAGE_SET_SUCCESS } from '../actions'

function* watchCreateImageSetSaga(projectId) {
  while (true) {
    yield take(PROJECTS_CREATE_IMAGE_SET_SUCCESS)
    yield put(push(`/projects/${projectId}/images`))
  }
}

export default watchCreateImageSetSaga
