import React from 'react'
import PropTypes from 'prop-types'
import { SortableContainer } from 'react-sortable-hoc'
import SortableItem from './SortableItem'
import './sortableArray.css'

const SortableArray = SortableContainer(({ fields, ...rest }) => {
  return (
    <div className="sortableArray">
      {fields.map((name, index) => {
        return (
          <SortableItem
            {...rest}
            key={`item-${index}`}
            index={index}
            itemIndex={index}
            value={fields.value[index]}
            name={name}
            fields={fields}
          />
        )
      })}
    </div>
  )
})

SortableArray.propTypes = {
  fields: PropTypes.object.isRequired
}

export default SortableArray
