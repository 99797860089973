import React from 'react'
import FormError from './FormError'
import { wrapDisplayName } from 'recompose'

export const formWithError = FormComponent => {
  const WrappedComponent = ({ error, ...rest }) => {
    if (!error) {
      return <FormComponent {...rest} />
    }

    return (
      <>
        <FormError error={error} />
        <FormComponent {...rest} />
      </>
    )
  }
  WrappedComponent.displayName = wrapDisplayName(FormComponent, 'formWithError')

  return WrappedComponent
}
