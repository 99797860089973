import React from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import { Route, NavLink, Switch } from 'react-router-dom'
import Projects from '../pages/Projects'
import About from '../pages/About'
import Stats from '../pages/Stats'
import Push from '../pages/Push'
import Users from '../pages/Users'
import Objects from '../pages/Objects'
import Account from '../pages/Account'
import Publications from '../pages/Publications'
import ClientsVersions from '../pages/ClientsVersions'
import PrivateRoute from '../../../components/PrivateRoute'
import PrivateNavLink from '../../../components/PrivateNavLink'
import '../styles.css'

const Home = ({ user, logout }) => {
  return (
    <div>
      <Header user={user} logout={logout} />
      <div className="app-body">
        <main className="main">
          <div className="container">
            <div className="row">
              <div className="col-2 leftMenu__wrapper">
                <nav className="leftMenu">
                  <NavLink
                    to="/projects"
                    className="leftMenu__link"
                    activeClassName="leftMenu__link--active"
                  >
                    Проекты
                  </NavLink>
                  <PrivateNavLink
                    rights={['admin']}
                    to="/users"
                    className="leftMenu__link"
                    activeClassName="leftMenu__link--active"
                  >
                    Пользователи
                  </PrivateNavLink>
                  <PrivateNavLink
                    rights={['admin']}
                    to="/stats/rates"
                    className="leftMenu__link"
                    activeClassName="leftMenu__link--active"
                  >
                    Статистика
                  </PrivateNavLink>
                  <NavLink
                    to="/pushes"
                    className="leftMenu__link"
                    activeClassName="leftMenu__link--active"
                  >
                    Push-уведомления
                  </NavLink>
                  <NavLink
                    to="/about"
                    className="leftMenu__link"
                    activeClassName="leftMenu__link--active"
                  >
                    О компании
                  </NavLink>
                  <NavLink
                    to={`/objects`}
                    className="leftMenu__link"
                    activeClassName="leftMenu__link--active"
                  >
                    Справочник объектов
                  </NavLink>
                  <PrivateNavLink
                    rights={['admin']}
                    to={`/publications`}
                    className="leftMenu__link"
                    activeClassName="leftMenu__link--active"
                  >
                    Публикация
                  </PrivateNavLink>
                  <PrivateNavLink
                    rights={['admin']}
                    to={`/versions`}
                    className="leftMenu__link"
                    activeClassName="leftMenu__link--active"
                  >
                    Обновления
                  </PrivateNavLink>
                </nav>
              </div>
              <div className="col-10">
                <Switch>
                  <Route path="/projects" component={Projects} />
                  <Route path="/about" component={About} />
                  <Route path="/account" component={Account} />
                  <PrivateRoute
                    rights={['admin']}
                    path="/stats"
                    component={Stats}
                  />
                  <Route path="/pushes" component={Push} />
                  <PrivateRoute
                    rights={['admin']}
                    path="/users"
                    component={Users}
                  />
                  <Route path="/objects" component={Objects} />
                  <PrivateRoute
                    rights={['admin']}
                    path="/publications"
                    component={Publications}
                  />
                  <PrivateRoute
                    rights={['admin']}
                    path="/versions"
                    component={ClientsVersions}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

Home.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func.isRequired
}

export default Home
