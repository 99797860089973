import { connect } from 'react-redux'
import { getProject } from '../../reducers/selectors'
import { default as EditProjectFormComponent } from './components/EditProjectForm'
import { enhanceWithSpinner } from '../../../../../../components'

const mapStateToProps = (state, ownProps) => {
  const currentProjectId = parseInt(ownProps.match.params.id, 10)
  const projectSelector = getProject(state)
  const project = projectSelector(currentProjectId)
  return {
    project,
    crmProjects: state.projects.crmProjects
  }
}

const FormWithSpinner = enhanceWithSpinner(props => {
  const { project } = props
  return project === null || project === undefined
}, EditProjectFormComponent)

const EditProjectForm = connect(mapStateToProps)(FormWithSpinner)

export default EditProjectForm
