import { connect } from 'react-redux'
import { default as GeneralSection } from './components/GeneralSection'
import { submitProjectGeneralSection } from './actions'
import { enhanceWithSpinner } from '../../../../../../../../components'

const GeneralSectionWithSpinner = enhanceWithSpinner(props => {
  const { project } = props
  return (
    project === null ||
    project === undefined ||
    project.generalSection === undefined
  )
}, GeneralSection)

export default connect(
  null,
  {
    submit: submitProjectGeneralSection
  }
)(GeneralSectionWithSpinner)
