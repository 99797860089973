import { fork, call } from 'redux-saga/effects'
import watchCreateObjectSaga from './watchCreateObjectSaga'
import fetchLocationSectionSaga from '../../../sagas/fetchLocationSectionSaga'

const createObjectSagaRoute = {
  '/projects/:projectId/location/objects': function* runCreateLocationSaga({
    projectId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const advantagesSection = yield call(
      fetchLocationSectionSaga,
      projectIdNumber
    )
    if (advantagesSection) {
      yield fork(watchCreateObjectSaga, projectIdNumber)
    }
  }
}

export default createObjectSagaRoute
