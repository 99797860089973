import {
  PROJECT_DOCUMENTS_SECTION_SUCCESS,
  PROJECT_DOCUMENTS_SECTION_FAILURE,
  PROJECT_DOCUMENTS_SECTION_SUBMIT_REQUEST,
  PROJECT_DOCUMENTS_SECTION_SUBMIT_SUCCESS,
  PROJECT_DOCUMENTS_SECTION_SUBMIT_FAILURE,
  PROJECT_DOCUMENTS_SECTION_SUBMIT_FILE_REQUEST,
  PROJECT_DOCUMENTS_SECTION_SUBMIT_FILE_SUCCESS,
  PROJECT_DOCUMENTS_SECTION_SUBMIT_FILE_FAILURE,
  PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_REQUEST,
  PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_SUCCESS,
  PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_FAILURE,
  PROJECT_DOCUMENTS_SECTION_PIN_IMAGE_META_SUCCESS,
  PROJECT_DOCUMENTS_SECTION_DOCUMENT_META_SUCCESS
} from '../actions'
import createSectionReducer from '../../../reducers/createSectionReducer'
import {
  setSection,
  setPinImageUrl,
  setDocumentUrl,
  setDocuments,
  setDocumentFile
} from './pinImage'

const documentsSectionReducer = createSectionReducer(
  'documentsSection',
  [PROJECT_DOCUMENTS_SECTION_SUCCESS, PROJECT_DOCUMENTS_SECTION_FAILURE],
  [
    PROJECT_DOCUMENTS_SECTION_SUBMIT_SUCCESS,
    PROJECT_DOCUMENTS_SECTION_SUBMIT_FAILURE
  ]
)

export default {
  ...documentsSectionReducer,
  [PROJECT_DOCUMENTS_SECTION_PIN_IMAGE_META_SUCCESS]: setPinImageUrl,
  [PROJECT_DOCUMENTS_SECTION_DOCUMENT_META_SUCCESS]: setDocumentUrl,
  [PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_REQUEST]: setDocuments,
  [PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_SUCCESS]: setDocuments,
  [PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_FAILURE]: setDocuments,
  [PROJECT_DOCUMENTS_SECTION_SUBMIT_FILE_REQUEST]: setDocumentFile,
  [PROJECT_DOCUMENTS_SECTION_SUBMIT_FILE_SUCCESS]: setDocumentFile,
  [PROJECT_DOCUMENTS_SECTION_SUBMIT_FILE_FAILURE]: setDocumentFile,
  [PROJECT_DOCUMENTS_SECTION_SUBMIT_REQUEST]: setSection(null),
  [PROJECT_DOCUMENTS_SECTION_SUBMIT_SUCCESS]: setSection(
    documentsSectionReducer[PROJECT_DOCUMENTS_SECTION_SUBMIT_SUCCESS]
  ),
  [PROJECT_DOCUMENTS_SECTION_SUBMIT_FAILURE]: setSection(
    documentsSectionReducer[PROJECT_DOCUMENTS_SECTION_SUBMIT_FAILURE]
  )
}
