import {
  fetchProjectGeneralSection,
  PROJECT_GENERAL_SECTION_SUCCESS,
  PROJECT_GENERAL_SECTION_FAILURE
} from '../actions'
import fetchGeneralSectionPinImagesSaga from './fetchGeneralSectionPinImagesSaga'
import createSectionSaga from '../../../sagas/createSectionSaga'

export default createSectionSaga(
  fetchProjectGeneralSection,
  [PROJECT_GENERAL_SECTION_SUCCESS, PROJECT_GENERAL_SECTION_FAILURE],
  project => project.generalSection,
  {
    sectionName: 'Общая информация',
    objects: []
  },
  fetchGeneralSectionPinImagesSaga
)
