import { default as projectPublicSpacesSectionSagaRoute } from './projectPublicSpacesSectionSagaRoute'
import createTabSagaRoute from '../pages/CreateTab/sagas'
import editTabSagaRoute from '../pages/EditTab/sagas'

export {
  default as fetchPublicSpacesSectionSaga
} from './fetchPublicSpacesSectionSaga'
export { watchPublicSpacesTabSubmitted } from '../pages/EditTab/sagas'

export default {
  ...editTabSagaRoute,
  ...createTabSagaRoute,
  ...projectPublicSpacesSectionSagaRoute
}
