import { put, take } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { EDIT_USER_SUCCESS } from '../actions'

export default function* watchUserEdited() {
  while (true) {
    yield take(EDIT_USER_SUCCESS)
    yield put(push('/users'))
  }
}
