import generalSectionReducer from '../pages/GeneralSection/reducers'
import imagesSectionReducer from '../pages/ImagesSection/reducers'
import aboutSectionReducer from '../pages/AboutSection/reducers'
import constructionProgressReducer from '../pages/ConstructionProgressSection/reducers'
import advantagesSectionReducer from '../pages/AdvantageSection/reducers'
import locationSectionReducer from '../pages/LocationSection/reducers'
import accomplishmentSectionReducer from '../pages/AccomplishmentSection/reducers'
import decorationSectionReducer from '../pages/DecorationSection/reducers'
import architectureSectionReducer from '../pages/ArchitectureSection/reducers'
import publicSpacesSectionReducer from '../pages/PublicSpacesSection/reducers'
import teamSectionReducer from '../pages/TeamSection/reducers'
import documentsSectionReducer from '../pages/DocumentsSection/reducers'

const editProjectReducer = {
  ...generalSectionReducer,
  ...imagesSectionReducer,
  ...aboutSectionReducer,
  ...constructionProgressReducer,
  ...advantagesSectionReducer,
  ...locationSectionReducer,
  ...accomplishmentSectionReducer,
  ...decorationSectionReducer,
  ...architectureSectionReducer,
  ...publicSpacesSectionReducer,
  ...teamSectionReducer,
  ...documentsSectionReducer
}

export { default as updateProject } from './updateProject'
export { default as createSectionReducer } from './createSectionReducer'

export default editProjectReducer
