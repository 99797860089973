import React from 'react'
import PropTypes from 'prop-types'
import { RemoveButton } from '../../../../../../components/Buttons'
import { PlusButton } from '../../../../../../../../components/Buttons'

const ParameterButtons = ({ fields, index }) => {
  if (fields.length === 1) {
    return (
      <PlusButton
        onClick={() => fields.push({ name: '', initialPrice: 0.0 })}
      />
    )
  }
  if (index === 0) {
    return [<RemoveButton key="remove" onClick={() => fields.remove(index)} />]
  }
  if (index === fields.length - 1) {
    return [
      <PlusButton
        key="add"
        onClick={() => fields.push({ name: '', initialPrice: 0.0 })}
      />,
      <RemoveButton key="remove" onClick={() => fields.remove(index)} />
    ]
  }
  return [<RemoveButton key="remove" onClick={() => fields.remove(index)} />]
}

ParameterButtons.propTypes = {
  fields: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}

export default ParameterButtons
