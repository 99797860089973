import createProjectReducer from '../../../../../reducers/createProjectReducer'

export const setPinImageUrlReducer = (state, action) => {
  const { pinImageType } = action.meta
  const { name, url } = action.payload
  const projectReducer = createProjectReducer(project => {
    return {
      ...project,
      generalSection: {
        ...project.generalSection,
        [pinImageType]: {
          ...project.generalSection[pinImageType],
          name,
          url
        }
      }
    }
  })

  return projectReducer(state, action)
}
