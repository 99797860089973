import { combineReducers } from 'redux'
import projectsReducer from '../pages/Home/pages/Projects/reducers'
import aboutReducer from '../pages/Home/pages/About/reducers'
import ratesReducer from '../pages/Home/pages/Stats/pages/Rates/reducers'
import commentsReducer from '../pages/Home/pages/Stats/pages/Comments/reducers'
import { userReducer } from '../pages/Login/reducers'
import usersReducer from '../pages/Home/pages/Users/reducers'
import pushesReducer from '../pages/Home/pages/Push/reducers'
import objectsReducer from '../pages/Home/pages/Objects/reducers'
import publicationsReducer from '../pages/Home/pages/Publications/reducers'
import clientsVersionsReducer from '../pages/Home/pages/ClientsVersions/reducers'
import submittingReducer from '../pages/Home/reducers'
import config from './config'

const rootReducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  projects: projectsReducer,
  pushes: pushesReducer,
  rates: ratesReducer,
  comments: commentsReducer,
  about: aboutReducer,
  config: config,
  submitting: submittingReducer,
  objects: objectsReducer,
  publication: publicationsReducer,
  clientsVersions: clientsVersionsReducer
})

export default rootReducer
