import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  ArrayFormField,
  SortableItemLink
} from '../../../../../../../../components/Form'
import './objectSortableList.css'

const ObjectFormField = ({ projectId }) => {
  return (
    <ArrayFormField
      name="infrastructureObjects"
      header={({ value, onRemove }) => {
        return (
          <>
            <SortableItemLink
              to={`/projects/${projectId}/location/objects/${value.id}`}
            >
              {value.title}
            </SortableItemLink>
            <button
              type="button"
              onClick={onRemove}
              className="btn btn--noPadding btn--delete"
              title="Удалить объект"
            />
          </>
        )
      }}
    />
  )
}

const ObjectSortableList = ({ projectId }) => {
  return [
    <div className="objectSortableList__head" key="header">
      <h4>Объекты инфраструктуры</h4>
      <Link
        to={`/projects/${projectId}/location/objects`}
        className="btn btn-warning btn-sm"
      >
        Добавить
      </Link>
    </div>,
    <ObjectFormField projectId={projectId} key="list" />
  ]
}

ObjectSortableList.propTypes = {
  fields: PropTypes.object.isRequired
}

export default ObjectSortableList
