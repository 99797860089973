import { put, race, take, call, fork } from 'redux-saga/effects'
import { fetchProjectSaga } from '../../../sagas'

const createSectionSaga = (
  fetchSection,
  fetchSectionActions,
  sectionProp,
  defaultSection,
  onSuccessSaga
) => {
  const [successAction, failureAction] = fetchSectionActions

  const sectionSaga = function* fetchSectionSaga(projectId) {
    const project = yield call(fetchProjectSaga, projectId)
    const section = sectionProp(project)
    if (section) {
      return section
    }
    yield put(fetchSection(project.id))
    const { success, failure } = yield race({
      success: take(successAction),
      failure: take(failureAction)
    })
    if (success) {
      if (onSuccessSaga) {
        yield fork(onSuccessSaga, projectId, success.payload)
      }
      return success.payload
    }

    if (failure && defaultSection) {
      const action = {
        type: successAction,
        meta: { projectId },
        payload: defaultSection
      }

      yield put(action)

      return action.payload
    }

    return null
  }

  return sectionSaga
}

export default createSectionSaga
