import { select, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { isAuthenticatedProp } from '../../Login/reducers/selectors'

function* homeSaga() {
  const isAuthenticated = yield select(isAuthenticatedProp)
  if (!isAuthenticated) {
    yield put(push('/login'))
  }
  yield put(push('/projects'))
}

export default homeSaga
