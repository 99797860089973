import { fork, call } from 'redux-saga/effects'
import watchEditObjectSaga from './watchEditObjectSaga'
import fetchTeamObjectSaga from './fetchTeamObjectSaga'

const editObjectSagaRoute = {
  '/projects/:projectId/team/objects/:id': function* runCreateTeamSaga({
    projectId,
    id
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const idNumber = parseInt(id, 10)
    const teamObject = yield call(
      fetchTeamObjectSaga,
      projectIdNumber,
      idNumber
    )
    if (teamObject) {
      yield fork(watchEditObjectSaga, projectIdNumber)
    }
  }
}

export default editObjectSagaRoute
