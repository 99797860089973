import { RSAA } from 'redux-api-middleware'

export const PROJECT_CREATE_PROJECT_REQUEST = 'PROJECT_CREATE_PROJECT_REQUEST'
export const PROJECT_CREATE_PROJECT_SUCCESS = 'PROJECT_CREATE_PROJECT_SUCCESS'
export const PROJECT_CREATE_PROJECT_FAILURE = 'PROJECT_CREATE_PROJECT_FAILURE'

export const createProject = project => {
  const success = {
    type: PROJECT_CREATE_PROJECT_SUCCESS,
    meta: {
      project
    }
  }
  return {
    [RSAA]: {
      endpoint: 'api/Projects/',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(project),
      types: [
        PROJECT_CREATE_PROJECT_REQUEST,
        success,
        PROJECT_CREATE_PROJECT_FAILURE
      ]
    }
  }
}
