import React from 'react'
import PropTypes from 'prop-types'
import { required } from '../../../../../components/Validation'
import { ImageiOS2x, ImageiOS3x, ImageAndroidSvg } from '../../ImageUpload'

const PinImageType = {
  iOS2xActiveImage: 'iOS2xActiveImage',
  iOS3xActiveImage: 'iOS3xActiveImage',
  androidSvgActiveImage: 'androidSvgActiveImage',
  iOS2xNotActiveImage: 'iOS2xNotActiveImage',
  iOS3xNotActiveImage: 'iOS3xNotActiveImage',
  androidSvgNotActiveImage: 'androidSvgNotActiveImage'
}

const getName = (prefix, activityImageType) => {
  if (prefix) {
    return `${prefix}.${activityImageType}`
  }
  return activityImageType
}

const PinImageFormField = ({ label = 'Изображение/иконка', form, name }) => {
  return [
    <div className="row" key="header">
      <div className="col">
        <h4>{label}</h4>
      </div>
    </div>,
    <div className="row" key="first">
      <div className="col">
        <label className="formField__label">Активный - iOS</label>
        <div className="imageGrid">
          <ImageiOS2x
            name={getName(name, PinImageType.iOS2xActiveImage)}
            form={form}
            validate={required}
          />
          <ImageiOS3x
            name={getName(name, PinImageType.iOS3xActiveImage)}
            form={form}
          />
        </div>
      </div>
      <div className="col">
        <label className="formField__label">Не активный - iOS</label>
        <div className="imageGrid">
          <ImageiOS2x
            name={getName(name, PinImageType.iOS2xNotActiveImage)}
            form={form}
          />
          <ImageiOS3x
            name={getName(name, PinImageType.iOS3xNotActiveImage)}
            form={form}
          />
        </div>
      </div>
    </div>,
    <div className="row" key="second">
      <div className="col">
        <label className="formField__label">Активный - Android</label>
        <div className="imageGrid">
          <ImageAndroidSvg
            name={getName(name, PinImageType.androidSvgActiveImage)}
            form={form}
          />
        </div>
      </div>
      <div className="col">
        <label className="formField__label">Не активный - Android</label>
        <div className="imageGrid">
          <ImageAndroidSvg
            name={getName(name, PinImageType.androidSvgNotActiveImage)}
            form={form}
          />
        </div>
      </div>
    </div>
  ]
}

PinImageFormField.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string
}

export default PinImageFormField
