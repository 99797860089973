import { RSAA } from 'redux-api-middleware'

export const PUSH_REQUEST = 'PUSH_REQUEST'
export const PUSH_SUCCESS = 'PUSH_SUCCESS'
export const PUSH_FAILURE = 'PUSH_FAILURE'

export const submitPush = form => {
  return {
    [RSAA]: {
      endpoint: `api/notifications`,
      method: 'post',
      types: [
        PUSH_REQUEST,
        {
          type: PUSH_SUCCESS,
          meta: form
        },
        PUSH_FAILURE
      ],
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(form)
    }
  }
}
