import { default as editAdvantageSagaRoute } from './editAdvantageSagaRoute'
import createImageSetSagaRoute from '../pages/CreateImageSet/sagas/createImageSetSagaRoute'
import editImageSetSagaRoute from '../pages/EditImageSet/sagas'

export { default as fetchAdvantageSaga } from './fetchAdvantageSaga'

export default {
  ...editAdvantageSagaRoute,
  ...createImageSetSagaRoute,
  ...editImageSetSagaRoute
}
