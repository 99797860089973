import { put, all, fork } from 'redux-saga/effects'
import { fetchPinImageMetaData } from '../actions'
import { fetchDocuments } from './submitDocumentsSaga'

function* fetchPinImageMetaDataSaga(projectId, pinImageMeta) {
  const { metaUrl, type } = pinImageMeta
  if (metaUrl) {
    yield put(fetchPinImageMetaData(projectId, type, metaUrl))
  }
}

function* fetchPinImagesSaga(projectId, section) {
  const images = Object.keys(section)
    .filter(key => key.endsWith('Image'))
    .map(key => {
      return {
        ...section[key],
        type: key
      }
    })
    .filter(t => t.metaUrl)

  yield fork(fetchDocuments, projectId)
  const fetchTasks = images.map(pinImageMeta =>
    fork(fetchPinImageMetaDataSaga, projectId, pinImageMeta)
  )
  yield all(fetchTasks)
}

export default fetchPinImagesSaga
