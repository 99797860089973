import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import { required } from '../../../../../../../../../../../../components/Validation'
import {
  FormField,
  TextareaField
} from '../../../../../../../../../../../../components/Form'
import projectForm from '../../../../../../../../components/projectForm'
import { SmallIconImageFormField } from '../../../../../../../../../../components/Form'
import { SubmitButton } from '../../../../../../../../../../components/Buttons'
import './mortgageForm.css'

const focusOnErrors = createDecorator()

const onSubmit = (projectId, submit) => advantage => {
  submit(projectId, advantage)
}

const CreateAdvantageForm = ({ projectId, submit }) => {
  return (
    <Form
      mutators={{
        ...arrayMutators
      }}
      onSubmit={onSubmit(projectId, submit)}
      decorators={[focusOnErrors]}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <Link
                  to={`/projects/${projectId}/advantages`}
                  className="formField__back"
                >
                  Назад
                </Link>
              </div>
            </div>
            <hr className="formField__separator" />
            <div className="row">
              <div className="col">
                <h4>Преимущества</h4>
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <FormField
                  name="title"
                  validate={required}
                  type="text"
                  label="Заголовок"
                  placeholder="Заголовок"
                />
              </div>
            </div>
            <div className="row">
              <div className="col formField">
                <TextareaField
                  name="description"
                  validate={required}
                  type="text"
                  label="Описание"
                  placeholder="Описание"
                />
              </div>
            </div>
            <SmallIconImageFormField form={form} />
            <div className="row">
              <div className="col formField" />
            </div>
            <SubmitButton
              pristine={pristine}
              submitSucceeded={submitSucceeded}
              dirtySinceLastSubmit={dirtySinceLastSubmit}
            />
          </form>
        )
      }}
    />
  )
}

CreateAdvantageForm.propTypes = {
  projectId: PropTypes.number.isRequired,
  submit: PropTypes.func.isRequired
}

export default projectForm(CreateAdvantageForm)
