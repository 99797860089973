import { connect } from 'react-redux'
import { Push as PushComponent } from './components/Push'
import { pushes, hasMorePushes } from './reducers/selectors'
import { fetchPushes } from './actions'

const mapStateToProps = state => {
  return {
    pushes: pushes(state),
    hasMore: hasMorePushes(state)
  }
}

const Push = connect(
  mapStateToProps,
  { fetchPushes }
)(PushComponent)

export default Push
