import { RSAA } from 'redux-api-middleware'
import { getAbsoluteUrl } from '../utils/getAbsoluteUrl'
import { apiRootProp } from '../reducers/selectors'

export default store => next => action => {
  const apiCallAction = action[RSAA]
  if (typeof apiCallAction === 'undefined') {
    return next(action)
  }

  const { endpoint } = apiCallAction

  if (endpoint.indexOf('http://') > -1 || endpoint === '/config.json') {
    return next(action)
  }

  const state = store.getState()
  const apiRoot = apiRootProp(state)
  const absoluteEndpoint = getAbsoluteUrl(apiRoot, endpoint)
  const newAction = {
    ...apiCallAction,
    endpoint: absoluteEndpoint
  }
  const newApiCall = {
    [RSAA]: newAction
  }

  return next(newApiCall)
}
