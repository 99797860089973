import { RSAA } from 'redux-api-middleware'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

export const createUser = user => {
  const meta = {
    user
  }
  const success = {
    type: CREATE_USER_SUCCESS,
    meta
  }
  const failure = {
    type: CREATE_USER_FAILURE,
    meta
  }
  return {
    [RSAA]: {
      endpoint: 'api/users/',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user),
      types: [CREATE_USER_REQUEST, success, failure]
    }
  }
}
