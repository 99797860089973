import { projectProp } from '../../../../../../../../reducers/selectors'

const imageSetProp = (projectId, imagesSetId) => state => {
  const project = projectProp(projectId)(state)
  if (project === undefined || project === null) {
    return undefined
  }
  if (project.imagesSection === undefined) {
    return undefined
  }
  const imagesSet = project.imagesSection.projectListImages.find(
    set => set.id === imagesSetId
  )
  const { position, id, ...images } = imagesSet
  return images
}

export default imageSetProp
