import { RSAA } from 'redux-api-middleware'

export const PROJECT_DECORATION_EDIT_DECORATION_TYPE_SUBMIT_REQUEST =
  'PROJECT_DECORATION_EDIT_DECORATION_TYPE_SUBMIT_REQUEST'
export const PROJECT_DECORATION_EDIT_DECORATION_TYPE_SUBMIT_SUCCESS =
  'PROJECT_DECORATION_EDIT_DECORATION_TYPE_SUBMIT_SUCCESS'
export const PROJECT_DECORATION_EDIT_DECORATION_TYPE_SUBMIT_FAILURE =
  'PROJECT_DECORATION_EDIT_DECORATION_TYPE_SUBMIT_FAILURE'

export const submit = (projectId, tabId, objectId, decorationObject) => {
  const success = {
    type: PROJECT_DECORATION_EDIT_DECORATION_TYPE_SUBMIT_SUCCESS,
    meta: {
      projectId,
      tabId,
      objectId,
      decorationObject
    }
  }
  const { imageSets, ...rest } = decorationObject
  const newDecorationObject = {
    ...rest,
    items: imageSets.map(imageSet => imageSet.id)
  }
  return {
    [RSAA]: {
      endpoint: `api/projects/${projectId}/decoration/tabs/${tabId}/objects/${objectId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newDecorationObject),
      types: [
        PROJECT_DECORATION_EDIT_DECORATION_TYPE_SUBMIT_REQUEST,
        success,
        PROJECT_DECORATION_EDIT_DECORATION_TYPE_SUBMIT_FAILURE
      ]
    }
  }
}

export const PROJECT_DECORATION_OBJECT_REQUEST =
  'PROJECT_DECORATION_OBJECT_REQUEST'
export const PROJECT_DECORATION_OBJECT_SUCCESS =
  'PROJECT_DECORATION_OBJECT_SUCCESS'
export const PROJECT_DECORATION_OBJECT_FAILURE =
  'PROJECT_DECORATION_OBJECT_FAILURE'

export const fetchObject = (projectId, tabId, objectId) => {
  const success = {
    type: PROJECT_DECORATION_OBJECT_SUCCESS,
    meta: {
      projectId,
      tabId,
      objectId
    }
  }

  return {
    [RSAA]: {
      endpoint: `api/projects/${projectId}/decoration/tabs/${tabId}/objects/${objectId}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      types: [
        PROJECT_DECORATION_OBJECT_REQUEST,
        success,
        PROJECT_DECORATION_OBJECT_FAILURE
      ]
    }
  }
}
