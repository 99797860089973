import { put, race, take, select } from 'redux-saga/effects'
import { fetchUsers, USER_LIST_FAILURE, USER_LIST_SUCCESS } from '../actions'
import { users } from '../reducers/selectors'

function* fetchUsersSaga() {
  const usersProps = yield select(users)
  if (!usersProps) {
    yield put(fetchUsers())
  }
  const { success } = yield race({
    success: take(USER_LIST_SUCCESS),
    failure: take(USER_LIST_FAILURE)
  })

  if (success) {
    return success.payload
  }

  return null
}

export default fetchUsersSaga
