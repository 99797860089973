const mergeProjects = (existingProjects, newProjects) => {
  return newProjects.map(project => {
    const existingProject =
      existingProjects !== null
        ? existingProjects.find(p => p.id === project.id)
        : null
    if (existingProject) {
      return {
        ...existingProject,
        ...project
      }
    }
    return project
  })
}

export default mergeProjects
