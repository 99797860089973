import { put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import projectGeneralSectionSagaRoute from '../pages/GeneralSection/sagas'
import projectImagesSectionSagaRoute from '../pages/ImagesSection/sagas'
import projectAboutSectionSagaRoute from '../pages/AboutSection/sagas'
import constructionProgressSagaRoute from '../pages/ConstructionProgressSection/sagas'
import advantagesSectionSagaRoute from '../pages/AdvantageSection/sagas'
import locationSectionSagaRoute from '../pages/LocationSection/sagas'
import accomplishmentSectionSagaRoute from '../pages/AccomplishmentSection/sagas'
import decorationSectionSagaRoute from '../pages/DecorationSection/sagas'
import architectureSectionSagaRoute from '../pages/ArchitectureSection/sagas'
import publicSpacesSectionSagaRoute from '../pages/PublicSpacesSection/sagas'
import teamSectionSagaRoute from '../pages/TeamSection/sagas'
import documentsSectionSagaRoute from '../pages/DocumentsSection/sagas'

const editProjectSagaRoute = {
  '/projects/:id': function* runProjectEditSaga({ id }) {
    yield put(push(`/projects/${id}/general`))
  },
  ...projectGeneralSectionSagaRoute,
  ...projectImagesSectionSagaRoute,
  ...projectAboutSectionSagaRoute,
  ...constructionProgressSagaRoute,
  ...advantagesSectionSagaRoute,
  ...locationSectionSagaRoute,
  ...accomplishmentSectionSagaRoute,
  ...decorationSectionSagaRoute,
  ...architectureSectionSagaRoute,
  ...publicSpacesSectionSagaRoute,
  ...teamSectionSagaRoute,
  ...documentsSectionSagaRoute
}

export default editProjectSagaRoute
