import createSectionPage from '../../createSectionPage'
import DocumentsSectionPage from './components'
import { submit } from './actions'

const DocumentsSectionPageRoutePage = createSectionPage(
  'documents',
  null,
  submit
)(DocumentsSectionPage)

export default DocumentsSectionPageRoutePage
