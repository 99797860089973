import { put, take, select, race, all, takeEvery } from 'redux-saga/effects'
import {
  fetchNewToken,
  NEW_TOKEN_SUCCESS,
  NEW_TOKEN_FAILURE
} from '../pages/Login/actions'
import { refreshTokenProp } from '../pages/Login/reducers/selectors'
import { push } from 'connected-react-router'
import { RSAA } from 'redux-api-middleware'

export default function* unauthorizedSaga() {
  while (true) {
    const firstFailureAction = yield take(
      action =>
        action.type.endsWith('_FAILURE') && action.payload.status === 401
    )
    const refreshToken = yield select(refreshTokenProp)
    yield put(fetchNewToken(refreshToken))
    let failedActions = [firstFailureAction]
    yield takeEvery(
      action =>
        action.type.endsWith('_FAILURE') && action.payload.status === 401,
      function*(action) {
        yield failedActions.push(action)
      }
    )
    const { tokenSuccess } = yield race({
      tokenSuccess: take(NEW_TOKEN_SUCCESS),
      tokenFailure: take(NEW_TOKEN_FAILURE)
    })
    if (tokenSuccess) {
      yield all(
        failedActions.map(action => put({ [RSAA]: action.meta.__RSAA }))
      )
    } else {
      yield put(push('/login'))
    }
  }
}
