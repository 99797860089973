import {
  PROJECT_ARCHITECTURE_CREATE_GALLERY_IMAGE_SET_SUBMIT_SUCCESS,
  PROJECT_ARCHITECTURE_CREATE_GALLERY_IMAGE_SET_SUBMIT_FAILURE
} from '../actions'
import updateArchitectureType from '../../../reducers/updateArchitectureType'

const createImageSetReducer = {
  [PROJECT_ARCHITECTURE_CREATE_GALLERY_IMAGE_SET_SUBMIT_SUCCESS]: updateArchitectureType(
    (architectureType, { payload }) => {
      const { imageSets = [] } = architectureType
      const newImageSets = [payload, ...imageSets]
      const newGallery = {
        ...architectureType,
        imageSets: newImageSets
      }
      return newGallery
    }
  ),
  [PROJECT_ARCHITECTURE_CREATE_GALLERY_IMAGE_SET_SUBMIT_FAILURE]: (
    state,
    action
  ) => {
    const { payload: { response } = {} } = action
    return {
      ...state,
      error: response
    }
  }
}

export default createImageSetReducer
