import { default as projectTeamSectionSagaRoute } from './projectTeamSectionSagaRoute'
import createObjectSagaRoute from '../pages/CreateObject/sagas'
import editObjectSagaRoute from '../pages/EditObject/sagas'

export { default as fetchTeamSectionSaga } from './fetchTeamSectionSaga'

export default {
  ...editObjectSagaRoute,
  ...createObjectSagaRoute,
  ...projectTeamSectionSagaRoute
}
