import { fork, call } from 'redux-saga/effects'
import watchCreateObjectSaga from './watchCreateObjectSaga'
import fetchObjectsSaga from '../../../sagas/fetchObjectsSaga'

const createObjectSagaRoute = {
  '/objects/add': function* runCreateObjectsSaga() {
    const objects = yield call(fetchObjectsSaga)
    if (objects) {
      yield fork(watchCreateObjectSaga)
    }
  }
}

export default createObjectSagaRoute
