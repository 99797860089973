import {
  fetchSection,
  PROJECT_ACCOMPLISHMENT_SECTION_SUCCESS,
  PROJECT_ACCOMPLISHMENT_SECTION_FAILURE
} from '../actions'
import createSectionSaga from '../../../sagas/createSectionSaga'
import fetchAccomplishmentSectionPinImagesSaga from './fetchAccomplishmentSectionPinImagesSaga'

export default createSectionSaga(
  fetchSection,
  [
    PROJECT_ACCOMPLISHMENT_SECTION_SUCCESS,
    PROJECT_ACCOMPLISHMENT_SECTION_FAILURE
  ],
  project => project.accomplishmentSection,
  {
    sectionName: 'Объект',
    accomplishmentObjects: []
  },
  fetchAccomplishmentSectionPinImagesSaga
)
