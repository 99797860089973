import updateTab from '../../../reducers/updateTab'

const updateArchitectureType = reducer =>
  updateTab((tab, action) => {
    const { objectId } = action.meta
    const architectureTypes = tab.objects.map(
      architectureType =>
        architectureType.id === objectId
          ? { ...architectureType, ...reducer(architectureType, action) }
          : architectureType
    )

    return {
      ...tab,
      objects: architectureTypes
    }
  })

export default updateArchitectureType
