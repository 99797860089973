import { connect } from 'react-redux'
import { default as HiddenProjectListComponent } from './HiddenProjectList'
import { deleteProject, unHideProject } from '../../actions'
import { hiddenProjectsProp, projectsErrorProp } from '../../reducers/selectors'

const mapStateToProps = state => {
  return {
    hiddenProjects: hiddenProjectsProp(state),
    error: projectsErrorProp(state)
  }
}

const HiddenProjectList = connect(
  mapStateToProps,
  {
    deleteProject,
    unHideProject
  }
)(HiddenProjectListComponent)

export default HiddenProjectList
