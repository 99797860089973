import React, { useState } from 'react'
import { CLIENTS } from '../'
import { ActionPopup } from '../../../components/UniversalPopup'

export const ClientVersion = ({ version, deleteClientVersion }) => {
  const [shownDeletePopup, setShownDeletePopup] = useState()

  const onDeleteHandler = () => {
    deleteClientVersion(version.id)
    setShownDeletePopup(false)
  }

  return (
    <div className="clients-versions__row">
      <div>{CLIENTS[version.client]}</div>
      <div>{`${version.major}.${version.minor}.${version.patch}`}</div>
      <div>
        {version.mandatory && (
          <span className="clients-versions__icon clients-versions__icon--check" />
        )}
      </div>
      <div>
        <button
          onClick={() => setShownDeletePopup(true)}
          className="btn btn--noPadding btn--delete"
          title="Удалить пользователя"
        />
      </div>
      {shownDeletePopup && (
        <ActionPopup
          title={() => 'Подтвердить удаление?'}
          confirmText="Да"
          onConfirm={onDeleteHandler}
          onCancel={() => setShownDeletePopup(false)}
          isOpened={shownDeletePopup}
        />
      )}
    </div>
  )
}
