import { connect } from 'react-redux'
import { submit } from './actions'
import CreateImageSetForm from './components'

export default connect(
  null,
  {
    submit
  }
)(CreateImageSetForm)
