import updateProject from '../../../reducers/updateProject'

const updateGallery = galleryReducer =>
  updateProject((project, action) => {
    const {
      meta: { galleryId }
    } = action
    const galleries = project.constructionProgressSection.galleries.map(
      gallery => {
        if (gallery.id === galleryId) {
          return galleryReducer(gallery, action)
        }

        return gallery
      }
    )

    const newProject = {
      ...project,
      constructionProgressSection: {
        ...project.constructionProgressSection,
        galleries
      }
    }

    return newProject
  })

export default updateGallery
