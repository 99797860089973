import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_ARCHITECTURE_CREATE_ARCHITECTURE_TYPE_SUBMIT_SUCCESS } from '../actions'

function* watchCreateObjectSaga(projectId, tabId) {
  while (true) {
    const {
      payload: { id }
    } = yield take(PROJECT_ARCHITECTURE_CREATE_ARCHITECTURE_TYPE_SUBMIT_SUCCESS)
    yield put(
      push(
        `/projects/${projectId}/architecture/tabs/${tabId}/architecture-type/${id}`
      )
    )
  }
}

export default watchCreateObjectSaga
