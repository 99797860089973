import createSectionPage from '../../createSectionPage'
import DecorationSectionPage from './components'
import { submit } from './actions'

const DecorationSectionRoutePage = createSectionPage(
  'decoration',
  null,
  submit
)(DecorationSectionPage)

export default DecorationSectionRoutePage
