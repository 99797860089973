import createImageUpload from './createImageUpload'
import createImageUploadFormField from './createImageUploadFormField'

export const Image = createImageUploadFormField(createImageUpload())
export const ImageiOS2x = createImageUploadFormField(createImageUpload('@2x'))
export const ImageiOS3x = createImageUploadFormField(createImageUpload('@3x'))

export const ImageAndroidSvg = createImageUploadFormField(
  createImageUpload('svg')
)
export const ImageMobile = createImageUploadFormField(
  createImageUpload('mobile')
)
export const ImageTablet = createImageUploadFormField(
  createImageUpload('tablet')
)

export const Video = createImageUploadFormField(createImageUpload('video'))
