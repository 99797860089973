import { connect } from 'react-redux'
import { default as ConstructionProgressForm } from './ConstructionProgressForm'
import { submit } from '../../actions'

export default connect(
  null,
  {
    submit
  }
)(ConstructionProgressForm)
