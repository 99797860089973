import { fork, call } from 'redux-saga/effects'
import watchCreateObjectSaga from './watchCreateObjectSaga'
import fetchPublicSpacesSectionSaga from '../../../../../sagas/fetchPublicSpacesSectionSaga'

const createObjectSagaRoute = {
  '/projects/:projectId/publicSpaces/tabs/:tabId/publicSpaces-type': function* runCreatePublicSpacesTypeSaga({
    projectId,
    tabId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const tabIdNumber = parseInt(tabId, 10)
    const publicSpacesSection = yield call(
      fetchPublicSpacesSectionSaga,
      projectIdNumber
    )
    if (publicSpacesSection) {
      yield fork(watchCreateObjectSaga, projectIdNumber, tabIdNumber)
    }
  }
}

export default createObjectSagaRoute
