import {
  PROJECT_ADVANTAGES_SECTION_SUCCESS,
  PROJECT_ADVANTAGES_SECTION_FAILURE,
  PROJECT_ADVANTAGES_SECTION_SUBMIT_SUCCESS,
  PROJECT_ADVANTAGES_SECTION_SUBMIT_FAILURE
} from '../actions'
import createSectionReducer from '../../../reducers/createSectionReducer'

const advantageSectionReducer = createSectionReducer(
  'advantagesSection',
  [PROJECT_ADVANTAGES_SECTION_SUCCESS, PROJECT_ADVANTAGES_SECTION_FAILURE],
  [
    PROJECT_ADVANTAGES_SECTION_SUBMIT_SUCCESS,
    PROJECT_ADVANTAGES_SECTION_SUBMIT_FAILURE
  ]
)

export default advantageSectionReducer
