import { put, all, fork, select } from 'redux-saga/effects'
import { fetchPinImageMetaData } from '../actions'
import { generalSectionProp } from '../reducers/selectors'

function* fetchPinImageMetaDataSaga(projectId, pinImageMeta) {
  const { metaUrl, type } = pinImageMeta
  if (metaUrl) {
    yield put(fetchPinImageMetaData(projectId, type, metaUrl))
  }
}

function* fetchGeneralSectionPinImagesSaga(projectId, generalSection) {
  const images = Object.keys(generalSection)
    .filter(key => key.endsWith('Image'))
    .map(key => {
      return {
        ...generalSection[key],
        type: key
      }
    })
    .filter(t => t.metaUrl)

  const fetchTasks = images.map(pinImageMeta =>
    fork(fetchPinImageMetaDataSaga, projectId, pinImageMeta)
  )
  yield all(fetchTasks)
}

export function* fetchPinImageFilesSaga(projectId) {
  const originalGeneralSection = yield select(generalSectionProp(projectId))
  yield fork(
    fetchGeneralSectionPinImagesSaga,
    projectId,
    originalGeneralSection
  )
}

export default fetchGeneralSectionPinImagesSaga
