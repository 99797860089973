import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'

export const PROJECT_TEAM_CREATE_OBJECT_SUBMIT_REQUEST =
  'PROJECT_TEAM_CREATE_OBJECT_SUBMIT_REQUEST'
export const PROJECT_TEAM_CREATE_OBJECT_SUBMIT_SUCCESS =
  'PROJECT_TEAM_CREATE_OBJECT_SUBMIT_SUCCESS'
export const PROJECT_TEAM_CREATE_OBJECT_SUBMIT_FAILURE =
  'PROJECT_TEAM_CREATE_OBJECT_SUBMIT_FAILURE'

export const submit = (projectId, teamObject) => {
  const success = {
    type: PROJECT_TEAM_CREATE_OBJECT_SUBMIT_SUCCESS,
    meta: {
      projectId,
      teamObject
    }
  }
  const formData = objectToFormData(teamObject)
  return {
    [RSAA]: {
      endpoint: `api/Projects/${projectId}/team/objects`,
      method: 'post',
      headers: {},
      body: formData,
      types: [
        PROJECT_TEAM_CREATE_OBJECT_SUBMIT_REQUEST,
        success,
        PROJECT_TEAM_CREATE_OBJECT_SUBMIT_FAILURE
      ]
    }
  }
}
