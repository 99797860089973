import React from 'react'

const ExpandIcon = ({ isExpanded }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      className={`publicationItem__expand publicationItem__expand--${isExpanded}`}
    >
      <path fill="#d8d8d8" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </svg>
  )
}
export default ExpandIcon
