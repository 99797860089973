import {
  PROJECT_PUBLIC_SPACES_CREATE_PUBLIC_SPACES_TYPE_SUBMIT_SUCCESS,
  PROJECT_PUBLIC_SPACES_CREATE_PUBLIC_SPACES_TYPE_SUBMIT_FAILURE
} from '../actions'
import updateTab from '../../../reducers/updateTab'

const createPublicSpacesTypeReducer = {
  [PROJECT_PUBLIC_SPACES_CREATE_PUBLIC_SPACES_TYPE_SUBMIT_SUCCESS]: updateTab(
    (tab, { payload }) => {
      const publicSpacesTypes = [...tab.objects, payload]
      return {
        ...tab,
        objects: publicSpacesTypes
      }
    }
  ),
  [PROJECT_PUBLIC_SPACES_CREATE_PUBLIC_SPACES_TYPE_SUBMIT_FAILURE]: (
    state,
    action
  ) => {
    return {
      ...state,
      error: action.payload.response
    }
  }
}

export default createPublicSpacesTypeReducer
