import { connect } from 'react-redux'
import { submit } from './actions'
import { default as CreateObjectForm } from './components/CreateObjectForm'
import { objects } from '../../../../../../../Objects/reducers/selectors'

const mapStateToProps = state => {
  const objectsProps = objects(state)
  const objectTypes = objectsProps.map(object => ({
    name: object.objectType,
    value: object.objectType
  }))
  return {
    objectTypes
  }
}

export default connect(
  mapStateToProps,
  { submit }
)(CreateObjectForm)
