import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_IMAGE_SET_SUBMIT_SUCCESS } from '../actions'

function* watchImageSetUpdatedSaga(projectId) {
  while (true) {
    yield take(
      PROJECT_CONSTRUCTION_PROGRESS_EDIT_GALLERY_IMAGE_SET_SUBMIT_SUCCESS
    )
    yield put(push(`/projects/${projectId}/construction`))
  }
}

export default watchImageSetUpdatedSaga
