import React, { useCallback } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import { formWithError } from '../../../../../../../components/Validation'
import { FormField, TextareaField } from '../../../../../../../components/Form'
import { SubmitButton } from '../../../../../components/Buttons'
import { Link } from 'react-router-dom'

const focusOnErrors = createDecorator()

const PushForm = props => {
  const { submit, loading = false } = props
  const onSubmit = useCallback(
    form => {
      const { data = [], ...rest } = form
      const [dataUrl] = data
      if (dataUrl && dataUrl.url) {
        const { url } = dataUrl
        submit({
          data: [{ key: 'url', value: url }],
          ...rest
        })
      } else {
        submit({ ...rest })
      }
    },
    [submit]
  )

  return (
    <Form
      mutators={{
        ...arrayMutators
      }}
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      render={({
        handleSubmit,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit
      }) => {
        return (
          <div className="pageWrapper">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col">
                  <Link to={`/pushes`} className="formField__back">
                    Назад
                  </Link>
                </div>
              </div>
              <hr className="formField__separator" />
              <div className="row">
                <div className="col">
                  <h4>Push-уведомление</h4>
                </div>
              </div>
              <div className="row">
                <div className="col formField">
                  <div className="aboutForm__titleContainer">
                    <FormField name="title" type="text" label="Заголовок" />
                  </div>
                  <TextareaField name="text" label="Текст" maxLength={500} />
                  <FormField name="data[0].url" type="text" label="Ссылка" />
                </div>
              </div>

              <SubmitButton
                text={loading ? 'Отправляем...' : 'Отправить'}
                pristine={pristine}
                submitSucceeded={submitSucceeded}
                dirtySinceLastSubmit={dirtySinceLastSubmit}
                disabled={loading}
              />
            </form>
          </div>
        )
      }}
    />
  )
}

export default formWithError(PushForm)
