import { call } from 'redux-saga/effects'
import { fetchLocationSectionSaga } from '../../../sagas'

function* fetchLocationObjectSaga(projectId, objectId) {
  const locationSection = yield call(fetchLocationSectionSaga, projectId)
  if (locationSection) {
    const { accomplishmentObjects = [] } = locationSection
    const locationObject = accomplishmentObjects.find(t => t.id === objectId)
    if (locationObject) {
      return locationObject
    }
  }

  return null
}

export default fetchLocationObjectSaga
