import moment from 'moment'
import { updateProject } from '../../../reducers/createSectionReducer'
import { createPinImageUrlReducer } from '../../../../../reducers/createProjectReducer'

export const setPinImageUrl = createPinImageUrlReducer('documentsSection')

export const setDocumentUrl = (state, action) => {
  const { document } = action.meta
  const reducer = updateProject(project => {
    return {
      ...project,
      documentsSection: {
        ...project.documentsSection,
        documents: project.documentsSection.documents.map(t => {
          if (t.id === document.id) {
            return {
              ...t,
              file: {
                ...t.file,
                ...action.payload
              }
            }
          }

          return t
        })
      }
    }
  })
  return reducer(state, action)
}

export const setSection = defaultReducer => (state, action) => {
  const newState = defaultReducer ? defaultReducer(state, action) : state
  return updateProject(project => {
    const { documentsSection } = action.meta
    return {
      ...project,
      documentsSection
    }
  })(newState, action)
}

export const setDocuments = updateProject((project, action) => {
  const { payload = { documents: null }, meta = { documents: [] } } = action

  var metaDocuments = meta.documents
  if (!payload.documents) {
    const documentsSection = {
      ...project.documentsSection,
      documents: metaDocuments
    }
    return {
      ...project,
      documentsSection
    }
  }

  const documentsSection = {
    ...project.documentsSection,
    documents: payload.documents.map(document => {
      var metaDocument = metaDocuments.find(t => {
        if (t.id === document.id) {
          return true
        }
        if (t.name !== document.name) {
          return false
        }
        const date = moment.utc(document.date).toDate()
        return date
      })
      if (metaDocument) {
        const newDocument = {
          ...document,
          ...metaDocument,
          id: document.id
        }
        return newDocument
      }

      return document
    })
  }
  return {
    ...project,
    documentsSection
  }
})

export const setDocumentFile = updateProject(state => state)
