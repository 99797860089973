import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import { formWithError, phone } from '../../../../../components/Validation'
import { FormField } from '../../../../../components/Form'
import { TextareaField } from '../../../../../components/Form'
import { SubmitButton } from '../../../components/Buttons'
import { Video } from '../../../components/ImageUpload'
import ImageSetLink from './ImageSetLink'
import {
  SmallIconImageFormField,
  ImageSetArrayFormField
} from '../../../components/Form'
import './aboutForm.css'

const focusOnErrors = createDecorator()

class AboutForm extends Component {
  onSubmit = about => {
    const { submit } = this.props
    submit(about)
  }

  render() {
    const { about } = this.props

    return (
      <Form
        initialValues={about}
        mutators={{
          ...arrayMutators
        }}
        onSubmit={this.onSubmit}
        decorators={[focusOnErrors]}
        render={({
          handleSubmit,
          submitting,
          pristine,
          submitSucceeded,
          dirtySinceLastSubmit,
          values,
          form
        }) => {
          return (
            <div>
              <form onSubmit={handleSubmit}>
                <div className="formField">
                  <FormField
                    name="phoneNumber"
                    type="text"
                    label="Телефон компании"
                    placeholder="+7 (ХХХ) XXX-XX-XX"
                    validate={phone}
                  />
                </div>
                <hr className="formField__separator" />
                <h4>Блок 1</h4>
                <div className="aboutForm__row">
                  <div className="formField">
                    <ImageSetLink
                      blockId={1}
                      previewImageUrl={values.block1.previewImageUrl}
                    />
                  </div>
                  <div className="aboutForm__wide formField">
                    <div className="aboutForm__titleContainer">
                      <FormField
                        name="block1.title"
                        type="text"
                        label="Заголовок"
                      />
                    </div>
                    <TextareaField
                      className="aboutForm__textarea"
                      name="block1.description"
                      label="Описание"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h4>Блок 2</h4>
                  </div>
                </div>
                <div className="aboutForm__row">
                  <div className="formField aboutForm__narrow">
                    <ImageSetArrayFormField
                      form={form}
                      name="block2.images"
                      addImageSetUrl={`about/blocks/2/images`}
                      editImageSetUrl={id => `about/blocks/2/images/${id}`}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col formField">
                    <div className="aboutForm__titleContainer">
                      <FormField
                        name="block2.title"
                        type="text"
                        label="Заголовок"
                      />
                    </div>
                    <TextareaField
                      className="aboutForm__textarea"
                      name="block2.description"
                      label="Описание"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h4>Блок 3</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-9 formField">
                    <SmallIconImageFormField
                      label=""
                      form={form}
                      prefix="block3"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col formField">
                    <TextareaField
                      className="aboutForm__textarea"
                      name="block3.description"
                      label="Описание"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h4>Блок 4</h4>
                  </div>
                </div>
                <div className="aboutForm__row">
                  <div className="formField">
                    <Video
                      name="video"
                      size="big"
                      form={form}
                      maxSize={524288000}
                      accept={['video/mp4']}
                    />
                  </div>
                  <div className="aboutForm__wide formField">
                    <div className={'aboutForm__titleContainer'}>
                      <FormField
                        name="block4.title"
                        type="text"
                        label="Заголовок"
                      />
                    </div>
                    <TextareaField
                      className="aboutForm__textarea"
                      name="block4.description"
                      label="Описание"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h4>Блок 5</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-9 formField">
                    <SmallIconImageFormField
                      label=""
                      form={form}
                      prefix="block5"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col formField">
                    <TextareaField
                      className="aboutForm__textarea"
                      name="block5.description"
                      label="Описание"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h4>Блок 6</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col formField">
                    <TextareaField
                      className="aboutForm__textarea"
                      name="block6.description"
                      label="Описание"
                    />
                  </div>
                </div>
                <SubmitButton
                  pristine={pristine}
                  submitSucceeded={submitSucceeded}
                  dirtySinceLastSubmit={dirtySinceLastSubmit}
                />
              </form>
            </div>
          )
        }}
      />
    )
  }
}

AboutForm.propTypes = {
  about: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired
}

export default formWithError(AboutForm)
