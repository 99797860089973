import { put, all, fork, select } from 'redux-saga/effects'
import { fetchPinImageMetaData } from '../actions'
import { publicSpacesSectionProp } from '../reducers/selectors'

function* fetchPinImageMetaDataSaga(projectId, iconType, pinImageMeta) {
  const { metaUrl, type } = pinImageMeta
  if (metaUrl) {
    yield put(fetchPinImageMetaData(projectId, iconType, type, metaUrl))
  }
}

function* fetchPublicSpacesSectionPinImagesSaga(
  projectId,
  publicSpacesSection
) {
  const images = Object.keys(publicSpacesSection)
    .filter(key => key.endsWith('Image'))
    .map(key => {
      return {
        ...publicSpacesSection[key],
        type: key,
        iconType: key.startsWith('map') ? 'Map' : 'Icon'
      }
    })
    .filter(t => t.metaUrl)

  const fetchTasks = images.map(pinImageMeta =>
    fork(
      fetchPinImageMetaDataSaga,
      projectId,
      pinImageMeta.iconType,
      pinImageMeta
    )
  )
  yield all(fetchTasks)
}

export function* fetchPinImageFilesSaga(projectId) {
  const originalPublicSpacesSection = yield select(
    publicSpacesSectionProp(projectId)
  )

  yield fork(
    fetchPublicSpacesSectionPinImagesSaga,
    projectId,
    'Map',
    originalPublicSpacesSection
  )
  yield fork(
    fetchPublicSpacesSectionPinImagesSaga,
    projectId,
    'Icon',
    originalPublicSpacesSection
  )
}

export default fetchPublicSpacesSectionPinImagesSaga
