import { call, fork } from 'redux-saga/effects'
import { fetchTeamSectionSaga } from '../../../sagas'
import fetchTeamObjectPinImagesSaga from './fetchTeamObjectPinImagesSaga'

function* fetchTeamObjectSaga(projectId, objectId) {
  const teamSection = yield call(fetchTeamSectionSaga, projectId)
  if (teamSection) {
    const { teamObjects = [] } = teamSection
    const teamObject = teamObjects.find(t => t.id === objectId)
    if (teamObject) {
      yield fork(fetchTeamObjectPinImagesSaga, projectId, teamObject)
      return teamObject
    }
  }

  return null
}

export default fetchTeamObjectSaga
