import { fork } from 'redux-saga/effects'
import fetchProjectsSaga from './fetchProjectsSaga'

const projectListSagaRoute = {
  '/projects': function* runProjectsSaga() {
    yield fork(fetchProjectsSaga)
  }
}

export default projectListSagaRoute
