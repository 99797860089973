import { put, call, all, select } from 'redux-saga/effects'
import { fetchRootImageMetaData } from '../actions'
import { about } from '../reducers/selectors'

function* fetchImageMetaDataSaga(imageMeta) {
  const { metaUrl, name } = imageMeta
  yield put(fetchRootImageMetaData(metaUrl, name))
}

function* fetchAboutRootImages() {
  const aboutProps = yield select(about)
  if (aboutProps) {
    const images = Object.keys(aboutProps)
      .filter(name => aboutProps[name] && aboutProps[name].metaUrl)
      .map(name => {
        return {
          name,
          ...aboutProps[name]
        }
      })

    const fetchTasks = images.map(imageMeta =>
      call(fetchImageMetaDataSaga, imageMeta)
    )
    yield all(fetchTasks)
    return true
  }

  return null
}

export default fetchAboutRootImages
