import { connect } from 'react-redux'
import { default as AdvantageSectionForm } from './AdvantageSectionForm'
import { submit } from '../../actions'

export default connect(
  null,
  {
    submit
  }
)(AdvantageSectionForm)
