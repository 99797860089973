import { connect } from 'react-redux'
import { compose } from 'recompose'
import { formWithError } from '../../../../../components/Validation'
import { projectsErrorProp } from '../reducers/selectors'

const mapErrorFromState = state => {
  const error = projectsErrorProp(state)
  return { error }
}

const projectForm = compose(
  connect(mapErrorFromState),
  formWithError
)

export default projectForm
