import { call, put, take } from 'redux-saga/effects'
import { fetchAccomplishmentSectionSaga } from '../../../sagas'
import { fetchTab, PROJECT_ACCOMPLISHMENT_TAB_SUCCESS } from '../actions'

function* fetchAccomplishmentTabSaga(projectId, tabId) {
  const accomplishmentSection = yield call(
    fetchAccomplishmentSectionSaga,
    projectId
  )
  if (accomplishmentSection) {
    yield put(fetchTab(projectId, tabId))
    const accomplishmentTab = yield take(PROJECT_ACCOMPLISHMENT_TAB_SUCCESS)
    if (accomplishmentTab) {
      return accomplishmentTab.payload
    }
  }

  return null
}

export default fetchAccomplishmentTabSaga
