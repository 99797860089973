import { default as editTabSagaRoute } from './editTabSagaRoute'
import createAccomplishmentTypeSagaRoute from '../pages/CreateAccomplishmentType/sagas'
import editAccomplishmentTypeSagaRoute from '../pages/EditAccomplishmentType/sagas'

export {
  default as watchAccomplishmentTabSubmitted
} from './watchAccomplishmentTabSubmitted'

export default {
  ...editTabSagaRoute,
  ...editAccomplishmentTypeSagaRoute,
  ...createAccomplishmentTypeSagaRoute
}
