import { default as CommentsComponent } from './components/Comments'
import { connect } from 'react-redux'
import { comments } from './reducers/selectors'
import { enhanceWithSpinner } from '../../../../../../components/Spinner'
import { filterChange, fetchComments } from './actions'

const CommentsWithSpinner = enhanceWithSpinner(
  ({ data }) => data === null || data === undefined,
  CommentsComponent
)

const mapStateToProps = state => {
  return {
    data: comments(state).data,
    filters: comments(state).filters,
    hasMore: comments(state).hasMore,
    loading: comments(state).loading
  }
}

const Comments = connect(
  mapStateToProps,
  {
    filterChange,
    fetchComments
  }
)(CommentsWithSpinner)

export default Comments
