import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { SortableElement } from 'react-sortable-hoc'
import { ActionPopup } from '../../../components/UniversalPopup'

class Item extends PureComponent {
  render() {
    const { object, showPopup } = this.props
    return (
      <div className="projectSortedList__item">
        <div className="projectSortedList__dots" title="Перенести проект" />
        <Link to={`/objects/${object.id}`} className="projectSortedList__link">
          {object.objectType}
        </Link>
        <button
          onClick={showPopup}
          className="btn btn--noPadding btn--delete"
          title="Удалить проект"
        />
      </div>
    )
  }
}

const SortableItem = SortableElement(Item)

class SortableItemWithPopup extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      popupDeleteOpened: false
    }
  }

  togglePopup = popup => {
    this.setState({ [popup]: !this.state[popup] })
  }

  createOnDelete = () => {
    const { object, submit, objects } = this.props
    const objectsForSubmit = {
      objects: objects.filter(item => item.id !== object.id)
    }
    return submit(objectsForSubmit)
  }

  render() {
    const { object, index, ...rest } = this.props
    const bodyText =
      object.linkedLocationObjects && object.linkedLocationObjects.length ? (
        <>
          <p>
            В разделе "Расположение" созданы объекты инфраструктуры с данным
            типом <b>«{object.objectType}»</b>.
          </p>
          <p>
            При удалении <b>«{object.objectType}»</b> у созданных объектов в
            разделе "Расположение" будет отсутствовать тип объекта.
          </p>
        </>
      ) : null
    const confirmationPopup = this.state.popupDeleteOpened ? (
      <ActionPopup
        title={() => 'Подтвердить удаление?'}
        body={() => bodyText}
        confirmText="Да"
        onConfirm={this.createOnDelete}
        onCancel={this.togglePopup.bind(this, 'popupDeleteOpened')}
        isOpened={this.state.popupDeleteOpened}
      />
    ) : null

    return (
      <>
        <SortableItem
          index={index}
          key={object.id}
          object={object}
          showPopup={this.togglePopup.bind(this, 'popupDeleteOpened')}
          {...rest}
        />
        {confirmationPopup}
      </>
    )
  }
}

SortableItemWithPopup.propTypes = {
  object: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}

export default SortableItemWithPopup
