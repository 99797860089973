import { fork } from 'redux-saga/effects'
import { router } from 'redux-saga-router'
import homeSagaRoute from '../pages/Home/sagas'
import loginSagaRoute from '../pages/Login/sagas'

const routesSaga = history => {
  const routes = {
    ...homeSagaRoute,
    ...loginSagaRoute
  }

  return function* createRoutesSaga() {
    yield fork(router, history, routes)
  }
}

export default routesSaga
