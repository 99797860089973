import React from 'react'
import PropTypes from 'prop-types'
import { PageTitle } from '../../../components'
import { Link, Redirect, Route, Switch } from 'react-router-dom'
import CreateObjectForm from '../pages/CreateObject'
import EditObjectForm from '../pages/EditObject'
import ObjectSortableList from './ObjectSortableList'

export const Objects = ({ objects, submit }) => {
  return (
    <div>
      <PageTitle
        title={() => {
          return <h2>Справочник объектов</h2>
        }}
      >
        <Route
          exact
          path="/objects"
          render={() => {
            return (
              <Link to="/objects/add" className="btn btn-sm btn-warning">
                Добавить
              </Link>
            )
          }}
        />
      </PageTitle>
      <Switch>
        <Route
          exact
          path={`/objects/add`}
          render={() => {
            return (
              <div className="objects objects__wrapper">
                <CreateObjectForm projectId={1} />
              </div>
            )
          }}
        />
        <Route
          path={`/objects/:id(\\d+)`}
          render={({ match: { params } }) => {
            const objectId = parseInt(params.id, 10)
            const dictionaryObject = objects.find(t => t.id === objectId)
            if (!dictionaryObject) {
              return <Redirect to="/404" />
            }
            return (
              <div className="objects objects__wrapper">
                <EditObjectForm
                  projectId={1}
                  objectId={objectId}
                  object={dictionaryObject}
                />
              </div>
            )
          }}
        />
        <Route
          path="/objects"
          render={() => {
            return <ObjectSortableList objects={objects} submit={submit} />
          }}
        />
      </Switch>
    </div>
  )
}

Objects.propTypes = {
  objects: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired
}
