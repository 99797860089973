import React from 'react'
import PropTypes from 'prop-types'
import Stars from '../Stars/Stars'

const ByStars = ({ filters = {}, filterChange, name, options }) => {
  const buttons = options.map(value => {
    const isActive = filters[name] && filters[name].includes(value)
    const isActiveClass = isActive ? 'filters__stars--active' : ''
    const newArray = isActive
      ? filters[name].filter(item => item !== value)
      : [...new Set([...filters[name], value])]
    return (
      <div
        className={`filters__stars ${isActiveClass}`}
        onClick={() => filterChange({ [name]: newArray })}
        key={value}
      >
        <Stars
          value={value}
          colorActive="#f8941e"
          size={118}
          isActive={isActive}
        />
      </div>
    )
  })

  return (
    <div className="filters">
      <header className="filters__title">Оценка</header>
      <div className="filters__group">{buttons}</div>
    </div>
  )
}

ByStars.propTypes = {
  filterChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  filters: PropTypes.object
}

export default ByStars
