import {
  PUBLICATIONS_REFRESH_REQUEST,
  PUBLICATIONS_REFRESH_SUCCESS,
  PUBLICATIONS_SUCCESS,
  PUBLICATIONS_HAS_CHANGES_REQUEST,
  PUBLICATIONS_HAS_CHANGES_SUCCESS
} from '../actions'

const initialState = null

const update = (state, action) => {
  const { payload } = action
  return payload
}

const updateByHasChanges = state => {
  const section = state === null ? { path: '', hasChanges: false } : state
  const { publication = {} } = section
  const newSection = {
    ...section,
    publication: {
      ...publication,
      subStatus: 'CheckingForChanges'
    }
  }

  return newSection
}

const updateByPublicate = state => {
  const section = state === null ? { path: '', hasChanges: false } : state
  const { publication = {} } = section
  const newSection = {
    ...section,
    publication: {
      ...publication,
      status: 'Started'
    }
  }

  return newSection
}

const mergedReducer = {
  [PUBLICATIONS_REFRESH_REQUEST]: updateByPublicate,
  [PUBLICATIONS_REFRESH_SUCCESS]: update,
  [PUBLICATIONS_SUCCESS]: update,
  [PUBLICATIONS_HAS_CHANGES_REQUEST]: updateByHasChanges,
  [PUBLICATIONS_HAS_CHANGES_SUCCESS]: update
}

const publicationsReducer = (state = initialState, action) => {
  let actionReducer = mergedReducer[action.type]
  if (actionReducer) {
    return actionReducer(state, action)
  }

  return state
}

export default publicationsReducer
