import { default as constructionProgressSagaRoute } from './constructionProgressSagaRoute'
import createGallerySagaRoute from '../pages/CreateGallery/sagas'
import editGallerySagaRoute from '../pages/EditGallery/sagas'
export {
  default as fetchConstructionProgressSaga
} from './fetchConstructionProgressSaga'
export {
  default as watchGalleryImageSetSubmitted
} from '../pages/EditGallery/pages/EditImageSet/sagas/watchImageSetSubmitted'

export default {
  ...createGallerySagaRoute,
  ...editGallerySagaRoute,
  ...constructionProgressSagaRoute
}
