import React from 'react'
import { connect } from 'react-redux'
import { enhanceWithSpinner } from '../../../../components'
import { ClientVersionsList } from './components'
import { createClientVersion, deleteClientVersion } from './actions'

export const CLIENTS = {
  '1': 'Android',
  '2': 'IOS'
}

const ClientVersionsComponent = ({
  versions,
  createClientVersion,
  deleteClientVersion,
  loadMore
}) => (
  <ClientVersionsList
    versions={versions}
    createClientVersion={createClientVersion}
    deleteClientVersion={deleteClientVersion}
    loadMore={loadMore}
  />
)

const ClientVersionsWithSpinner = enhanceWithSpinner(
  ({ versions }) => versions.list === null,
  ClientVersionsComponent
)

const ClientVersions = connect(
  state => ({
    versions: state.clientsVersions
  }),
  {
    createClientVersion,
    deleteClientVersion,
    loadMore: () => ({ type: 'CLIENTS_VERSIONS_MORE' })
  }
)(ClientVersionsWithSpinner)

export default ClientVersions
