import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import './subnav.css'

const SubNav = ({ projectId }) => {
  return (
    <div className="subNav">
      <NavLink
        to={`/projects/${projectId}/general`}
        className="subNav__link"
        activeClassName="subNav__link--active"
      >
        Общая информация
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/images`}
        className="subNav__link"
        activeClassName="subNav__link--active"
      >
        Изображения
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/about`}
        className="subNav__link"
        activeClassName="subNav__link--active"
      >
        О проекте
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/construction`}
        className="subNav__link"
        activeClassName="subNav__link--active"
      >
        Ход строительства
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/advantages`}
        className="subNav__link"
        activeClassName="subNav__link--active"
      >
        Преимущества
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/location`}
        className="subNav__link"
        activeClassName="subNav__link--active"
      >
        Расположение
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/accomplishment`}
        className="subNav__link"
        activeClassName="subNav__link--active"
      >
        Благоустройство
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/architecture`}
        className="subNav__link"
        activeClassName="subNav__link--active"
      >
        Архитектура
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/publicSpaces`}
        className="subNav__link"
        activeClassName="subNav__link--active"
      >
        Места публичного пользования
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/decoration`}
        className="subNav__link"
        activeClassName="subNav__link--active"
      >
        Отделка
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/team`}
        className="subNav__link"
        activeClassName="subNav__link--active"
      >
        Команда проекта
      </NavLink>
      <NavLink
        to={`/projects/${projectId}/documents`}
        className="subNav__link"
        activeClassName="subNav__link--active"
      >
        Документы по проекту
      </NavLink>
    </div>
  )
}

SubNav.propTypes = {
  projectId: PropTypes.number.isRequired
}

export default memo(SubNav)
