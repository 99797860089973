import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { Transition } from 'react-transition-group'
import { TweenMax } from 'gsap/all'
import {
  required,
  formWithError,
  validate,
  numbers
} from '../../../../../components/Validation'
import { FormField } from '../../../../../components/Form'
import { Select } from '../../../components'
import { CLIENTS } from '../'

const AddClientVersionForm = ({ show, setShownAddForm, onFormSubmit }) => {
  const startState = { autoAlpha: 0, y: -120 }
  const [client, setClient] = useState()
  const [clientError, setClientError] = useState()

  const onCancel = () => {
    setShownAddForm(false)
  }

  const onSubmit = form => {
    form = {
      client,
      ...form
    }
    setClientError(!client)
    if (client) {
      onFormSubmit(form)
      setShownAddForm(false)
    }
  }

  const onClientChange = ({ target: { value } }) => {
    setClient(value)
    setClientError(!value)
  }

  return (
    <Transition
      unmountOnExit
      in={show}
      timeout={1000}
      onEnter={node => TweenMax.set(node, startState)}
      addEndListener={(node, done) => {
        TweenMax.to(node, 0.5, {
          autoAlpha: show ? 1 : 0,
          y: show ? 0 : -120,
          onComplete: done
        })
      }}
    >
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit} className="add-client-version-form">
            <div className="add-client-version-form__cell">
              <Select
                options={[
                  { label: 'Клиент', value: '' },
                  ...Object.entries(CLIENTS).map(([value, label]) => ({
                    label,
                    value
                  }))
                ]}
                defaultValue=""
                onChange={onClientChange}
                className="form-control form-control-sm"
              />
              {clientError && (
                <div className="formField__error">Выберите версию</div>
              )}
            </div>
            <div className="add-client-version-form__cell">
              <FormField
                name="major"
                type="text"
                label=""
                placeholder="Версия (major)"
                validate={validate(required, numbers)}
                className="form-control form-control-sm "
              />
            </div>
            <div className="add-client-version-form__cell">
              <FormField
                name="minor"
                type="text"
                label=""
                placeholder="Версия (minor)"
                validate={validate(required, numbers)}
                className="form-control form-control-sm "
              />
            </div>
            <div className="add-client-version-form__cell">
              <FormField
                name="patch"
                type="text"
                label=""
                placeholder="Версия (patch)"
                validate={validate(required, numbers)}
                className="form-control form-control-sm "
              />
            </div>
            <div className="checkbox-control">
              <FormField
                name="mandatory"
                type="checkbox"
                label="Обязательная"
                className=""
              />
            </div>
            <div className="buttons">
              <button
                className="btn btn-sm btn-success"
                type="submit"
                disabled={submitting || pristine}
              >
                Сохранить
              </button>
              {!submitting && (
                <div className="btn btn-sm btn-danger" onClick={onCancel}>
                  Отменить
                </div>
              )}
            </div>
          </form>
        )}
      />
    </Transition>
  )
}

export default formWithError(AddClientVersionForm)
