import EditImageSetForm from './components/EditImageSetForm'
import { connect } from 'react-redux'
import { projectProp } from '../../../../../../reducers/selectors'
import imageSetProp from './reducers/selectors'
import { submit } from './actions'
import { default as EditImageSetFormComponent } from './components'

const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.project.id
  const imageSetId = parseInt(ownProps.match.params.imageSetId, 10)
  return {
    project: projectProp(projectId)(state),
    imageSetId,
    images: imageSetProp(projectId, imageSetId)(state)
  }
}

export const EditListImageSetForm = connect(
  mapStateToProps,
  { submit }
)(EditImageSetFormComponent)

export default EditImageSetForm
