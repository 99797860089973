import React, { PureComponent } from 'react'

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      info: null,
      error: null
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, info, error })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong.</h1>
          <h3>{this.state.error.message}</h3>
          <div>{this.state.info.componentStack}</div>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
