import { PUBLIC_SPACES_CHANGE } from 'connected-react-router'
import { takeLatest, take, fork } from 'redux-saga/effects'
import { PROJECT_PUBLIC_SPACES_EDIT_TAB_SUBMIT_SUCCESS } from '../actions'
import fetchPublicSpacesTabSaga from './fetchPublicSpacesTabSaga'

function* refreshImages(action) {
  const {
    meta: { projectId, tabId }
  } = action
  yield take(PUBLIC_SPACES_CHANGE)
  yield fork(fetchPublicSpacesTabSaga, projectId, tabId)
}

function* watchImageSetSubmitted() {
  yield takeLatest(PROJECT_PUBLIC_SPACES_EDIT_TAB_SUBMIT_SUCCESS, refreshImages)
}

export default watchImageSetSubmitted
