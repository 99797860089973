import {
  fetchProjectImagesSection,
  PROJECT_IMAGES_SECTION_SUCCESS,
  PROJECT_IMAGES_SECTION_FAILURE
} from '../actions'
import createSectionSaga from '../../../sagas/createSectionSaga'

export default createSectionSaga(
  fetchProjectImagesSection,
  [PROJECT_IMAGES_SECTION_SUCCESS, PROJECT_IMAGES_SECTION_FAILURE],
  project => project.imagesSection,
  {
    sectionName: 'Изображения',
    projectListImages: [],
    projectCardImages: []
  }
)
