import {
  PROJECT_CONSTRUCTION_PROGRESS_SUCCESS,
  PROJECT_CONSTRUCTION_PROGRESS_FAILURE,
  PROJECT_CONSTRUCTION_PROGRESS_SUBMIT_SUCCESS,
  PROJECT_CONSTRUCTION_PROGRESS_SUBMIT_FAILURE
} from '../actions'
import createSectionReducer from '../../../reducers/createSectionReducer'

const constructionProgressFormReducer = createSectionReducer(
  'constructionProgressSection',
  [
    PROJECT_CONSTRUCTION_PROGRESS_SUCCESS,
    PROJECT_CONSTRUCTION_PROGRESS_FAILURE
  ],
  [
    PROJECT_CONSTRUCTION_PROGRESS_SUBMIT_SUCCESS,
    PROJECT_CONSTRUCTION_PROGRESS_SUBMIT_FAILURE
  ]
)

export default constructionProgressFormReducer
