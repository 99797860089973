import { default as projectArchitectureSectionSagaRoute } from './projectArchitectureSectionSagaRoute'
import createTabSagaRoute from '../pages/CreateTab/sagas'
import editTabSagaRoute from '../pages/EditTab/sagas'

export {
  default as fetchArchitectureSectionSaga
} from './fetchArchitectureSectionSaga'
export { watchArchitectureTabSubmitted } from '../pages/EditTab/sagas'

export default {
  ...editTabSagaRoute,
  ...createTabSagaRoute,
  ...projectArchitectureSectionSagaRoute
}
