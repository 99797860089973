import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import { required } from '../../../../../../../../../../components/Validation'
import projectForm from '../../../../../../components/projectForm'
import { FormField } from '../../../../../../../../../../components/Form'
import { SubmitButton } from '../../../../../../../../components/Buttons'
import DocumentArrayFormField from '../DocumentArrayFormField'
import { SmallIconImageFormField } from '../../../../../../../../components/Form'

const focusOnErrors = createDecorator()

const onSubmit = (projectId, submit) => documentsSection => {
  submit(projectId, documentsSection)
}

const DocumentsForm = props => {
  const { projectId, submit, documentsSection } = props

  if (documentsSection.documents.length === 0) {
    documentsSection.documents.push({
      name: ''
    })
  }
  return (
    <Form
      initialValues={documentsSection}
      mutators={{
        ...arrayMutators
      }}
      onSubmit={onSubmit(projectId, submit)}
      decorators={[focusOnErrors]}
      render={({
        handleSubmit,
        submitting,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit,
        form
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col formField">
                <FormField
                  name="sectionName"
                  validate={required}
                  type="text"
                  label="Название раздела"
                  placeholder="Название раздела"
                />
              </div>
            </div>
            <SmallIconImageFormField
              form={form}
              prefix="section"
              label="Иконка раздела"
            />
            <SmallIconImageFormField
              form={form}
              prefix="document"
              label="Иконка документа"
            />
            <div className="row">
              <div className="col">
                <DocumentArrayFormField form={form} />
              </div>
            </div>
            <SubmitButton
              pristine={pristine}
              submitSucceeded={submitSucceeded}
              dirtySinceLastSubmit={dirtySinceLastSubmit}
            />
          </form>
        )
      }}
    />
  )
}

DocumentsForm.propTypes = {
  submit: PropTypes.func.isRequired
}

export default projectForm(DocumentsForm)
