import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_SUBMIT_SUCCESS } from '../actions'
import { fetchSection } from '../../../actions'

function* watchCreateGallerySaga(projectId) {
  while (true) {
    const {
      payload: { id }
    } = yield take(PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_SUBMIT_SUCCESS)
    yield put(fetchSection(projectId))
    yield put(push(`/projects/${projectId}/construction/galleries/${id}`))
  }
}

export default watchCreateGallerySaga
