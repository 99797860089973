import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { branch, renderComponent } from 'recompose'
import { Hover } from '../../../../components'
import { ErrorPopup } from '../../components/UniversalPopup'
import addImageIcon from '../../../../images/icn-add-img.svg'
import './styles.css'

const Image = ({ image }) => {
  const imageUrl = image ? image.url || image.preview : image
  const isVideo = image.type === 'video'
  const videoClass = isVideo ? 'imageUpload__image--video' : ''
  return (
    <div
      className={`imageUpload__image ${videoClass}`}
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      {isVideo ? 'Видеоролик добавлен' : ''}
    </div>
  )
}

const isNotEmptyImage = props => {
  return (
    (props.image !== null && props.image.preview) ||
    (props.image !== null &&
      props.image.url !== null &&
      props.image.url !== undefined)
  )
}

const createImageUpload = platform => {
  const EmptyImage = () => {
    return [
      <div key="add" className="imageUpload__add">
        <img src={addImageIcon} alt="Добавить" />
      </div>,
      <div key="label" className="imageUpload__tag">
        <label>{platform}</label>
      </div>
    ]
  }

  const NotEmptyImage = ({ image, onRemove }) => {
    const name = image.name ? image.name : ''
    return (
      <Hover
        overflow={
          <div className="imageUpload__overlay">
            <div className="imageUpload__tag imageUpload__tag--light">
              {platform}
            </div>
            <div className="imageUpload__fileName">{name}</div>
            <div className="imageUpload__description">
              Перетащите или кликните для замены
            </div>
            <div className="imageUpload__delete" onClick={onRemove}>
              Удалить
            </div>
          </div>
        }
      >
        <Image image={image} />
      </Hover>
    )
  }

  const ImageFile = branch(
    props => isNotEmptyImage(props),
    renderComponent(NotEmptyImage)
  )(EmptyImage)

  class ImageUpload extends PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        popupErrorOpened: false
      }
    }

    onDrop = (files, rejectedFiles) => {
      const { maxSize = 5242880 } = this.props
      rejectedFiles.forEach(file => window.URL.revokeObjectURL(file.preview))

      if (rejectedFiles[0] && rejectedFiles[0].size >= maxSize) {
        this.setState({ popupErrorOpened: !this.state.popupErrorOpened })
      }
      this.props.onDrop(files[0])
    }

    onRemove = event => {
      event.stopPropagation()
      this.props.onRemove()
    }

    render() {
      const {
        image = null,
        size = 'small',
        hasError = false,
        minSize = 1,
        maxSize = 5242880,
        accept = [
          'image/png',
          'image/jpeg',
          'image/svg+xml',
          'application/pdf',
          'image/svg'
        ]
      } = this.props
      const errorClass = hasError ? 'imageUpload__dropZone--error' : ''
      const errorPopup = this.state.popupErrorOpened ? (
        <ErrorPopup
          title={() => 'Ошибка!'}
          body={() =>
            `Максимальный рамер файла не должен превышать ${maxSize /
              1048576}Mb`
          }
          confirmText="Ок"
          onConfirm={() =>
            this.setState({ popupErrorOpened: !this.state.popupErrorOpened })
          }
          isOpened={this.state.popupErrorOpened}
        />
      ) : null

      return (
        <div className={`imageUpload imageUpload--${size}`}>
          <Dropzone
            onDrop={this.onDrop}
            multiple={false}
            minSize={minSize}
            maxSize={maxSize}
            accept={accept}
            className={`imageUpload__dropZone ${errorClass}`}
          >
            <ImageFile image={image} onRemove={this.onRemove} />
          </Dropzone>
          {errorPopup}
        </div>
      )
    }
  }

  ImageUpload.propTypes = {
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onDrop: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    size: PropTypes.oneOf(['big', 'small'])
  }

  return ImageUpload
}

export default createImageUpload
