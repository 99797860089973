import React from 'react'
import PropTypes from 'prop-types'

const FormError = ({ error }) => {
  if (!error) {
    return null
  }
  const keys = Object.keys(error)
  return keys.map(key => (
    <div key={key} className="alert alert-danger" role="alert">
      {error[key]}
    </div>
  ))
}

FormError.propTypes = {
  error: PropTypes.object
}

export default FormError
