import { fork, call } from 'redux-saga/effects'
import watchCreateGallerySaga from './watchCreateGallerySaga'
import { fetchConstructionProgressSaga } from '../../../sagas'

const createGallerySagaRoute = {
  '/projects/:projectId/construction/galleries/add': function* runCreateGallerySaga({
    projectId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const constructionProgressSection = yield call(
      fetchConstructionProgressSaga,
      projectIdNumber
    )
    if (constructionProgressSection) {
      yield fork(watchCreateGallerySaga, projectId)
    }
  }
}

export default createGallerySagaRoute
