import { RSAA } from 'redux-api-middleware'
import objectToFormData from 'json-form-data'
import moment from 'moment'

export const PROJECT_DOCUMENTS_SECTION_REQUEST =
  'PROJECT_DOCUMENTS_SECTION_REQUEST'
export const PROJECT_DOCUMENTS_SECTION_SUCCESS =
  'PROJECT_DOCUMENTS_SECTION_SUCCESS'
export const PROJECT_DOCUMENTS_SECTION_FAILURE =
  'PROJECT_DOCUMENTS_SECTION_FAILURE'

export const fetchSection = projectId => {
  const success = {
    type: PROJECT_DOCUMENTS_SECTION_SUCCESS,
    meta: {
      projectId
    }
  }
  return {
    [RSAA]: {
      endpoint: `api/projects/${projectId}/documents`,
      method: 'get',
      types: [
        PROJECT_DOCUMENTS_SECTION_REQUEST,
        success,
        PROJECT_DOCUMENTS_SECTION_FAILURE
      ]
    }
  }
}

export const PROJECT_DOCUMENTS_SECTION_SUBMIT_REQUEST =
  'PROJECT_DOCUMENTS_SECTION_SUBMIT_REQUEST'
export const PROJECT_DOCUMENTS_SECTION_SUBMIT_SUCCESS =
  'PROJECT_DOCUMENTS_SECTION_SUBMIT_SUCCESS'
export const PROJECT_DOCUMENTS_SECTION_SUBMIT_FAILURE =
  'PROJECT_DOCUMENTS_SECTION_SUBMIT_FAILURE'

export const submit = (projectId, documentsSection) => {
  const meta = {
    projectId,
    documentsSection
  }
  const request = {
    type: PROJECT_DOCUMENTS_SECTION_SUBMIT_REQUEST,
    meta
  }
  const success = {
    type: PROJECT_DOCUMENTS_SECTION_SUBMIT_SUCCESS,
    meta
  }
  const failure = {
    type: PROJECT_DOCUMENTS_SECTION_SUBMIT_FAILURE,
    meta
  }

  const { documents, ...section } = documentsSection
  const formData = objectToFormData(section)

  return {
    [RSAA]: {
      endpoint: `api/projects/${projectId}/documents`,
      method: 'put',
      headers: {},
      body: formData,
      types: [request, success, failure]
    }
  }
}

export const PROJECT_DOCUMENTS_SECTION_PIN_IMAGE_META_REQUEST =
  'PROJECT_DOCUMENTS_SECTION_PIN_IMAGE_META_REQUEST'
export const PROJECT_DOCUMENTS_SECTION_PIN_IMAGE_META_SUCCESS =
  'PROJECT_DOCUMENTS_SECTION_PIN_IMAGE_META_SUCCESS'
export const PROJECT_DOCUMENTS_SECTION_PIN_IMAGE_META_FAILURE =
  'PROJECT_DOCUMENTS_SECTION_PIN_IMAGE_META_FAILURE'

export const fetchPinImageMetaData = (projectId, imageType, url) => {
  const meta = {
    projectId,
    imageType
  }
  const success = {
    type: PROJECT_DOCUMENTS_SECTION_PIN_IMAGE_META_SUCCESS,
    meta
  }
  const failure = {
    type: PROJECT_DOCUMENTS_SECTION_PIN_IMAGE_META_FAILURE,
    meta
  }

  return {
    [RSAA]: {
      endpoint: url,
      method: 'get',
      types: [
        PROJECT_DOCUMENTS_SECTION_PIN_IMAGE_META_REQUEST,
        success,
        failure
      ]
    }
  }
}

export const PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_REQUEST =
  'PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_REQUEST'
export const PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_SUCCESS =
  'PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_SUCCESS'
export const PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_FAILURE =
  'PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_FAILURE'

export const submitFiles = (projectId, documents) => {
  const meta = {
    projectId,
    documents
  }
  const success = {
    type: PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_SUCCESS,
    meta
  }
  const request = {
    type: PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_REQUEST,
    meta
  }
  const failure = {
    type: PROJECT_DOCUMENTS_SECTION_SUBMIT_FILES_FAILURE,
    meta
  }

  const newDocuments = {
    documents: documents.map(document => {
      const date = moment(document.date).format('YYYY-MM-DD')
      return {
        ...document,
        date
      }
    })
  }

  const formData = JSON.stringify(newDocuments)

  return {
    [RSAA]: {
      endpoint: `api/projects/${projectId}/documents/files`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json-patch+json'
      },
      body: formData,
      types: [request, success, failure]
    }
  }
}

export const PROJECT_DOCUMENTS_SECTION_SUBMIT_FILE_REQUEST =
  'PROJECT_DOCUMENTS_SECTION_SUBMIT_FILE_REQUEST'
export const PROJECT_DOCUMENTS_SECTION_SUBMIT_FILE_SUCCESS =
  'PROJECT_DOCUMENTS_SECTION_SUBMIT_FILE_SUCCESS'
export const PROJECT_DOCUMENTS_SECTION_SUBMIT_FILE_FAILURE =
  'PROJECT_DOCUMENTS_SECTION_SUBMIT_FILE_FAILURE'

export const submitFile = (projectId, id, file) => {
  const meta = {
    projectId,
    id,
    file
  }
  const success = {
    type: PROJECT_DOCUMENTS_SECTION_SUBMIT_FILE_SUCCESS,
    meta
  }
  const failure = {
    type: PROJECT_DOCUMENTS_SECTION_SUBMIT_FILE_FAILURE,
    meta
  }
  const request = {
    type: PROJECT_DOCUMENTS_SECTION_SUBMIT_FILE_REQUEST,
    meta
  }
  var formData = new FormData()
  formData.append('file', file)

  return {
    [RSAA]: {
      endpoint: `api/projects/${projectId}/documents/${id}`,
      method: 'put',
      headers: {},
      body: formData,
      types: [request, success, failure]
    }
  }
}

export const PROJECT_DOCUMENTS_SECTION_DOCUMENT_META_REQUEST =
  'PROJECT_DOCUMENTS_SECTION_DOCUMENT_META_REQUEST'
export const PROJECT_DOCUMENTS_SECTION_DOCUMENT_META_SUCCESS =
  'PROJECT_DOCUMENTS_SECTION_DOCUMENT_META_SUCCESS'
export const PROJECT_DOCUMENTS_SECTION_DOCUMENT_META_FAILURE =
  'PROJECT_DOCUMENTS_SECTION_DOCUMENT_META_FAILURE'

export const fetchDocument = (projectId, document) => {
  const meta = {
    projectId,
    document
  }
  const success = {
    type: PROJECT_DOCUMENTS_SECTION_DOCUMENT_META_SUCCESS,
    meta
  }
  const failure = {
    type: PROJECT_DOCUMENTS_SECTION_DOCUMENT_META_FAILURE,
    meta
  }

  return {
    [RSAA]: {
      endpoint: document.file.metaUrl,
      method: 'get',
      types: [PROJECT_DOCUMENTS_SECTION_DOCUMENT_META_REQUEST, success, failure]
    }
  }
}
