import React from 'react'
import ParameterButtons from './ParameterButtons'
import { ArrayFormField } from '../../../../../../../../components/Form'
import { required } from '../../../../../../../../../../components/Validation'
import { FormField } from '../../../../../../../../../../components/Form'
import { DocumentUploadFormField } from '../../../../../../../../components'
import DatePicker from './DatePicker'

const DocumentArrayFormField = ({ form }) => {
  return (
    <ArrayFormField
      name="documents"
      header={({ fields, index }) => {
        return (
          <>
            <h4>Документ {index + 1}</h4>
            <ParameterButtons fields={fields} index={index} />
          </>
        )
      }}
    >
      {({ name }) => {
        return [
          <div className="row" key="file">
            <div className="col formField">
              <DocumentUploadFormField
                validate={required}
                form={form}
                name={`${name}.file`}
              />
            </div>
            <div className="col formField">
              <DatePicker
                validate={required}
                name={`${name}.date`}
                label="Дата"
                form={form}
              />
            </div>
          </div>,
          <div className="row" key="name">
            <div className="col formField">
              <FormField
                name={`${name}.name`}
                validate={required}
                type="text"
                label="Название документа"
              />
            </div>
          </div>
        ]
      }}
    </ArrayFormField>
  )
}

export default DocumentArrayFormField
