import {
  PROJECT_LOCATION_EDIT_OBJECT_SUBMIT_SUCCESS,
  PROJECT_LOCATION_EDIT_OBJECT_SUBMIT_FAILURE
} from '../actions'
import updateProject from '../../../../../reducers/updateProject'

const updateObject = reducer =>
  updateProject((project, action) => {
    const { objectId } = action.meta
    const objects = project.locationSection.infrastructureObjects.map(
      locationObject =>
        locationObject.id === objectId
          ? { ...locationObject, ...reducer(locationObject, action) }
          : locationObject
    )

    return {
      ...project,
      locationSection: {
        ...project.locationSection,
        infrastructureObjects: objects
      }
    }
  })

const editObjectReducer = {
  [PROJECT_LOCATION_EDIT_OBJECT_SUBMIT_SUCCESS]: updateObject(
    (_locationObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_LOCATION_EDIT_OBJECT_SUBMIT_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  }
}

export default editObjectReducer
