import { default as editAdvantageReducer } from './editAdvantageReducer'
import { default as createImageSetReducer } from '../pages/CreateImageSet/reducers'
import { default as editImageSetReducer } from '../pages/EditImageSet/reducers'

export { default as updateAdvantage } from './updateAdvantage'

export default {
  ...editAdvantageReducer,
  ...createImageSetReducer,
  ...editImageSetReducer
}
