import { LOCATION_CHANGE } from 'connected-react-router'
import { takeLatest, take, put } from 'redux-saga/effects'
import { PROJECT_GENERAL_SECTION_SUBMIT_SUCCESS, refresh } from '../actions'

function* refreshGeneralSectionSaga(action) {
  const {
    meta: { projectId, generalSection }
  } = action
  yield take(LOCATION_CHANGE)
  yield put(refresh(projectId, generalSection))
}

function* watchGeneralSection() {
  yield takeLatest(
    PROJECT_GENERAL_SECTION_SUBMIT_SUCCESS,
    refreshGeneralSectionSaga
  )
}

export default watchGeneralSection
