import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-focus'
import {
  formWithError,
  latinAndNumbers,
  password,
  repeatPassword,
  validate,
  required,
  email
} from '../../../../../../../components/Validation'
import { SubmitButton } from '../../../../../components/Buttons'
import { FormField } from '../../../../../../../components/Form'
import Link from 'react-router-dom/es/Link'
import SelectField from '../../../../../components/Form/SelectField'

const focusOnErrors = createDecorator()

const EditUserForm = props => {
  const { user, updateUser: onSubmit } = props
  return (
    <Form
      initialValues={user}
      mutators={{
        ...arrayMutators
      }}
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      render={({
        handleSubmit,
        pristine,
        submitSucceeded,
        dirtySinceLastSubmit,
        form
      }) => {
        return (
          <div className="editUserForm pageWrapper">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col">
                  <Link className="formField__back" to="/users">
                    Назад
                  </Link>
                </div>
              </div>
              <hr className="formField__separator" />
              <div className="row">
                <div className="col">
                  <h4>Пользователь</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-4 formField">
                  <div>
                    <SelectField
                      name="role"
                      form={form}
                      options={[
                        {
                          label: '',
                          value: null
                        },
                        {
                          label: 'Администратор',
                          value: 'admin'
                        },
                        {
                          label: 'Контент-менеджер',
                          value: 'content'
                        },
                        {
                          label: 'CrmBotUser',
                          value: 'crmBotUser'
                        }
                      ]}
                      label="Роль"
                      placeholder="Роль"
                    />
                  </div>
                </div>
                <div className="col-4 formField">
                  <div>
                    <FormField
                      name="email"
                      type="text"
                      label="Email"
                      validate={validate(required, email)}
                    />
                  </div>
                </div>
                <div className="col-4 formField">
                  <div>
                    <FormField
                      name="login"
                      type="text"
                      label="Логин"
                      validate={validate(required, latinAndNumbers)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 formField">
                  <div>
                    <FormField
                      name="firstName"
                      type="text"
                      label="Имя"
                      validate={required}
                    />
                  </div>
                </div>
                <div className="col-6 formField">
                  <div>
                    <FormField
                      name="lastName"
                      type="text"
                      label="Фамилия"
                      validate={required}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h4>Смена пароля</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-6 formField">
                  <div>
                    <FormField
                      name="password"
                      type="password"
                      label="Новый пароль"
                      validate={password}
                    />
                  </div>
                </div>
                <div className="col-6 formField">
                  <div>
                    <FormField
                      name="repeatPassword"
                      type="password"
                      label="Новый пароль еще раз"
                      validate={repeatPassword(form.getState().values.password)}
                    />
                  </div>
                </div>
              </div>
              <SubmitButton
                pristine={pristine}
                submitSucceeded={submitSucceeded}
                dirtySinceLastSubmit={dirtySinceLastSubmit}
              />
            </form>
          </div>
        )
      }}
    />
  )
}

EditUserForm.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired
}

export default formWithError(EditUserForm)
