import { call, put, take } from 'redux-saga/effects'
import { fetchObject, PROJECT_PUBLIC_SPACES_OBJECT_SUCCESS } from '../actions'
import fetchPublicSpacesTabSaga from '../../../sagas/fetchPublicSpacesTabSaga'

function* fetchObjectSaga(projectId, tabId, objectId) {
  const publicSpacesTab = yield call(fetchPublicSpacesTabSaga, projectId, tabId)
  if (publicSpacesTab) {
    yield put(fetchObject(projectId, tabId, objectId))
    const publicSpacesObject = yield take(PROJECT_PUBLIC_SPACES_OBJECT_SUCCESS)
    if (publicSpacesObject) {
      return publicSpacesObject.payload
    }
  }
  return null
}

export default fetchObjectSaga
