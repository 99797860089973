import { fork, take, race } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import fetchPublicationsSagaOnce from './fetchPublicationsSagaOnce'
import {
  PUBLICATIONS_SUCCESS,
  PUBLICATIONS_FAILURE,
  PUBLICATIONS_REFRESH_SUCCESS,
  PUBLICATIONS_HAS_CHANGES_SUCCESS
} from '../actions'

const publicationsSagaRoute = {
  '/publications': function* runFetchPublicationsSaga() {
    yield fork(fetchPublicationsSagaOnce)
    yield race({
      success: take(PUBLICATIONS_SUCCESS),
      error: take(PUBLICATIONS_FAILURE)
    })

    yield race({
      refreshSuccess: take(PUBLICATIONS_REFRESH_SUCCESS),
      hasChangesSuccess: take(PUBLICATIONS_HAS_CHANGES_SUCCESS),
      timeout: delay(5000)
    })

    while (true) {
      yield delay(5000)
      yield fork(fetchPublicationsSagaOnce)
      yield race({
        success: take(PUBLICATIONS_SUCCESS),
        error: take(PUBLICATIONS_FAILURE)
      })
    }
  }
}

export default publicationsSagaRoute
