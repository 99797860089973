import { call } from 'redux-saga/effects'
import { fetchConstructionProgressSaga } from '../../../sagas'

function* fetchGallerySaga(projectId, galleryId) {
  const constructionProgressSection = yield call(
    fetchConstructionProgressSaga,
    projectId
  )
  if (constructionProgressSection) {
    const { galleries = [] } = constructionProgressSection
    const gallery = galleries.find(t => t.id === galleryId)
    if (gallery) {
      return gallery
    }
  }

  return null
}

export default fetchGallerySaga
