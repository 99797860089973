import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ProjectConsumer } from '../../../ProjectContext'
import ArchitectureSectionForm from './ArchitectureSectionForm'
import CreateTabForm from '../pages/CreateTab'
import EditTabForm from '../pages/EditTab'
import CreateArchitectureType from '../pages/EditTab/pages/CreateArchitectureType'
import EditArchitectureType from '../pages/EditTab/pages/EditArchitectureType'
import CreateImageSet from '../pages/EditTab/pages/EditArchitectureType/pages/CreateImageSet'
import EditImageSet from '../pages/EditTab/pages/EditArchitectureType/pages/EditImageSet'

const ArchitectureSectionPage = props => {
  const {
    architectureSection,
    match: { url },
    submit
  } = props

  return (
    <ProjectConsumer>
      {project => {
        const projectId = project.id
        return (
          <Switch>
            <Route
              exact
              path={`${url}/tabs`}
              render={() => {
                return <CreateTabForm projectId={projectId} />
              }}
            />
            <Route
              exact
              path={`${url}/tabs/:id(\\d+)`}
              render={({ match: { params } }) => {
                const tabId = parseInt(params.id, 10)
                const tab = architectureSection.tabs.find(t => t.id === tabId)
                if (!tab) {
                  return <Redirect to="/404" />
                }
                return (
                  <EditTabForm projectId={projectId} tab={tab} tabId={tabId} />
                )
              }}
            />
            <Route
              exact
              path={`${url}/tabs/:id(\\d+)/architecture-type`}
              render={({ match: { params } }) => {
                const tabId = parseInt(params.id, 10)
                const tab = architectureSection.tabs.find(t => t.id === tabId)
                if (!tab) {
                  return <Redirect to="/404" />
                }
                return (
                  <CreateArchitectureType
                    projectId={projectId}
                    tab={tab}
                    tabId={tabId}
                  />
                )
              }}
            />
            <Route
              exact
              path={`${url}/tabs/:id(\\d+)/architecture-type/:objectId(\\d+)`}
              render={({ match: { params } }) => {
                const tabId = parseInt(params.id, 10)
                const tab = architectureSection.tabs.find(t => t.id === tabId)
                const objectId = parseInt(params.objectId, 10)
                const type = tab.objects.find(t => t.id === objectId)
                return (
                  <EditArchitectureType
                    projectId={projectId}
                    tab={tab}
                    tabId={tabId}
                    type={type}
                    objectId={objectId}
                  />
                )
              }}
            />
            <Route
              exact
              path={`${url}/tabs/:tabId(\\d+)/architecture-type/:objectId(\\d+)/images/add`}
              render={({ match: { params } }) => {
                const tabId = parseInt(params.tabId, 10)
                const tab = architectureSection.tabs.find(t => t.id === tabId)
                const objectId = parseInt(params.objectId, 10)
                const type = tab.objects.find(t => t.id === objectId)
                if (!type) {
                  return <Redirect to="/404" />
                }

                return (
                  <CreateImageSet
                    projectId={projectId}
                    tab={tab}
                    tabId={tabId}
                    type={type}
                    objectId={objectId}
                  />
                )
              }}
            />
            <Route
              exact
              path={`${url}/tabs/:tabId(\\d+)/architecture-type/:objectId(\\d+)/images/:id`}
              render={({ match: { params } }) => {
                const tabId = parseInt(params.tabId, 10)
                const tab = architectureSection.tabs.find(t => t.id === tabId)
                let objectId, object, imageId, imageSet
                if (tab) {
                  objectId = parseInt(params.objectId, 10)
                  object = tab.objects.find(t => t.id === objectId)
                  if (object && object.imageSets) {
                    imageId = parseInt(params.id, 10)
                    imageSet = object.imageSets.find(t => t.id === imageId)
                  }
                }

                return (
                  <EditImageSet
                    projectId={projectId}
                    tab={tab}
                    tabId={tabId}
                    object={object}
                    objectId={objectId}
                    imageSet={imageSet}
                    id={imageId}
                    backUrl={`${url}/tabs/${tabId}/architecture-type/${objectId}`}
                  />
                )
              }}
            />
            <ArchitectureSectionForm
              architectureSection={architectureSection}
              projectId={projectId}
              submit={submit}
            />
          </Switch>
        )
      }}
    </ProjectConsumer>
  )
}

ArchitectureSectionPage.propTypes = {
  architectureSection: PropTypes.shape({
    sectionName: PropTypes.string.isRequired,
    infrastructureObjects: PropTypes.arrayOf.isRequired
  }).isRequired,
  projectId: PropTypes.number.isRequired
}

export default ArchitectureSectionPage
