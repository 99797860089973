import React from 'react'
import PropTypes from 'prop-types'
import getDisplayName from 'recompose/getDisplayName'
import { Field } from 'react-final-form'
import FormControl from './FormControl'

const createFormField = getProps => WrappedComponent => {
  const WrappedComponentFormField = ({
    name,
    form,
    validate,
    label,
    className = 'formField__control form-control form-control-sm',
    ...rest
  }) => {
    return (
      <Field name={name} validate={validate}>
        {({ input, meta }) => {
          const props = getProps({
            ...rest,
            form,
            name,
            input
          })
          const components = []
          if (label) {
            components.push(
              <label key="label" className="formField__label">
                {label}
              </label>
            )
          }

          const errorClassName = meta.error ? 'formField__control--error' : ''
          components.push(
            <WrappedComponent
              key="input"
              name={name}
              className={className + ' ' + errorClassName}
              {...rest}
              {...props}
            />
          )
          const error =
            meta.error && meta.touched ? (
              <span className="formField__error" key="error">
                {meta.error}
              </span>
            ) : null
          if (error !== null) {
            components.push(error)
          }
          return components
        }}
      </Field>
    )
  }

  WrappedComponentFormField.displayName = `formField(${getDisplayName(
    WrappedComponent
  )})`

  WrappedComponentFormField.propTypes = {
    ...FormControl.propTypes,
    name: PropTypes.string.isRequired,
    validate: PropTypes.func,
    form: PropTypes.object.isRequired
  }

  return WrappedComponentFormField
}

export default createFormField
