import { fork, call } from 'redux-saga/effects'
import watchProjectCreated from './watchProjectCreated'
import fetchProjectsSaga from '../../../sagas/fetchProjectsSaga'

const createProjectSagaRoute = {
  '/projects/add': function* runAddProjectSaga() {
    const projects = yield call(fetchProjectsSaga)
    if (projects) {
      yield fork(watchProjectCreated)
    }
  }
}

export default createProjectSagaRoute
