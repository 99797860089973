import {
  PROJECT_ARCHITECTURE_CREATE_TAB_SUBMIT_SUCCESS,
  PROJECT_ARCHITECTURE_CREATE_TAB_SUBMIT_FAILURE
} from '../actions'
import updateProject from '../../../../../reducers/updateProject'

const createTabReducer = {
  [PROJECT_ARCHITECTURE_CREATE_TAB_SUBMIT_SUCCESS]: updateProject(
    (project, { payload }) => {
      const section = project.architectureSection
      const tabs = [...section.tabs, payload]
      const newSection = {
        ...section,
        tabs
      }
      const newProject = {
        ...project,
        architectureSection: newSection
      }
      return newProject
    }
  ),
  [PROJECT_ARCHITECTURE_CREATE_TAB_SUBMIT_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  }
}

export default createTabReducer
