import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import './imageSet.css'

const AddImageSet = ({ addImagePath }) => {
  return (
    <Link to={addImagePath} className="addImageSet">
      Добавить изображение
    </Link>
  )
}

AddImageSet.propTypes = {
  addImagePath: PropTypes.string.isRequired
}

export default AddImageSet
