import { default as RatesComponent } from './components/Rates'
import { connect } from 'react-redux'
import { rates } from './reducers/selectors'
import { enhanceWithSpinner } from '../../../../../../components/Spinner'
import { filterChange } from './actions'

const RatesWithSpinner = enhanceWithSpinner(
  ({ data }) => data === null || data === undefined,
  RatesComponent
)

const mapStateToProps = state => {
  return {
    data: rates(state).data,
    filters: rates(state).filters,
    loading: rates(state).loading
  }
}

const Rates = connect(
  mapStateToProps,
  {
    filterChange
  }
)(RatesWithSpinner)

export default Rates
