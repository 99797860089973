import updateAdvantage from '../../../reducers/updateAdvantage'

const updateAdvantageImageSet = reducer =>
  updateAdvantage((advantage, action) => {
    const { id } = action.meta
    const imageSets = advantage.imageSets.map(
      imageSet =>
        imageSet.id === id
          ? { ...imageSet, ...reducer(imageSet, action) }
          : imageSet
    )

    return {
      ...advantage,
      imageSets
    }
  })

export default updateAdvantageImageSet
