import { LOCATION_CHANGE } from 'connected-react-router'
import { takeLatest, take, fork } from 'redux-saga/effects'
import { PROJECT_LOCATION_EDIT_OBJECT_SUBMIT_SUCCESS } from '../actions'
import fetchLocationObjectSaga from './fetchLocationObjectSaga'

function* refreshImages(action) {
  const {
    meta: { projectId, objectId }
  } = action
  yield take(LOCATION_CHANGE)
  yield fork(fetchLocationObjectSaga, projectId, objectId)
}

function* watchImageSetSubmitted() {
  yield takeLatest(PROJECT_LOCATION_EDIT_OBJECT_SUBMIT_SUCCESS, refreshImages)
}

export default watchImageSetSubmitted
