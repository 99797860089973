import { connect } from 'react-redux'
import { About as AboutComponent } from './components/About'
import { about } from './reducers/selectors'
import { enhanceWithSpinner } from '../../../../components'
import { submitAbout } from './actions'

const AboutWithSpinner = enhanceWithSpinner(
  ({ about }) => about === null || about === undefined,
  AboutComponent
)

const mapStateToProps = state => {
  return {
    about: about(state)
  }
}

const About = connect(
  mapStateToProps,
  {
    submit: submitAbout
  }
)(AboutWithSpinner)

export default About
