import PropTypes from 'prop-types'
import ImageSet from './ImageSet'
import { createFormField } from '../../../../components/Form'

const getProps = ({ form, name, input, onRemove }) => {
  const {
    value: { position, id, ...images }
  } = input
  return {
    position,
    id,
    images,
    name,
    onRemove: () => {
      form.change(name, null)
      onRemove(id)
    }
  }
}
const ImageSetFormField = createFormField(getProps)(ImageSet)

ImageSetFormField.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired
}

export default ImageSetFormField
