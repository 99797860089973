import React from 'react'
import PropTypes from 'prop-types'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router'
import { Home } from './pages/Home'
import Login from './pages/Login/components'
import { PrivateRoute, NoMatch } from './components'

const App = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <PrivateRoute path="/" component={Home} />
        <Route component={NoMatch} />
      </Switch>
    </ConnectedRouter>
  )
}

App.propTypes = {
  history: PropTypes.object
}

export default App
