import { default as editTabSagaRoute } from './editTabSagaRoute'
import createArchitectureTypeSagaRoute from '../pages/CreateArchitectureType/sagas'
import editArchitectureTypeSagaRoute from '../pages/EditArchitectureType/sagas'

export {
  default as watchArchitectureTabSubmitted
} from './watchArchitectureTabSubmitted'

export default {
  ...editTabSagaRoute,
  ...editArchitectureTypeSagaRoute,
  ...createArchitectureTypeSagaRoute
}
