import { fork, call } from 'redux-saga/effects'
import fetchAboutSaga from './fetchAboutSaga'
import editImageSetSagaRoute from '../pages/EditImageSet/sagas'
import fetchAboutRootImages from './fetchAboutRootImages'

const aboutSagaRoute = {
  '/about': function* runAboutSaga() {
    const about = yield call(fetchAboutSaga)
    if (about) {
      yield fork(fetchAboutRootImages)
    }
  },
  ...editImageSetSagaRoute
}

export default aboutSagaRoute
