import {
  PROJECT_LOCATION_SECTION_SUCCESS,
  PROJECT_LOCATION_SECTION_FAILURE,
  PROJECT_LOCATION_SECTION_SUBMIT_SUCCESS,
  PROJECT_LOCATION_SECTION_SUBMIT_FAILURE,
  PROJECT_LOCATION_SECTION_PIN_IMAGE_META_SUCCESS,
  PROJECT_LOCATION_SECTION_RESET
} from '../actions'
import createSectionReducer from '../../../reducers/createSectionReducer'
import { setPinImageUrl } from './pinImage'
import createObjectReducer from '../pages/CreateObject/reducers'
import editObjectReducer from '../pages/EditObject/reducers'
import updateProject from '../../../reducers/updateProject'

const locationSectionReducer = createSectionReducer(
  'locationSection',
  [PROJECT_LOCATION_SECTION_SUCCESS, PROJECT_LOCATION_SECTION_FAILURE],
  [
    PROJECT_LOCATION_SECTION_SUBMIT_SUCCESS,
    PROJECT_LOCATION_SECTION_SUBMIT_FAILURE
  ]
)

export default {
  ...locationSectionReducer,
  [PROJECT_LOCATION_SECTION_PIN_IMAGE_META_SUCCESS]: (state, action) => {
    return setPinImageUrl(state, action)
  },
  [PROJECT_LOCATION_SECTION_RESET]: updateProject(project => {
    const { locationSection, ...rest } = project
    return { ...rest }
  }),
  ...createObjectReducer,
  ...editObjectReducer
}
