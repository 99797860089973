import { fork, call } from 'redux-saga/effects'
import watchCreateObjectSaga from './watchCreateObjectSaga'
import fetchAccomplishmentSectionSaga from '../../../sagas/fetchAccomplishmentSectionSaga'

const createTabSagaRoute = {
  '/projects/:projectId/accomplishment/tabs': function* runCreateAccomplishmentTabSaga({
    projectId
  }) {
    const projectIdNumber = parseInt(projectId, 10)
    const accomplishmentSection = yield call(
      fetchAccomplishmentSectionSaga,
      projectIdNumber
    )
    if (accomplishmentSection) {
      yield fork(watchCreateObjectSaga, projectIdNumber)
    }
  }
}

export default createTabSagaRoute
