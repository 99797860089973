import {
  PROJECT_ACCOMPLISHMENT_EDIT_ACCOMPLISHMENT_TYPE_SUBMIT_SUCCESS,
  PROJECT_ACCOMPLISHMENT_EDIT_ACCOMPLISHMENT_TYPE_SUBMIT_FAILURE,
  PROJECT_ACCOMPLISHMENT_OBJECT_SUCCESS,
  PROJECT_ACCOMPLISHMENT_OBJECT_FAILURE
} from '../actions'
import updateAccomplishmentType from './updateAccomplishmentType'
import createImageSetReducer from '../pages/CreateImageSet/reducers'
import editImageSetReducer from '../pages/EditImageSet/reducers'

const editAccomplishmentTypeReducer = {
  [PROJECT_ACCOMPLISHMENT_EDIT_ACCOMPLISHMENT_TYPE_SUBMIT_SUCCESS]: updateAccomplishmentType(
    (_accomplishmentObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_ACCOMPLISHMENT_EDIT_ACCOMPLISHMENT_TYPE_SUBMIT_FAILURE]: (
    state,
    action
  ) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  [PROJECT_ACCOMPLISHMENT_OBJECT_SUCCESS]: updateAccomplishmentType(
    (_accomplishmentObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_ACCOMPLISHMENT_OBJECT_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  ...createImageSetReducer,
  ...editImageSetReducer
}

export default editAccomplishmentTypeReducer
