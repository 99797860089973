import { take, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_IMAGE_SET_SUBMIT_SUCCESS } from '../actions'

function* watchImageSetCreatedSaga(projectId, galleryId) {
  while (true) {
    yield take(
      PROJECT_CONSTRUCTION_PROGRESS_CREATE_GALLERY_IMAGE_SET_SUBMIT_SUCCESS
    )
    yield put(
      push(`/projects/${projectId}/construction/galleries/${galleryId}`)
    )
  }
}

export default watchImageSetCreatedSaga
