import { PUSH_LIST_SUCCESS } from '../actions'
import addPushReducer from '../pages/AddPushForm/reducers'

const initialState = {
  data: null,
  hasMore: false
}

const mergedReducer = {
  [PUSH_LIST_SUCCESS]: (state = initialState, action) => {
    return {
      data: state.data
        ? [...state.data, ...action.payload.data]
        : action.payload.data,
      hasMore: action.payload.hasMore
    }
  },
  ...addPushReducer
}

const pushesReducer = (state = initialState, action) => {
  let actionReducer = mergedReducer[action.type]
  if (actionReducer) {
    return actionReducer(state, action)
  }

  return state
}

export default pushesReducer
