import React from 'react'
import { Line } from 'react-chartjs-2'
import COLORS from '../colors'
import moment from 'moment'

const StyledLine = ({
  colorsArray = COLORS,
  data,
  labels = {},
  tooltipWithStars = false
}) => {
  const lineSettings = {
    fill: false,
    lineTension: 0.1,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointHoverRadius: 5,
    pointRadius: 2,
    pointHitRadius: 5
  }

  const lines = Object.keys(data).map((key, index) => {
    const color = data[key][0].color
    return {
      ...lineSettings,
      backgroundColor: color || colorsArray[index],
      borderColor: color || colorsArray[index],
      pointBorderColor: color || colorsArray[index],
      pointBackgroundColor: color || colorsArray[index],
      pointHoverBackgroundColor: color || colorsArray[index],
      pointHoverBorderColor: color || colorsArray[index],
      label: labels[key] ? labels[key] : key,
      data: data[key]
    }
  })

  return (
    <Line
      data={{ datasets: lines }}
      options={{
        animation: {
          duration: 0 // general animation time
        },
        tooltips: {
          backgroundColor: 'rgba(255,255,255,1)',
          titleFontColor: 'black',
          titleFontStyle: 'normal',
          bodyFontSize: 16,
          bodyFontStyle: 'bold',
          bodyFontColor: 'black',
          xPadding: 13,
          yPadding: 13,
          borderColor: 'rgba(0,0,0,0.1)',
          borderWidth: 1,
          enabled: true,
          displayColors: false,
          callbacks: {
            title: function(tooltipItem) {
              let title = tooltipItem[0].xLabel || ''
              title = moment(title).format('LL')
              return title
            },
            label: function(tooltipItem) {
              const label = tooltipItem.yLabel
              let stars = ''
              for (let i = 0; i < label; i++) {
                stars += '★'
              }
              return tooltipWithStars ? `${stars} ${label}` : `${label}`
            },
            labelTextColor: function(tooltipItem, chart) {
              return (
                chart.config.data.datasets[tooltipItem.datasetIndex]
                  .borderColor || '#000'
              )
            },
            displayColors: false
          }
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              type: 'time'
            }
          ]
        }
      }}
    />
  )
}

export default StyledLine
