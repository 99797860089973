import { USER_LIST_SUCCESS, USER_DELETE_SUCCESS } from '../actions'
import addUserReducer from '../pages/AddUserForm/reducers'
import editUserReducer from '../pages/EditUserForm/reducers'

const initialState = null

const mergedReducer = {
  [USER_LIST_SUCCESS]: (state = initialState, action) => {
    return { users: [...action.payload] }
  },
  [USER_DELETE_SUCCESS]: (state = initialState, action) => {
    return {
      ...state,
      users: state.users.filter(item => item.id !== action.meta.userId)
    }
  },
  ...addUserReducer,
  ...editUserReducer
}

const usersReducer = (state = initialState, action) => {
  let actionReducer = mergedReducer[action.type]
  if (actionReducer) {
    return actionReducer(state, action)
  }

  return state
}

export default usersReducer
