import { put, take } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { CREATE_USER_SUCCESS } from '../actions'

function* watchUserCreated() {
  while (true) {
    const newUser = yield take(CREATE_USER_SUCCESS)
    yield put(push(`/users/${newUser.payload.id}`))
  }
}

export default watchUserCreated
