import { default as editGallerySagaRoute } from './editGallerySagaRoute'
import editImageSetSagaRoute from '../pages/EditImageSet/sagas'
import createImageSetSagaRoute from '../pages/CreateImageSet/sagas'
export { default as fetchGallerySaga } from './fetchGallerySaga'

export default {
  ...createImageSetSagaRoute,
  ...editImageSetSagaRoute,
  ...editGallerySagaRoute
}
