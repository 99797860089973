import {
  PROJECT_PUBLIC_SPACES_EDIT_TAB_SUBMIT_SUCCESS,
  PROJECT_PUBLIC_SPACES_EDIT_TAB_SUBMIT_FAILURE,
  PROJECT_PUBLIC_SPACES_TAB_SUCCESS,
  PROJECT_PUBLIC_SPACES_TAB_FAILURE
} from '../actions'
import createPublicSpacesTypeReducer from '../pages/CreatePublicSpacesType/reducers'
import editPublicSpacesTypeReducer from '../pages/EditPublicSpacesType/reducers'
import updateTab from './updateTab'

const editTabReducer = {
  [PROJECT_PUBLIC_SPACES_EDIT_TAB_SUBMIT_SUCCESS]: updateTab(
    (_publicSpacesObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_PUBLIC_SPACES_EDIT_TAB_SUBMIT_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  [PROJECT_PUBLIC_SPACES_TAB_SUCCESS]: updateTab(
    (_publicSpacesObject, { payload }) => {
      return payload
    }
  ),
  [PROJECT_PUBLIC_SPACES_TAB_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.response
    }
  },
  ...createPublicSpacesTypeReducer,
  ...editPublicSpacesTypeReducer
}

export default editTabReducer
