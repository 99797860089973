import {
  PROJECT_ACCOMPLISHMENT_SECTION_SUCCESS,
  PROJECT_ACCOMPLISHMENT_SECTION_FAILURE,
  PROJECT_ACCOMPLISHMENT_SECTION_SUBMIT_SUCCESS,
  PROJECT_ACCOMPLISHMENT_SECTION_SUBMIT_FAILURE,
  PROJECT_ACCOMPLISHMENT_SECTION_PIN_IMAGE_META_SUCCESS
} from '../actions'
import createSectionReducer from '../../../reducers/createSectionReducer'
import { setPinImageUrl } from './pinImage'
import createTabReducer from '../pages/CreateTab/reducers'
import editTabReducer from '../pages/EditTab/reducers'

const accomplishmentSectionReducer = createSectionReducer(
  'accomplishmentSection',
  [
    PROJECT_ACCOMPLISHMENT_SECTION_SUCCESS,
    PROJECT_ACCOMPLISHMENT_SECTION_FAILURE
  ],
  [
    PROJECT_ACCOMPLISHMENT_SECTION_SUBMIT_SUCCESS,
    PROJECT_ACCOMPLISHMENT_SECTION_SUBMIT_FAILURE
  ]
)

export default {
  ...accomplishmentSectionReducer,
  [PROJECT_ACCOMPLISHMENT_SECTION_PIN_IMAGE_META_SUCCESS]: (state, action) => {
    return setPinImageUrl(state, action)
  },
  ...createTabReducer,
  ...editTabReducer
}
