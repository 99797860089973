import React from 'react'
import PropTypes from 'prop-types'

const createButtonComponent = (btnClass, alt) => {
  const Button = ({
    disabled = false,
    className = `btn btn--noPadding ${btnClass}`,
    type = 'button',
    ...rest
  }) => {
    return (
      <button
        type={type}
        className={className}
        disabled={disabled}
        title={alt}
        {...rest}
      />
    )
  }

  Button.propTypes = {
    onClick: PropTypes.func.isRequired
  }

  return Button
}

export default createButtonComponent
