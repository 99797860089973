import React from 'react'
import PropTypes from 'prop-types'
import FormField from '../FormField'
import Select from '../../Select'

const createOnChange = (form, name) => event => {
  const value = event.target.value
  form.change(name, value)
  form.blur(name)
}

const SelectField = ({ form, name, options, onChange, ...rest }) => {
  return (
    <FormField name={name} {...rest}>
      {({ input, className }) => (
        <Select
          options={options}
          value={input.value}
          onChange={createOnChange(form, name)}
          className={className}
        />
      )}
    </FormField>
  )
}

SelectField.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired
}

export default SelectField
