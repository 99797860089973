import React from 'react'

const RefreshIcon = ({ refresh, isSelected = false }) => {
  return (
    <input
      className="publicationItem__refresh"
      type="checkbox"
      checked={isSelected}
      onChange={refresh}
    />
  )
}

export default RefreshIcon
