import updateTab from '../../../reducers/updateTab'

const updateAccomplishmentType = reducer =>
  updateTab((tab, action) => {
    const { objectId } = action.meta
    const accomplishmentTypes = tab.objects.map(
      accomplishmentType =>
        accomplishmentType.id === objectId
          ? { ...accomplishmentType, ...reducer(accomplishmentType, action) }
          : accomplishmentType
    )

    return {
      ...tab,
      objects: accomplishmentTypes
    }
  })

export default updateAccomplishmentType
