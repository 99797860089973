import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'
import './tabs.css'

const Tabs = () => {
  return (
    <nav className="tabs">
      <NavLink
        to={`/stats/rates/`}
        className="tabs__link"
        activeClassName="tabs__link--active"
      >
        Оценки
      </NavLink>
      <NavLink
        to={`/stats/comments/`}
        className="tabs__link"
        activeClassName="tabs__link--active"
      >
        Комментарии
      </NavLink>
    </nav>
  )
}

export default memo(Tabs)
