import { AppContainer } from 'react-hot-loader'
import { applyMiddleware, compose, createStore } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import createSagaMiddleware from 'redux-saga'
import { apiMiddleware } from 'redux-api-middleware'
import persistState from 'redux-localstorage'
import { createLogger } from 'redux-logger'
import App from './App'
import rootReducer from './reducers'
import saga from './sagas'
import { apiRoot, apiAuthorization } from './middlewares'
import { unregister } from './registerServiceWorker'
import './index.css'

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  connectRouter(history)(rootReducer),
  composeEnhancer(
    applyMiddleware(
      apiRoot,
      apiAuthorization,
      apiMiddleware,
      sagaMiddleware,
      routerMiddleware(history),
      createLogger({
        collapsed: true
      })
    ),
    persistState(['user'])
  )
)

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <App history={history} />
      </Provider>
    </AppContainer>,
    document.getElementById('root')
  )
}

sagaMiddleware.run(saga, history, store.dispatch)
render()
unregister()

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./App', () => {
    render()
  })

  // Reload reducers
  module.hot.accept('./reducers', () => {
    store.replaceReducer(connectRouter(history)(rootReducer))
  })
}
