import {
  fetchSection,
  PROJECT_LOCATION_SECTION_SUCCESS,
  PROJECT_LOCATION_SECTION_FAILURE
} from '../actions'
import createSectionSaga from '../../../sagas/createSectionSaga'
import fetchLocationSectionPinImagesSaga from './fetchLocationSectionPinImagesSaga'

export default createSectionSaga(
  fetchSection,
  [PROJECT_LOCATION_SECTION_SUCCESS, PROJECT_LOCATION_SECTION_FAILURE],
  project => project.locationSection,
  {
    sectionName: 'Расположение',
    infrastructureObjects: []
  },
  fetchLocationSectionPinImagesSaga
)
